// @ts-ignore
import fromKeyLike from 'jose-browser-runtime/dist/browser/jwk/from_key_like';
// @ts-ignore
import parseJwk from 'jose-browser-runtime/dist/browser/jwk/parse';
import { JWK, KeyLike } from 'jose-browser-runtime/types';

import __RUNTIME_CONFIG__ from '../../envConfig';

/**
 * Parses a KeyLike to JWK
 * @param {KeyLike} key KeyLike object going to be converted to JWK
 * @return {Promise<JWK>} JWK parsed from KeyLike object
 */
export const keyLikeToJwk = async (key: KeyLike): Promise<JWK> => {
    const jwkGenerated = await fromKeyLike(key);
    return jwkGenerated;
};

/**
 *  Parses a JWK to KeyLike
 * @param {JWK} jwk JWK going to be parse to KeyLike object
 * @param {boolean} octAsKeyObject Forces a symmetric key to be converted to a KeyObject or CryptoKey
 * @return {Promise<KeyLike>} A KeyLike object parsed from JWK
 */
export const jwkToKeyLike = async (jwk: JWK, octAsKeyObject?: boolean): Promise<KeyLike> => {
    const parsedKey = await parseJwk(
        jwk,
        __RUNTIME_CONFIG__.CRIPTOGRAPHY.PARSING.alg,
        octAsKeyObject
    );
    return parsedKey;
};

/**
 *  Parses a JWK to KeyLike with custom algorithm
 * @param {JWK} jwk JWK going to be parse to KeyLike object
 * @param {boolean} octAsKeyObject Forces a symmetric key to be converted to a KeyObject or CryptoKey
 * @param {string} alg Algorithm standard protocol
 * @return {Promise<KeyLike>} A KeyLike object parsed from JWK
 */
export const jwkToKeyLikeCustomAlg = async (
    jwk: JWK,
    alg?: string,
    octAsKeyObject?: boolean
): Promise<KeyLike> => {
    const parsedKey = await parseJwk(jwk, alg, octAsKeyObject);
    return parsedKey;
};
