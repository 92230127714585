import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import CheckoutPage from '../pages/CheckoutPage/CheckoutPage';
import CheckoutPlusPaymentAppPage from '../pages/CheckoutPlusPaymentAppPage/CheckoutPlusPaymentAppPage';
import CheckoutPlusPaymentPage from '../pages/CheckoutPlusPaymentPage/CheckoutPlusPaymentPage';
import { NotFoundPage } from '../pages/NotFoundPage/NotFoundPage';
import { ECheckoutRoutePaths } from './enum/ECheckoutRoutePaths';
import { NOT_FOUND_ROUTE_PATHS } from './enum/ENotFoundRoutePaths';
import { PrivateRoute } from './PrivateRoute/PrivateRoute';
import { PublicRoute } from './PublicRoute/PublicRoute';

const CheckoutSwitch: FC = () => {
    return (
        <Switch>
            <PublicRoute
                exact
                key={ECheckoutRoutePaths.CHECKOUT_SUCCESS}
                path={ECheckoutRoutePaths.CHECKOUT_SUCCESS}>
                <CheckoutPage />
            </PublicRoute>
            <PublicRoute
                exact
                key={ECheckoutRoutePaths.CHECKOUT_PLUS_PAYMENT}
                path={ECheckoutRoutePaths.CHECKOUT_PLUS_PAYMENT}>
                <CheckoutPlusPaymentPage />
            </PublicRoute>
            <PrivateRoute
                exact
                key={ECheckoutRoutePaths.CHECKOUT_LOGGED_USER_PLUS_PAYMENT}
                path={ECheckoutRoutePaths.CHECKOUT_LOGGED_USER_PLUS_PAYMENT}>
                <CheckoutPlusPaymentPage />
            </PrivateRoute>
            <PrivateRoute
                exact
                key={ECheckoutRoutePaths.CHECKOUT_LOGGED_USER_SUCCESS}
                path={ECheckoutRoutePaths.CHECKOUT_LOGGED_USER_SUCCESS}>
                <CheckoutPage />
            </PrivateRoute>
            <Route
                exact
                path={ECheckoutRoutePaths.CHECKOUT_PLUS_PAYMENT_APP}
                render={() => <CheckoutPlusPaymentAppPage />}
            />
            <Route path={NOT_FOUND_ROUTE_PATHS} exact render={() => <NotFoundPage />} />
        </Switch>
    );
};

export default CheckoutSwitch;
