import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    SquareButton: {
        display: 'flex',
        border: `solid 1px ${theme.palette.grey[500]}`,
        padding: '10px',
        borderRadius: '8px',
        transition: 'all .2s ease-in-out',
        textTransform: 'none',
        '& .MuiTouchRipple-child': {
            backgroundColor: theme.palette.primary.main
        }
    }
}));

export default useStyles;
