import { Box } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { FC } from 'react';

import { IMessageBox } from './interfaces/IMessageBox';
import useStyles from './MessageBoxStyles.material';
const MessageBox: FC<IMessageBox> = ({
    icon,
    classNames = '',
    children,
    iconColor = 'info',
    borderColor = 'error'
}) => {
    const classes = useStyles();
    return (
        <Box className={`${classes.MessageBox} ${classNames} ${borderColor}`}>
            {icon ? icon : <WarningIcon className={`${classes.InfoIcon} ${iconColor}`} />}{' '}
            {children}
        </Box>
    );
};

export default MessageBox;
