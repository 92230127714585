export default interface ISelectedCardProps {
    classNames?: string;
    cardBrand: string;
    cardMaskedNumber: string;
    captionText?: string;
    cardType: string;
    variant?: ESelectedCardVariants;
}

export enum ESelectedCardVariants {
    fill = 'fill',
    outline = 'outline'
}
