import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import CardPaymentDetailPage from '../pages/CardPaymentDetailPage/CardPaymentDetailPage';
import CardSelectPage from '../pages/CardSelectPage/CardSelectPage';
import CardSubscribedPage from '../pages/CardSubscribedPage/CardSubscribedPage';
import CardSubscriptionPage from '../pages/CardSubscriptionPage/CardSubscriptionPage';
import CardValidatePage from '../pages/CardValidatePage/CardValidatePage';
import { NotFoundPage } from '../pages/NotFoundPage/NotFoundPage';
import SelectCardTypePage from '../pages/SelectCardTypePage/SelectCardTypePage';
import { ECardRoutePaths } from './enum/ECardRoutePaths';
import { NOT_FOUND_ROUTE_PATHS } from './enum/ENotFoundRoutePaths';
import { PrivateRoute } from './PrivateRoute/PrivateRoute';

const CardSwitch: FC = () => {
    return (
        <Switch>
            <PrivateRoute
                exact
                key={ECardRoutePaths.CARD_SELECT}
                path={ECardRoutePaths.CARD_SELECT}>
                <CardSelectPage />
            </PrivateRoute>
            <PrivateRoute
                exact
                key={ECardRoutePaths.CARD_SELECT_TYPE}
                path={ECardRoutePaths.CARD_SELECT_TYPE}>
                <SelectCardTypePage />
            </PrivateRoute>
            <PrivateRoute
                exact
                key={ECardRoutePaths.CARD_SUBSCRIBE}
                path={ECardRoutePaths.CARD_SUBSCRIBE}>
                <CardSubscriptionPage />
            </PrivateRoute>
            <PrivateRoute
                exact
                key={ECardRoutePaths.CARD_CONFIRMATION}
                path={ECardRoutePaths.CARD_CONFIRMATION}>
                <CardSubscribedPage />
            </PrivateRoute>
            <PrivateRoute
                exact
                key={ECardRoutePaths.CARD_VALIDATE}
                path={ECardRoutePaths.CARD_VALIDATE}>
                <CardValidatePage />
            </PrivateRoute>
            <PrivateRoute
                exact
                key={ECardRoutePaths.CARD_PAYMENT_DETAIL}
                path={ECardRoutePaths.CARD_PAYMENT_DETAIL}>
                <CardPaymentDetailPage />
            </PrivateRoute>
            <Route path={NOT_FOUND_ROUTE_PATHS} exact render={() => <NotFoundPage />} />
        </Switch>
    );
};

export default CardSwitch;
