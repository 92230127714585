import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    CustomScrollbar: {
        width: '100%',
        '& .simplebar-scrollbar:before':{
            left: '6px',
            right: '0px',
        },
        '&.normal': {
          '& .simplebar-scrollbar:before': {
            background: theme.palette.primary.main,
          },
        },
        '&.line': {
          '& .simplebar-scrollbar:before': {
            background: theme.palette.grey[500],
          },
          '& .simplebar-vertical:after': {
              backgroundColor: theme.palette.grey[500],
              content: '""',
              display: 'block',
              width: '1px',
              height: 'calc(100% - 10px)',
              position: 'absolute',
              right: 2,
              top: 5
          }
        },
        '& .simplebar-scrollbar.simplebar-visible:before': {
            opacity: 1
        }
    }
}));

export default useStyles;
