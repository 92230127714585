import sabbiHttpInstance from '../../instances/sabbi.instance';
import { IChangePassword } from './interfaces/IChangePassword';
import { ILogin } from './interfaces/ILogin';
import { ILogout } from './interfaces/ILogout';
import { IRegister } from './interfaces/IRegister';
import { IValidateDocumentNumber } from './interfaces/IValidateDocumentNumber';

export const loginService = async (data: ILogin, TPA: string) => {
    const response = await sabbiHttpInstance.post(`/user/login`, data);
    return response.data;
};

export const logoutService = async (data: ILogout) => {
    const response = await sabbiHttpInstance.post(`/user/logout`, data);
    return response.data;
};

export const registerService = async (data: IRegister) => {
    const response = await sabbiHttpInstance.post(`/user/register`, data);
    return response.data;
};

export const validateDocumentNumberService = async (data: IValidateDocumentNumber) => {
    const response = await sabbiHttpInstance.post(`/user/validate-document-number`, data);
    return response.data;
};

export const changePasswordService = async (data: IChangePassword) => {
    const response = await sabbiHttpInstance.post(`/user/recovery-password`, data);
    return response.data;
};
