import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    CardRadioButton: {
        display: 'flex',
        border: `solid 1px ${theme.palette.grey[500]}`,
        marginRight: 0,
        padding: '5px .5rem 5px 1rem',
        borderRadius: '12px',
        backgroundColor: theme.palette.common.white,
        minHeight: '52px',
        maxHeight: '52px',
        '&.checked': {
            border: `solid 1px rgba(0, 0, 0, 0)`,
            boxShadow: `0px 0px 0px 2px ${theme.palette.primary.main}`
        },
        '& .MuiFormControlLabel-label': {
            width: '100%'
        }
    },
    CardTypeRadioButton: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid transparent',
        borderRadius: '100px',
        boxShadow: '-2px 2px 12px -2px rgba(0, 0, 0, 0.25)',
        color: theme.palette.primary.main,
        display: 'flex',
        marginRight: 0,
        maxHeight: '56px',
        minHeight: '56px',
        padding: '5px .7rem 5px 1.3rem',
        transition: 'background-color .2s ease-in-out',
        '&.checked': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[200],
            border: `1px solid ${theme.palette.grey[400]}`
        },
        '& .MuiFormControlLabel-label': {
            width: '100%'
        }
    },
    RadioButtonContainer: {
        position: 'relative',
        maxHeight: '145px',
        maxWidth: '100%'
    },
    GreyBadge: {
      '& .MuiBadge-badge': {
        backgroundColor: theme.palette.grey[200],
        border: `1px solid ${theme.palette.grey[400]}`,
        fontFamily: 'ceraRoundProMedium',
        
      },
    },
    DisabledBadge: {
      marginRight: '20px',
    },
    TypeBadge: {
      width: '45px',
      '& .MuiBadge-badge': {
        color: theme.palette.common.black,
        border: `1px solid ${theme.palette.grey[400]}`, 
      },
      ['@media (max-width:380px)']: {
        display: 'none',
      }
    },
    DeleteButton: {
        position: 'absolute',
        top: 'calc(50% - 12px)',
        right: '55px'
    },
    RadioBoxButton: {
        display: 'flex',
        alignItems: 'center'
    },
    TypeRadioBoxButton: {
        display: 'flex',
        alignItems: 'center'
    },
    CreditCardImage: {
        marginRight: '.8rem',
    },
    CreditCardDiv: {
      width: '40px',
      marginRight: '14px',
    },
    CardBrand: {
        marginRight: '1rem',
        fontSize: '.9rem',
        minWidth: '100px'
    },
    Dots: {
        fontSize: '35px',
        letterSpacing: '6px',
        lineHeight: 0,
        color: theme.palette.grey[400],
        marginRight: '10px',
    },
    MaskedCard: {
      fontSize: '16px'
    },
    RadioMaterial: {
        marginLeft: 'auto'
    }
}));

export default useStyles;
