import { Button, Grid, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import SabbiLogo from '../../assets/logo/sabbi.svg';
import SabbiLogoNoBorderSrc from '../../assets/logo/sabbi-noborder.svg';
import AlertBar from '../../components/AlertBar/AlertBar';
import ContentBox from '../../components/ContentBox/ContentBox';
import { FormContext } from '../../components/FormsContext/FormContext/FormContext';
import OtpInput from '../../components/FormsContext/OtpInput/OtpInput';
import ProgressCircular from '../../components/FormsContext/ProgressCircular/ProgressCircular';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import HyperLink from '../../components/HyperLink/HyperLink';
import MaskedUserPhone from '../../components/MaskedUserPhone/MaskedUserPhone';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useTpa } from '../../context/TPAContext/tpa';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { EPasswordRoutePaths } from '../../routes/enum/EPasswordRoutePaths';
import { EUserRoutePaths } from '../../routes/enum/EUserRoutePaths';
import { IGenerateNewOtp } from '../../services/sabbi/otp/interfaces/IGenerateNewOtp';
import { generateNewOtpService, validateOtpService } from '../../services/sabbi/otp/otp';
import { cancelTRXandReturnTrade, maskUserTarget } from '../../utils/clientHelpers';
import { generateEncryptedOtp } from '../../utils/cryptography/cypher';
import { IGenerateEncryptedOtp } from '../../utils/cryptography/cypher/interfaces/IGenerateEncryptedOtp';
import { extractPayloadFromJWT } from '../../utils/cryptography/JWManipulation/jwt';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { EFlowIndicator } from '../../utils/enums/EFlowIndicator';
import { handleErrorMessage } from '../../utils/errors';
import { IErrorValues } from '../../utils/errors/interfaces/IErrorValues';
import { joinOtpValues } from '../../utils/inputHelpers';
import { getRemainingSeconds } from '../../utils/moment';
import IModalValuesState from './interfaces/IModalValuesState';
import { IOtpValidationLocationState } from './interfaces/IOtpValidationLocationState';
import useStyles from './OtpValidationPageStyles.material';

type TPOtpInfo = {
    cipher: string;
    exp: number;
    mediaSendDestinationObsfuscated: string;
    mediaSendType: string;
};

const OtpValidationPage = () => {
    const [modalValues, setModalValues] = useState<IModalValuesState>({
        cancelModal: false
    });
    const [userTarget, setUserTarget] = useState<string>();
    const [isError, setIsError] = useState<boolean>(false);
    const [errorValues, setErrorValues] = useState<IErrorValues>({
        title: '',
        visible: false
    });
    const location = useLocation<IOtpValidationLocationState>();
    const [TPOTP, setTPOTP] = useState<string>(location?.state?.TPOTP || '');
    const [seconds, setSeconds] = useState<number>(0);
    const classes = useStyles();
    const history = useHistory();
    const authContext = useAuth();
    const backdrop = useBackdrop();
    const tpaContext = useTpa();
    const methods = useForm<FieldValues>({ mode: 'all', defaultValues: {} });
    const { handleErrorRedirection, shouldRedirect } = useRedirection();

    useEffect(() => {
        if (!validateLocationStateData(location.state)) {
            if (!authContext.user?.te) {
                history.replace(ELoginRoutePaths.LOGIN);
            } else {
                history.replace(ECardRoutePaths.CARD_SELECT);
            }
        } else {
            let userPhone;
            const TPOTPInfo: TPOtpInfo = extractPayloadFromJWT(location.state.TPOTP);
            const newdate = new Date().valueOf();
            const rSeconds = getRemainingSeconds(newdate, TPOTPInfo.exp * 1000);
            setSeconds(rSeconds);
            if (location.state.flow == EFlowIndicator.REGISTER) {
                userPhone = location.state.userContactData?.cellphone as string;
            } else if (location.state.flow == EFlowIndicator.RECOVER_PASSWORD) {
                userPhone = TPOTPInfo.mediaSendDestinationObsfuscated;
            }
            if (userPhone) {
                const hiddenTarget = maskUserTarget(userPhone, 5, '*');
                setUserTarget(hiddenTarget);
            }
        }
    }, []);
    const validateLocationStateData = (obj: IOtpValidationLocationState) => {
        if (obj && obj.flow in EFlowIndicator) {
            return 'TPOTP' in obj && 'flow' in obj;
        }
        return false;
    };
    const getRedirectUrlTrade = async () => {
        backdrop.openBackdrop();
        try {
            await cancelTRXandReturnTrade(tpaContext.tokenInfo.tpa as string);
        } catch (error) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };

    const showModal = (modalName: keyof IModalValuesState) => {
        setModalValues({
            ...modalValues,
            [modalName]: true
        });
    };
    const closeModals = () => {
        setModalValues({
            cancelModal: false
        });
    };

    const onSubmit: SubmitHandler<any> = async (data) => {
        try {
            backdrop.openBackdrop();
            const otpValues = joinOtpValues(data.otpFieldArray);
            const cypherOtpData: IGenerateEncryptedOtp = {
                TPA: tpaContext.tokenInfo.tpa as string,
                OTPCode: otpValues
            };
            const encriptedOtpCode = await generateEncryptedOtp(cypherOtpData);
            const userDevice = getDataDevice();
            const validateOtpRequest = {
                TPOTP,
                OTPCode: encriptedOtpCode?.payload as string,
                device: userDevice,
                TPA: tpaContext.tokenInfo.tpa as string
            };
            const validateOtp = await validateOtpService(validateOtpRequest);
            const TOTP = validateOtp.payload.TOTP;
            if (location.state.flow == EFlowIndicator.REGISTER) {
                history.replace({
                    pathname: EUserRoutePaths.UNIQUE_KEY,
                    state: {
                        userRegisterData: location.state.userRegisterData,
                        userContactData: location.state.userContactData,
                        TOTP,
                        flow: location.state.flow
                    }
                });
            }
            if (location.state.flow == EFlowIndicator.RECOVER_PASSWORD) {
                history.replace({
                    pathname: EPasswordRoutePaths.PASSWORD_CREATE,
                    state: {
                        userRecoverPasswordData: location.state.userRecoverPasswordData,
                        TOTP,
                        flow: location.state.flow
                    }
                });
            }
        } catch (error) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError('El código ingresado es incorrecto.');
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    const generateNewOtp = async () => {
        backdrop.openBackdrop();
        const userDevice = getDataDevice();
        let requestNewOtp: IGenerateNewOtp;
        if (location.state.flow == EFlowIndicator.REGISTER) {
            const target = location.state.userContactData?.cellphone as string;
            requestNewOtp = {
                mediaSendDestination: target,
                device: userDevice,
                TPA: tpaContext.tokenInfo.tpa as string
            };
        } else {
            const rut = location.state.userRecoverPasswordData?.rut as string;
            requestNewOtp = {
                requestedByRut: rut,
                device: userDevice,
                TPA: tpaContext.tokenInfo.tpa as string
            };
        }
        try {
            const newTPOTP = await generateNewOtpService(requestNewOtp);
            const TPOTPInfo: TPOtpInfo = extractPayloadFromJWT(newTPOTP.payload.TPOTP);
            const newdate = new Date().valueOf();
            const rSeconds = getRemainingSeconds(newdate, TPOTPInfo.exp * 1000);
            setSeconds(rSeconds);
            setTPOTP(newTPOTP.payload.TPOTP);
        } catch (error) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                const successUrl =
                    location.state.flow == EFlowIndicator.REGISTER
                        ? EUserRoutePaths.USER_CONFIRMATION_ERROR
                        : EPasswordRoutePaths.PASSWORD_CREATED;
                history.replace({
                    pathname: successUrl,
                    state: {
                        success: false
                    }
                });
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    const handleCancel = async () => {
        history.replace(ELoginRoutePaths.LOGIN);
    };
    const handleAlertTimeout = () => {
        setTimeout(() => {
            clearError();
        }, 6000);
    };
    const showError = (title: string) => {
        setErrorValues({
            title: title,
            visible: true
        });
        handleAlertTimeout();
    };

    const clearError = () => {
        setErrorValues({
            title: '',
            visible: false
        });
    };

    return (
        <section className={classes.OtpValidationPage}>
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <div className={classes.HyperLinkBox}>
                        <HyperLink
                            classNames={classes.HyperLink_GoBack}
                            underline="none"
                            onClick={getRedirectUrlTrade}>
                            <ChevronLeftIcon />
                            volver al comercio
                        </HyperLink>
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <ContentBox classNames={classes.ContentBox}>
                        <img className={classes.SabbiLogo} src={SabbiLogo} alt="SabbiLogo" />
                        <Typography className={classes.ValidationTitle} variant="h2" component="h2">
                            Validaremos tu teléfono enviándote un código de 4 dígitos
                        </Typography>
                        <AlertBar
                            classNames={classes.AlertBar}
                            variant="filled"
                            severity="error"
                            message={errorValues.title}
                            open={errorValues.visible}
                            closeOnClick={() => clearError()}
                            icon={<WarningRoundedIcon />}
                        />
                        <Typography
                            className={classes.OtpValidationPageText}
                            component="p"
                            gutterBottom>
                            Ingresa el código enviado por SMS a:
                        </Typography>
                        <MaskedUserPhone
                            classNames={classes.MaskedUserPhone}
                            phone={userTarget?.replace(/\*/g, '')}
                        />
                        <FormContext
                            className={classes.Form}
                            id={'form-otp'}
                            onSubmit={onSubmit}
                            methods={methods}>
                            <OtpInput
                                classNames={classes.OtpInput}
                                inputNumber={4}
                                placeholder="_"
                                isError={isError}
                                onChange={() => setIsError(false)}
                            />
                            <ProgressCircular
                                classNames={classes.ProgressCircular}
                                buttonText="Nuevo código"
                                linkOnClick={generateNewOtp}
                                max={seconds}
                            />
                            <Typography className={classes.CaptionText} variant="caption">
                                Si no pudiste completar el código podrás solicitar uno nuevo al
                                finalizar el tiempo.
                            </Typography>
                            <div className={classes.ButtonsBox}>
                                <Button
                                    className={classes.CancelButton}
                                    onClick={() => showModal('cancelModal')}>
                                    Cancelar
                                </Button>
                                <RoundButton
                                    classNames={classes.SubmitButton}
                                    id={'submitbtn'}
                                    color="primary"
                                    name={'submitbtn'}
                                    type="submit">
                                    Continuar
                                </RoundButton>
                            </div>
                        </FormContext>
                    </ContentBox>
                </Grid>
            </Grid>

            <ModalDialog
                id="ModalCancel"
                open={modalValues.cancelModal}
                handleCancel={closeModals}
                handleAccept={handleCancel}
                cancelText="Volver"
                title={
                    <>
                        <img
                            className={classes.ModalNotNumber_SabbiNoBorder}
                            src={SabbiLogoNoBorderSrc}
                            alt="Sabbi"
                        />
                        <Typography variant="h2" component="h2">
                            ¿Deseas cancelar el proceso?{' '}
                            <span role="img" aria-label="confused-face">
                                😕
                            </span>
                        </Typography>
                        <Typography component="p" className={classes.WarningText}>
                            Tu cuenta se podrá mantener activa pero deberás culminar el proceso al
                            volver y agregar una una tarjeta de crédito.
                        </Typography>
                    </>
                }
                scroll={'paper'}
                maxWidth={'sm'}>
                <div className={classes.CancelModal}></div>
            </ModalDialog>
        </section>
    );
};

export default OtpValidationPage;
