import { ENameError } from './enums/ENameError';
import { IFrontApplicationError } from './interfaces/IFrontApplicationError';

export class FrontApplicationError extends Error {
    code: string;
    meta?: { [k: string]: unknown };
    constructor(options: IFrontApplicationError) {
        super();
        this.name = options.name ? options.name : ENameError.APP_ERROR;
        this.code = options.code;
        this.message = options.message;
        this.meta = options.meta;
        /**
         * setPrototypeOf is needed here because extending from Error, Array and Map causes some issues when using es5 to transpile.
         * For more information:
         * https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
         */
        Object.setPrototypeOf(this, FrontApplicationError.prototype);
    }
}
