import { Badge } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { FC, useEffect, useRef } from 'react';
import VanillaTilt from 'vanilla-tilt';

import { ECardType } from '../../utils/enums/ECardType';
import { EPaymentMethods } from '../../utils/enums/EPaymentMethods';
import CreditCardImage from '../CreditCardIcon/CreditCardImage';
import { IShowCreditCardProps } from './interfaces/IShowCreditCardProps';
import useStyles from './ShowCreditCardStyles.material';

const ShowCreditCard: FC<IShowCreditCardProps> = ({
    cardBrand,
    classNames = '',
    cardNumber,
    cardType
}) => {
    const classes = useStyles();
    const tilt = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const tiltOptions = {
            scale: 1.07,
            speed: 800,
            max: 10,
            perspective: 800
        };
        if (tilt.current) {
            VanillaTilt.init(tilt.current, tiltOptions);
        }
    }, []);

    return (
        <div ref={tilt} className={`${classes.CreditCard} ${classNames}`}>
            <div className={classes.TitleCard}>
                <Badge
                    className={`${classes.GreyBadge} ${classes.TypeBadge}`}
                    badgeContent={
                        cardType &&
                        (cardType === ECardType.DEBIT || cardType === EPaymentMethods.Debito)
                            ? 'Débito'
                            : 'Crédito'
                    }
                />
                <CheckIcon className={classes.Card_Icon_Check} />
            </div>
            <div className={classes.DotsCard}>
                <span className={classes.DotsCard_Dots}>**** **** **** {cardNumber}</span>
            </div>
            <div className={classes.CardImage_NoUser_Card}>
                <CreditCardImage
                    classNames={classes.CardImage_NoUser}
                    creditCardBrand={`${cardBrand?.toLowerCase()}`}
                    showUnknown={true}
                />
            </div>
        </div>
    );
};

export default ShowCreditCard;
