export enum EInvalidBins {
    COMPANIA_EMISORA_DE_MEDIOS_DE_PAGO_DIGITALES_SA = '559391',
    DALE_COOPEUCH = '531926',
    FAN = '475774',
    FINTUAL_PREPAID = '542149',
    GLOBAL_CARD_SA = '519858',
    GLOBAL66 = '555505',
    HUB_SITE_CHILE = '530478',
    HUB_SITE_CHILE_2 = '533514',
    LOS_ANDES_PREPAID = '230863',
    LOS_ANDES_TAPP = '522416',
    LOS_HEROES_PREPAID = '555912',
    MACH = '412003',
    MERCADO_PAGO_EMISORA = '510869',
    RIPLEY_BANK = '521253',
    RIPLEY_BANK_2 = '234061',
    RIPLEY_CHECK = '525384',
    SANTANDER_BANK = '538765',
    SANTANDER_SUPERDIGITAL = '538759',
    SBPAY = '457615',
    SCOTIABANK_CHILE = '516998',
    SCOTIABANK_CHILE_2 = '527698',
    SUMUP = '533941',
    TENPO = '517608',
    U_PAYMENTS = '233031',
    U_PAYMENTS_2 = '518286',
    U_PAYMENTS_3 = '523250'
}
