import {
    FormControl,
    FormHelperText,
    InputAdornment,
    InputBaseComponentProps,
    InputLabel,
    OutlinedInput
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import creditCardType from 'credit-card-type';
import { CreditCardType } from 'credit-card-type/dist/types';
import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MaskedInput from 'react-text-mask';

import { invalidBinNumber } from '../../../utils/clientHelpers';
import { cleanCardNumber } from '../../../utils/creditCardHelpers';
import CreditCardImage from '../../CreditCardIcon/CreditCardImage';
import useStyles from './CreditCardInputStyles.material';
import ICreditCardInputProps from './interfaces/ICreditCardInputProps';

interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
}

const CreditCardInput: FC<ICreditCardInputProps> = ({
    id,
    name,
    classNames = '',
    label,
    defaultHelperText,
    placeholder = '',
    rules,
    defaultValue = ''
}) => {
    const [touched, setTouched] = useState(false);
    const [creditCardBrand, setCreditCardBrand] = useState<string>();
    const classes = useStyles();
    const { formState, control } = useFormContext();
    const errors = formState?.errors;

    const checkError = () => {
        if (errors?.[name]) {
            return 'error';
        } else if (touched) {
            return 'success';
        }
    };

    const handleRenderIcon = () => {
        if (touched) {
            if (errors?.[name]) {
                return <ClearIcon />;
            } else {
                return <CheckCircleIcon />;
            }
        } else {
            if (errors?.[name]) {
                return <ClearIcon />;
            } else {
                return <CreditCardIcon />;
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const cardNumber = event.target.value.replace(/\s/g, '');
        const cardBrand: CreditCardType[] = creditCardType(cardNumber);
        if (cardBrand.length == 1) {
            setCreditCardBrand(cardBrand[0].type);
        } else {
            if (creditCardBrand !== '') {
                setCreditCardBrand('');
            }
        }
    };

    const validateSBCreditCard = (value: string) => {
        const creditCardNumber = cleanCardNumber(value);
        if (touched && invalidBinNumber(creditCardNumber)) {
            return false;
        }
        return true;
    };

    const validateMinLenght = (value: string) => {
        if (touched && value.length < 18) {
            return false;
        }
        return true;
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={{
                validate: {
                    isValid: (value) => {
                        return !validateSBCreditCard(value)
                            ? 'No esta permitido enrolar esta tarjeta en Sabbi.'
                            : !validateMinLenght(value)
                            ? 'La tarjeta es requerida.'
                            : true;
                    }
                },
                ...rules
            }}
            render={({ field }) => (
                <FormControl
                    size="small"
                    classes={{ root: classes.root }}
                    className={`${classNames} ${checkError()} ${creditCardBrand && 'foundCard'}`}
                    variant="outlined">
                    {label && (
                        <InputLabel className={classes.label} htmlFor={`${id}`}>
                            {label}
                        </InputLabel>
                    )}
                    <div
                        className={`${classes.CreditCardDiv} ${
                            creditCardBrand ? 'opened' : 'closed'
                        }`}>
                        <CreditCardImage
                            classNames={classes.CreditCardIcon}
                            showUnknown={false}
                            creditCardBrand={creditCardBrand}
                        />
                    </div>
                    <OutlinedInput
                        autoComplete="off"
                        placeholder={`${placeholder}`}
                        id={`${id}`}
                        className={classes.label}
                        inputComponent={maskedCreditCardInput as FC<InputBaseComponentProps>}
                        label={label}
                        value={field.value}
                        onChange={(value) => {
                            field.onChange(value);
                            setTouched(true);
                            handleChange(value as React.ChangeEvent<HTMLInputElement>);
                        }}
                        onBlur={field.onBlur}
                        endAdornment={
                            <InputAdornment position="end">{handleRenderIcon()}</InputAdornment>
                        }
                    />
                    <FormHelperText id={`helper-text-${id}`}>
                        {errors?.[name] ? errors?.[name].message : defaultHelperText}
                    </FormHelperText>
                </FormControl>
            )}
        />
    );
};

const maskedCreditCardInput = (props: TextMaskCustomProps) => {
    const { inputRef, ...otherProps } = props;
    const maskedArray = [
        /[0-9]/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /[0-9]/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /[0-9]/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /[0-9]/,
        /\d/,
        /\d/,
        /\d/
    ];
    return (
        <MaskedInput
            {...otherProps}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={maskedArray}
            placeholderChar={'\u2000'}
            guide={false}
        />
    );
};

export default CreditCardInput;
