import { makeStyles } from '@material-ui/core/styles';
import COLORS from '../../../themes/colors';

const useStyles = makeStyles((theme) => ({
    OtpInput: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& input': {
            fontSize: '1.8rem',
            textAlign: 'center',
            fontFamily: 'ceraRoundProBold',
            color: theme.palette.primary.main,
            '&::placeholder': {
                fontFamily: 'ceraRoundProLight',
                fontSize: '3rem',
                color: `${theme.palette.primary.main}`,
                opacity: 1,
                ['@media (max-width:431px)']: {
                    fontSize: '1.4rem'
                }
            },
            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none'
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none'
            },
            '&[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '&:-webkit-autofill': {
                '-webkit-box-shadow': '0 0 0 30px white inset',
                color: theme.palette.grey[800],
                fontFamily: 'ceraRoundProRegular'
            },
            ['@media (max-width:431px)']: {
                fontSize: '1.3rem'
            }
        }
    },
    OtpInputBase: {
        width: '50px',
        height: '50px',
        minWidth: '50px',
        minHeight: '50px',
        border: `solid 2px ${theme.palette.primary.main}`,
        borderRadius: '50%',
        margin: '1rem .5rem',
        backgroundColor: COLORS.lilacLight,
        transition: 'all .2s ease-in-out',
        '&.Mui-focused': {
            border: `solid 2px ${theme.palette.primary.main}`
        },
        '&.success': {
            border: `solid 2px ${theme.palette.success.main}`,
            backgroundColor: COLORS.greenLight
        },
        '&.invalidOtp': {
            border: `solid 2px ${theme.palette.error.main}`,
            backgroundColor: COLORS.redLighter,
            '& input': {
                color: theme.palette.grey[800]
            }
        },
        '&.Mui-disabled': {
            border: `solid 2px ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[300],
            '& input': {
                color: theme.palette.grey[400]
            }
        },
        '& input': {
            padding: '8px 0 6px 0'
        },
        ['@media (max-width:431px)']: {
            width: '40px',
            height: '40px',
            minWidth: '40px',
            minHeight: '40px'
        },
        ['@media (max-width:352px)']: {
            width: '30px',
            height: '30px',
            minWidth: '30px',
            minHeight: '30px'
        }
    }
}));

export default useStyles;
