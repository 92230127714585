import { makeStyles } from '@material-ui/core/styles';
import { FOOTER_SHADOW } from '../../themes/shadows';

const useStyles = makeStyles((theme) => ({
    BasicFooter: {
        maxWidth: '100vw',
        height: '68px',
        backgroundColor: theme.palette.common.white,
        alignContent: 'center',
        marginTop: 'auto',
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: FOOTER_SHADOW
    },
    PrivacyText: {
        fontWeight: 'bold',
        justifyContent: 'flex-end',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        display: 'flex',
        fontSize: '14px',
        '& a': {
            textDecoration: 'none',
            alignItems: 'center',
            display: 'flex',
            color: theme.palette.common.black
        },
        ['@media (max-width:959px)']: {
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center'
        },
    },
    PrivacyText_Icon: {
        marginRight: '5px',
        fontSize: '18px',
    },
    CopyrightText: {
        display: 'flex',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        fontSize: '14px',
        ['@media (max-width:959px)']: {
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center'
        }
    }
}));

export default useStyles;
