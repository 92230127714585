import { FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'center',
        '& .MuiFormControlLabel-label': {
            fontSize: '.9rem'
        },
        '&.error': {
            '& .MuiFormHelperText-root': {
                color: theme.palette.error.main
            }
        },
        '& .MuiFormHelperText-root': {
            marginTop: '-6px'
        }
    }
}));

export default useStyles;
