import { FormHelperText, Grid, InputAdornment } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import { Autocomplete } from '@material-ui/lab';
import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import HyperLink from '../../HyperLink/HyperLink';
import useStyles from './AutocompleteInputStyles.material';
import IAutocompleteInput from './interfaces/IAutocompleteInput';
import { IAutocompleteOption } from './interfaces/IAutocompleteOption';

const AutocompleteInput: FC<IAutocompleteInput> = ({
    id,
    name,
    classNames = '',
    label,
    defaultHelperText,
    placeholder = '',
    icon,
    rules,
    defaultValue = '',
    hasHyperLink = false,
    hyperLinkText,
    onClickHyperLink,
    onClick,
    options,
    noOptionsText,
    disabled = false
}) => {
    const [touched, setTouched] = useState(false);
    const classes = useStyles();
    const { formState, control } = useFormContext();
    const errors = formState?.errors;
    const checkError = (value: unknown) => {
        if (disabled) {
            return 'disabled';
        } else if (errors?.[name]) {
            return 'error';
        } else if (touched) {
            if (value === null || value === '') {
                return undefined;
            }
            return 'success';
        }
    };
    const handleRenderIcon = (value: unknown) => {
        if (disabled) {
            return icon;
        } else if (touched) {
            if (errors?.[name]) {
                return <ClearIcon />;
            } else if (value === null || value === '') {
                return icon;
            } else {
                return <CheckCircleIcon />;
            }
        } else {
            if (errors?.[name]) {
                return <ClearIcon />;
            } else {
                return icon;
            }
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field: { onChange, onBlur, value } }) => (
                <div className={classes.AutocompleteContainer}>
                    <Autocomplete
                        disablePortal
                        fullWidth
                        className={`${classNames} ${classes.AutocompleteInput} ${checkError(
                            value
                        )}`}
                        classes={{ root: classes.root }}
                        id={id}
                        getOptionLabel={(option: IAutocompleteOption) => option.value || ''}
                        getOptionSelected={(option: IAutocompleteOption, value) => {
                            return option.id === value.id;
                        }}
                        options={options}
                        value={value}
                        onChange={async (_, data) => {
                            setTouched(true);
                            onChange(data);
                        }}
                        disabled={disabled}
                        onClick={onClick}
                        onBlur={onBlur}
                        noOptionsText={noOptionsText}
                        renderOption={(option: IAutocompleteOption) => {
                            return <Grid item>{option.value}</Grid>;
                        }}
                        renderInput={(params) => (
                            <>
                                <TextField
                                    {...params}
                                    label={label}
                                    id={id}
                                    autoComplete="off"
                                    placeholder={placeholder}
                                    variant="outlined"
                                    onClick={onClick}
                                    onBlur={onBlur}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {handleRenderIcon(value)}
                                            </InputAdornment>
                                        ),
                                        className: classes.label
                                    }}
                                    InputLabelProps={{
                                        className: classes.label
                                    }}
                                />
                                <div className={classes.HelperSection}>
                                    <FormHelperText id={`helper-text-${id}`}>
                                        {errors?.[name]
                                            ? errors?.[name].message
                                            : defaultHelperText}
                                    </FormHelperText>
                                    {hasHyperLink && (
                                        <HyperLink
                                            classNames={classes.HyperLink}
                                            color="initial"
                                            onClick={onClickHyperLink}>
                                            {hyperLinkText}
                                        </HyperLink>
                                    )}
                                </div>
                            </>
                        )}
                    />
                </div>
            )}
        />
    );
};

export default AutocompleteInput;
