import { Grid, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { FC } from 'react';

import ISuccessCheckoutTableProps from './interfaces/ISuccessCheckoutTableProps';
import useStyles from './SuccessCheckoutTableStyles.material';

const SuccessCheckoutTable: FC<ISuccessCheckoutTableProps> = ({
    tableData,
    tableHeaders,
    classNames = ''
}) => {
    const classes = useStyles();

    return (
        <TableContainer className={`${classNames} ${classes.SuccessCheckoutTable}`}>
            {tableHeaders.map((row: Record<string, string>) => (
                <Grid container className={classes.containerTotal} key={`theader-${row.value}`}>
                    <Grid item lg={8} xs={8}>
                        <Typography className={classes.ShopName} component="h4">
                            {row.text}
                        </Typography>
                    </Grid>
                    <Grid item lg={4} xs={4}>
                        <Typography className={classes.TotalPrice} component="h4">
                            {row.value}
                        </Typography>
                    </Grid>
                </Grid>
            ))}
            <Table aria-label="success-checkout-table">
                <TableBody>
                    {tableData.map((row: Record<string, string>) => (
                        <TableRow key={`tbody-${row.value}`}>
                            <TableCell scope="row">{row.text}</TableCell>
                            <TableCell align="right">{row.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SuccessCheckoutTable;
