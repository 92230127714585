import { Paper, Typography } from '@material-ui/core';

import COLORS from '../../themes/colors';
import HyperLink from '../HyperLink/HyperLink';
import { IWhatIsSabbi } from './interfaces/IWhatIsSabbi';
import useStyles from './WhatIsSabbiStyles.material';

const WhatIsSabbi = ({ className = '' }: IWhatIsSabbi) => {
    const classes = useStyles();
    return (
        <Paper className={`${classes.WhatIsSabbi} ${className}`}>
            <Typography className={classes.WhatIsSabbi_Title}>¿Qué es Sabbi?</Typography>
            <Typography style={{ marginBottom: '1rem' }}>
                Si eliges pagar con un crédito <b>Sabbi</b> estás tomando un crédito que cuyas
                cuotas se cargarán mes a mes de forma automática, en la Tarjeta de Crédito que
                seleccionas al momento del pago.
            </Typography>
            <Typography style={{ marginBottom: '1rem' }}>
                Este crédito es otorgado por <b>Tarjeta Sabbi</b>*
            </Typography>
            <Typography style={{ marginBottom: '1rem' }}>
                ¿Necesitas más información? Dirígete a{' '}
                <HyperLink href="https://sabbi.cl/" target="_blank">
                    www.sabbi.cl
                </HyperLink>
            </Typography>
            <Typography style={{ marginBottom: '1rem' }}>
                <b>
                    <span style={{ color: COLORS.violetMain }}>*</span> Tarjeta Sabbi es emitida por
                    Matickard SA.
                </b>
            </Typography>
        </Paper>
    );
};

export default WhatIsSabbi;
