import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  EmbedContainer: {
      width: '100%',
      overflow: 'auto'
  },
  Embed: {
    maxWidth: '100%'
  }
}));

export default useStyles;
