import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    CardRadioButton: {
        margin: '.5rem 0rem'
    },
    CardGroupControl: {
        '&.error': {
            '& .MuiFormControlLabel-labelPlacementStart': {
                border: `solid 1px ${theme.palette.error.main}`
            },
            '& .MuiFormHelperText-root': {
                color: theme.palette.error.main
            }
        }
    },
    HiddenRadio: {
        display: 'none'
    },
    HelperText: {
        marginLeft: '1rem'
    },
    CardsHelperText: {
        margin: '1rem auto'
    }
}));

export default useStyles;
