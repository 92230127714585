import { makeStyles } from '@material-ui/core/styles';
import { ACCORDION_SHADOW } from '../../themes/shadows';

const useStyles = makeStyles((theme) => ({
    AccordionCollapse: {
        boxShadow: ACCORDION_SHADOW,
        borderRadius: '10px',
        '&.MuiAccordion-rounded:last-child':{
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
        },
        '&.MuiAccordion-rounded:first-child':{
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        }
    },
    AccordionSummary: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: '10px',
        fontSize: '.8rem',
        height: '50px',
        minHeight: '50px',
        padding: '0 14px',
        transition: 'all .3s ease-in-out',
        transitionDelay: '.3s',
        '&.Mui-expanded': {
            minHeight: '50px',
            margin: 0,
            transition: 'all .0s ease-in-out',
            transitionDelay: '0s',
            borderRadius: '10px 10px 0 0',
        },
        '& .MuiAccordionSummary-content': {
            margin: 0,
            display: 'flex',
            alignItems: 'center'
        },
        '& .MuiIconButton-root':{
            color: theme.palette.grey[800]
        }
    },
    AccordionDetails: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: '0 0 10px 10px'
    },
}));

export default useStyles;
