import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { thousandSeparator } from '../../../utils/clientHelpers';
import { IProduct } from '../../../utils/tokenManipulation/interfaces/IProduct';
import AccordionCollapse from '../../AccordionCollapse/AccordionCollapse';
import CustomScrollbar from '../../CustomScrollbar/CustomScrollbar';
import { ITransactionDetail } from '../interfaces/ITransactionDetail';
import useStyles from './MobileTransactionDetailStyles.material';

const MobileTransactionDetail: FC<ITransactionDetail> = ({ infoTransactionTpa }) => {
    const classes = useStyles();
    const simplebar = useRef<HTMLDivElement>();

    const [showGradient, setShowGradient] = useState<boolean>(true);

    const handleScroll = useCallback(() => {
        if (simplebar.current) {
            const { scrollTop, scrollHeight, clientHeight } = simplebar.current;
            if (scrollTop + clientHeight === scrollHeight) {
                setShowGradient(false);
            } else {
                setShowGradient(true);
            }
        }
    }, []);

    useEffect(() => {
        const customScrollElement = simplebar.current!;
        customScrollElement.addEventListener('scroll', handleScroll);
        return () => {
            customScrollElement.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const handleShowGradient = () => {
        if (infoTransactionTpa?.productList && infoTransactionTpa?.productList.length > 5) {
            return true;
        } else {
            return false;
        }
    };
    return (
        <AccordionCollapse
            classNames={classes.MobileTransactionDetail}
            defaultExpanded={false}
            accordionSummary={
                <>
                    <ShoppingCartIcon className={classes.CartIcon} />
                    <Typography variant="body1">Total compra</Typography>
                    <KeyboardArrowDownIcon id="rotateChevronIcon" className={classes.ChevronIcon} />
                    <Typography variant="body1" className={classes.TotalPriceText}>
                        ${' '}
                        {thousandSeparator(
                            infoTransactionTpa?.total ? infoTransactionTpa?.total : 0,
                            '.'
                        )}
                    </Typography>
                </>
            }>
            <Grid container>
                <Typography className={classes.ShopTitle} component="h4">
                    Pago en <b>{infoTransactionTpa.businessPartnerName}</b>
                </Typography>
                <CustomScrollbar
                    classNames={classes.CustomScrollbar}
                    type={handleShowGradient() ? 'line' : undefined}
                    scrollableNodeRef={simplebar}>
                    <TableContainer className={classes.TableContainer}>
                        <Table aria-label="detail-transaction-table">
                            <TableBody>
                                {infoTransactionTpa?.productList &&
                                    infoTransactionTpa?.productList.map((row: IProduct) => (
                                        <TableRow key={row.sku}>
                                            <TableCell
                                                scope="row"
                                                className={classes.TableCellProduct}>
                                                <Typography
                                                    className={classes.ShopName}
                                                    component="h4">
                                                    {row.description}
                                                </Typography>
                                                <Typography
                                                    component="p"
                                                    className={classes.Quantity}>
                                                    ({row.quantity}){' '}
                                                    {Number(row.quantity) > 1
                                                        ? 'unidades'
                                                        : 'unidad'}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CustomScrollbar>
                {handleShowGradient() === showGradient ? (
                    <div className={classes.WhiteGradient}></div>
                ) : (
                    <> </>
                )}
            </Grid>
        </AccordionCollapse>
    );
};

export default MobileTransactionDetail;
