import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import { FC } from 'react';

import IMaskedUserPhone from './interfaces/IMaskedUserPhone';
import useStyles from './MaskedUserPhoneStyles.material';

const MaskedUserPhone: FC<IMaskedUserPhone> = ({ id = '', classNames = '', phone }) => {
    const classes = useStyles();
    return (
        <div className={`${classNames} ${classes.MaskedUserPhone}`} id={id}>
            <PhoneAndroidIcon className={classes.Icon} />
            <span className={classes.Dots}>····</span>
            <span className={classes.Phone}>{phone}</span>
        </div>
    );
};

export default MaskedUserPhone;
