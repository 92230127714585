import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '70px 100px',
        width: '650px',
        maxWidth: '100%',
        borderRadius: '20px',
        '&.lg': {
            width: '650px'
        },
        '&.md': {
            width: '540px'
        },
        ['@media (max-width:550px)']: {
            padding: '1.6rem'
        },
        ['@media (max-width:425px)']: {
            padding: '1rem'
        }
    }
}));

export default useStyles;
