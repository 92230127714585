import { SABBI_FRONT_ERRORS } from '../errors/enums/EAppErrors';
import { FrontApplicationError } from '../errors/FrontApplicationError';
import { parseStringToJSON, stringifyJSON } from '../objectManipulation';
import { validateTE, validateTPA } from '../tokenManipulation';
import { ITEValidatedData } from '../tokenManipulation/interfaces/ITEValidatedData';
import { ITPAValidatedData } from '../tokenManipulation/interfaces/ITPAValidatedData';

/**
 * Adds a localStorage item
 * @param {string} itemName localStorage variable name
 * @returns {void}
 */
export const setLocalStorageItem = (itemName: string, itemValue: any): void => {
    localStorage.setItem(itemName, stringifyJSON(itemValue));
};

/**
 * Gets a localStorage item
 * @param {string} itemName localStorage variable name
 * @returns {any}
 */
export const getLocalStorageItem = (itemName: string): any => {
    const storageObject = localStorage.getItem(itemName);
    if (storageObject) {
        return parseStringToJSON(storageObject);
    }
};

/**
 * Removes an item from localStorage
 * @param itemName localStorage variable name
 */
export const removeLocalStorageItem = (itemName: string): void => {
    localStorage.removeItem(itemName);
};

/**
 * Clears localStorage
 */
export const clearLocalStorage = (): void => {
    localStorage.clear();
};

/**
 * Get TPA information
 * @param {string} TPA Access Token
 */
export const getTPAInformation = async (TPA: string | null) => {
    if (TPA) {
        try {
            const {
                jwtVerification: { isExpired, payload }
            } = await validateTPA(TPA);
            const TPAInfo: ITPAValidatedData = {
                bodyTPA: payload,
                isExpired
            };
            return TPAInfo;
        } catch (error) {
            throw new FrontApplicationError(SABBI_FRONT_ERRORS.SABBI_F_01);
        }
    } else {
        throw new FrontApplicationError(SABBI_FRONT_ERRORS.SABBI_F_01);
    }
};

/**
 * Get TE information
 * @param {string} TE Session Token
 * @returns {Promise<ITEValidatedData>} TE information validated
 */
export const getTEInformation = async (TE: string | null): Promise<ITEValidatedData> => {
    if (TE) {
        try {
            const {
                jwtVerification: { isExpired, payload }
            } = await validateTE(TE);
            const TEInfo: ITEValidatedData = {
                bodyTE: payload,
                isExpired
            };
            return TEInfo;
        } catch (error) {
            throw new FrontApplicationError(SABBI_FRONT_ERRORS.SABBI_F_03);
        }
    } else {
        throw new FrontApplicationError(SABBI_FRONT_ERRORS.SABBI_F_03);
    }
};

/**
 * Deletes TPA and access related data from localstorage
 */
export const deleteLocalStorageAccessData = () => {
    removeLocalStorageItem('TPA');
    removeLocalStorageItem('PURCHASEID');
    removeLocalStorageItem('FP');
};

/**
 * Deletes TE and sessino data from localstorage
 */
export const deleteLocalStorageSessionData = () => {
    removeLocalStorageItem('TE');
};
