import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '&.success': {
            transition: 'all .3s ease-in-out',
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    color: theme.palette.success.main
                },
                '&.Mui-focused .MuiSvgIcon-root': {
                    color: theme.palette.success.main
                }
            },
            '& label.Mui-focused': {
                color: theme.palette.primary.main
            },
            '& .MuiFormHelperText-root.Mui-focused': {
                color: theme.palette.primary.main
            },
            '& .Mui-focused .MuiSvgIcon-colorPrimary': {
                color: theme.palette.primary.main
            },
            '& .MuiSvgIcon-root': {
                color: theme.palette.success.main
            }
        },
        '&.error': {
            transition: 'all .3s ease-in-out',
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: theme.palette.error.main
                },
                '&.Mui-focused .MuiSvgIcon-root': {
                    color: theme.palette.error.main
                }
            },
            '& label': {
                color: theme.palette.error.main
            },
            '& .MuiFormHelperText-root': {
                color: theme.palette.error.main
            },
            '& .MuiSvgIcon-colorPrimary': {
                color: theme.palette.error.main
            },
            '& .MuiSvgIcon-root': {
                color: theme.palette.error.main
            }
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main
            },
            '&.Mui-focused .MuiSvgIcon-root': {
                color: theme.palette.primary.main
            },
            '&.Mui-focused fieldset': {
                borderWidth: '1px'
            },
            '& fieldset': {
                borderColor: theme.palette.grey[500],
                borderRadius: '20px 20px',
                boxSizing: 'border-box'
            },
            '& .MuiInputAdornment-positionEnd': {
                marginLeft: '16px',
                marginRight: '6px',
                '& .MuiSvgIcon-root': {
                    maxWidth: '20px',
                    maxHeight: '20px'
                }
            }
        },
        '& input': {
            paddingLeft: '20px',
            '&::placeholder': {
                fontSize: '1rem'
            },
            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none'
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none'
            },
            '&[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '&:-webkit-autofill': {
                '-webkit-box-shadow': '0 0 0 30px white inset',
                color: theme.palette.grey[800],
                fontFamily: 'ceraRoundProRegular'
            },
            '&:-internal-autofill-selected': {
                color: theme.palette.grey[800]
            },
            '&[type=password]': {
                letterSpacing: '6px'
            },
        }
    },
    label: {
        fontSize: '1rem'
    },
    HyperLink: {
        color: 'blue',
        textAlign: 'right',
        marginTop: '5px',
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '1rem',
        '& p': {
            fontSize: '.9rem'
        }
    },
    HelperSection: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

export default useStyles;
