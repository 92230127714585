import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import CdiBack from '../../assets/cdi/cedulaAntigua.svg';
import CdiFront from '../../assets/cdi/cedulaFrontal.svg';
import SabbiLogo from '../../assets/logo/sabbi.svg';
import AlertBar from '../../components/AlertBar/AlertBar';
import ContentBox from '../../components/ContentBox/ContentBox';
import CheckboxInput from '../../components/FormsContext/CheckboxInput/CheckboxInput';
import { FormContext } from '../../components/FormsContext/FormContext/FormContext';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import RutInput from '../../components/FormsContext/RutInput/RutInput';
import TextInput from '../../components/FormsContext/TextInput/TextInput';
import HtmlTooltip from '../../components/HtmlTooltip/HtmlTooltip';
import { ClickableTooltip } from '../../components/HtmlTooltip/HtmlTooltip';
import HyperLink from '../../components/HyperLink/HyperLink';
import ModalError from '../../components/ModalError/ModalError';
import ModalTerms from '../../components/ModalTerms/ModalTerms';
import DownloadFile from '../../components/ViewFile/ViewFile';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useTpa } from '../../context/TPAContext/tpa';
import { useRedirection } from '../../hooks/useRedirection';
import { EUserRoutePaths } from '../../routes/enum/EUserRoutePaths';
import { previewDocumentPublic } from '../../services/sabbi/document/document';
import { IParamsPreviewDocument } from '../../services/sabbi/document/interface/IParamsPreviewDocument';
import { IPreviewDocumentPublic } from '../../services/sabbi/document/interface/IPreviewDocumentPublic';
import { IRutValidationData } from '../../services/sabbi/validation/interfaces/IRutValidationData';
import { rutValidateOnboarding } from '../../services/sabbi/validation/validation';
import { cancelTRXandReturnTrade } from '../../utils/clientHelpers';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { EPaymentMethod } from '../../utils/enums/EPaymentMethod';
import { ETemplates } from '../../utils/enums/ETemplates';
import { handleErrorMessage } from '../../utils/errors';
import { SABBI_FRONT_ERRORS } from '../../utils/errors/enums/EAppErrors';
import { FrontApplicationError } from '../../utils/errors/FrontApplicationError';
import { IErrorValues } from '../../utils/errors/interfaces/IErrorValues';
import { clean } from '../../utils/rutHelpers';
import { IModalValuesState } from './interfaces/IModalValuesState';
import useStyles from './UserRegisterPageStyles.material';

type Inputs = {
    documentNumber?: string;
    documentSerial?: string;
    checkbox?: boolean;
};

const UserRegisterPage = () => {
    const [modalValues, setModalValues] = useState<IModalValuesState>({
        termsModal: false,
        errorDocumentModal: false
    });
    const [errorValues, setErrorValues] = useState<IErrorValues>({
        title: '',
        visible: false
    });
    const [termsBase64, setTermsBase64] = useState<string>('');
    const [documentNumber, setDocumentNumber] = useState<string>('999999999');
    const classes = useStyles();
    const history = useHistory();
    const backdrop = useBackdrop();
    const tpaContext = useTpa();
    const { handleErrorRedirection, shouldRedirect } = useRedirection();
    const defaultValues: Partial<Inputs> = {};
    const methods = useForm<FieldValues>({ mode: 'all', defaultValues });

    const getRedirectUrlTrade = async () => {
        backdrop.openBackdrop();
        try {
            await cancelTRXandReturnTrade(tpaContext.tokenInfo.tpa as string);
        } catch (error) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };

    const handleAlertTimeout = () => {
        setTimeout(() => {
            clearError();
        }, 6000);
    };

    const showError = (title: string) => {
        setErrorValues({
            title: title,
            visible: true
        });
        handleAlertTimeout();
    };

    const clearError = () => {
        setErrorValues({
            title: '',
            visible: false
        });
    };

    const onSubmit: SubmitHandler<any> = async (data) => {
        const CONST_VALID_DOCUMENT = 'V';
        try {
            backdrop.openBackdrop();
            const device = getDataDevice();
            const requestRutValidation = {
                documentNumber: clean(data.documentNumber),
                documentSerial: data.documentSerial,
                TPA: tpaContext.tokenInfo.tpa as string,
                device
            };
            const rutValidationDataResponse = await rutValidateOnboarding(requestRutValidation);
            const rutValidationData = rutValidationDataResponse.payload;
            history.push({
                pathname: EUserRoutePaths.USER_REGISTER_CONTACT,
                state: {
                    userRegisterData: { ...rutValidationData }
                }
            });
        } catch (error) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };

    const handleShowModal = (modalName: keyof IModalValuesState, show: boolean) => {
        setModalValues({
            ...modalValues,
            [modalName]: show
        });
    };

    /**
     * Opens modal showing termsAndConditions
     * @returns {void}
     */
    const handleTermsAndConditions = async () => {
        backdrop.openBackdrop();
        try {
            if (!termsBase64) {
                const paramPreviewDocument: IParamsPreviewDocument = {
                    paymentMethod: EPaymentMethod.ONECLICK,
                    typeDocument: ETemplates.SABBI_TERMS_AND_CONDITIONS
                };
                const dataPreviewDocument: IPreviewDocumentPublic = {
                    TPA: tpaContext.tokenInfo.tpa as string,
                    device: getDataDevice()
                };
                const docBase64 = await previewDocumentPublic(
                    dataPreviewDocument,
                    paramPreviewDocument
                );
                setTermsBase64(docBase64.payload.documentBase64);
                handleShowModal('termsModal', true);
            } else {
                handleShowModal('termsModal', true);
            }
        } catch (error) {
            handleShowModal('errorDocumentModal', true);
        } finally {
            backdrop.closeBackdrop();
        }
    };
    const closeModals = () => {
        setModalValues({
            termsModal: false,
            errorDocumentModal: false
        });
    };
    const handleLoginRedirection = () => {
        history.goBack();
    };

    /**
     * Render clickable tooltip depending on screen size
     * @returns {JSX.Element}
     */
    const renderTooltip = (): JSX.Element => {
        const CONST_SCREEN_WIDTH = 769;
        const width =
            window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        const tooltipContent = (
            <div className={classes.TooltipCustomized}>
                <p className={classes.TooltipCustomized_pTitle}>Serial CVV N° de documento</p>
                <p className={classes.TooltipCustomized_p}>Para cédulas actuales (Frontal)</p>
                <img className={classes.CdiFront} src={CdiFront} alt="CdiFront" />
                <p className={classes.TooltipCustomized_p}>Para cédulas antiguas (Al reverso)</p>
                <img className={classes.CdiBack} src={CdiBack} alt="CdiBack" />
            </div>
        );
        const tooltipChildren = <span className={classes.SpanTooltip}>¿Dónde lo encuentro?</span>;

        if (width < CONST_SCREEN_WIDTH) {
            return (
                <ClickableTooltip
                    placement={'bottom-end'}
                    size={'sm'}
                    tooltipContent={tooltipContent}>
                    {tooltipChildren}
                </ClickableTooltip>
            );
        } else {
            return (
                <HtmlTooltip placement={'bottom-end'} size={'sm'} tooltipContent={tooltipContent}>
                    {tooltipChildren}
                </HtmlTooltip>
            );
        }
    };

    return (
        <section className={classes.RegisterPage}>
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <div className={classes.HyperLinkBox}>
                        <HyperLink
                            classNames={classes.HyperLink_GoBack}
                            underline="none"
                            onClick={getRedirectUrlTrade}>
                            <ChevronLeftIcon />
                            volver al comercio
                        </HyperLink>
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <ContentBox classNames={classes.ContentBox}>
                        <img className={classes.LogoImage} src={SabbiLogo} alt="LogoSabbi" />
                        <Typography variant="h2" component="h2" gutterBottom>
                            Ingresa tus datos personales para registrarte
                        </Typography>
                        <AlertBar
                            classNames={classes.AlertBar}
                            variant="filled"
                            severity="error"
                            message={errorValues.title}
                            open={errorValues.visible}
                            closeOnClick={() => clearError()}
                            icon={<WarningRoundedIcon />}
                        />
                        <FormContext
                            className={classes.Form}
                            id={'RegisterForm'}
                            onSubmit={onSubmit}
                            methods={methods}>
                            <RutInput
                                id={'documentNumber'}
                                name={'documentNumber'}
                                label={'RUT'}
                                rules={{
                                    required: { value: true, message: 'El RUT es requerido' }
                                }}
                                icon={<AssignmentIndIcon />}
                            />
                            <TextInput
                                id={'documentSerial'}
                                name={'documentSerial'}
                                type={'password'}
                                label={'N° de documento'}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'El número de serial es requerido'
                                    },
                                    minLength: { value: 9, message: 'Campo Incorrecto' },
                                    maxLength: { value: 10, message: 'Campo Incorrecto' }
                                }}
                                maxLength={10}
                                icon={<MoreHorizIcon />}
                                hasHyperLink={true}
                                hyperLinkText={renderTooltip()}
                            />
                            <Typography
                                className={classes.DisclaimerText}
                                variant="caption"
                                component="div">
                                Para tu seguridad, con estos datos validaremos tu identidad{' '}
                                <span role="img" aria-label="finger-pointing-up">
                                    ☝
                                </span>
                            </Typography>
                            <CheckboxInput
                                classNames={classes.CheckboxInput}
                                id="checkbox"
                                clickableLabel={false}
                                label={
                                    <HyperLink
                                        classNames={classes.CheckboxHyperLink}
                                        color="initial"
                                        onClick={() => handleTermsAndConditions()}>
                                        Aceptar términos y condiciones
                                    </HyperLink>
                                }
                                name={'checkbox'}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Debe aceptar los términos y condiciones'
                                    }
                                }}
                            />
                            <div className={classes.ButtonsBox}>
                                <Button
                                    className={classes.CancelButton}
                                    onClick={handleLoginRedirection}>
                                    Cancelar
                                </Button>
                                <RoundButton
                                    classNames={classes.SubmitButton}
                                    id={'submitbtn'}
                                    color="primary"
                                    name={'submitbtn'}
                                    type="submit">
                                    Continuar
                                </RoundButton>
                            </div>
                        </FormContext>
                    </ContentBox>
                </Grid>
            </Grid>

            <ModalTerms
                id={'phoneTerms'}
                open={modalValues.termsModal}
                handleClose={closeModals}
                title="Términos y condiciones"
                scroll={'paper'}
                fullWidth={true}
                maxWidth={'md'}>
                <div className={classes.ModalViewDocument_PDF}>
                    {termsBase64 == '' ? (
                        <div className={classes.ModalViewDocument_Loader}>
                            <CircularProgress color="primary" />
                            <Typography component="p" gutterBottom>
                                Cargando Documento
                            </Typography>
                        </div>
                    ) : (
                        <DownloadFile dataFile={termsBase64} />
                    )}
                </div>
            </ModalTerms>
            <ModalError
                id="errorDocumentModal"
                open={modalValues.errorDocumentModal}
                handleAccept={closeModals}
                scroll={'paper'}
                fullWidth={false}
                maxWidth="sm"
                title="¡Lo sentimos!">
                <Typography component="p">
                    Hubo un problema en la carga de este documento.
                </Typography>
            </ModalError>
        </section>
    );
};
export default UserRegisterPage;
