import { IHandleError } from '../interfaces/IHandleError';

export const SERVICE_ERRORS: Record<string, IHandleError> = {
    SABBI_01: {
        code: 'SABBI_01',
        message: 'Unable to generate transaction ID',
        messageHandle: 'Error al generar transacción.'
    },
    SABBI_02: {
        code: 'SABBI_02',
        message: 'Unable to retrieve transaction information',
        messageHandle: 'Error al recuperar transacción.'
    },
    SABBI_03: {
        code: 'SABBI_03',
        message: 'Unable to get business partner configuration',
        messageHandle: 'No se encontró el socio comercial.'
    },
    SABBI_04: {
        code: 'SABBI_04',
        message: 'Unable to get business partner configuration',
        messageHandle: 'No se encontró el socio comercial.'
    },
    SABBI_05: {
        code: 'SABBI_05',
        message: 'Unable to get user information',
        messageHandle: 'No se encontró usuario.'
    },
    SABBI_06: {
        code: 'SABBI_06',
        message: 'Unable to get user cards',
        messageHandle: 'No se encontraron tarjetas del usuario.'
    },
    SABBI_07: {
        code: 'SABBI_07',
        message: 'Unable to get card bin information',
        messageHandle: 'No se encontró tarjeta.'
    },
    SABBI_08: {
        code: 'SABBI_08',
        message: 'Unable to get card subscription information',
        messageHandle: 'No se encontró tarjeta.'
    },
    SABBI_09: {
        code: 'SABBI_09',
        message: 'Unable to get card subscription',
        messageHandle: 'No se encontró tarjeta.'
    },
    SABBI_10: {
        code: 'SABBI_10',
        message: 'Unable to get card subscription',
        messageHandle: 'No se encontró tarjeta.'
    },
    SABBI_11: {
        code: 'SABBI_11',
        message: 'Unable to validate session token',
        messageHandle: 'No se puede validar sesión.'
    },
    SABBI_12: {
        code: 'SABBI_12',
        message: 'Unable to set card subscription as favorite',
        messageHandle: 'No se puede configurar la tarjeta como favorita.'
    },
    SABBI_13: {
        code: 'SABBI_13',
        message: 'Unable to get user favorite card subscription',
        messageHandle: 'No se puede obtener la tarjeta favorita.'
    },
    SABBI_14: {
        code: 'SABBI_14',
        message: 'Unable to process your transaction',
        messageHandle: 'Error al procesar transacción.'
    },
    SABBI_16: {
        code: 'SABBI_16',
        message: 'Invalid TPA',
        messageHandle: 'Transacción inválida.'
    },
    SABBI_17: {
        code: 'SABBI_17',
        message: 'TPA expired',
        messageHandle: 'Transacción expirada.'
    },
    SABBI_18: {
        code: 'SABBI_18',
        message: 'Invalid TE',
        messageHandle: 'Sesión inválida.'
    },
    SABBI_19: {
        code: 'SABBI_19',
        message: 'TE expired',
        messageHandle: 'Sesión expirada.'
    },
    SABBI_20: {
        code: 'SABBI_20',
        message: 'Unable to add card subscription, user already has it',
        messageHandle: 'La tarjeta ya se encuentra agregada.'
    },
    SABBI_21: {
        code: 'SABBI_21',
        message: 'Unable to add card subscription, too many matches',
        messageHandle: 'Error al suscribir la tarjeta, intenten más tarde.'
    },
    SABBI_22: {
        code: 'SABBI_22',
        message: 'Unable to get user simulation quotas, purchase amount limit exceeded',
        messageHandle: 'Se excedió el límite de compra.'
    },
    SABBI_23: {
        code: 'SABBI_23',
        message: 'Unable to get user simulation quotas, user simulation does not exist',
        messageHandle: 'El usuario no existe.'
    },
    SABBI_24: {
        code: 'SABBI_24',
        message: 'Unable to get user simulation quotas, user does not match requirements',
        messageHandle: 'El usuario no cumple con los requisitos comerciales.'
    },
    SABBI_25: {
        code: 'SABBI_25',
        message: 'Unable to get user simulation quotas, unable to get user simulation',
        messageHandle: 'Error al generar simulación.'
    },
    SABBI_26: {
        code: 'SABBI_26',
        message: 'Unable to sign terms and conditions document',
        messageHandle: 'Error al firmar documento de términos y condiciones.'
    },
    SABBI_30: {
        code: 'SABBI_30',
        message: 'User email already exists',
        messageHandle: 'Este email ya se encuentra asociado a una cuenta Sabbi.'
    },
    SABBI_31: {
        code: 'SABBI_31',
        message: 'User phone already used',
        messageHandle: 'Este teléfono ya se encuentra asociado a una cuenta Sabbi.'
    },
    SABBI_32: {
        code: 'SABBI_32',
        message: 'Business has missing business credentials',
        messageHandle: 'Este comercio no posee credenciales registradas.'
    },
    SABBI_33: {
        code: 'SABBI_33',
        message: 'Error validating business credentials',
        messageHandle: 'Error validando las credenciales de comercio.'
    },
    SABBI_34: {
        code: 'SABBI_34',
        message: 'Unable to delete card suscription, because it has an associated credit',
        messageHandle: 'La tarjeta no se puede eliminar, debido a que tiene un crédito asociado.'
    },
    SABBI_35: {
        code: 'SABBI_35',
        message: 'User is already registered',
        messageHandle: 'El Rut ya se encuentra registrado.'
    },
    SABBI_36: {
        code: 'SABBI_36',
        message: 'User is underage',
        messageHandle: 'El Rut no cumple con la edad mínima legal.'
    },
    SABBI_37: {
        code: 'SABBI_37',
        message: 'Unable to validate user age',
        messageHandle: 'No es posible validar la información de edad del Rut.'
    },
    SABBI_38: {
        code: 'SABBI_38',
        message: 'User document is not Valid',
        messageHandle: 'El Rut no está vigente.'
    },
    SABBI_39: {
        code: 'SABBI_39',
        message: 'User is blocked',
        messageHandle: 'El Rut se encuentra asociado a un usuario bloqueado.'
    },
    SABBI_40: {
        code: 'SABBI_40',
        message: 'Unable to validate user document',
        messageHandle: 'No es posible validar la información del Rut.'
    },
    SABBI_43: {
        code: 'SABBI_43',
        message: 'Invalid card number',
        messageHandle: 'No es posible ingresar esta tarjeta.'
    },
    SABBI_49: {
        code: 'SABBI_49',
        message: 'Invalid card type',
        messageHandle: 'No es posible ingresar este tipo de tarjeta.'
    },
    SABBI_58: {
        code: 'SABBI_58',
        message: 'Unable to subscribe credit card',
        messageHandle: 'No es posible validar tu tarjeta. Por favor intentalo de nuevo.'
    },
    SABBI_59: {
        code: 'SABBI_59',
        message: 'Unable to add card subscription, user already has too many cards.',
        messageHandle: 'No es posible agregar tu tarjeta, excede el límite máximo permitido.'
    }
};

export const SOTER_ERRORS: Record<string, IHandleError> = {
    SOTER_03: {
        code: 'SOTER_03',
        message: 'User account blocked',
        messageHandle: 'Cuenta de usuario bloqueada.'
    }
};
