import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    MyContentBox: {
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        padding: '0px !important',
        borderRadius: '20px',
        margin: 'auto'
    },
    Header: {
        background: theme.palette.grey[100],
        paddingLeft: '3rem',
        paddingRight: '3rem',
        borderTopRightRadius: '20px',
        borderTopLeftRadius: '20px'
    },
    ContainerImgShop: {
        textAlign: 'end',
        marginTop: '30px',
        marginBottom: '30px'
    },
    BusinessLogoContainer: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: '100%',
        display: 'flex',
        height: '80px',
        justifyContent: 'center',
        width: '80px',
    },
    LogoShop: {
        maxHeight: '45px',
        maxWidth: '45px',
    },
    ContainerDetail: {
        paddingLeft: '3rem',
        paddingRight: '3rem',
        marginTop: '10px'
    },
    CustomScrollbar: {
        maxHeight: '310px'
    },
    TableContainer: {
        paddingRight: '15px',
        paddingBottom: '20px',
        '& .MuiTableCell-root': {
            padding: '7px 17px'
        },
        '& .MuiTableRow-root:last-child .MuiTableCell-root': {
          borderBottom: 'none'
        }
    },
    TableCellProduct: {
        width: '80%',
    },
    ShopName: {
        fontFamily: 'ceraRoundProMedium'
    },
    Quantity: {
        fontSize: '12px',
        fontFamily: 'ceraRoundProMedium'
    },
    WhiteGradient:{
        width: '100%',
        bottom: 0,
        height: '50px',
        zIndex: 1000,
        background: 'linear-gradient(180deg,rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .4) 30%, #FFFFFF 100%)',
        marginTop: '-45px',
    },
    ContainerTotal: {
        alignItems: 'center',
        background: theme.palette.primary.light,
        borderRadius: '20px',
        display: 'flex',
        height: '50px',
        marginBottom: '25px',
        marginTop: '50px',
        padding: '0 20px',
    },
    TotalPrice: {
        fontFamily: 'ceraRoundProMedium',
        textAlign: 'right'
    },
}));

export default useStyles;
