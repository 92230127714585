import { FC, ReactNode, useEffect, useState } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext/auth';
import { useBusiness } from '../../context/BusinessContext/business';
import { EModalError } from '../../context/ModalContext/enums/EModalError';
import { useModalExpired } from '../../context/ModalContext/modalExpired';
import { useTpa } from '../../context/TPAContext/tpa';
import { userSubscribedCardsQuantity } from '../../services/sabbi/card/card';
import { getTEInformation, getTPAInformation } from '../../utils/localStorageManipulation';
import { checkBusinessPartnerId } from '../../utils/tokenManipulation';
import { ECardRoutePaths } from '../enum/ECardRoutePaths';
import { ENotAllowedRoutePaths } from '../enum/ENotAllowedRoutePaths';

export interface PublicRouteProps {
    children: ReactNode;
    path: string | string[];
    exact?: boolean;
}

export const PublicRoute: FC<PublicRouteProps> = ({ children, path, ...rest }) => {
    const [tpaValidationInfo, setTpaValidationInfo] = useState<{
        validated: boolean;
        isValid: boolean;
    }>();
    const [teValidationInfo, setTeValidationInfo] = useState<{
        expired: boolean;
        isAuthenticated: boolean;
    }>();
    const [fpValidationInfo, setFpValidationInfo] = useState<{
        validated: boolean;
        isValid: boolean;
    }>();
    const [userCardsInfo, setUserCardsInfo] = useState<{ hasCards: boolean; quantity: number }>();
    const [isLoading, setIsloading] = useState<boolean>(true);
    const tpaContext = useTpa();
    const fpContext = useBusiness();
    const authContext = useAuth();
    const history = useHistory();
    const modalContext = useModalExpired();

    const { checkFinancialProducts } = fpContext;

    useEffect(() => {
        checkTPAValidation();
    }, []);
    useEffect(() => {
        if (tpaValidationInfo) {
            if (tpaValidationInfo.isValid) {
                checkFPValidation();
            } else {
                redirectToNotAllowed();
            }
        }
    }, [tpaValidationInfo]);
    useEffect(() => {
        if (fpValidationInfo) {
            if (fpValidationInfo.isValid) {
                checkTEValidation();
            } else {
                redirectToNotAllowed();
            }
        }
    }, [fpValidationInfo]);
    useEffect(() => {
        if (teValidationInfo) {
            if (teValidationInfo.isAuthenticated) {
                checkUserCardCount();
            } else {
                setIsloading(false);
            }
        }
    }, [teValidationInfo]);
    useEffect(() => {
        if (userCardsInfo) {
            setIsloading(false);
        }
    }, [userCardsInfo]);
    const checkTPAValidation = async () => {
        try {
            if (tpaContext.tokenInfo.tpa) {
                const TPAInfo = await getTPAInformation(tpaContext.tokenInfo.tpa);
                if (!TPAInfo.isExpired) {
                    setTpaValidationInfo({ validated: true, isValid: true });
                } else {
                    setTpaValidationInfo({ validated: true, isValid: false });
                }
            } else {
                setTpaValidationInfo({ validated: true, isValid: false });
            }
        } catch (error) {
            setTpaValidationInfo({ validated: true, isValid: false });
        }
    };
    const checkFPValidation = async () => {
        if (checkFinancialProducts()) {
            setFpValidationInfo({ validated: true, isValid: true });
        } else {
            setFpValidationInfo({ validated: true, isValid: false });
        }
    };
    const checkTEValidation = async () => {
        try {
            if (authContext.user.te) {
                const TEInfo = await getTEInformation(authContext.user.te);
                const { isExpired } = TEInfo;
                if (!isExpired) {
                    const validBusiness = await checkBusinessPartnerId(
                        tpaContext.tokenInfo.tpa,
                        authContext.user.te
                    );
                    if (validBusiness) {
                        setTeValidationInfo({ expired: false, isAuthenticated: true });
                    } else {
                        modalContext.setExpiredTE(true);
                        modalContext.updateModalExpired(EModalError.ERRORCOMERCIO);
                        setTeValidationInfo({ expired: false, isAuthenticated: true });
                    }
                } else {
                    modalContext.setExpiredTE(true);
                    modalContext.updateModalExpired(EModalError.TEEXPIRED);
                    setTeValidationInfo({ expired: true, isAuthenticated: true });
                }
            } else {
                setTeValidationInfo({ expired: false, isAuthenticated: false });
            }
        } catch (error) {
            authContext.cleanTE();
            setTeValidationInfo({ expired: false, isAuthenticated: false });
        }
    };
    const checkUserCardCount = async () => {
        try {
            if (authContext.user.te) {
                if (!teValidationInfo?.expired) {
                    const response = await userSubscribedCardsQuantity({
                        TE: authContext.user.te,
                        TPA: tpaContext.tokenInfo.tpa as string
                    });
                    const { cardsQuantity } = response.payload;
                    setUserCardsInfo({
                        hasCards: cardsQuantity > 0 ? true : false,
                        quantity: cardsQuantity
                    });
                } else {
                    setUserCardsInfo({
                        hasCards: false,
                        quantity: 0
                    });
                }
            } else {
                redirectToNotAllowed();
            }
        } catch (error) {
            setUserCardsInfo({ hasCards: false, quantity: 0 });
        }
    };
    const redirectToNotAllowed = () => {
        history.replace(ENotAllowedRoutePaths.NOT_AUTHORIZED);
    };
    const checkRedirection = (children: ReactNode) => {
        if (teValidationInfo?.isAuthenticated) {
            if (userCardsInfo && !userCardsInfo.hasCards) {
                return (
                    <Redirect
                        to={{
                            pathname: ECardRoutePaths.CARD_SELECT_TYPE
                        }}
                    />
                );
            } else {
                return (
                    <Redirect
                        to={{
                            pathname: ECardRoutePaths.CARD_SELECT
                        }}
                    />
                );
            }
        } else {
            return children;
        }
    };
    if (isLoading) {
        return <></>;
    } else {
        return <Route {...rest} render={() => checkRedirection(children)} />;
    }
};
