import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    SelectPage: {
        maxWidth: '100%',
        '& h2': {
            textAlign: 'center',
            marginBottom: '20px'
        }
    },
    HyperLinkBox: {
        maxWidth: '100%',
        marginBottom: '10px',
        ['@media (max-width:959px)']: {
            width: '600px',
            margin: 'auto',
            marginBottom: '10px'
        }
    },
    HyperLink_GoBack: {
        ['@media (max-width:959px)']: {
            marginLeft: '-9px'
        },
        '& a': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    AlertBar: {
        '&.opened': {
            marginBottom: '1rem'
        }
    },
    MessageBox: {
        marginTop: '15px'
    },
    MessageBox_Message: {
        lineHeight: '20px',
        marginLeft: '15px'
    },
    SelectPageText: {
        textAlign: 'center',
        margin: '1rem auto 2rem auto',
        maxWidth: '400px'
    },
    LogoImage: {
        maxWidth: '100%',
        width: '60px',
        margin: '0 auto 24px auto',
        display: 'flex'
    },
    Form: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '320px',
        margin: '40px auto 0 auto',
        '& .MuiFormControl-root': {
            maxWidth: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    SelectTypeText: {
        fontFamily: 'ceraRoundProBold',
        marginLeft: '20px',
        color: theme.palette.primary.main
    },
    SubmitButton: {
        margin: '1rem 0'
    },
    CancelButton: {
        color: theme.palette.grey[800],
        textTransform: 'none',
        margin: 0,
        '&:hover': {
            backgroundColor: theme.palette.common.white
        }
    },
    ButtonsDiv: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '20px'
    },
    ModalNoCards: {
        textAlign: 'center',
        '& p': {
            margin: 0
        }
    },
    ErrorIcon: {
        marginBottom: '2rem'
    }
}));

export default useStyles;
