import { Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { FC } from 'react';

import ContentBox from '../../components/ContentBox/ContentBox';
import SuccessMessage from '../../components/SuccessMessage/SuccessMessage';
import { deleteLocalStorageAccessData } from '../../utils/localStorageManipulation';
import useStyles from './NotAllowedPageStyles.material';

export const NotAllowedPage: FC = () => {
    useEffect(() => {
        deleteLocalStorageAccessData();
    }, []);
    const classes = useStyles();
    return (
        <section className={classes.CreatedPasswordPage}>
            <ContentBox>
                <SuccessMessage
                    success={false}
                    title="No esta autorizado para ver esta página."
                    continueOnClick={() => {
                        console.log();
                    }}>
                    <Typography className={classes.Message} component="p">
                        No se ha podido acceder a esta página.
                    </Typography>
                </SuccessMessage>
            </ContentBox>
        </section>
    );
};
