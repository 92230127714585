import {
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { checkPass } from '../../../utils/inputHelpers';
import HyperLink from '../../HyperLink/HyperLink';
import IPasswordInputProps from './interfaces/IPasswordInputProps';
import useStyles from './PasswordInputStyles.material';

const PasswordInput: FC<IPasswordInputProps> = ({
    id,
    name,
    classNames = '',
    label,
    forcedError,
    defaultHelperText,
    placeholder = '',
    rules,
    defaultValue = '',
    hyperlinkText = '',
    passwordOnClick
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [touched, setTouched] = useState(false);
    const classes = useStyles();
    const { formState, control } = useFormContext();
    const errors = formState?.errors;
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const checkError = () => {
        if (errors?.[name]) {
            return 'error';
        } else if (touched) {
            return 'success';
        }
    };
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field }) => (
                <FormControl
                    size="small"
                    classes={{ root: classes.root }}
                    className={`${classNames} ${errors?.[name]} ${
                        forcedError ? 'error' : checkError()
                    }`}
                    variant="outlined">
                    {label && (
                        <InputLabel className={classes.label} htmlFor={`${id}`}>
                            {label}
                        </InputLabel>
                    )}
                    <OutlinedInput
                        id={`${id}`}
                        autoComplete="off"
                        className={classes.label}
                        type={showPassword ? 'text' : 'password'}
                        label={label}
                        placeholder={`${placeholder}`}
                        onChange={(value) => {
                            const passValue = checkPass(value.target.value);
                            setTouched(true);
                            field.onChange(passValue);
                        }}
                        onBlur={field.onBlur}
                        value={field.value}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end">
                                    {showPassword ? <LockOpenIcon /> : <LockIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <div className={classes.HelperSection}>
                        <FormHelperText id={`helper-text-${id}`}>
                            {errors?.[name] ? errors?.[name].message : defaultHelperText}
                        </FormHelperText>
                        {hyperlinkText && (
                            <HyperLink
                                classNames={classes.PasswordHyperLink}
                                color="initial"
                                onClick={
                                    passwordOnClick ? passwordOnClick : handleClickShowPassword
                                }>
                                <MoreHorizIcon /> {hyperlinkText}
                            </HyperLink>
                        )}
                    </div>
                </FormControl>
            )}
        />
    );
};

export default PasswordInput;
