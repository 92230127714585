import { Badge, FormControlLabel, Radio, RadioProps, Typography } from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DeleteIcon from '@material-ui/icons/Delete';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { FC } from 'react';

import RadioButtonCheckedIcon from '../../../assets/icons/RadioButtonCheckedIcon.svg';
import { ECardType } from '../../../utils/enums/ECardType';
import { EPaymentMethods } from '../../../utils/enums/EPaymentMethods';
import CreditCardImage from '../../CreditCardIcon/CreditCardImage';
import useStyles from './CardRadioButtonStyles.material';
import ICardRadioButtonProps from './interfaces/ICardRadioButtonProps';
import IRadioBoxButtonProps from './interfaces/IRadioBoxButtonProps';

const RadioBoxButton: FC<IRadioBoxButtonProps> = ({ label }) => {
    const classes = useStyles();

    const renderCardButton = () => {
        if (typeof label === 'object' && label !== null) {
            const { cardBrand, cardMaskedNumber, cardType } = label;
            const maskedCard = cardMaskedNumber.substr(cardMaskedNumber.length - 4);
            return (
                <div className={classes.RadioBoxButton}>
                    <div className={classes.CreditCardDiv}>
                        <CreditCardImage
                            classNames={classes.CreditCardImage}
                            creditCardBrand={cardBrand?.toLowerCase()}
                            showUnknown={true}
                        />
                    </div>

                    <span className={classes.Dots}>····</span>
                    <span className={classes.MaskedCard}>{maskedCard}</span>
                    <Badge
                        className={`${classes.GreyBadge} ${classes.TypeBadge}`}
                        badgeContent={
                            cardType &&
                            (cardType === ECardType.DEBIT || cardType === EPaymentMethods.Debito)
                                ? 'Débito'
                                : 'Crédito'
                        }
                    />
                </div>
            );
        } else {
            return (
                <div className={classes.TypeRadioBoxButton}>
                    <CreditCardIcon className={classes.CreditCardImage} />
                    <Typography variant="subtitle2" component="p">
                        {label}
                    </Typography>
                </div>
            );
        }
    };

    return renderCardButton();
};

const CardRadioButton: FC<ICardRadioButtonProps> = ({
    label,
    value,
    classNames,
    isChecked,
    disabled,
    disabledText,
    idCard = '',
    hasDeleteButton = false,
    deleteOnClick
}) => {
    const classes = useStyles();
    const handleDeleteClick = () => {
        if (deleteOnClick && value) {
            const req = {
                idCard
            };
            deleteOnClick(req);
        }
    };
    const checkRadioGroup = () => {
        if (typeof label === 'object' && label !== null) {
            return classes.CardRadioButton;
        } else {
            return classes.CardTypeRadioButton;
        }
    };

    const StyledRadio = (props: RadioProps) => {
        const classes = useStyles();
        return (
            <Radio
                disableRipple
                color="default"
                checkedIcon={<img src={RadioButtonCheckedIcon} alt="radio-button-checked" />}
                icon={
                    props.disabled && disabledText ? (
                        <Badge
                            className={`${classes.GreyBadge} ${classes.DisabledBadge}`}
                            badgeContent={disabledText}
                        />
                    ) : (
                        <RadioButtonUncheckedIcon />
                    )
                }
                {...props}
            />
        );
    };

    return (
        <div className={classes.RadioButtonContainer}>
            <FormControlLabel
                className={`${classNames} ${checkRadioGroup()} ${isChecked ? 'checked' : null}`}
                value={value}
                disabled={disabled}
                control={<StyledRadio disabled={disabled} />}
                labelPlacement="start"
                label={<RadioBoxButton label={label} />}
            />
            {hasDeleteButton && (
                <DeleteIcon onClick={handleDeleteClick} className={classes.DeleteButton} />
            )}
        </div>
    );
};

export default CardRadioButton;
