import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1000,
            pointerEvents: 'all',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            flexDirection: 'column'
        },
        loader: {
            width: '90px',
            objectFit: 'contain',
            ['@media (max-width:360px)']: {
                width: '70px'
            }
        },
        message: {
            fontFamily: 'ceraRoundProMedium',
            fontSize: '1.1rem',
            color: theme.palette.primary.main,
            maxWidth: '200px',
            textAlign: 'center'
        }
    })
);

export default useStyles;
