import { createContext, FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ENotAllowedRoutePaths } from '../../routes/enum/ENotAllowedRoutePaths';
import { ILogin } from '../../services/sabbi/user/interfaces/ILogin';
import { loginService, logoutService } from '../../services/sabbi/user/user';
import { FrontApplicationError } from '../../utils/errors/FrontApplicationError';
import {
    deleteLocalStorageSessionData,
    getLocalStorageItem,
    getTPAInformation,
    setLocalStorageItem
} from '../../utils/localStorageManipulation';
import { IAuthContext } from './interfaces/IAuthContext';

const setDefaultValues = (): IAuthContext => {
    const defaultValues: IAuthContext = {
        user: {
            te: null
        },
        queueRedirection: '',
        login: () => new Promise((resolve) => resolve(null)),
        logout: () => new Promise((resolve) => resolve(null)),
        setRedirection: () => null,
        updateTE: () => new Promise((resolve) => resolve(null)),
        cleanTE: () => null
    };
    return defaultValues;
};

const authContext = createContext(setDefaultValues());

const useProvideAuth = () => {
    let session: { te: string | null } = { te: null };
    const history = useHistory();
    try {
        const te = getLocalStorageItem('TE');
        if (te) {
            session = {
                te
            };
        }
    } catch (error) {
        history.replace(ENotAllowedRoutePaths.NOT_AUTHORIZED);
    }
    const [user, setUser] = useState<{ te: string | null }>(session);
    const [queueRedirection, setQueueRedirection] = useState<string>();
    const login = async (data: ILogin) => {
        try {
            const TPA = getLocalStorageItem('TPA');
            await getTPAInformation(TPA);
            const response = await loginService(data, TPA);
            const TE = response.payload.TE;
            const isAuth = { te: TE };
            setLocalStorageItem('TE', TE);
            setUser({ ...isAuth });
            return response;
        } catch (error) {
            throw new FrontApplicationError(error);
        }
    };

    const logout = async (TPA: string) => {
        const logOutRequest = {
            TE: user.te as string,
            TPA
        };
        try {
            const response = await logoutService(logOutRequest);
            cleanTE();
            return response;
        } catch (error) {
            throw new FrontApplicationError(error);
        }
    };

    const setRedirection = (data: string) => {
        setQueueRedirection(data);
    };

    const updateTE = (newTE: string) => {
        try {
            setLocalStorageItem('TE', newTE);
            const isAuth = { te: newTE };
            setUser({ ...isAuth });
        } catch (error) {
            throw new FrontApplicationError(error);
        }
    };

    const cleanTE = () => {
        deleteLocalStorageSessionData();
        setUser({ te: null });
    };

    return {
        user,
        queueRedirection,
        login,
        logout,
        setRedirection,
        updateTE,
        cleanTE
    };
};

export const ProvideAuth: FC = ({ children }) => {
    const auth: IAuthContext = useProvideAuth();
    return <authContext.Provider value={auth}> {children} </authContext.Provider>;
};

export const useAuth = () => {
    return useContext(authContext);
};
