import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { FC, useEffect, useState } from 'react';

import { IProgressCircularProps } from './interfaces/IProgressCircularProps';
import useStyles from './ProgressCircularStyles.material';

const ProgressCircular: FC<IProgressCircularProps> = ({
    isDecrement = true,
    min = 0,
    max = 30,
    intervalTick = 1000,
    classNames = '',
    buttonText,
    linkOnClick,
    reset
}) => {
    const defaultProgressValue = isDecrement ? max : min;
    const [progress, setProgress] = useState(defaultProgressValue);
    const classes = useStyles();

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((previousProgress) => {
                if (isDecrement) {
                    if (previousProgress <= min) {
                        clearInterval(timer);
                    }
                } else {
                    if (previousProgress >= max) {
                        clearInterval(timer);
                    }
                }
                const returnValue = isDecrement ? previousProgress - 1 : previousProgress + 1;
                return returnValue;
            });
        }, intervalTick);
        return () => {
            clearInterval(timer);
        };
    }, [intervalTick, isDecrement, max, min, progress]);

    const normalise = (value: number) => ((value - min) * 100) / (max - min);

    const handleOnClick = () => {
        setProgress(max);
        if (linkOnClick) {
            if (reset) {
                reset({
                    otpFieldArray: [
                        {
                            otp: ''
                        },
                        {
                            otp: ''
                        },
                        {
                            otp: ''
                        },
                        {
                            otp: ''
                        }
                    ],
                    keepDefaultValues: true
                });
            }
            linkOnClick();
        }
    };

    const renderButton = () => {
        if (buttonText) {
            return (
                <Button type="button" className={classes.SendButton} onClick={handleOnClick}>
                    <SendIcon className={classes.SendIcon} />
                    {buttonText}
                </Button>
            );
        }
    };

    return (
        <div className={`${classNames}`}>
            {(isDecrement && progress > min) || (!isDecrement && progress < max) ? (
                <Box className={classes.ProgressCircular}>
                    <CircularProgress variant="determinate" value={normalise(progress)} />
                    <Box className={classes.NumberBox}>
                        <Typography className={classes.Number} variant="caption" component="div">
                            {progress}
                        </Typography>
                    </Box>
                </Box>
            ) : (
                renderButton()
            )}
        </div>
    );
};

export default ProgressCircular;
