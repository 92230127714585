// @ts-ignore
import CompactEncrypt from 'jose-browser-runtime/dist/browser/jwe/compact/encrypt';
import { EncryptOptions, KeyLike } from 'jose-browser-runtime/types';

import __RUNTIME_CONFIG__ from '../../envConfig';
import { SABBI_FRONT_ERRORS } from '../../errors/enums/EAppErrors';
import { FrontApplicationError } from '../../errors/FrontApplicationError';
import { utf8Encoder } from '../encode';

/**
 * Generates a compact JWE
 * @param {{publicKey: KeyLike, options: EncryptOptions}} encryptOptions Additional options used to encrypt data
 * @param {string | undefined} dataToEncode Data to be encoded prior encryption
 * @return {Promise<string>} A string JWE representation
 */
export const jweCompactEncryption = async (
    encryptOptions: {
        publicKey: KeyLike;
        options?: EncryptOptions;
    },
    dataToEncode: string | undefined
): Promise<string> => {
    try {
        const encodedData = utf8Encoder(dataToEncode);
        const encriptionOptions = __RUNTIME_CONFIG__.CRIPTOGRAPHY.JWE.encryptionOptions;
        const jwe = await new CompactEncrypt(encodedData)
            .setProtectedHeader(encriptionOptions)
            .encrypt(encryptOptions.publicKey, encryptOptions.options);
        return jwe;
    } catch (error) {
        throw new FrontApplicationError(SABBI_FRONT_ERRORS.SABBI_F_05);
    }
};
