import { createTheme } from '@material-ui/core/styles';

import COLORS from './colors';
import {
    ceraRoundProBold,
    ceraRoundProLight,
    ceraRoundProMedium,
    ceraRoundProRegular
} from './fonts';

const theme = createTheme({
    props: {
        MuiBadge: {
            overlap: 'rectangular'
        }
    },
    palette: {
        common: {
            black: COLORS.black,
            white: COLORS.white
        },
        primary: {
            main: COLORS.violetMain,
            dark: COLORS.violetDark,
            light: COLORS.violetLight
        },
        secondary: {
            light: COLORS.cyanLight,
            main: COLORS.cyanMain,
            dark: COLORS.blueDark
        },
        error: {
            light: COLORS.redLight,
            main: COLORS.red
        },
        warning: {
            main: COLORS.orange,
            light: COLORS.violetAlert
        },
        info: {
            light: COLORS.skyBlueLight,
            main: COLORS.blueDark
        },
        success: {
            main: COLORS.green
        },
        grey: {
            50: COLORS.grey50,
            100: COLORS.grey100,
            200: COLORS.grey200,
            300: COLORS.grey300,
            400: COLORS.grey400,
            500: COLORS.grey500,
            600: COLORS.grey600,
            700: COLORS.grey700,
            800: COLORS.grey800,
            900: COLORS.grey900
        },
        text: {
            primary: COLORS.grey800,
            secondary: COLORS.grey500
        },
        background: {
            default: COLORS.grey50
        }
    },
    typography: {
        fontFamily: 'ceraRoundProRegular',
        body1: {
            fontFamily: 'ceraRoundProRegular',
            fontSize: '14px'
        },
        h1: {
            fontFamily: 'ceraRoundProBold',
            fontSize: '2rem',
            color: COLORS.grey800
        },
        h2: {
            fontFamily: 'ceraRoundProMedium',
            fontSize: '1.3rem',
            color: COLORS.grey800
        },
        h3: {
            fontFamily: 'ceraRoundProRegular',
            fontSize: '1.12rem',
            color: COLORS.grey800
        },
        h4: {
            fontFamily: 'ceraRoundProRegular',
            fontSize: '1rem',
            color: COLORS.grey800
        },
        h5: {
            fontFamily: 'ceraRoundProRegular',
            fontSize: '.88rem',
            color: COLORS.grey800
        },
        h6: {
            fontFamily: 'ceraRoundProRegular',
            fontSize: '.75rem',
            color: COLORS.grey800
        },
        subtitle2: {
            fontFamily: 'ceraRoundProMedium'
        }
    },
    shadows: [
        'none',
        //Paper
        '8px 8px 24px 12px rgba(0, 0, 0, 0.08)',
        //Buttons
        'none',
        '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
        //Button:hover
        '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
        '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
        '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
        '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
        '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
        '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
        '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
        '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
        '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
        '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
        '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
        '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
        '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
        '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
        '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
        '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
        '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
        '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
        '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
        '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
        '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)'
    ],
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [
                    ceraRoundProRegular,
                    ceraRoundProMedium,
                    ceraRoundProBold,
                    ceraRoundProLight
                ]
            }
        },
        MuiInputBase: {
            root: {
                color: COLORS.grey800,
                fontFamily: 'ceraRoundProMedium'
            }
        },
        MuiFormControl: {
            root: {
                width: '460px'
            }
        },
        MuiInputLabel: {
            root: {
                fontSize: '1rem',
                color: COLORS.grey500
            }
        },
        MuiFormHelperText: {
            root: {
                fontSize: '.9rem',
                color: COLORS.grey500
            }
        },
        MuiButton: {
            root: {
                fontSize: '1rem',
                borderRadius: '100px'
            }
        },
        MuiLink: {
            root: {
                color: COLORS.blue
            }
        },
        MuiTooltip: {
            popper: {
                '&[x-placement="bottom-end"]': {
                    marginTop: '-10px'
                }
            }
        }
    }
});
export default theme;
