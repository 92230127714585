import CeraBoldFont from '../assets/fonts/cera-round-pro/cera-round-pro-bold.otf';
import CeraLightFont from '../assets/fonts/cera-round-pro/cera-round-pro-light.otf';
import CeraMediumFont from '../assets/fonts/cera-round-pro/cera-round-pro-medium.otf';
import CeraRegularFont from '../assets/fonts/cera-round-pro/cera-round-pro-regular.otf';

export const ceraRoundProLight = {
    fontFamily: 'CeraRoundProLight',
    fontStyle: 'normal',
    fontWeight: 300,
    src: `
  local('CeraRoundProLight'),
  url(${CeraLightFont})
`,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};
export const ceraRoundProRegular = {
    fontFamily: 'CeraRoundProRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `
    local('CeraRoundProRegular'),
    url(${CeraRegularFont})
  `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};
export const ceraRoundProMedium = {
    fontFamily: 'CeraRoundProMedium',
    fontStyle: 'normal',
    fontWeight: 500,
    src: `
  local('CeraRoundProMedium'),
  url(${CeraMediumFont})
`,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};
export const ceraRoundProBold = {
    fontFamily: 'CeraRoundProBold',
    fontStyle: 'normal',
    fontWeight: 700,
    src: `
local('CeraRoundProBold'),
url(${CeraBoldFont})
`,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};
