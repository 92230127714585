import { Button, Grid, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import chileanCommunesData from '../../assets/data/communeRegion/communes/chilean-communes.json';
import chileanRegionsData from '../../assets/data/communeRegion/regions/chilean-regions.json';
import SabbiLogo from '../../assets/logo/sabbi.svg';
import AlertBar from '../../components/AlertBar/AlertBar';
import ContentBox from '../../components/ContentBox/ContentBox';
import AutocompleteInput from '../../components/FormsContext/AutocompleteInput/AutocompleteInput';
import { IAutocompleteOption } from '../../components/FormsContext/AutocompleteInput/interfaces/IAutocompleteOption';
import { FormContext } from '../../components/FormsContext/FormContext/FormContext';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import TextInput from '../../components/FormsContext/TextInput/TextInput';
import HyperLink from '../../components/HyperLink/HyperLink';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useTpa } from '../../context/TPAContext/tpa';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { EUserRoutePaths } from '../../routes/enum/EUserRoutePaths';
import { IAddress } from '../../utils/address/interfaces/IAddress';
import { cancelTRXandReturnTrade } from '../../utils/clientHelpers';
import { handleErrorMessage } from '../../utils/errors';
import { IErrorValues } from '../../utils/errors/interfaces/IErrorValues';
import { IRegisterContactLocationState } from './interfaces/IRegisterContactLocationState';
import useStyles from './UserRegisterManualAddressPage.material';

type Inputs = {
    region?: IAutocompleteOption;
    commune?: IAutocompleteOption;
    street?: string;
    streetNumber?: string;
    unitNumber?: string;
};

const UserRegisterManualAddressPage = (): JSX.Element => {
    const [errorValues, setErrorValues] = useState<IErrorValues>({
        title: '',
        visible: false
    });
    const [disabledCommuneInput, setDisabledCommuneInput] = useState(true);
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation<IRegisterContactLocationState>();
    const backdrop = useBackdrop();
    const tpaContext = useTpa();
    const authContext = useAuth();
    const { handleErrorRedirection, shouldRedirect } = useRedirection();
    const methods = useForm<FieldValues>({ mode: 'all', defaultValues: {} });

    useEffect(() => {
        if (!validateLocationStateData(location.state)) {
            if (!authContext.user?.te) {
                history.replace(ELoginRoutePaths.LOGIN);
            } else {
                history.replace(ECardRoutePaths.CARD_SELECT);
            }
        }
    }, []);

    const validateLocationStateData = (obj: IRegisterContactLocationState) => {
        if (obj) {
            return 'userRegisterData' in obj;
        }
        return false;
    };

    const getRedirectUrlTrade = async () => {
        backdrop.openBackdrop();
        try {
            await cancelTRXandReturnTrade(tpaContext.tokenInfo.tpa as string);
        } catch (error) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        backdrop.openBackdrop();

        const address: IAddress = {
            country: 'Chile',
            region: data.region?.value as string,
            comuna: data.commune?.value as string,
            street1: data.street as string,
            streetNumber: data.streetNumber as string,
            latitude: 0,
            longitude: 0
        };

        const unitNumber = methods.getValues().unitNumber;

        if (unitNumber && unitNumber != '') address.unitNumber = unitNumber;

        history.replace({
            pathname: EUserRoutePaths.USER_REGISTER_CONTACT,
            state: {
                userRegisterData: location.state.userRegisterData,
                userManualAddress: address
            }
        });

        backdrop.closeBackdrop();
    };

    const handleAlertTimeout = () => {
        setTimeout(() => {
            clearError();
        }, 6000);
    };
    const showError = (title: string) => {
        setErrorValues({
            title: title,
            visible: true
        });
        handleAlertTimeout();
    };
    const clearError = () => {
        setErrorValues({
            title: '',
            visible: false
        });
    };
    const handleCancel = () => {
        history.replace({
            pathname: EUserRoutePaths.USER_REGISTER_CONTACT,
            state: {
                userRegisterData: location.state.userRegisterData
            }
        });
    };

    const [communes, setCommunes] = useState<IAutocompleteOption[]>([]);

    useEffect(() => {
        const getCommunesFromId = (id: string) => {
            const regionCommunes =
                chileanCommunesData[id as string as keyof typeof chileanCommunesData];
            const communesList: IAutocompleteOption[] = [];

            regionCommunes.communes.map(({ code, description }) => {
                communesList.push({ code, value: description });
            });

            if (communesList) {
                setCommunes(communesList);
            }
        };

        const code = methods.getValues('region')?.code;

        methods.setValue('commune', '');
        setCommunes([]);

        if (code) {
            setDisabledCommuneInput(false);
            getCommunesFromId(code);
        } else {
            setDisabledCommuneInput(true);
        }
    }, [methods.watch('region')]);

    const regionsList = () => {
        const regions: IAutocompleteOption[] = [];

        chileanRegionsData.map(({ code, name }) => {
            regions.unshift({ code, value: name });
        });

        return regions;
    };

    return (
        <section className={classes.RegisterManualAddressPage}>
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <div className={classes.HyperLinkBox}>
                        <HyperLink
                            classNames={classes.HyperLink_GoBack}
                            underline="none"
                            onClick={getRedirectUrlTrade}>
                            <ChevronLeftIcon />
                            volver al comercio
                        </HyperLink>
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <ContentBox classNames={classes.ContentBox}>
                        <img className={classes.SabbiLogo} src={SabbiLogo} alt="SabbiLogo" />
                        <Typography variant="h2" component="h2" gutterBottom>
                            Ingresa tu dirección
                        </Typography>
                        <AlertBar
                            classNames={classes.AlertBar}
                            variant="filled"
                            severity="error"
                            message={errorValues.title}
                            open={errorValues.visible}
                            closeOnClick={() => clearError()}
                            icon={<WarningRoundedIcon />}
                        />
                        <Typography className={classes.Subtitle_Text} component="h3" gutterBottom>
                            ¿Dónde vives?
                        </Typography>
                        <FormContext
                            className={classes.Form}
                            id={'RegisterForm'}
                            onSubmit={onSubmit}
                            methods={methods}>
                            <AutocompleteInput
                                name={'region'}
                                id={'region'}
                                type={'text'}
                                label={'Región'}
                                rules={{
                                    required: { value: true, message: 'Selecciona tu región' }
                                }}
                                icon={<ExpandMoreIcon />}
                                options={regionsList()}
                                noOptionsText={'Sin resultados, reintenta'}
                            />
                            <AutocompleteInput
                                name={'commune'}
                                id={'commune'}
                                type={'text'}
                                label={'Comuna'}
                                rules={{
                                    required: { value: true, message: 'Selecciona tu comuna' }
                                }}
                                icon={<ExpandMoreIcon />}
                                options={communes}
                                noOptionsText={'Sin resultados, reintenta'}
                                disabled={disabledCommuneInput}
                            />
                            <TextInput
                                id={'street'}
                                name="street"
                                type={'street'}
                                label={'Calle'}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Escribe tu calle'
                                    }
                                }}
                                icon={<EditIcon />}
                            />
                            <TextInput
                                id={'streetNumber'}
                                name={'streetNumber'}
                                type={'streetNumber'}
                                label={'Nº de calle'}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Ingresa número de calle o "sin número", si aplica'
                                    }
                                }}
                                icon={<EditIcon />}
                            />
                            <TextInput
                                id={'unitNumber'}
                                name={'unitNumber'}
                                type={'unitNumber'}
                                label={'Nº de depto. (opcional)'}
                                icon={<EditIcon />}
                            />
                            <div className={classes.ButtonsBox}>
                                <Button
                                    type="button"
                                    className={classes.CancelButton}
                                    onClick={handleCancel}>
                                    Volver
                                </Button>
                                <RoundButton
                                    classNames={classes.SubmitButton}
                                    id={'submitbtn'}
                                    color="primary"
                                    name={'submitbtn'}
                                    type="submit">
                                    Guardar datos
                                </RoundButton>
                            </div>
                        </FormContext>
                    </ContentBox>
                </Grid>
            </Grid>
        </section>
    );
};
export default UserRegisterManualAddressPage;
