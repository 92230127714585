export const CARD_ROUTE = '/card';

export enum ECardRoutePaths {
    CARD_SUBSCRIBE = '/card/subscribe',
    CARD_SELECT = '/card/select',
    CARD_SELECT_TYPE = '/card/select-type',
    CARD_SUBSCRIBE_DEBIT = '/card/subscribe-debit',
    CARD_PAYMENT_DETAIL = '/card/payment-detail',
    CARD_CONFIRMATION = '/card/confirmation',
    CARD_VALIDATE = '/card/validate'
}

export const CARD_ROUTE_PATHS: ECardRoutePaths[] = Object.values(ECardRoutePaths);
