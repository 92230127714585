import { SABBI_FRONT_ERRORS } from '../errors/enums/EAppErrors';
import { FrontApplicationError } from '../errors/FrontApplicationError';

/**
 * Parses a given string to object
 * @param {string} stringToParse String to parse
 * @return {T} Parsed object from string
 */
export const parseStringToJSON = <T>(stringToParse: string): T => {
    try {
        const parsedObject: T = JSON.parse(stringToParse);
        return parsedObject;
    } catch (error) {
        throw new FrontApplicationError(SABBI_FRONT_ERRORS.SABBI_F_11);
    }
};

/**
 * Stringifies a given object
 * @param {string} objectToStringify String to parse
 * @return {string} Stringified object
 */
export const stringifyJSON = <T>(objectToStringify: T): string => {
    try {
        const parsedObject: string = JSON.stringify(objectToStringify);
        return parsedObject;
    } catch (error) {
        throw new FrontApplicationError(SABBI_FRONT_ERRORS.SABBI_F_11);
    }
};
