import { FormControl, FormHelperText, RadioGroup } from '@material-ui/core';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import BNPLRadioButton from '../BNPLRadioButton/BNPLRadioButton';
import useStyles from './BNPLRadioGroupStyles.material';
import IBNPLRadioGroupProps from './interfaces/IBNPLRadioGroupProps';

const BNPLRadioGroup: FC<IBNPLRadioGroupProps> = ({
    id,
    name,
    classNames = '',
    rules,
    defaultValue = '',
    radioButtons,
    onChange,
    defaultHelperText = ''
}) => {
    const classes = useStyles();
    const { formState, control } = useFormContext();
    const { errors } = formState;
    const handleOnchange = (value: string | number | undefined) => {
        onChange && onChange(value);
    };
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field }) => (
                <FormControl
                    component="fieldset"
                    className={`${classNames} ${classes.BNPLGroupControl} ${
                        errors?.[name] ? 'error' : 'success'
                    }`}>
                    <RadioGroup
                        aria-label={name}
                        value={field.value}
                        id={id}
                        name={name}
                        onChange={(value) => {
                            handleOnchange(value.target.value);
                            field.onChange(value);
                        }}>
                        {radioButtons.map((radioButton) => (
                            <BNPLRadioButton
                                isChecked={field.value == radioButton.value}
                                key={`k-${radioButton.key}`}
                                label={radioButton.label}
                                value={radioButton.value}
                                amount={radioButton.amount}
                                caption={radioButton.caption}
                                priceCaption={radioButton.priceCaption}
                            />
                        ))}
                    </RadioGroup>
                    <FormHelperText className={classes.HelperText} id={`helper-text-${id}`}>
                        {errors?.[name] ? errors?.[name].message : defaultHelperText}
                    </FormHelperText>
                </FormControl>
            )}
        />
    );
};
export default BNPLRadioGroup;
