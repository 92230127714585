import { verifyAndExtractPayloadFromJWT } from '../cryptography/JWManipulation/jwt';
import { IJWTVerification } from '../cryptography/JWManipulation/jwt/interfaces/IJWTVerification';
import { FrontApplicationError } from '../errors/FrontApplicationError';
import { getKeyData, getSPKey } from '../keysManipulation';
import { getTEInformation, getTPAInformation } from '../localStorageManipulation';
import { ITEData } from './interfaces/ITEData';
import { ITPAData } from './interfaces/ITPAData';

/**
 * Run validation on TPA token
 * @param {string} TPA
 * @param {IDevice} currentDevice
 * @return {Promise<IValidateTPA>}
 */
export const validateTPA = async (TPA: string): Promise<IJWTVerification<ITPAData>> => {
    const { ppKey } = await getSPKey();
    const validatedTPA = await verifyAndExtractPayloadFromJWT<ITPAData>(TPA, ppKey, false);
    return validatedTPA;
};

/**
 * Run validation on TE token
 * @param {string} TE
 * @return {Promise<any>}
 */
export const validateTE = async (TE: string): Promise<IJWTVerification<ITEData>> => {
    const { ppKey } = await getSPKey();
    const validatedTE = await verifyAndExtractPayloadFromJWT<ITEData>(TE, ppKey, true);
    return validatedTE;
};

/**
 * Check if TPA businessPartnerId exists in TE
 * @param {string | null} TPA
 * @param {string | null} TE
 * @return {Promise<any>}
 */
export const checkBusinessPartnerId = async (
    TPA: string | null,
    TE: string | null
): Promise<boolean | string> => {
    try {
        const TEInfo = await getTEInformation(TE);
        const TPAInfo = await getTPAInformation(TPA);
        const TPABusinessPartnerId = TPAInfo?.bodyTPA.businessPartner?.businessPartnerId;
        const TPABusinessPartnerUserId =
            TPAInfo?.bodyTPA.businessPartner?.businessPartnerUserData.userId;
        const TEKeyArray = getKeyData(TEInfo.bodyTE.sessionToken);
        const foundKey = TEKeyArray.find((key) => key.businessPartnerId == TPABusinessPartnerId);
        if (foundKey) {
            if (foundKey.businessPartnerUserId == TPABusinessPartnerUserId) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (error) {
        throw new FrontApplicationError(error);
    }
};
