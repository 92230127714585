import {
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MaskedInput from 'react-text-mask';

import { checkExpDate } from '../../../utils/inputHelpers';
import useStyles from './ExpDateInputStyles.material';
import IExpDateInputProps from './interfaces/IExpDateInputProps';

interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
}

const ExpDateInput: FC<IExpDateInputProps> = ({
    id,
    name,
    classNames = '',
    label,
    defaultHelperText,
    placeholder = '',
    rules,
    defaultValue = '',
    icon
}) => {
    const [touched, setTouched] = useState(false);
    const classes = useStyles();
    const { formState, control } = useFormContext();
    const errors = formState?.errors;
    const checkError = () => {
        if (errors?.[name]) {
            return 'error';
        } else if (touched) {
            return 'success';
        }
    };
    const handleRenderIcon = () => {
        if (touched) {
            if (errors?.[name]) {
                return <ClearIcon />;
            } else {
                return <CheckCircleIcon />;
            }
        } else {
            if (errors?.[name]) {
                return <ClearIcon />;
            } else {
                return icon;
            }
        }
    };
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field }) => (
                <FormControl
                    size="small"
                    classes={{ root: classes.root }}
                    className={`${classNames}  ${checkError()}`}
                    variant="outlined">
                    {label && (
                        <InputLabel className={classes.label} htmlFor={`${id}`}>
                            {label}
                        </InputLabel>
                    )}
                    <OutlinedInput
                        autoComplete="off"
                        placeholder={`${placeholder}`}
                        id={`${id}`}
                        className={classes.label}
                        value={field.value}
                        onChange={(value) => {
                            const textValue = checkExpDate(value.target.value);
                            setTouched(true);
                            field.onChange(textValue);
                        }}
                        onBlur={field.onBlur}
                        inputComponent={maskedExpDateInput as any}
                        label={label}
                        type="text"
                        endAdornment={
                            <InputAdornment position="end">{handleRenderIcon()}</InputAdornment>
                        }
                    />
                    <FormHelperText id={`helper-text-${id}`}>
                        {errors?.[name] ? errors?.[name].message : defaultHelperText}
                    </FormHelperText>
                </FormControl>
            )}
        />
    );
};

const maskedExpDateInput = (props: TextMaskCustomProps) => {
    const { inputRef, ...otherProps } = props;
    return (
        <MaskedInput
            {...otherProps}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0-9]/, /\d/, '/', /[0-9]/, /\d/]}
            guide={false}
            placeholderChar={'\u2000'}
        />
    );
};

export default ExpDateInput;
