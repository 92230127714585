import { FC, ReactNode, useEffect, useState } from 'react';
import { Redirect, Route, RouteComponentProps, useHistory, useRouteMatch } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext/auth';
import { useBusiness } from '../../context/BusinessContext/business';
import { EModalError } from '../../context/ModalContext/enums/EModalError';
import { useModalExpired } from '../../context/ModalContext/modalExpired';
import { useTpa } from '../../context/TPAContext/tpa';
import { userSubscribedCardsQuantity } from '../../services/sabbi/card/card';
import { getTEInformation, getTPAInformation } from '../../utils/localStorageManipulation';
import { checkBusinessPartnerId } from '../../utils/tokenManipulation';
import { ECardRoutePaths } from '../enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../enum/ELoginRoutePaths';
import { ENotAllowedRoutePaths } from '../enum/ENotAllowedRoutePaths';

export interface PrivateRouteProps {
    children: ReactNode;
    path: string | string[];
    exact?: boolean;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ children, ...rest }) => {
    const [TPAValidationInfo, setTPAValidationInfo] = useState<{
        validated: boolean;
        isValid: boolean;
    }>();
    const [teValidationInfo, setTeValidationInfo] = useState<{
        expired: boolean;
        isAuthenticated: boolean;
    }>();
    const [fpValidationInfo, setFpValidationInfo] = useState<{
        validated: boolean;
        isValid: boolean;
    }>();
    const [userCardsInfo, setUserCardsInfo] = useState<{ hasCards: boolean; quantity: number }>();
    const [isLoading, setIsloading] = useState<boolean>(true);
    const tpaContext = useTpa();
    const fpContext = useBusiness();
    const authContext = useAuth();
    const modalContext = useModalExpired();
    const history = useHistory();

    const { checkFinancialProducts } = fpContext;

    useEffect(() => {
        isValidTPA();
    }, []);

    useEffect(() => {
        if (TPAValidationInfo) {
            if (TPAValidationInfo.isValid) {
                checkFPValidation();
            } else {
                redirectToNotAllowed();
            }
        }
    }, [TPAValidationInfo]);

    useEffect(() => {
        if (fpValidationInfo) {
            if (fpValidationInfo.isValid) {
                checkTEValidation();
            } else {
                redirectToNotAllowed();
            }
        }
    }, [fpValidationInfo]);
    useEffect(() => {
        if (teValidationInfo) {
            if (teValidationInfo.isAuthenticated && !teValidationInfo.expired) {
                checkUserCardCount();
            } else {
                setIsloading(false);
            }
        }
    }, [teValidationInfo]);
    useEffect(() => {
        if (userCardsInfo) {
            setIsloading(false);
        }
    }, [userCardsInfo]);
    const isValidTPA = async () => {
        try {
            if (tpaContext.tokenInfo.tpa) {
                const TPAInfo = await getTPAInformation(tpaContext.tokenInfo.tpa);
                if (!TPAInfo.isExpired) {
                    setTPAValidationInfo({ validated: true, isValid: true });
                } else {
                    setTPAValidationInfo({ validated: true, isValid: false });
                }
            } else {
                setTPAValidationInfo({ validated: true, isValid: false });
            }
        } catch (error) {
            redirectToNotAllowed();
        }
    };
    const checkFPValidation = async () => {
        if (checkFinancialProducts()) {
            setFpValidationInfo({ validated: true, isValid: true });
        } else {
            setFpValidationInfo({ validated: true, isValid: false });
        }
    };
    const checkTEValidation = async () => {
        try {
            if (authContext.user.te) {
                const TEInfo = await getTEInformation(authContext.user.te);
                const { isExpired } = TEInfo;
                if (!isExpired) {
                    const validBusiness = await checkBusinessPartnerId(
                        tpaContext.tokenInfo.tpa,
                        authContext.user.te
                    );
                    if (validBusiness) {
                        setTeValidationInfo({ expired: false, isAuthenticated: true });
                    } else {
                        modalContext.setExpiredTE(true);
                        modalContext.updateModalExpired(EModalError.ERRORCOMERCIO);
                        setTeValidationInfo({ expired: false, isAuthenticated: true });
                    }
                } else {
                    modalContext.setExpiredTE(true);
                    modalContext.updateModalExpired(EModalError.TEEXPIRED);
                    setTeValidationInfo({ expired: true, isAuthenticated: true });
                }
            } else {
                history.replace(ELoginRoutePaths.LOGIN);
            }
        } catch (error) {
            authContext.cleanTE();
            history.replace(ELoginRoutePaths.LOGIN);
        }
    };
    const checkUserCardCount = async () => {
        try {
            if (authContext.user.te) {
                const response = await userSubscribedCardsQuantity({
                    TE: authContext.user.te,
                    TPA: tpaContext.tokenInfo.tpa as string
                });
                const { cardsQuantity } = response.payload;
                setUserCardsInfo({
                    hasCards: cardsQuantity > 0 ? true : false,
                    quantity: cardsQuantity
                });
            } else {
                history.replace(ELoginRoutePaths.LOGIN);
            }
        } catch (error) {
            if (error.code == 'SABBI_19') {
                authContext.cleanTE();
                history.replace(ELoginRoutePaths.LOGIN);
            } else {
                setUserCardsInfo({ hasCards: true, quantity: 0 });
            }
        }
    };
    const redirectToNotAllowed = () => {
        history.replace(ENotAllowedRoutePaths.NOT_AUTHORIZED);
    };
    const checkValidLocation = (locationString: string) => {
        switch (locationString) {
            case ECardRoutePaths.CARD_SUBSCRIBE:
            case ECardRoutePaths.CARD_SELECT_TYPE:
            case ECardRoutePaths.CARD_VALIDATE:
            case ECardRoutePaths.CARD_CONFIRMATION:
                return true;
            default:
                return false;
        }
    };

    const checkRedirection = (children: ReactNode, locationObj: RouteComponentProps) => {
        if (teValidationInfo?.isAuthenticated) {
            if (userCardsInfo && !userCardsInfo.hasCards) {
                if (checkValidLocation(locationObj.location.pathname)) {
                    return children;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: ECardRoutePaths.CARD_SELECT_TYPE
                            }}
                        />
                    );
                }
            } else {
                return children;
            }
        } else {
            return (
                <Redirect
                    to={{
                        pathname: ELoginRoutePaths.LOGIN
                    }}
                />
            );
        }
    };

    if (isLoading) {
        return <></>;
    } else {
        return <Route {...rest} render={(location) => checkRedirection(children, location)} />;
    }
};
