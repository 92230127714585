import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    CardValidationPage: {
        maxWidth: '100%',
        '& h2': {
            textAlign: 'center',
            marginBottom: '1rem'
        }
    },
    HyperLinkBox: {
        maxWidth: '100%',
        marginBottom: '10px',
        ['@media (max-width:959px)']: {
            width: '600px',
            margin: 'auto',
            marginBottom: '10px'
        }
    },
    HyperLink_GoBack: {
        ['@media (max-width:959px)']: {
            marginLeft: '-9px'
        },
        '& a': {
            fontFamily: 'ceraRoundProMedium',
            display: 'flex',
            alignItems: 'center'
        }
    },
    ContentBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    SabbiLogo: {
        width: '60px',
        height: '60px',
        alignSelf: 'center',
        marginBottom: '1.9rem'
    },
    CardValidationText: {
        textAlign: 'center',
        margin: '0 auto 1rem auto'
    },
    DisclaimerText: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '1rem',
        alignItems: 'center',
        '& svg': {
            marginRight: '5px'
        }
    },
    AlertBar: {
        '&.opened': {
            marginBottom: '1rem'
        }
    },
    ValueDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    OtpInput: {
        marginBottom: '.8rem',
    },
    CurrencySpan: {
        fontSize: '2rem',
        marginBottom: '10px',
        marginRight: '5px',
        color: theme.palette.primary.main,
        fontFamily: 'ceraRoundProMedium'
    },
    CurrencySpan_Disabled: {
        color: theme.palette.grey[400]
    },
    MaxAttemptsMessage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    MaxAttemptsMessage_ErrorIcon: {
        color: theme.palette.error.main,
        marginRight: '.5rem'
    },
    ButtonsBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      ['@media (max-width:450px)']: {
        flexDirection: 'column-reverse'
      },
    },
    CancelButton: {
        color: theme.palette.grey[800],
        width: '185px',
        maxWidth: '225px',
        minWidth: '185px',
        textTransform: 'none',
        margin: '1rem',
        ['@media (max-width:450px)']: {
          maxWidth: '100%',
          width: '100%',
          margin: '1rem 0',
        }
    },
    SubmitButton: {
        maxWidth: '225px',
        alignSelf: 'center',
        ['@media (max-width:450px)']: {
          width: '100%',
          maxWidth: '100%',
          margin: '1rem 0',
        }
    },
    CancelModal: {
        textAlign: 'center',
        '& p': {
            margin: 0
        }
    }
}));

export default useStyles;
