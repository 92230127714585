import { makeStyles } from '@material-ui/core/styles';
import Background from '../../assets/backgrounds/background.svg';

const useStyles = makeStyles((_theme) => ({
    HeaderFooterLayout: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundPosition: 'left top',
        backgroundImage: `url(${Background})`,
        backgroundRepeat: 'no-repeat',
        maxHeight: '100vh',
        overflow: 'auto'
    },
    HeaderFooterLayout_Page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '2rem',
        paddingTop: '2rem',
        ['@media (max-width:959px)']: {
            paddingTop: '12px'
        }
    },
    HeaderFooterLayout_Footer: {
        marginTop: 'auto'
    }
}));

export default useStyles;
