import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import SabbiLogoSrc from '../../assets/logo/sabbi.svg';
import ContentBox from '../../components/ContentBox/ContentBox';
import BNPLRadioButton from '../../components/FormsContext/BNPLRadioButton/BNPLRadioButton';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import HyperLink from '../../components/HyperLink/HyperLink';
import ProgressCircular from '../../components/ProgressCircular/ProgressCircular';
import RejectedBNPL from '../../components/RejectedBNPL/RejectedBNPL';
import SelectedCard from '../../components/SelectedCard/SelectedCard';
import SuccessCheckoutTable from '../../components/SuccessCheckoutTable/SuccessCheckoutTable';
import SuccessMessage from '../../components/SuccessMessage/SuccessMessage';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useTpa } from '../../context/TPAContext/tpa';
import { useQuery } from '../../hooks/useQuery';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { saveWpTransaction } from '../../services/sabbi/transaction/transaction';
import { thousandSeparator } from '../../utils/clientHelpers';
import { extractPayloadFromJWT } from '../../utils/cryptography/JWManipulation/jwt';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { IDevice } from '../../utils/device/interfaces/IDevice';
import { EPaymentMethod } from '../../utils/enums/EPaymentMethod';
import { servicesErrorHandler } from '../../utils/errors';
import {
    deleteLocalStorageAccessData,
    getTPAInformation
} from '../../utils/localStorageManipulation';
import { removeLocalStorageItem } from '../../utils/localStorageManipulation/index';
import { IBnplPayments } from '../CardPaymentDetailPage/interfaces/IBnplPayments';
import useStyles from './CheckoutPageStyles.material';
import { ICheckoutPageLocationState } from './interfaces/ICheckoutPageLocationState';
import { ICheckoutSuccesInfoState } from './interfaces/ICheckoutSuccessInfoState';
import { ISelectedBNPLState } from './interfaces/ISelectedBNPLState';
import { ITableData } from './interfaces/ITableData';
import { ITransactionResponse } from './interfaces/ITransactionResponse';

const CheckoutPage: FC = () => {
    const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(true);
    const [tableData, setTableData] = useState<Array<Record<string, string>>>([{}]);
    const [tableHeaders, setTableHeaders] = useState<Array<Record<string, string>>>([{}]);
    const [CheckoutInfo, setCheckoutInfo] = useState<ICheckoutSuccesInfoState>();
    const [selectedBNPL, setSelectedBNPL] = useState<ISelectedBNPLState>();
    const [BnplPayments, setBnplPayments] = useState<IBnplPayments[]>([]);
    const [businessPartnerUrl, setBusinessPartnerUrl] = useState<string>('');
    const [hasBnpl, setHasBnpl] = useState<boolean>(false);

    const classes = useStyles();
    const { handleErrorRedirection, shouldRedirect } = useRedirection();
    const location = useLocation<ICheckoutPageLocationState>();
    const backdrop = useBackdrop();
    const history = useHistory();
    const tpaContext = useTpa();
    const authContext = useAuth();
    const query = useQuery(location.search);

    useEffect(() => {
        const token = query.get('token');
        removeLocalStorageItem('publicMerchantId');
        if (token) {
            handleWPTransaction(token);
        } else {
            if (validateLocationStateData(location.state)) {
                handleOneClickTransaction();
            } else {
                if (!authContext.user?.te) {
                    history.replace(ELoginRoutePaths.LOGIN);
                } else {
                    history.replace(ECardRoutePaths.CARD_SELECT);
                }
            }
        }
    }, []);

    useEffect(() => {
        if (businessPartnerUrl !== '') {
            handleTimeOut();
        }
    }, [businessPartnerUrl]);

    /**
     * Gets TPA Information
     * @param {ICheckoutPageLocationState} obj Location state object
     * @return {boolean}
     */
    const validateLocationStateData = (obj: ICheckoutPageLocationState): boolean => {
        if (obj) {
            return 'success' in obj && 'transaction' in obj;
        }
        return false;
    };

    /**
     * Handle WebPayPlus Transaction
     * @param {string} token token obtained from query params
     * @return {Promise<void>}
     */
    const handleWPTransaction = async (token: string): Promise<void> => {
        backdrop.openBackdrop();
        let businessUrl;
        try {
            const tpaInfo = await getTPAInformation(tpaContext.tokenInfo.tpa);
            businessUrl = tpaInfo.bodyTPA.businessPartner?.responseUrl as string;
            const device: IDevice = getDataDevice();
            const requestGetStatus = {
                token,
                TPA: tpaContext.tokenInfo.tpa as string,
                TE: authContext.user?.te as string,
                device
            };
            const responseGetStatus = await saveWpTransaction(requestGetStatus);
            const extractedTT = extractPayloadFromJWT(responseGetStatus.payload.TT);
            handleTableHeadersFromLocation(extractedTT as ITransactionResponse);
            handleTableDataFromLocation(extractedTT as ITransactionResponse);
            handleCheckoutInfoFromLocation(extractedTT as ITransactionResponse);
            setBusinessPartnerUrl(`${businessUrl}/?TT=${responseGetStatus.payload.TT}`);
            setPaymentSuccess(true);
        } catch (error: any) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                const serviceError = servicesErrorHandler(error);
                setBusinessPartnerUrl(`${businessUrl}/?TT=${serviceError.meta?.TT}`);
                setPaymentSuccess(false);
            }
        } finally {
            setIsBusy(false);
            backdrop.closeBackdrop();
        }
    };

    /**
     * Handle OneClick Transaction
     * @return {Promise<void>}
     */
    const handleOneClickTransaction = async (): Promise<void> => {
        backdrop.openBackdrop();
        let businessUrl;
        try {
            const tpaInfo = await getTPAInformation(tpaContext.tokenInfo.tpa);
            businessUrl = tpaInfo.bodyTPA.businessPartner?.responseUrl as string;
            if (location.state.success) {
                if (location.state.paymentMethods === EPaymentMethod.PAYMENTPSP) {
                    const extractedTT = extractPayloadFromJWT(location.state.transaction.TT);
                    handleTableHeadersFromLocation(extractedTT as ITransactionResponse);
                    handleTableDataFromLocation(extractedTT as ITransactionResponse);
                    handleCheckoutInfoFromLocation(extractedTT as ITransactionResponse);
                }
                if (location.state.paymentMethods === EPaymentMethod.BNPL) {
                    const extractedTT = extractPayloadFromJWT(location.state.transaction.TT);
                    const transactionBNPL = extractedTT as ITransactionResponse;
                    const dataBNPL = transactionBNPL.metaInfo.simulationSelected;
                    const detailBNPL: ISelectedBNPLState = {
                        label: `${transactionBNPL.metaInfo.simulationSelected?.plazo} cuotas Sabbi`,
                        amount: Number(transactionBNPL.metaInfo.simulationSelected?.valorCuota),
                        caption: `Pago primera cuota  ${location.state.bnplDetailCuotas[0].date}`,
                        priceCaption: `${transactionBNPL.metaInfo.simulationSelected?.costoTotal}`,
                        tasaAnual: String(transactionBNPL.metaInfo.simulationSelected?.tasaAnual),
                        tasaInteres: String(
                            transactionBNPL.metaInfo.simulationSelected?.tasaInteres
                        ),
                        value: `${dataBNPL?.id}`,
                        key: `BNPL-${transactionBNPL.metaInfo.simulationSelected?.plazo}-${transactionBNPL.metaInfo.simulationSelected?.codAutorizacion}`,
                        interes: Number(transactionBNPL.metaInfo.simulationSelected?.interes),
                        CAE: String(transactionBNPL.metaInfo.simulationSelected?.cae)
                    };
                    setSelectedBNPL(detailBNPL);
                    handleTableHeaders(
                        tpaInfo.bodyTPA.businessPartner?.businessPartnerName as string
                    );
                    const tableData = {
                        created: new Date(transactionBNPL.metaInfo.transaction.createdAt).getTime(),
                        ticketNumber: transactionBNPL.metaInfo.transaction.trxId
                    };
                    handleTableData(tableData);
                    const checkoutInfo = {
                        amount: Number(transactionBNPL.metaInfo.simulationSelected?.valorCuota),
                        cardType: '',
                        numberCard: ``,
                        paymentBrand: '',
                        transactionId: tpaInfo.bodyTPA.metadata?.trxPmi as string,
                        businessPartnerLogo: tpaInfo.bodyTPA.businessPartner
                            ?.businessPartnerLogo as string,
                        merchantName: tpaInfo.bodyTPA.businessPartner?.businessPartnerName as string
                    };
                    setBnplPayments(location.state.bnplDetailCuotas);
                    setCheckoutInfo(checkoutInfo);
                    setHasBnpl(true);
                }
            }
            setBusinessPartnerUrl(`${businessUrl}/?TT=${location.state.transaction.TT}`);
            setPaymentSuccess(location.state.success);
        } catch (error: any) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                setBusinessPartnerUrl(`${businessUrl}/?TT=${location.state.transaction.TT}`);
                setPaymentSuccess(false);
            }
        } finally {
            setIsBusy(false);
            backdrop.closeBackdrop();
        }
    };

    /**
     * Handle user auto redirection
     * @return {void}
     */
    const handleTimeOut = (): void => {
        setTimeout(() => {
            handleClose();
        }, 45000);
    };

    /**
     * Handle user redirection
     * @return {void}
     */
    const handleClose = (): void => {
        backdrop.openBackdrop();
        deleteLocalStorageAccessData();
        window.location.href = businessPartnerUrl;
        backdrop.closeBackdrop();
    };

    /**
     * Sets table data (WebPayPLus)
     * @param {ITableData} tableData
     * @return {void}
     */
    const handleTableData = (tableData: ITableData) => {
        const date = moment(tableData.created).format('DD-MM-YYYY');
        const time = moment(tableData.created).format('LT');
        const TABLE_DATA: Array<Record<string, string>> = [
            { text: 'Fecha de compra', value: date },
            { text: 'Hora de compra', value: time },
            { text: 'Cód. autorización', value: tableData.ticketNumber }
        ];
        setTableData(TABLE_DATA);
    };

    /**
     * Sets table headers (WebPayPLus)
     * @param {string} businessPartner
     * @return {void}
     */
    const handleTableHeaders = (businessPartner: string): void => {
        const TABLE_HEADERS: Array<Record<string, string>> = [
            {
                text: 'Comercio',
                value: businessPartner
            }
        ];
        setTableHeaders(TABLE_HEADERS);
    };

    /**
     * Sets table data from location (OneClick)
     * @param {ITransactionResponse} transactionResponse
     * @return {void}
     */
    const handleTableDataFromLocation = (transactionResponse: ITransactionResponse): void => {
        const date = moment(transactionResponse.metaInfo.transaction.createdAt).format(
            'DD-MM-YYYY'
        );
        const time = moment(transactionResponse.metaInfo.transaction.createdAt).format('LT');
        const TABLE_DATA: Array<Record<string, string>> = [
            { text: 'Fecha de compra', value: date },
            { text: 'Hora de compra', value: time },
            {
                text: 'Cód. autorización',
                value: String(
                    transactionResponse.metaInfo.transaction.psp?.moreDetails?.ticketNumber
                )
            }
        ];
        setTableData(TABLE_DATA);
    };

    /**
     * Sets table headers from location (OneClick)
     * @param {ITransactionResponse} transactionResponse
     * @return {void}
     */
    const handleTableHeadersFromLocation = (transactionResponse: ITransactionResponse): void => {
        const TABLE_HEADERS: Array<Record<string, string>> = [
            {
                text: 'Comercio',
                value: transactionResponse?.metaInfo.transaction.psp?.moreDetails?.merchantName
                    ? transactionResponse?.metaInfo.transaction.psp?.moreDetails?.merchantName
                    : 'Comercio Asociado'
            }
        ];
        setTableHeaders(TABLE_HEADERS);
    };

    /**
     * Sets checkout information from location (OneClick)
     * @param {ITransactionResponse} transactionResponse
     * @return {Promise<void>}
     */
    const handleCheckoutInfoFromLocation = async (
        transactionResponse: ITransactionResponse
    ): Promise<void> => {
        const tpaInfo = await getTPAInformation(tpaContext.tokenInfo.tpa);
        const cardInfo: ICheckoutSuccesInfoState = {
            amount: Number(transactionResponse.metaInfo.transaction.approvedTransactionAmount),
            cardType: transactionResponse.metaInfo.transaction.psp?.moreDetails?.binInfo.type || '',
            numberCard: `XXXX XXXX XXXX ${transactionResponse.metaInfo.transaction.psp?.moreDetails?.lastFourDigits}`,
            paymentBrand:
                transactionResponse.metaInfo.transaction.psp?.moreDetails?.paymentBrand || '',
            transactionId: transactionResponse.metaInfo.transaction.transactionId,
            businessPartnerLogo: tpaInfo.bodyTPA.businessPartner?.businessPartnerLogo as string,
            merchantName: String(
                transactionResponse.metaInfo.transaction.psp?.moreDetails?.merchantName
            )
        };
        setCheckoutInfo(cardInfo);
    };

    /**
     * Render success checkout
     */
    const renderSuccess = () => (
        <div className={classes.RenderCheckout}>
            <Grid container justifyContent="space-between" className={classes.HeaderSucces}>
                <Grid item lg={2} md={2}>
                    <img
                        className={classes.partnerLogo}
                        src={
                            CheckoutInfo?.businessPartnerLogo
                                ? CheckoutInfo?.businessPartnerLogo
                                : SabbiLogoSrc
                        }
                        alt="Company logo"
                    />
                </Grid>
                <Grid item lg={8} md={8}>
                    <Typography className={classes.Title} variant="h2" component="h2">
                        ¡Compra exitosa!
                    </Typography>
                    <Typography className={classes.Title} variant="h4" component="h4">
                        ID compra {CheckoutInfo?.transactionId}
                    </Typography>
                </Grid>
                <Grid item lg={2} md={2}>
                    <CheckCircleIcon className={`${classes.SuccessIcon} success`} />
                </Grid>
            </Grid>

            <Grid container className={classes.bodySucces}>
                {!hasBnpl ? (
                    <>
                        <Typography className={classes.Title} variant="h4" component="h4">
                            Compra realizada con la tarjeta
                        </Typography>
                        <Grid item lg={12} xs={12}>
                            <SelectedCard
                                cardBrand={CheckoutInfo?.paymentBrand as string}
                                cardMaskedNumber={CheckoutInfo?.numberCard as string}
                                cardType={CheckoutInfo?.cardType as string}
                                classNames={classes.SelectedCard}
                            />
                        </Grid>
                    </>
                ) : (
                    <></>
                )}
                <BNPLRadioButton
                    classNames={classes.BNPLRadioButton}
                    isChecked={false}
                    key={`k-${selectedBNPL?.key}`}
                    label={selectedBNPL?.label ? selectedBNPL?.label : 'Total compra'}
                    value={selectedBNPL?.value ? selectedBNPL?.value : ''}
                    amount={
                        selectedBNPL?.amount
                            ? Number(selectedBNPL?.amount)
                            : Number(CheckoutInfo?.amount)
                    }
                    caption={selectedBNPL?.caption ? selectedBNPL?.caption : ''}
                    priceCaption={
                        selectedBNPL?.priceCaption
                            ? `Costo Total $ ${thousandSeparator(
                                  Number(selectedBNPL?.priceCaption),
                                  '.'
                              )}`
                            : ''
                    }
                />
                {hasBnpl ? (
                    <TableContainer className={classes.Table_Container}>
                        <Table aria-label="success-checkout-table">
                            <TableHead className={classes.TableHead}>
                                <TableRow>
                                    <TableCell
                                        className={classes.CellHead}
                                        component="th"
                                        scope="row">
                                        Costo total
                                    </TableCell>
                                    <TableCell
                                        className={classes.CellHead}
                                        component="th"
                                        align="right">
                                        ${' '}
                                        {selectedBNPL?.priceCaption &&
                                            thousandSeparator(
                                                Number(selectedBNPL?.priceCaption),
                                                '.'
                                            )}
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.CellBody} scope="row">
                                        <Typography component="p">Tasa de interés</Typography>
                                        <Typography variant="caption">Mensual</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        {selectedBNPL?.tasaInteres}%
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.CellBody} scope="row">
                                        <Typography component="p">Tasa de interés</Typography>
                                        <Typography variant="caption">Anual</Typography>
                                    </TableCell>
                                    <TableCell align="right">{selectedBNPL?.tasaAnual} %</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.CellBody} scope="row">
                                        <Typography component="p">CAE</Typography>
                                    </TableCell>
                                    <TableCell align="right">{selectedBNPL?.CAE}%</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.CellBody} scope="row">
                                        <Typography component="p">1° Vencimiento</Typography>
                                        <Typography variant="caption">
                                            1era cuota ${' '}
                                            {BnplPayments[0]?.amount ? (
                                                thousandSeparator(BnplPayments[0].amount, '.')
                                            ) : (
                                                <></>
                                            )}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">{BnplPayments[0].date}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <></>
                )}

                <SuccessCheckoutTable
                    classNames={classes.CheckoutTable}
                    tableData={tableData}
                    tableHeaders={tableHeaders}
                />
                <div className={classes.ButtonsBox}>
                    <div className={classes.ProgressDiv}>
                        <ProgressCircular max={45} />
                        <Typography variant="caption" component="p">
                            Serás redirigido automáticamente al comercio
                        </Typography>
                    </div>

                    <RoundButton
                        classNames={classes.RoundButton}
                        type="button"
                        onClick={handleClose}>
                        Volver al comercio
                    </RoundButton>
                </div>
            </Grid>
        </div>
    );

    /**
     * Render error checkout
     */
    const renderError = () => (
        <SuccessMessage
            success={paymentSuccess}
            title={'No se pudo procesar tu pago'}
            continueOnClick={handleClose}
            cancelOnClick={handleClose}
            continueText={'Continuar'}>
            {location.state.paymentMethods === EPaymentMethod.BNPL &&
            location.state.bnplRejectionReasons ? (
                <div style={{ marginBottom: '1rem' }}>
                    <RejectedBNPL rejectionReasons={location.state.bnplRejectionReasons} />
                </div>
            ) : (
                <Typography component="p" className={classes.Message} gutterBottom>
                    <b>¡Lo sentimos!</b> Por error de sistema no podemos procesar tu transacción.
                    Por favor vuelve a intentarlo.
                </Typography>
            )}
            <div className={classes.ProgressDiv}>
                <ProgressCircular max={45} />
                <Typography variant="caption" component="p">
                    Serás redirigido automáticamente al comercio
                </Typography>
            </div>
        </SuccessMessage>
    );

    return !isBusy ? (
        <section className={classes.CheckoutPage}>
            <Grid item xs={12} lg={12}>
                <div className={classes.HyperLinkBox}>
                    <HyperLink
                        classNames={classes.HyperLink_GoBack}
                        underline="none"
                        onClick={handleClose}>
                        <ChevronLeftIcon />
                        volver al comercio
                    </HyperLink>
                </div>
            </Grid>
            <ContentBox classNames={paymentSuccess ? classes.ContentBox : undefined}>
                {paymentSuccess ? renderSuccess() : renderError()}
            </ContentBox>
        </section>
    ) : (
        <></>
    );
};
export default CheckoutPage;
