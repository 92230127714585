import { Container, Grid, Typography } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { FC } from 'react';

import HyperLink from '../HyperLink/HyperLink';
import useStyles from './BasicFooterStyles.material';

const BasicFooter: FC = () => {
    const classes = useStyles();
    return (
        <footer>
            <Container className={classes.BasicFooter}>
                <Grid container>
                    <Grid item xs={12} md={6} lg={6}>
                        <HyperLink classNames={classes.PrivacyText} href="mailto:contacto@sabbi.cl">
                            <MailOutlineIcon className={classes.PrivacyText_Icon} /> Contacto
                        </HyperLink>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Typography className={classes.CopyrightText}>
                            Copyright © 2019-2023 Sabbi Chile Ltda.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
};

export default BasicFooter;
