import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    SuccessCheckoutTable: {
        '& .MuiTableCell-body': {
            padding: '9px',
            marginTop: '50px',
            fontSize: '1rem',
            wordBreak: 'break-all'
        }
    },
    ValueCell: {
        fontFamily: 'ceraRoundProBold'
    },
    TotalPrice: {
        fontFamily: 'ceraRoundProBold',
        textAlign: 'right'
    },
    ShopName: {
        fontFamily: 'ceraRoundProBold'
    },
    containerTotal: {
        borderRadius: '18px 18px 0 0',
        padding: '18px 10px 10px 18px',
        marginTop: '30px',
        backgroundColor: theme.palette.grey[200]
    }
}));

export default useStyles;
