import { makeStyles } from '@material-ui/core/styles';
import { FontDownload } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    RegisterContactPage: {
        maxWidth: '100%',
        '& h2': {
            textAlign: 'center',
            marginBottom: '2rem'
        }
    },
    HyperLinkBox: {
      maxWidth: '100%',
      marginBottom: '10px',
      ['@media (max-width:959px)']: {
          width: '600px',
          margin: 'auto',
          marginBottom: '10px'
      }
    },
    HyperLink_GoBack: {
      ['@media (max-width:959px)']: {
          marginLeft: '-9px'
      },
      '& a': {
          display: 'flex',
          alignItems: 'center'
      }
    },  
    ContentBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    Form: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& .MuiFormControl-root': {
            maxWidth: '100%',
            marginBottom: '30px',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        '& .MuiFormControl-root:last-of-type': {
            marginBottom: '20px'
        }
    },
    SubmitButton: {
        maxWidth: '225px',
        alignSelf: 'center',
        ['@media (max-width:450px)']: {
          width: '100%',
          maxWidth: '100%',
          margin: '1rem 0',
        }
    },
    CancelButton: {
        color: theme.palette.grey[800],
        width: '185px',
        maxWidth: '225px',
        minWidth: '185px',
        textTransform: 'none',
        margin: '1rem',
        ['@media (max-width:450px)']: {
          maxWidth: '100%',
          width: '100%',
          margin: '1rem 0',
        }
    },
    ButtonsBox: {
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        padding: '0 22px',
        ['@media (max-width:450px)']: {
          flexDirection: 'column-reverse'
        }
    },
    SabbiLogo: {
        width: '60px',
        height: '60px',
        alignSelf: 'center',
        marginBottom: '1.9rem'
    },
    ModalNotNumber_SabbiNoBorder:{
        marginBottom: '2rem'
    },
    CancelModal: {
        textAlign: 'center',
        '& p': {
            margin: 0
        }
    },
    AlertBar: {
        '&.opened': {
            marginBottom: '1rem'
        }
    },
    Tooltip_Text: {
        color: theme.palette.common.white,
        fontSize: '.8rem',
        marginTop: 0,
        lineHeight: '1.5em',
        textAlign: 'left'
    },
    Tooltip_Head: {
        color: theme.palette.secondary.light,
        textAlign: 'left',
        fontSize: '.9rem',
        lineHeight: '1.1rem'
    },
    Ocupation_Input: {
        marginTop: '18px'
    },
    WarningText: {
        textAlign: 'center',
        maxWidth: '500px',
        margin: 'auto'
    }
}));

export default useStyles;
