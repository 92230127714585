import { ISabbiConfig } from './interfaces/ISabbiConfig';
import { parseStringToJSON } from './objectManipulation';

const getEnvConfig = () => {
    const configObject = parseStringToJSON<ISabbiConfig>(
        process.env.REACT_APP_SABBI_CONFIG as string
    );
    return configObject;
};

const __RUNTIME_CONFIG__ = getEnvConfig();

export default __RUNTIME_CONFIG__;
