import { CardContent, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { FC } from 'react';

import useStyles from './DocumentsListStyle.material';
import { IDocumentsList } from './interfaces/IDocumentsList';

const DocumentsList: FC<IDocumentsList> = ({ docsArray, readOnClick }) => {
    const classes = useStyles();
    const handleFieldClick = (documentId: string) => {
        if (readOnClick) {
            readOnClick(documentId);
        }
    };

    return (
        <div className={classes.CardMessageBNPL}>
            <CardContent className={classes.CardContentBNPL}>
                <div className={classes.CardBNPLTitle}>
                    <DescriptionIcon className={classes.CardBNPLTitle_Icon} />
                    <Typography className={classes.CardBNPLTitle_Text} component="h4">
                        Documentación legal Sabbi
                    </Typography>
                </div>
                <Typography className={classes.CardBNPLText} component="p" gutterBottom>
                    Para continuar con la aprobación de tú crédito Sabbi y la apertura de tu tarjeta
                    digital Sabbi, te presentamos tus contratos digitales. Puedes aceptarlos todos
                    directamente, o abrir para leer cada uno como PDF.
                </Typography>
                <div className={classes.CardBNPLTable}>
                    {docsArray.length === 1 ? (
                        <div className={classes.CardBNPLTable_Doc_Single}>
                            <div>
                                <Typography
                                    className={classes.CardBNPLTable_Doc_Info_Name}
                                    component="p">
                                    Acuerdo de otorgamiento
                                </Typography>
                                <Typography
                                    className={classes.CardBNPLTable_Doc_Info_Read}
                                    component="p">
                                    Leer documento anexo
                                </Typography>
                            </div>
                            <div className={classes.CardBNPLTable_Doc_Icon}>
                                <VisibilityIcon className={classes.CardBNPLTable_Doc_Icon_Eye} />
                            </div>
                        </div>
                    ) : (
                        docsArray.map((doc) => {
                            return (
                                <div key={doc.documentId} className={classes.CardBNPLTable_Doc}>
                                    <div>
                                        <Typography
                                            className={classes.CardBNPLTable_Doc_Info_Name}
                                            component="p">
                                            {doc.documentTitle}
                                        </Typography>
                                        <Typography
                                            onClick={() => handleFieldClick(doc.documentId)}
                                            className={classes.CardBNPLTable_Doc_Info_Read}
                                            component="p">
                                            Leer documento anexo
                                        </Typography>
                                    </div>
                                    <div className={classes.CardBNPLTable_Doc_Icon}>
                                        {doc.read ? (
                                            <CheckIcon
                                                className={classes.CardBNPLTable_Doc_Check}
                                            />
                                        ) : (
                                            <VisibilityIcon
                                                className={classes.CardBNPLTable_Doc_Icon_Eye}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>
            </CardContent>
        </div>
    );
};

export default DocumentsList;
