import { Grid, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LockIcon from '@material-ui/icons/Lock';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { FC, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import SabbiLogoSrc from '../../assets/logo/sabbi.svg';
import SabbiLogoNoBorder from '../../assets/logo/sabbi-noborder.svg';
import AlertBar from '../../components/AlertBar/AlertBar';
import ContentBox from '../../components/ContentBox/ContentBox';
import { FormContext } from '../../components/FormsContext/FormContext/FormContext';
import PasswordInput from '../../components/FormsContext/PasswordInput/PasswordInput';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import RutInput from '../../components/FormsContext/RutInput/RutInput';
import HyperLink from '../../components/HyperLink/HyperLink';
import { ITransactionDetailInfo } from '../../components/TransactionDetail/interfaces/ITransactionDetailInfo';
import TransactionDetail from '../../components/TransactionDetail/TransactionDetail';
import WhatIsSabbi from '../../components/WhatIsSabbi/WhatIsSabbi';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useTpa } from '../../context/TPAContext/tpa';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { EPasswordRoutePaths } from '../../routes/enum/EPasswordRoutePaths';
import { EUserRoutePaths } from '../../routes/enum/EUserRoutePaths';
import { userSubscribedCardsQuantity } from '../../services/sabbi/card/card';
import { cancelTRXandReturnTrade } from '../../utils/clientHelpers';
import { generateEncryptedLogin } from '../../utils/cryptography/cypher';
import { IGenerateEncryptedLogin } from '../../utils/cryptography/cypher/interfaces/IGenerateEncryptedLogin';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { handleErrorMessage } from '../../utils/errors';
import { IErrorValues } from '../../utils/errors/interfaces/IErrorValues';
import { getTPAInformation } from '../../utils/localStorageManipulation';
import { clean } from '../../utils/rutHelpers';
import { ILoginPageProps } from './interfaces/ILoginPageProps';
import useStyles from './LoginPageStyles.material';

type Inputs = {
    documentNumber: string;
    password: string;
};

const LoginPage: FC<ILoginPageProps> = ({ queueUrl }) => {
    const [infoTpa, setInfoTpa] = useState<ITransactionDetailInfo>();
    const [errorValues, setErrorValues] = useState<IErrorValues>({
        title: '',
        visible: false
    });
    const history = useHistory();
    const backdrop = useBackdrop();
    const authContext = useAuth();
    const tpaContext = useTpa();
    const classes = useStyles();
    const defaultValues: Partial<Inputs> = {};
    const methods = useForm<FieldValues>({ mode: 'all', defaultValues });
    const { handleErrorRedirection, shouldRedirect } = useRedirection();
    let alertTimeout: NodeJS.Timeout;

    const isMobile = useMediaQuery('(max-width:768px)');

    useEffect(() => {
        backdrop.openBackdrop();
        const TPA = tpaContext.tokenInfo.tpa as string;
        setInfoTPA(TPA);
        return () => {
            clearTimeout(alertTimeout);
        };
    }, []);

    const setInfoTPA = async (tpa: string) => {
        try {
            const infoTpa = await getTPAInformation(tpa);
            const infoTPAFormated: ITransactionDetailInfo = {
                businessPartnerLogo: infoTpa?.bodyTPA.businessPartner?.businessPartnerLogo,
                businessPartnerName: infoTpa?.bodyTPA.businessPartner?.businessPartnerName,
                productList: infoTpa?.bodyTPA.transactionDetails?.orderDetails?.productList,
                total: infoTpa?.bodyTPA.transactionDetails?.orderDetails?.amount.total,
                transactionId: infoTpa?.bodyTPA.transactionDetails?.orderDetails?.transactionId,
                purchaseId: infoTpa?.bodyTPA.metadata?.trxPmi as string
            };
            setInfoTpa(infoTPAFormated);
        } catch (error) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    const getRedirectUrlTrade = async () => {
        backdrop.openBackdrop();
        try {
            await cancelTRXandReturnTrade(tpaContext.tokenInfo.tpa as string);
        } catch (error) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    const onSubmit: any = async (data: Inputs) => {
        try {
            backdrop.openBackdrop();
            await getTPAInformation(tpaContext.tokenInfo.tpa);
            const cleanedRut = clean(data.documentNumber);
            const device = getDataDevice();
            const cypherLoginData: IGenerateEncryptedLogin = {
                TPA: tpaContext.tokenInfo.tpa as string,
                password: data.password,
                rut: cleanedRut
            };
            const encriptedLogin = await generateEncryptedLogin(cypherLoginData);
            const requestLogin = {
                loginPayload: encriptedLogin.payload,
                device,
                TPA: tpaContext.tokenInfo.tpa as string
            };
            const loginResponse = await authContext.login(requestLogin);
            const cardsQtyResponse = await userSubscribedCardsQuantity({
                TE: loginResponse.payload.TE,
                TPA: tpaContext.tokenInfo.tpa as string
            });
            if (cardsQtyResponse.payload.cardsQuantity > 0) {
                history.push(queueUrl ? queueUrl : ECardRoutePaths.CARD_SELECT);
            } else {
                history.replace(ECardRoutePaths.CARD_SELECT_TYPE);
            }
        } catch (error) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    const handleAlertTimeout = () => {
        alertTimeout = setTimeout(() => {
            clearError();
        }, 6000);
    };
    const showError = (title: string) => {
        setErrorValues({
            title: title,
            visible: true
        });
        handleAlertTimeout();
    };
    const clearError = () => {
        setErrorValues({
            title: '',
            visible: false
        });
    };
    const handlePageRedirection = (url: string) => {
        history.push(url);
    };

    if (!infoTpa) {
        return <></>;
    } else {
        return (
            <section className={classes.LoginPage}>
                <Grid container>
                    <Grid item xs={12} lg={12}>
                        <div className={classes.HyperLinkBox}>
                            <HyperLink
                                classNames={classes.HyperLink_GoBack}
                                underline="none"
                                onClick={getRedirectUrlTrade}>
                                <ChevronLeftIcon />
                                volver al comercio
                            </HyperLink>
                        </div>
                    </Grid>

                    <Grid container className={classes.MainGrid} spacing={2}>
                        <Grid item xs={12} md={7}>
                            <ContentBox classNames={classes.ContentBox}>
                                <img
                                    className={classes.SabbiLogo}
                                    src={SabbiLogoSrc}
                                    alt="LogoSabbi"
                                />
                                <Typography variant="h2" component="h2" gutterBottom>
                                    Ingresa tus datos para iniciar sesión
                                </Typography>
                                <AlertBar
                                    classNames={classes.AlertBar}
                                    variant="filled"
                                    severity="error"
                                    message={errorValues.title}
                                    open={errorValues.visible}
                                    closeOnClick={() => clearError()}
                                    icon={<WarningRoundedIcon />}
                                />
                                <FormContext
                                    className={classes.Form}
                                    id={'LoginForm'}
                                    onSubmit={onSubmit}
                                    methods={methods}>
                                    <RutInput
                                        id={'documentNumber'}
                                        name={'documentNumber'}
                                        label={'RUT'}
                                        maxLength={12}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'El RUT es requerido'
                                            }
                                        }}
                                        icon={<AssignmentIndIcon />}
                                    />
                                    <PasswordInput
                                        id={'password'}
                                        name={'password'}
                                        label={'Clave'}
                                        passwordOnClick={() =>
                                            handlePageRedirection(
                                                EPasswordRoutePaths.PASSWORD_RECOVER
                                            )
                                        }
                                        hyperlinkText="Olvidé mi contraseña"
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'La contraseña es requerida'
                                            }
                                        }}
                                        icon={<LockIcon />}
                                    />
                                    <div className={classes.ButtonsBox}>
                                        <RoundButton
                                            classNames={classes.SubmitButton}
                                            id={'loginbutton'}
                                            name={'loginbutton'}
                                            type="submit"
                                            color="primary">
                                            Iniciar sesión
                                        </RoundButton>
                                    </div>
                                    <div className={classes.ButtonsBox}>
                                        <RoundButton
                                            classNames={classes.CreateButton}
                                            id={'registerUser'}
                                            name={'registerUser'}
                                            type="button"
                                            onClick={() =>
                                                handlePageRedirection(EUserRoutePaths.USER_REGISTER)
                                            }>
                                            Crear una cuenta
                                            <img
                                                className={classes.SabbiLogoSmall}
                                                src={SabbiLogoNoBorder}
                                                alt="LogoSabbi"
                                            />
                                        </RoundButton>
                                    </div>
                                </FormContext>
                            </ContentBox>
                            {isMobile && <WhatIsSabbi className={classes.Whatissabbi} />}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TransactionDetail infoTransactionTpa={infoTpa} />
                            {!isMobile && <WhatIsSabbi className={classes.Whatissabbi} />}
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        );
    }
};
export default LoginPage;
