import { makeStyles } from '@material-ui/core/styles';
import { HEADER_SHADOW } from '../../themes/shadows';

const useStyles = makeStyles((theme) => ({
    BasicHeader: {
        minHeight: '60px',
        height: '60px',
        background: theme.palette.common.white,
        boxShadow: HEADER_SHADOW
    },
    Toolbar: {
      paddingLeft: 0,
      paddingRight: 0,
      minHeight: '60px',
      height: '60px'
    },
    SabbiLogo:{
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center'
    },
    ExitToAppIcon: {
        fontSize: '1.8rem',
        marginLeft: '11px'
    },
    ExitContent: {
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            boxShadow: 'none',
          },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: theme.palette.common.white,
        },
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        paddingBottom: 0,
        paddingTop: 0,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        textTransform: 'none',
    },
    ExitButtonText: {
      fontSize: '14px',
      ['@media (max-width:500px)']: {
        display: 'none'
      }
    }
}));

export default useStyles;
