import CreditCardIcon from '@material-ui/icons/CreditCard';
import { FC } from 'react';

import { getSourceImageByKey } from '../../utils/clientHelpers';
import { CreditCardIconsSources } from '../../utils/creditCardHelpers';
import useStyles from './CreditCardImageStyles.material';
import ICreditCardImageProps from './interfaces/ICreditCardImageProps';

const CreditCardImage: FC<ICreditCardImageProps> = ({
    id = '',
    classNames = '',
    creditCardBrand,
    showUnknown = false
}) => {
    const classes = useStyles();
    const renderUnknown = () => {
        if (showUnknown) {
            return <CreditCardIcon />;
        } else {
            return null;
        }
    };
    return (
        <div id={`${id}`} className={`${classNames} ${classes.creditCardImage}`}>
            {creditCardBrand && getSourceImageByKey(CreditCardIconsSources, creditCardBrand) ? (
                <img
                    src={getSourceImageByKey(CreditCardIconsSources, creditCardBrand)}
                    alt={creditCardBrand}
                />
            ) : (
                renderUnknown()
            )}
        </div>
    );
};

export default CreditCardImage;
