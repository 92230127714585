import {
    Button,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import SabbiLogoSrc from '../../assets/logo/sabbi.svg';
import AlertBar from '../../components/AlertBar/AlertBar';
import ContentBox from '../../components/ContentBox/ContentBox';
import DocumentsList from '../../components/DocumentsList/DocumentsList';
import { DocumentItem } from '../../components/DocumentsList/interfaces/IDocumentsList';
import BNPLRadioButton from '../../components/FormsContext/BNPLRadioButton/BNPLRadioButton';
import {
    IBNPLRadioButton,
    PAGOPSPVALUE
} from '../../components/FormsContext/BNPLRadioGroup/interfaces/IBNPLRadioGroupProps';
import CheckboxInput from '../../components/FormsContext/CheckboxInput/CheckboxInput';
import { FormContext } from '../../components/FormsContext/FormContext/FormContext';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import HyperLink from '../../components/HyperLink/HyperLink';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import ModalError from '../../components/ModalError/ModalError';
import SelectedCard from '../../components/SelectedCard/SelectedCard';
import { ITransactionDetailInfo } from '../../components/TransactionDetail/interfaces/ITransactionDetailInfo';
import TransactionDetail from '../../components/TransactionDetail/TransactionDetail';
import DownloadFile from '../../components/ViewFile/ViewFile';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { EModalError } from '../../context/ModalContext/enums/EModalError';
import { useModalExpired } from '../../context/ModalContext/modalExpired';
import { useTpa } from '../../context/TPAContext/tpa';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ECheckoutRoutePaths } from '../../routes/enum/ECheckoutRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { previewDocumentPrivate } from '../../services/sabbi/document/document';
import { IParamsPreviewDocument } from '../../services/sabbi/document/interface/IParamsPreviewDocument';
import { IPreviewDocumentPrivate } from '../../services/sabbi/document/interface/IPreviewDocumentPrivate';
import { ITransactionSimulation } from '../../services/sabbi/document/interface/ITransactionSimulation';
import { IPurchaseBNPL } from '../../services/sabbi/transaction/interfaces/IPurchaseBNPL';
import { chargeService, purchaseBNPLService } from '../../services/sabbi/transaction/transaction';
import { cancelTRXandReturnTrade, thousandSeparator } from '../../utils/clientHelpers';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { IDevice } from '../../utils/device/interfaces/IDevice';
import { ECardType } from '../../utils/enums/ECardType';
import { EPaymentMethod } from '../../utils/enums/EPaymentMethod';
import { ETemplates } from '../../utils/enums/ETemplates';
import { handleErrorMessage, servicesErrorHandler } from '../../utils/errors';
import { IErrorValues } from '../../utils/errors/interfaces/IErrorValues';
import { handleRequestTokenCharge } from '../../utils/kushkiHelpers';
import {
    getLocalStorageItem,
    getTEInformation,
    getTPAInformation
} from '../../utils/localStorageManipulation';
import { ITransactionCharge } from '../CheckoutPage/interfaces/ITransactionCharge';
import useStyles from './CardPaymentDetailPageStyles.material';
import { EDocumentTitle } from './enum/EDocumentTitle';
import { IBnplPayments } from './interfaces/IBnplPayments';
import IModalValuesState from './interfaces/IModalValuesState';
import { IPaymentDetailPageLocationState } from './interfaces/IPaymentDetailPageLocationState';
import ISelectedCardInfoState from './interfaces/ISelectedCardInfoState';

type DetailBNPL = {
    CTC: string | undefined;
    CAE: string | undefined;
    tasaInteres: string | undefined;
    ITE: number | undefined;
    tasaAnual: string | undefined;
};
type PaymentMethod = {
    paymentMethod?: string;
    checkTerms?: boolean;
};

const CardPaymentDetailPage: FC = () => {
    const [hasBnpl, setHasBnpl] = useState<boolean>(false);
    const [selectedCardInfo, setSelectedCardInfo] = useState<ISelectedCardInfoState>({
        subscriptionId: '',
        idCard: '',
        cardBrand: '',
        cardMaskedNumber: '',
        bnplId: '',
        cardType: ''
    });
    const [detailBNPL, setDetailBNPL] = useState<DetailBNPL>();
    const [detailPayment, setDetailPayment] = useState<IBNPLRadioButton>();
    const [paymentBNPLDocument, setPaymentBNPLDocument] = useState<ITransactionSimulation>();
    const [modalValues, setModalValues] = useState<IModalValuesState>({
        viewDocumentModal: false,
        errorDocumentModal: false
    });
    const [infoTransactionTpa, setInfoTransactionTpa] = useState<ITransactionDetailInfo>();
    const [BnplPayments, setBnplPayments] = useState<IBnplPayments[]>([]);
    const [errorValues, setErrorValues] = useState<IErrorValues>({
        title: '',
        visible: false
    });
    const [documentBase64, setDocumentBase64] = useState<string>('');
    const [userDocuments, setUserDocuments] = useState<DocumentItem[]>([]);
    const [selectedDocument, setSelectedDocument] = useState<DocumentItem>();
    const history = useHistory();
    const location = useLocation<IPaymentDetailPageLocationState>();
    const backdrop = useBackdrop();
    const authContext = useAuth();
    const tpaContext = useTpa();
    const classes = useStyles();
    const modalContext = useModalExpired();
    const defaultValues: Partial<PaymentMethod> = {
        paymentMethod: location.state?.paymentMethods || '',
        checkTerms: false
    };
    const { handleErrorRedirection, shouldRedirect } = useRedirection();
    const methods = useForm<FieldValues>({ mode: 'all', defaultValues });

    const BNPL_CREDIT_DOCUMENTS = [
        {
            documentTitle: EDocumentTitle.SABBI_DIGITAL_CARD_CONTRACT,
            documentId: ETemplates.SABBI_DIGITAL_CARD_CONTRACT,
            base64: '',
            read: false
        },
        {
            documentTitle: EDocumentTitle.SABBI_SUMMARY_SHEET,
            documentId: ETemplates.SABBI_SUMMARY_SHEET,
            base64: '',
            read: false
        },
        {
            documentTitle: EDocumentTitle.PAT_SABBI_MANDATE,
            documentId: ETemplates.PAT_SABBI_MANDATE,
            base64: '',
            read: false
        },
        {
            documentTitle: EDocumentTitle.SABBI_CUSTOMER_SERVICE_AGREEMENT,
            documentId: ETemplates.SABBI_CUSTOMER_SERVICE_AGREEMENT,
            base64: '',
            read: false
        }
    ];
    const BNPL_DEBIT_DOCUMENTS = [
        {
            documentTitle: EDocumentTitle.SABBI_DIGITAL_CARD_CONTRACT,
            documentId: ETemplates.SABBI_DIGITAL_CARD_CONTRACT,
            base64: '',
            read: false
        },
        {
            documentTitle: EDocumentTitle.SABBI_SUMMARY_SHEET,
            documentId: ETemplates.SABBI_SUMMARY_SHEET,
            base64: '',
            read: false
        },
        {
            documentTitle: EDocumentTitle.PAC_SABBI_MANDATE,
            documentId: ETemplates.PAC_SABBI_MANDATE,
            base64: '',
            read: false
        },
        {
            documentTitle: EDocumentTitle.SABBI_CUSTOMER_SERVICE_AGREEMENT,
            documentId: ETemplates.SABBI_CUSTOMER_SERVICE_AGREEMENT,
            base64: '',
            read: false
        }
    ];

    useEffect(() => {
        if (!validateLocationStateData(location.state)) {
            if (authContext.user.te) {
                history.replace(ECardRoutePaths.CARD_SELECT);
            } else {
                history.replace(ELoginRoutePaths.LOGIN);
            }
        } else {
            obtainInfo();
        }
    }, []);

    /**
     * Validates incoming location.state data
     * @param {IPaymentDetailPageLocationState} obj object with location.state data
     * @return {boolean}
     */
    const validateLocationStateData = (obj: IPaymentDetailPageLocationState): boolean => {
        if (obj) {
            return (
                'success' in obj &&
                'paymentMethods' in obj &&
                'meta' in obj &&
                'subscriptionId' in obj.meta &&
                'idCard' in obj.meta &&
                'cardBrand' in obj.meta &&
                'cardMaskedNumber' in obj.meta &&
                'cardType' in obj.meta
            );
        }
        return false;
    };

    /**
     * Cancel transaction and return to businessPartner URL
     * @return {Promise<void>}
     */
    const getRedirectUrlTrade = async (): Promise<void> => {
        backdrop.openBackdrop();
        try {
            await cancelTRXandReturnTrade(
                tpaContext.tokenInfo.tpa as string,
                authContext.user?.te as string
            );
        } catch (error: any) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };

    /**
     * Update states with transaction information
     * @return {Promise<void>}
     */
    const obtainInfo = async (): Promise<void> => {
        backdrop.openBackdrop();
        const infoTPA = await getTPAInformation(tpaContext.tokenInfo.tpa);
        const infoTPAFormated: ITransactionDetailInfo = {
            businessPartnerLogo: infoTPA?.bodyTPA.businessPartner?.businessPartnerLogo,
            businessPartnerName: infoTPA?.bodyTPA.businessPartner?.businessPartnerName,
            productList: infoTPA?.bodyTPA.transactionDetails?.orderDetails.productList,
            total: infoTPA?.bodyTPA.transactionDetails?.orderDetails.amount.total,
            transactionId: infoTPA?.bodyTPA.transactionDetails?.orderDetails.transactionId,
            purchaseId: infoTPA?.bodyTPA.metadata?.trxPmi as string
        };
        setInfoTransactionTpa(infoTPAFormated);
        const selectCardPayment = {
            subscriptionId: location.state.meta.subscriptionId,
            idCard: location.state.meta.idCard,
            cardBrand: location.state.meta.cardBrand,
            cardMaskedNumber: location.state.meta.cardMaskedNumber,
            bnplId: location.state.meta.BNPLInfo?.id || 0,
            cardType: location.state.meta.cardType
        };
        setSelectedCardInfo(selectCardPayment);
        if (location.state.paymentMethods === EPaymentMethod.BNPL) {
            const { BNPLInfo } = location.state.meta;
            const dateFormat = moment(String(BNPLInfo?.fechaVencimiento)).format('DD/MM/YYYY');
            const selectPayment: IBNPLRadioButton = {
                label: `${BNPLInfo?.plazo} cuotas Sabbi`,
                amount: Number(BNPLInfo?.valorCuota),
                caption: `Pago primera cuota ${dateFormat}`,
                priceCaption: `Costo Total $ ${thousandSeparator(Number(BNPLInfo?.CTC), '.')}`,
                value: String(BNPLInfo?.id),
                key: `BNPL-${BNPLInfo?.plazo}-${BNPLInfo?.codAutorizacion}`
            };
            setDetailPayment(selectPayment);
            const selectPaymentDetailBNPL: DetailBNPL = {
                CTC: `$ ${thousandSeparator(Number(BNPLInfo?.CTC), '.')}`,
                CAE: BNPLInfo?.CAE,
                tasaInteres: BNPLInfo?.tasaInteres,
                ITE: BNPLInfo?.ITE,
                tasaAnual: BNPLInfo?.tasaAnual
            };
            setDetailBNPL(selectPaymentDetailBNPL);
            const simulationDocumentBNPL: ITransactionSimulation = {
                id: BNPLInfo?.id || 0,
                codAutorizacion: BNPLInfo?.codAutorizacion || 0,
                capital: BNPLInfo?.capital || 0,
                plazo: BNPLInfo?.plazo || 0,
                tasaAnual: BNPLInfo?.tasaAnual || '',
                CAE: BNPLInfo?.CAE || '',
                valorCuota: BNPLInfo?.valorCuota || 0,
                interes: BNPLInfo?.interes || 0,
                tasaInteres: BNPLInfo?.tasaInteres || '',
                comision: BNPLInfo?.comision || 0,
                fechaFacturacion: BNPLInfo?.fechaFacturacion || '',
                fechaVencimiento: BNPLInfo?.fechaVencimiento || '',
                createdAt: BNPLInfo?.createdAt || '',
                ITE: BNPLInfo?.ITE || 0,
                CTC: BNPLInfo?.CTC || 0
            };
            if (location.state.meta.cardType === ECardType.DEBIT) {
                setUserDocuments(BNPL_DEBIT_DOCUMENTS);
            } else {
                setUserDocuments(BNPL_CREDIT_DOCUMENTS);
            }
            setPaymentBNPLDocument(simulationDocumentBNPL);
            BNPLPaymentsData();
            setHasBnpl(true);
        } else {
            const selectPaymentPSP = {
                label: 'Pago único de ',
                amount: infoTPA?.bodyTPA.transactionDetails?.orderDetails.amount.total as number,
                caption: 'Costo total ',
                priceCaption: `$ ${thousandSeparator(
                    Number(
                        JSON.stringify(
                            infoTPA?.bodyTPA.transactionDetails?.orderDetails.amount.total as number
                        )
                    ),
                    '.'
                )}`,
                value: PAGOPSPVALUE,
                key: PAGOPSPVALUE
            };
            setDetailPayment(selectPaymentPSP);
        }
        backdrop.closeBackdrop();
    };

    /**
     * Submit form information
     * @param {Inputs} data Inputs information
     * @return {Promise<void>}
     */
    const onSubmit: SubmitHandler<PaymentMethod> = async (data) => {
        try {
            const TEInfo = await getTEInformation(authContext.user?.te as string);
            if (TEInfo.isExpired) {
                modalContext.updateModalExpired(EModalError.DEFAULT);
                modalContext.setExpiredTE(true);
                modalContext.openModal();
            } else {
                backdrop.openBackdrop();
                const device: IDevice = getDataDevice();
                if (String(data.paymentMethod) == EPaymentMethod.BNPL) {
                    const purchaseBNPLServiceRequest: IPurchaseBNPL = {
                        idSimulation: Number(selectedCardInfo.bnplId),
                        idCard: selectedCardInfo.idCard,
                        TE: authContext.user?.te as string,
                        TPA: tpaContext.tokenInfo.tpa as string,
                        device
                    };
                    const purchaseBNPLServiceResponse = await purchaseBNPLService(
                        purchaseBNPLServiceRequest
                    );
                    history.replace({
                        pathname: ECheckoutRoutePaths.CHECKOUT_LOGGED_USER_SUCCESS,
                        state: {
                            success: true,
                            paymentMethods: EPaymentMethod.BNPL,
                            transaction: purchaseBNPLServiceResponse.payload,
                            bnplDetailCuotas: BnplPayments
                        }
                    });
                } else {
                    const transactionToken = await handleRequestTokenCharge({
                        subscriptionId: selectedCardInfo.subscriptionId
                    });
                    const requestTransactionData: ITransactionCharge = {
                        idCard: selectedCardInfo.idCard,
                        token: transactionToken.token,
                        publicMerchantId: getLocalStorageItem('publicMerchantId') as string,
                        device,
                        TE: authContext.user?.te as string,
                        TPA: tpaContext.tokenInfo.tpa as string
                    };
                    const transactionResponse = await chargeService(requestTransactionData);
                    history.replace({
                        pathname: ECheckoutRoutePaths.CHECKOUT_LOGGED_USER_SUCCESS,
                        state: {
                            success: true,
                            paymentMethods: EPaymentMethod.PAYMENTPSP,
                            transaction: transactionResponse.payload
                        }
                    });
                }
            }
        } catch (error: any) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                const serviceError = servicesErrorHandler(error);
                let TT = serviceError.meta?.TT;
                let rejectionReasons = undefined;
                if (TT === undefined || TT === null) {
                    const cancelTrxResponse = await cancelTRXandReturnTrade(
                        tpaContext.tokenInfo.tpa as string,
                        authContext.user?.te as string,
                        true
                    );
                    TT = cancelTrxResponse;
                }
                if (error.meta.decisionParsed) {
                    rejectionReasons = {
                        reasons: error.meta.decisionParsed.reasons
                    };
                }
                history.replace({
                    pathname: ECheckoutRoutePaths.CHECKOUT_LOGGED_USER_SUCCESS,
                    state: {
                        success: false,
                        transaction: {
                            TT
                        },
                        paymentMethods: defaultValues.paymentMethod,
                        bnplRejectionReasons: rejectionReasons
                    }
                });
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    const BNPLPaymentsData = async () => {
        for (let index = 0; index < Number(location.state.meta.BNPLInfo?.plazo); index++) {
            BnplPayments.push({
                key: String(index + 1),
                amount: Number(location.state.meta.BNPLInfo?.valorCuota),
                fee: index + 1,
                date: String(
                    moment(String(location.state.meta.BNPLInfo?.fechaVencimiento))
                        .add(index, 'M')
                        .format('DD/MM/YYYY')
                )
            });
        }
        setBnplPayments(BnplPayments);
    };
    const handleOnChange = (value: boolean | undefined) => {
        const readDocuments = [...userDocuments];
        readDocuments.map((document) => {
            document.read = true;
        });
        setUserDocuments(readDocuments);
    };

    /**
     * Opens modal showing selected Document by id
     * @param idDocument string
     * @returns {void}
     */
    const viewDocument = async (idDocument: string): Promise<void> => {
        const readDocuments = [...userDocuments];
        try {
            const TEInfo = await getTEInformation(authContext.user?.te as string);
            if (TEInfo.isExpired) {
                modalContext.updateModalExpired(EModalError.DEFAULT);
                modalContext.setExpiredTE(true);
                modalContext.openModal();
            } else {
                backdrop.openBackdrop();
                for (let index = 0; index < readDocuments.length; index++) {
                    if (readDocuments[index].documentId === idDocument) {
                        setSelectedDocument(readDocuments[index]);
                        if (readDocuments[index].base64 == '') {
                            const base64 = await getDocumentBase64(idDocument);
                            readDocuments[index].base64 = base64;
                            readDocuments[index].read = true;
                            setUserDocuments(readDocuments);
                            setDocumentBase64(base64);
                            handleShowModal('viewDocumentModal', true);
                        } else {
                            setDocumentBase64(readDocuments[index].base64);
                            handleShowModal('viewDocumentModal', true);
                        }
                        backdrop.closeBackdrop();
                        break;
                    }
                }
            }
        } catch (error) {
            backdrop.closeBackdrop();
            handleShowModal('errorDocumentModal', true);
        }
    };

    /**
     * Get document from PreviewDocument service by id
     * @param idDocument string
     * @returns {void}
     */
    const getDocumentBase64 = async (idDocument: string) => {
        const maskedCardNumber = selectedCardInfo.cardMaskedNumber;
        const paramPreviewDocument: IParamsPreviewDocument = {
            paymentMethod: EPaymentMethod.BNPL,
            typeDocument: idDocument
        };
        const dataPreviewDocument: IPreviewDocumentPrivate = {
            TPA: tpaContext.tokenInfo.tpa as string,
            TE: authContext.user?.te as string,
            device: getDataDevice(),
            metadata: {
                simulation: paymentBNPLDocument as ITransactionSimulation,
                maskedCardNumber
            }
        };
        const docBase64 = await previewDocumentPrivate(dataPreviewDocument, paramPreviewDocument);
        return docBase64.payload.documentBase64;
    };
    const handleShowModal = (modalName: keyof IModalValuesState, show: boolean) => {
        setModalValues({
            ...modalValues,
            [modalName]: show
        });
    };
    const closeModals = () => {
        setModalValues({
            viewDocumentModal: false,
            errorDocumentModal: false
        });
        setTimeout(() => {
            setDocumentBase64('');
        }, 500);
    };
    const handleBackRedirection = () => {
        history.goBack();
    };
    const handleAlertTimeout = () => {
        setTimeout(() => {
            clearError();
        }, 6000);
    };
    const showError = (title: string) => {
        setErrorValues({
            title: title,
            visible: true
        });
        handleAlertTimeout();
    };
    const clearError = () => {
        setErrorValues({
            title: '',
            visible: false
        });
    };
    return (
        <section className={classes.PaymentDetailPage}>
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <div className={classes.HyperLinkBox}>
                        <HyperLink
                            classNames={classes.HyperLink_GoBack}
                            underline="none"
                            onClick={getRedirectUrlTrade}>
                            <ChevronLeftIcon />
                            volver al comercio
                        </HyperLink>
                    </div>
                </Grid>
                <Grid container className={classes.MainGrid} spacing={2}>
                    <Grid item xs={12} md={7} lg={7}>
                        <ContentBox classNames={classes.ContentBox}>
                            <img className={classes.SabbiLogo} src={SabbiLogoSrc} alt="LogoSabbi" />
                            <Typography variant="h2" component="h2" gutterBottom>
                                Este es tu detalle de pago
                            </Typography>
                            <AlertBar
                                classNames={classes.AlertBar}
                                variant="filled"
                                severity="error"
                                message={errorValues.title}
                                open={errorValues.visible}
                                closeOnClick={() => clearError()}
                                icon={<WarningRoundedIcon />}
                            />
                            <SelectedCard
                                classNames={classes.SelectedCard}
                                cardBrand={selectedCardInfo?.cardBrand}
                                cardMaskedNumber={selectedCardInfo?.cardMaskedNumber}
                                cardType={selectedCardInfo.cardType}
                                captionText=""
                            />
                            <BNPLRadioButton
                                classNames={classes.BNPLRadioButton}
                                isChecked={false}
                                key={detailPayment?.key ? detailPayment?.key : ''}
                                label={detailPayment?.label ? detailPayment?.label : ''}
                                value={detailPayment?.value ? detailPayment?.value : ''}
                                amount={detailPayment?.amount ? detailPayment?.amount : 0}
                                caption={detailPayment?.caption ? detailPayment?.caption : ''}
                                priceCaption={
                                    detailPayment?.priceCaption ? detailPayment?.priceCaption : ''
                                }
                            />
                            <FormContext
                                id={'ConfirmPaymentForm'}
                                onSubmit={onSubmit}
                                methods={methods}>
                                <Controller
                                    name="paymentMethod"
                                    rules={{
                                        required: { value: true, message: '' }
                                    }}
                                    render={({ field }) => {
                                        return <input type="hidden" {...field} />;
                                    }}
                                />

                                {hasBnpl ? (
                                    <>
                                        <TableContainer>
                                            <Table aria-label="success-checkout-table">
                                                <TableHead className={classes.TableHead}>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.CellHead}
                                                            component="th"
                                                            scope="row">
                                                            Costo total
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.CellHead}
                                                            component="th"
                                                            align="right">
                                                            {detailBNPL?.CTC}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.CellBody}
                                                            scope="row">
                                                            <Typography component="p">
                                                                Tasa de interés
                                                            </Typography>
                                                            <Typography variant="caption">
                                                                Mensual
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {detailBNPL?.tasaInteres} %
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.CellBody}
                                                            scope="row">
                                                            <Typography component="p">
                                                                Tasa de interés
                                                            </Typography>
                                                            <Typography variant="caption">
                                                                Anual
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {detailBNPL?.tasaAnual} %
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.CellBody}
                                                            scope="row">
                                                            <Typography component="p">
                                                                CAE
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {detailBNPL?.CAE} %
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.CellBody}
                                                            scope="row">
                                                            <Typography component="p">
                                                                ITE
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            ${' '}
                                                            {thousandSeparator(
                                                                Number(detailBNPL?.ITE),
                                                                '.'
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.CellBody}
                                                            scope="row">
                                                            <Typography component="p">
                                                                1° Vencimiento
                                                            </Typography>
                                                            <Typography variant="caption">
                                                                1era cuota ${' '}
                                                                {BnplPayments[0]?.amount
                                                                    ? thousandSeparator(
                                                                          BnplPayments[0].amount,
                                                                          '.'
                                                                      )
                                                                    : ''}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {BnplPayments[0]?.date}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* <Accordion
                                            className={classes.AccordionFee}
                                            defaultExpanded={false}>
                                            <AccordionSummary
                                                className={classes.AccordionFee_Summary}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                        className={classes.AccordionFee_LeftGrid}>
                                                        <Typography component="p">
                                                            1° Vencimiento
                                                        </Typography>
                                                        <Typography variant="caption">
                                                            1era cuota ${' '}
                                                            {BnplPayments[0]?.amount
                                                                ? thousandSeparator(
                                                                      BnplPayments[0].amount,
                                                                      '.'
                                                                  )
                                                                : ''}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                        className={classes.AccordionFee_RightGrid}>
                                                        <Typography component="p">
                                                            {BnplPayments[0]?.date}
                                                        </Typography>
                                                        <Typography variant="caption">
                                                            ver detalle de pagos
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                className={classes.AccordionFeeDetails}>
                                                <TableContainer className={classes.TableFees}>
                                                    <Table aria-label="payment fees table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.TableFees_HeadCell
                                                                    }
                                                                    align="left">
                                                                    Monto
                                                                </TableCell>
                                                                <TableCell
                                                                    className={
                                                                        classes.TableFees_HeadCell
                                                                    }
                                                                    align="center">
                                                                    N° Cuota
                                                                </TableCell>
                                                                <TableCell
                                                                    align="right"
                                                                    className={
                                                                        classes.TableFees_HeadCell
                                                                    }>
                                                                    Vencimiento
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.InnerTableContainer
                                                                    }
                                                                    colSpan={3}>
                                                                    <CustomScrollbar
                                                                        classNames={
                                                                            classes.CustomScrollbar
                                                                        }>
                                                                        <Table
                                                                            className={
                                                                                classes.InnerTable
                                                                            }>
                                                                            <TableBody>
                                                                                {BnplPayments.length >
                                                                                0 ? (
                                                                                    BnplPayments.map(
                                                                                        (
                                                                                            row,
                                                                                            index
                                                                                        ) => (
                                                                                            <TableRow
                                                                                                key={`${row.key}-${index}`}>
                                                                                                <TableCell align="left">
                                                                                                    ${' '}
                                                                                                    {
                                                                                                        row.amount
                                                                                                    }
                                                                                                </TableCell>
                                                                                                <TableCell align="center">
                                                                                                    {
                                                                                                        row.fee
                                                                                                    }
                                                                                                </TableCell>
                                                                                                <TableCell align="right">
                                                                                                    {
                                                                                                        row.date
                                                                                                    }
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        )
                                                                                    )
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </CustomScrollbar>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionDetails>
                                        </Accordion> */}
                                        <DocumentsList
                                            docsArray={userDocuments}
                                            readOnClick={viewDocument}
                                        />

                                        <CheckboxInput
                                            classNames={classes.Checkbox}
                                            id="checkTerms"
                                            clickableLabel={false}
                                            onChangeCheck={handleOnChange}
                                            label={
                                                <Typography
                                                    className={classes.Checkbox_Hyperlink}
                                                    color="initial">
                                                    He leído y tomado conocimiento de todos los
                                                    contratos indicados.
                                                </Typography>
                                            }
                                            name={'checkTerms'}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message:
                                                        'Debe aceptar los los contratos indicados'
                                                }
                                            }}
                                        />

                                        <ModalDialog
                                            id="ModalViewDocument"
                                            open={modalValues.viewDocumentModal}
                                            handleCancel={closeModals}
                                            acceptButtonType="submit"
                                            scroll={'paper'}
                                            acceptText="Confirmar pago"
                                            title={
                                                <div className={classes.ModalViewDocument_Title}>
                                                    <img
                                                        className={classes.ModalViewDocument_Logo}
                                                        src={SabbiLogoSrc}
                                                        alt="Logo Sabbi"
                                                    />{' '}
                                                    <Typography component="h2">
                                                        {selectedDocument?.documentTitle}
                                                    </Typography>
                                                </div>
                                            }
                                            maxWidth="md"
                                            customFooter={
                                                <div className={classes.ButtonsBox}>
                                                    <RoundButton
                                                        classNames={
                                                            classes.ModalViewDocument_NextDocumentButton
                                                        }
                                                        onClick={closeModals}
                                                        color="primary"
                                                        type="button">
                                                        Volver
                                                    </RoundButton>
                                                </div>
                                            }>
                                            <div>
                                                <div className={classes.ModalViewDocument_PDF}>
                                                    {documentBase64 === '' ? (
                                                        <div
                                                            className={
                                                                classes.ModalViewDocument_Loader
                                                            }>
                                                            <CircularProgress color="primary" />
                                                            <Typography component="p" gutterBottom>
                                                                Cargando Documento
                                                            </Typography>
                                                        </div>
                                                    ) : (
                                                        <DownloadFile dataFile={documentBase64} />
                                                    )}
                                                </div>
                                            </div>
                                        </ModalDialog>
                                        <ModalError
                                            id="errorDocumentModal"
                                            open={modalValues.errorDocumentModal}
                                            handleAccept={closeModals}
                                            scroll={'paper'}
                                            fullWidth={false}
                                            maxWidth="sm"
                                            title="¡Lo sentimos!"
                                            customFooter={
                                                <>
                                                    <Button
                                                        id={'backcommercebtn'}
                                                        name={'backcommercebtn'}
                                                        className={classes.GuestButton}
                                                        type="button"
                                                        onClick={getRedirectUrlTrade}>
                                                        Volver más tarde
                                                    </Button>
                                                    <RoundButton
                                                        id={'backsimulationbtn'}
                                                        name={'contibacksimulationbtnnuebtn'}
                                                        type="button"
                                                        color="primary"
                                                        onClick={handleBackRedirection}>
                                                        Continuar
                                                    </RoundButton>
                                                </>
                                            }>
                                            <Typography component="p">
                                                Ocurrió un error de carga en algunos de los
                                                documentos legales, por lo que no será posible
                                                completar tu pago en cuotas. Por favor, selecciona
                                                una nueva forma de pago o vuelve a intentarlo más
                                                tarde.
                                            </Typography>
                                        </ModalError>
                                    </>
                                ) : (
                                    <></>
                                )}
                                <div className={classes.ButtonsBox}>
                                    <Button
                                        className={classes.GuestButton}
                                        type="button"
                                        onClick={handleBackRedirection}>
                                        Volver
                                    </Button>
                                    <RoundButton
                                        classNames={classes.SubmitButton}
                                        id={'submitbtn'}
                                        color="primary"
                                        name={'submitbtn'}
                                        type="submit">
                                        Aceptar y pagar
                                    </RoundButton>
                                </div>
                            </FormContext>
                        </ContentBox>
                    </Grid>

                    <Grid item xs={12} md={5} lg={5}>
                        {infoTransactionTpa && (
                            <TransactionDetail infoTransactionTpa={infoTransactionTpa} />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </section>
    );
};
export default CardPaymentDetailPage;
