import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((_theme) => ({
    CreatedPasswordPage: {
        maxWidth: '100%'
    },
    Message: {
        textAlign: 'center'
    },
    SabbiLogo: {
        width: '60px',
        height: '60px',
        display: 'flex',
        alignSelf: 'center',
        margin: '0 auto 1.9rem auto'
    }
}));

export default useStyles;
