import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '&.success': {
            transition: 'all .2s ease-in-out',
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.main
                },
                '&.Mui-focused .MuiSvgIcon-root': {
                    color: theme.palette.success.main
                }
            },
            '& label.Mui-focused': {
                color: theme.palette.primary.main
            },
            '& .MuiFormHelperText-root.Mui-focused': {
                color: theme.palette.primary.main
            },
            '& .Mui-focused .MuiSvgIcon-colorPrimary': {
                color: theme.palette.primary.main
            },
            '& .MuiSvgIcon-root': {
                color: theme.palette.success.main
            }
        },
        '&.error': {
            transition: 'all .2s ease-in-out',
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: theme.palette.error.main
                },
                '&.Mui-focused .MuiSvgIcon-root': {
                    color: theme.palette.error.main
                }
            },
            '& label': {
                color: theme.palette.error.main
            },
            '& .MuiFormHelperText-root': {
                color: theme.palette.error.main
            },
            '& .MuiSvgIcon-colorPrimary': {
                color: theme.palette.error.main
            },
            '& .MuiSvgIcon-root': {
                color: theme.palette.error.main
            }
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                transition: 'all .2s ease-in-out',
                borderColor: theme.palette.primary.main
            },
            '&.Mui-focused .MuiSvgIcon-root': {
                color: theme.palette.primary.main
            },
            '&.Mui-focused fieldset': {
                borderWidth: '1px'
            },
            '& fieldset': {
                borderColor: theme.palette.grey[500],
                borderRadius: '20px 20px',
                boxSizing: 'border-box'
            },
            '& .MuiInputAdornment-positionEnd': {
                marginLeft: '16px',
                marginRight: '6px',
                '& .MuiSvgIcon-root': {
                    maxWidth: '20px',
                    maxHeight: '20px'
                }
            }
        },
        '&.foundCard': {
            '& input': {
                transition: 'padding-left .3s ease-in-out',
                paddingLeft: '55px'
            }
        },
        '& input': {
            paddingLeft: '20px',
            '&::placeholder': {
                fontSize: '1rem'
            },
            '&:-webkit-autofill': {
                '-webkit-box-shadow': '0 0 0 30px white inset',
                color: theme.palette.grey[800],
                fontFamily: 'ceraRoundProRegular'
            }
        }
    },
    label: {
        fontSize: '1rem'
    },
    CreditCardDiv: {
        overflowX: 'hidden',
        position: 'absolute',
        top: '8px',
        left: '18px',
        height: '26px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&.closed': {
            width: 0,
            transition: 'all .2s ease-in-out'
        },
        '&.opened': {
            width: '26px',
            transition: 'all .3s ease-in-out'
        }
    },
    CreditCardIcon: {
      maxWidth: '26px',
      '& img': {
        maxWidth: '26px',
      }
    }
}));

export default useStyles;
