import { ITransactionCharge } from '../../../pages/CheckoutPage/interfaces/ITransactionCharge';
import sabbiHttpInstance from '../../instances/sabbi.instance';
import { IDataSign } from './interfaces/IDataSign';
import { IPurchaseBNPL } from './interfaces/IPurchaseBNPL';
import { ISendEmail } from './interfaces/ISendEmail';
import { ISimulationBNPL } from './interfaces/ISimulationBNPL';
import { IWebPayPlusTransaction } from './interfaces/IWebPayPlusTransaction';
import { IWebPayPlusTransactionGuest } from './interfaces/IWebPayPlusTransactionGuest';
import { IWPToken } from './interfaces/IWPToken';

export const chargeService = async (data: ITransactionCharge) => {
    const response = await sabbiHttpInstance.post(`/transaction/card-transaction-charge`, data);
    return response.data;
};

export const wpPlusTransaction = async (data: IWebPayPlusTransaction) => {
    const response = await sabbiHttpInstance.post(`/transaction/web-pay-plus-transaction`, data);
    return response.data;
};

export const wpPlusTransactionGuest = async (data: IWebPayPlusTransactionGuest) => {
    const response = await sabbiHttpInstance.post(
        `/transaction/web-pay-plus-transaction-guest`,
        data
    );
    return response.data;
};

export const saveWpTransaction = async (data: IWPToken) => {
    const response = await sabbiHttpInstance.post(`/transaction/save-wp-transaction`, data);
    return response.data;
};

export const sendEmailService = async (data: ISendEmail) => {
    const response = await sabbiHttpInstance.post(`/transaction/send-email`, data);
    return response.data;
};

export const simulationBNPLService = async (data: ISimulationBNPL) => {
    const response = await sabbiHttpInstance.post(`/transaction/simulation-bnpl`, data);
    return response.data;
};

export const purchaseBNPLService = async (data: IPurchaseBNPL) => {
    const response = await sabbiHttpInstance.post(`/transaction/purchase-bnpl`, data);
    return response.data;
};

export const dataSignService = async (data: IDataSign) => {
    const response = await sabbiHttpInstance.post(`/transaction/transaction-data-sign`, data);
    return response.data;
};
