import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { FC } from 'react';

import RoundButton from '../FormsContext/RoundButton/RoundButton';
import IModalDialogProps from './interfaces/IModalDialogProps';
import useStyles from './ModalDialogStyles.material';

const ModalDialog: FC<IModalDialogProps> = ({
    id,
    classNames = '',
    open,
    customFooter,
    handleCancel,
    handleAccept,
    acceptButtonType = 'button',
    acceptButtonColor = 'primary',
    children,
    fullWidth = true,
    scroll,
    disableBackdropClick,
    disableEscapeKeyDown,
    transition,
    title,
    acceptText = 'Continuar',
    cancelText = 'Cancelar',
    maxWidth = 'md'
}) => {
    const classes = useStyles();
    return (
        <Dialog
            id={`${id}`}
            open={open}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            scroll={scroll}
            disableBackdropClick={disableBackdropClick}
            disableEscapeKeyDown={disableEscapeKeyDown}
            onClose={handleCancel}
            TransitionComponent={transition}
            aria-labelledby={`${id}-alert-dialog-title`}
            className={`${classNames} ${classes.ModalDialog}`}>
            {title && (
                <div className={classes.DialogTitle} id={`${id}-alert-dialog-title`}>
                    {title}
                </div>
            )}
            <DialogContent className={classes.DialogContent}>{children}</DialogContent>
            <DialogActions
                className={`${classes.DialogActions} ${customFooter ? 'customFooter' : ''}`}>
                {customFooter ? (
                    customFooter
                ) : (
                    <>
                        <RoundButton classNames={classes.CancelButton} onClick={handleCancel}>
                            {cancelText}
                        </RoundButton>
                        <RoundButton
                            classNames={classes.RoundButton}
                            color={acceptButtonColor}
                            type={acceptButtonType}
                            onClick={handleAccept && handleAccept}>
                            {acceptText}
                        </RoundButton>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ModalDialog;
