import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    LoginPage: {
        maxWidth: '100%',
        '& h2': {
            textAlign: 'center',
            marginBottom: '50px'
        }
    },
    MainGrid: {
        ['@media (max-width:959px)']: {
            flexFlow: 'column-reverse'
        }
    },
    PaymentInfo: {
        width: '1000px',
        maxWidth: '100%',
        marginBottom: '1rem',
        padding: '2rem'
    },
    HyperLinkBox: {
        maxWidth: '100%',
        marginBottom: '10px',
        ['@media (max-width:959px)']: {
            width: '600px',
            margin: 'auto',
            marginBottom: '10px'
        }
    },
    HyperLink_GoBack: {
        ['@media (max-width:959px)']: {
            marginLeft: '-9px'
        },
        '& a': {
            fontFamily: 'ceraRoundProMedium',
            display: 'flex',
            alignItems: 'center'
        }
    },
    ShopName: {
        fontFamily: 'ceraRoundProMedium'
    },
    Amount: {
        fontFamily: 'ceraRoundProMedium',
        fontSize: '20px',
        color: theme.palette.primary.main
    },
    TransactionInfo: {
        textAlign: 'right'
    },
    ContentBox: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        ['@media (max-width:768px)']: {
            height: 'auto'
        },
        ['@media (max-width:959px)']: {
            margin: 'auto'
        }
    },
    Whatissabbi: {
        ['@media (max-width:959px)']: {
            margin: '2rem auto 1rem auto'
        }
    },
    Form: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        margin: '0 3rem',
        '& .MuiFormControl-root': {
            maxWidth: '100%',
            marginBottom: '30px',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        '& .MuiFormControl-root:last-of-type': {
            marginBottom: '20px'
        }
    },
    SubmitButton: {
        margin: '0',
        alignSelf: 'center',
        width: '500px',
        backgroundColor: theme.palette.common.white
    },
    Divider: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    CreateButton: {
        margin: '0',
        width: '500px',
        alignSelf: 'center',
        backgroundColor: theme.palette.common.white
    },
    GuestButton: {
        margin: '1rem auto 1rem 0',
        boxShadow: 'none'
    },
    ButtonsBox: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        maxWidth: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '10px',
        justifyContent: 'space-between'
    },
    WebPayPlus: {
        marginLeft: 'initial'
    },
    RegisterText: {
        color: theme.palette.primary.main,
        borderBottom: '1px solid',
        alignSelf: 'flex-end'
    },
    SabbiLogo: {
        width: '60px',
        height: '60px',
        display: 'flex',
        alignSelf: 'center',
        margin: '0 auto 1.9rem auto'
    },
    SabbiLogoSmall: {
        height: '28px',
        marginLeft: '5px'
    },
    AlertBar: {
        '&.opened': {
            marginBottom: '1rem'
        }
    }
}));

export default useStyles;