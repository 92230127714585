import { Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { createContext, ReactNode, useContext, useState } from 'react';

import loader from '../../assets/gifs/loader.gif';
import useStyles from './Backdrop.material';

export interface IBackdropContext {
    isOpen: boolean;
    openBackdrop: (messageText?: string) => void;
    closeBackdrop: () => void;
    message?: string;
}

const backdropHandle = {
    isOpen: false,
    openBackdrop(messageText?: string) {
        backdropHandle.isOpen = true;
        messageText;
    },
    closeBackdrop() {
        backdropHandle.isOpen = false;
    }
};

const backdropContext = createContext(backdropHandle);

const useProvideBackdrop = () => {
    const [isOpen, setOpen] = useState(false);
    const [message, setMessageText] = useState<string | undefined>();

    const openBackdrop = (messageText?: string) => {
        setOpen(true);
        setMessageText(messageText);
    };
    const closeBackdrop = () => {
        setOpen(false);
    };
    return {
        isOpen,
        openBackdrop,
        closeBackdrop,
        message
    };
};

export const ProvideBackdrop = ({ children }: { children: ReactNode }) => {
    const backdrop: IBackdropContext = useProvideBackdrop();
    const classes = useStyles();
    return (
        <backdropContext.Provider value={backdrop}>
            {children}
            <Backdrop className={classes.backdrop} open={backdrop.isOpen}>
                {backdrop.isOpen && <img src={loader} alt="loading" className={classes.loader} />}
                <Typography variant="h2" component="p" className={classes.message}>
                    {backdrop.message}
                </Typography>
            </Backdrop>
        </backdropContext.Provider>
    );
};

export const useBackdrop = () => {
    return useContext(backdropContext);
};
