import { ITransactionResponse } from '../pages/CheckoutPage/interfaces/ITransactionResponse';
import { IDataSign } from '../services/sabbi/transaction/interfaces/IDataSign';
import { dataSignService } from '../services/sabbi/transaction/transaction';
import { extractPayloadFromJWT } from './cryptography/JWManipulation/jwt';
import { getDataDevice } from './device/dataDeviceHelpers';
import { IDevice } from './device/interfaces/IDevice';
import { EInvalidBins } from './enums/EInvalidBins';
import { FrontApplicationError } from './errors/FrontApplicationError';
import { deleteLocalStorageAccessData } from './localStorageManipulation';

/**
 * @param {Record<string, string>} IconsSources Array of strings
 * Get a sourceUrl image item from array given its key
 * @param {string} key key reference (name) from array
 * @returns {string | undefined}
 */
export const getSourceImageByKey = (
    IconsSources: Record<string, string>,
    key: string
): string | undefined => {
    return IconsSources[key];
};

/**
 * Replace certain number of characters from string with another
 * @param {string} target string from wich will be replacing characters
 * @param {number} charQty number of characters to replace
 * @param {number} charToReplace character to replace with
 * @returns {string} String with replaced characters
 */
export const maskUserTarget = (target: string, charQty: number, charToReplace: string): string => {
    return target
        .substring(0, charQty)
        .split('')
        .map((ele) => (ele = charToReplace))
        .join('')
        .concat(target.substring(charQty, target.length));
};

/**
 * Returns a string with thousand separator from a number
 * @param {number} number Number to apply thousand separator
 * @param {string} separator character as a thousand separator
 * @returns {string} string with thousand separator
 */
export const thousandSeparator = (number: number, separator: string): string => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

/**
 * Get a specific Param from queryLocation string
 * @param {string | Record<string, string> | string[][] | URLSearchParams | undefined} locationUrl queryString from wich will be extracted the param
 * @param {string} paramName variable name from queryString
 * @returns {string | null}
 */
export const getParamFromUrl = (
    locationUrl: string | Record<string, string> | string[][] | URLSearchParams | undefined,
    paramName: string
): string | null => {
    const urlParams = new URLSearchParams(locationUrl);
    const myParam = urlParams.get(paramName);
    return myParam;
};

/**
 * Get TPA string from URL
 * @returns {string | undefined}
 */
export const getTPAFromUrl = () => {
    const queryString = window.location.search;
    const TPA = getParamFromUrl(queryString, 'TPA') as string;
    return TPA;
};

/**
 * Get transaction from URL
 * @returns {string | undefined}
 */
export const getTransactionFromUrl = () => {
    const queryString = window.location.search;
    const transaction = getParamFromUrl(queryString, 'transaction') as string;
    return transaction;
};

/**
 * Validate Format Email
 * @returns {stirng}
 */
export const validateEmail = (email: string): string => {
    // eslint-disable-next-line no-useless-escape
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return re.test(String(email).toLowerCase()) ? email : '';
};

/**
 * Initialize the Data Sign service to cancel Transaction and create redirection URL to merchant
 * @param {string} trxPmi Purchase ID
 * @param {string} TPA Access Token
 * @param {string} TE Session Token
 * @param {boolean} plusPayment Session Token
 */
export const cancelTRXandReturnTrade = async (TPA: string, TE?: string, plusPayment?: boolean) => {
    try {
        const device: IDevice = getDataDevice();
        const requestGetStatus = {
            TPA,
            device
        } as IDataSign;
        if (TE) {
            requestGetStatus.TE = TE;
        }
        const dataSingResponse = await dataSignService(requestGetStatus);
        const extractedTT = extractPayloadFromJWT(
            dataSingResponse.payload.TT
        ) as ITransactionResponse;
        const url = `${extractedTT.businessPartner.responseUrl}/?TT=${dataSingResponse.payload.TT}`;
        if (plusPayment) {
            return dataSingResponse.payload.TT;
        } else {
            deleteLocalStorageAccessData();
            window.location.href = url;
        }
    } catch (error: any) {
        throw new FrontApplicationError(error);
    }
};

/**
 * Check if credit card has a valid Bin
 * @param {string} creditCardNumber
 * @return {boolean} is invalid bin
 */
export const invalidBinNumber = (creditCardNumber: string) => {
    const binNumber = creditCardNumber.slice(0, 6);
    if (creditCardNumber.length > 5) {
        return Object.values(EInvalidBins).includes(binNumber as EInvalidBins);
    }
    return false;
};
