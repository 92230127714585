import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

import { getLocalStorageItem } from '../../utils/localStorageManipulation';
import {
    FinancialProducts,
    IBusinessContext,
    IContractedProducts,
    IFinancialProducts
} from './interfaces/IBusinessContext';

const CONST_METHOD_CREDIT = 'credito';
const CONST_METHOD_DEBIT = 'debito';
const CONST_ONECLICK_PAYMENT_METHOD = 'ONECLICK';
const CONST_WEBPAYPLUS_PAYMENT_METHOD = 'WEBPAYPLUS';

const mapFpFromLocalStorage = () => {
    const defaultFinacialProducts: FinancialProducts = {
        bnpl: null,
        psp: {
            oneclick: null,
            webpayplus: null
        }
    };
    let fp: IContractedProducts | null = null;
    try {
        fp = getLocalStorageItem('FP');
        if (fp) {
            defaultFinacialProducts.bnpl =
                fp?.financialProducts.bnpl.paymentMethods.findIndex(
                    (pm) =>
                        (pm.methods == CONST_METHOD_CREDIT && pm.state == true) ||
                        (pm.methods == CONST_METHOD_DEBIT && pm.state == true)
                ) !== -1
                    ? true
                    : false;
            defaultFinacialProducts.psp.oneclick =
                fp?.financialProducts.psp.financialProducts.findIndex(
                    (pm) => pm.paymentMethod == CONST_ONECLICK_PAYMENT_METHOD && pm.state == true
                ) !== -1
                    ? true
                    : false;
            defaultFinacialProducts.psp.webpayplus =
                fp?.financialProducts.psp.financialProducts.findIndex(
                    (pm) => pm.paymentMethod == CONST_WEBPAYPLUS_PAYMENT_METHOD && pm.state == true
                ) !== -1
                    ? true
                    : false;
        }
    } catch (error) {
        console.log('@@@@@ ERROR PARSING FP mapFpFromLocalStorage');
    }
    return defaultFinacialProducts;
};

const setDefaultValues = (): IBusinessContext => {
    const defaultValues: IBusinessContext = {
        financialProducts: {
            bnpl: null,
            psp: {
                oneclick: null,
                webpayplus: null
            }
        },
        checkFinancialProducts: () => false,
        updateFinancialProducts: () => null
    };
    return defaultValues;
};

const businessContext = createContext(setDefaultValues());

const useProvideBusiness = () => {
    const [financialProducts, setFinancialProducts] = useState<FinancialProducts>(
        mapFpFromLocalStorage()
    );

    const updateFinancialProducts = (fP: IFinancialProducts) => {
        const businessProducts = {
            bnpl:
                fP.bnpl.paymentMethods.findIndex(
                    (pm) =>
                        (pm.methods == CONST_METHOD_CREDIT && pm.state == true) ||
                        (pm.methods == CONST_METHOD_DEBIT && pm.state == true)
                ) !== -1
                    ? true
                    : false,
            psp: {
                oneclick:
                    fP.psp.financialProducts.findIndex(
                        (pm) =>
                            pm.paymentMethod == CONST_ONECLICK_PAYMENT_METHOD && pm.state == true
                    ) !== -1
                        ? true
                        : false,
                webpayplus:
                    fP.psp.financialProducts.findIndex(
                        (pm) =>
                            pm.paymentMethod == CONST_WEBPAYPLUS_PAYMENT_METHOD && pm.state == true
                    ) !== -1
                        ? true
                        : false
            }
        };
        setFinancialProducts({ ...businessProducts });
    };

    const checkFinancialProducts = () => {
        if (financialProducts) {
            return 'bnpl' in financialProducts && 'psp' in financialProducts;
        }
        return false;
    };

    return {
        financialProducts,
        checkFinancialProducts,
        updateFinancialProducts
    };
};

export const ProvideBusiness = ({ children }: { children: ReactNode }) => {
    const business: IBusinessContext = useProvideBusiness();
    const memoizedBusiness = useMemo(
        () => ({
            financialProducts: business.financialProducts,
            checkFinancialProducts: business.checkFinancialProducts,
            updateFinancialProducts: business.updateFinancialProducts
        }),
        [business.financialProducts]
    );
    return (
        <businessContext.Provider value={memoizedBusiness}> {children} </businessContext.Provider>
    );
};

export const useBusiness = () => {
    return useContext(businessContext);
};
