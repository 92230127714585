import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((_theme) => ({
    SBLogoImg: {
        '&.xs': {
            width: '50px'
        },
        '&.sm': {
            width: '80px'
        },
        '&.md': {
            width: '100px'
        },
        '&.lg': {
            width: '150px'
        },
        '&.xl': {
            width: '175px'
        }
    }
}));

export default useStyles;
