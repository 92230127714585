import sabbiHttpInstance from '../../instances/sabbi.instance';
import { IAuthenticateUniqueKey } from './interfaces/IAuthenticateUniqueKey';
import { IEmailCount } from './interfaces/IEmailCount';
import { IPhoneCount } from './interfaces/IPhoneCount';
import { IRutValidation } from './interfaces/IRutValidation';
import { IValidateUniqueKey } from './interfaces/IValidateUniqueKey';

export const rutValidateOnboarding = async (data: IRutValidation) => {
    const response = await sabbiHttpInstance.post(`/validation/rut-validate-onboarding`, data);
    return response.data;
};

export const emailCountValidation = async (data: IEmailCount) => {
    const response = await sabbiHttpInstance.post(`/validation/user/email/count`, data);
    return response.data;
};

export const phoneCountValidation = async (data: IPhoneCount) => {
    const response = await sabbiHttpInstance.post(`/validation/user/phone/count`, data);
    return response.data;
};

export const authenticateUniqueKey = async (data: IAuthenticateUniqueKey) => {
    const response = await sabbiHttpInstance.post(
        `/validation/user/unique-key-authentication`,
        data
    );
    return response.data;
};

export const validateUniqueKey = async (data: IValidateUniqueKey) => {
    const response = await sabbiHttpInstance.post(`/validation/user/unique-key-validation`, data);
    return response.data;
};
