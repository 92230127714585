import Button from '@material-ui/core/Button';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { IButtonProps } from './interfaces/IButtonProps';
import useStyles from './RoundButtonStyles.material';

const RoundButton: FC<IButtonProps> = ({
    id,
    name,
    children,
    onClick,
    type = 'button',
    color,
    size = 'medium',
    variant = 'contained',
    disabled = false,
    disableElevation = false,
    classNames = ''
}) => {
    const formContext = useFormContext();
    const isValid = formContext?.formState?.isValid;
    const classes = useStyles();
    return (
        <Button
            id={`${id}`}
            disableElevation={disableElevation}
            size={size}
            name={`${name}`}
            classes={classes}
            className={`${classNames} ${color}`}
            onClick={type === 'submit' ? undefined : onClick}
            type={type}
            variant={variant}
            disabled={type === 'submit' ? !isValid : disabled}>
            {children}
        </Button>
    );
};

export default RoundButton;
