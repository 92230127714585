import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    CardRadioButton: {
        display: 'flex',
        border: `solid 1px ${theme.palette.grey[200]}`,
        padding: '15px 1rem',
        borderRadius: '20px',
        height: '80px',
        margin: '.6rem auto',
        '&.checked': {
            border: `solid 1px rgba(0, 0, 0, 0)`,
            boxShadow: `0px 0px 0px 2px ${theme.palette.primary.main}`
        },
        '& .MuiFormControlLabel-label': {
            width: '100%'
        },
    },
    Radio: {
        display: 'none'
    },
    RadioButtonContainer: {
        position: 'relative',
        maxHeight: '145px',
        width: 'initial',
    },
    DeleteButton: {
        position: 'absolute',
        top: 'calc(50% - 12px)',
        right: '55px'
    },
    RadioBoxButton: {
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    Label: {
        color: theme.palette.primary.main,
        fontSize: '1.1rem',
        lineHeight: '1.2rem',
        marginBottom: '.4rem',
        '@media (max-width:500px)': {
          fontSize: '1rem',
          marginBottom: 0
        }
    },
    Amount: {
        color: theme.palette.primary.main,
        fontSize: '1.3rem',
        lineHeight: '1.2rem',
        marginBottom: '.4rem',
        textAlign: 'right',
    },
    PriceCaption: {
        textAlign: 'right'
    },
    RadioMaterial: {
        marginLeft: 'auto'
    }
}));

export default useStyles;
