import { Button } from '@material-ui/core';
import { FC } from 'react';

import ICardRadioButtonProps from './interfaces/ISquareButton';
import useStyles from './SquareButtonStyles.material';

const SquareButton: FC<ICardRadioButtonProps> = ({
    onClick,
    children,
    classNames = '',
    disabled
}) => {
    const classes = useStyles();
    return (
        <Button
            className={`${classNames} ${classes.SquareButton}`}
            onClick={onClick}
            disabled={disabled}>
            {children}
        </Button>
    );
};

export default SquareButton;
