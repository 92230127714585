/**
 * Allows to encode data in UTF8 format
 * @param {string | undefined} dataToEncode Data to be UTF-8 encoded
 * @return {Uint8Array} Result of running UTF-8's encoder
 */
export const utf8Encoder = (dataToEncode: string | undefined): Uint8Array => {
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(dataToEncode);
    return encodedData;
};
