import { ChangeEvent } from 'react';

export const checkExpDate = (text: string): string => {
    let textSliced = text;
    if (parseInt(text) > 12) {
        textSliced = `0${text}`;
    }
    return textSliced;
};

export const checkPass = (passValue: string): string => {
    const isnum = /^\d+$/.test(passValue);
    let passSliced = passValue;
    if (isnum) {
        if (passValue.length > 6) {
            passSliced = passValue.slice(0, 6);
        }
    } else {
        passSliced = passValue.slice(0, -1);
    }
    return passSliced;
};

export const limitMaxLength = (text: string, maxLength: number | undefined): string => {
    let textSliced = text;
    if (maxLength && text.length > maxLength) {
        textSliced = text.slice(0, maxLength);
    }
    return textSliced;
};

export const replaceValue = (previous: string | undefined, newValue: string): string => {
    let replaced = newValue;
    if (previous) {
        replaced = newValue.replace(previous, '');
    }
    return replaced;
};

export const onlyNumber = (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const stringValue = value.target.value;
    return stringValue.replace(/[^0-9]/g, '');
};

export type otpField = {
    otp: string;
};

export const joinOtpValues = (otpArray: Array<otpField>) => {
    return otpArray.map((otpNumber) => otpNumber.otp).join('');
};
