import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ModalExpired from '../../components/ModalExpired/ModalExpired';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { getTEInformation } from '../../utils/localStorageManipulation';
import { checkBusinessPartnerId } from '../../utils/tokenManipulation';
import { useAuth } from '../AuthContext/auth';
import { useTpa } from '../TPAContext/tpa';
import { EModalError } from './enums/EModalError';
import {
    ErrorCode,
    IErrorModalExpired,
    IModalExpiredContext
} from './interfaces/IModalExpiredContext';

const modalExpiredHandle: IModalExpiredContext = {
    isOpen: false,
    errorContext: {
        code: null,
        title: '',
        description: '',
        icon: ''
    },
    isExpired: false,
    setExpiredTE(expired) {
        modalExpiredHandle.isExpired = expired;
    },
    openModal() {
        modalExpiredHandle.isOpen = true;
    },
    closeModal() {
        modalExpiredHandle.isOpen = false;
    },
    checkTeExpiration: () => null,
    updateModalExpired: () => null
};

const modalExpiredContext = createContext(modalExpiredHandle);

const useProvideModalExpired = () => {
    const [isOpen, setOpen] = useState(false);
    const [isExpired, setIsExpired] = useState<boolean>(false);
    const [errorContext, setErrorContext] = useState<IErrorModalExpired>({
        code: null,
        title: '',
        description: '',
        icon: ''
    });
    const history = useHistory();
    const authContext = useAuth();
    const tpaContext = useTpa();

    const openModal = () => {
        setOpen(true);
    };
    const closeModal = () => {
        setOpen(false);
    };
    const setExpiredTE = (isExpired: boolean) => {
        setIsExpired(isExpired);
    };
    const updateModalExpired = (errorCode: ErrorCode) => {
        let modalExpiredContent = {
            code: null,
            title: 'string',
            description: 'string',
            icon: 'string'
        };
        switch (errorCode) {
            case EModalError.ERRORCOMERCIO:
                modalExpiredContent = {
                    ...modalExpiredContent,
                    title: 'Ingresa tu contraseña',
                    description: 'Para autorizar el acceso a un nuevo comercio.',
                    icon: 'info'
                };
                break;
            case EModalError.ERRORADDCARD:
                modalExpiredContent = {
                    ...modalExpiredContent,
                    title: 'Se agotó el tiempo de espera',
                    description: 'Para continuar, ingresa tu contraseña.',
                    icon: 'info'
                };
                break;
            case EModalError.DELETECARD:
                modalExpiredContent = {
                    ...modalExpiredContent,
                    title: 'Se agotó el tiempo de espera',
                    description: 'Para continuar, ingresa tu contraseña.',
                    icon: 'info'
                };
                break;
            case EModalError.TEEXPIRED:
                modalExpiredContent = {
                    ...modalExpiredContent,
                    title: 'Se agotó el tiempo de espera',
                    description: 'Para continuar, ingresa tu contraseña.',
                    icon: 'warning'
                };
                break;
            default:
                modalExpiredContent = {
                    ...modalExpiredContent,
                    title: 'Se agotó el tiempo de espera',
                    description: 'Para continuar, ingresa tu contraseña.',
                    icon: 'info'
                };
                break;
        }
        setErrorContext({ ...modalExpiredContent });
    };
    const checkTeExpiration = async () => {
        const TEInfo = await getTEInformation(authContext.user?.te as string);
        try {
            if (TEInfo.isExpired) {
                setIsExpired(true);
                updateModalExpired(EModalError.TEEXPIRED);
                openModal();
            } else {
                const validBusiness = await checkBusinessPartnerId(
                    tpaContext.tokenInfo.tpa,
                    authContext.user?.te
                );
                if (validBusiness) {
                    setIsExpired(false);
                    closeModal();
                } else {
                    setIsExpired(true);
                    updateModalExpired(EModalError.ERRORCOMERCIO);
                    openModal();
                }
            }
        } catch (error) {
            authContext.cleanTE();
            history.replace(ELoginRoutePaths.LOGIN);
        }
    };
    return {
        isOpen,
        isExpired,
        setExpiredTE,
        openModal,
        closeModal,
        errorContext,
        checkTeExpiration,
        updateModalExpired
    };
};

export const ProvideModalExpired = ({ children }: { children: ReactNode }) => {
    const [renderModal, setRenderModal] = useState<boolean>(false);
    const modalExpired: IModalExpiredContext = useProvideModalExpired();
    useEffect(() => {
        modalExpired.isExpired ? setRenderModal(true) : setRenderModal(false);
    }, [modalExpired.isExpired]);
    return (
        <modalExpiredContext.Provider value={modalExpired}>
            {children}
            {renderModal && (
                <ModalExpired
                    title={modalExpired.errorContext.title || ''}
                    description={modalExpired.errorContext.description || ''}
                    open={modalExpired.isOpen}
                    onClose={modalExpired.closeModal}
                    icon={modalExpired.errorContext.icon || ''}
                />
            )}
        </modalExpiredContext.Provider>
    );
};

export const useModalExpired = () => {
    return useContext(modalExpiredContext);
};
