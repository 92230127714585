import { FC } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { ProvideAuth } from '../context/AuthContext/auth';
import { ProvideBackdrop } from '../context/BackdropContext/backdrop';
import { ProvideBusiness } from '../context/BusinessContext/business';
import { ProvideModalExpired } from '../context/ModalContext/modalExpired';
import { ProvideTpa } from '../context/TPAContext/tpa';
import HeaderFooterLayout from '../layouts/HeaderFooterLayout/HeaderFooterLayout';
import LoginPage from '../pages/LoginPage/LoginPage';
import { NotAllowedPage } from '../pages/NotAllowedPage/NotAllowedPage';
import { NotFoundPage } from '../pages/NotFoundPage/NotFoundPage';
import OtpValidationPage from '../pages/OtpValidationPage/OtpValidationPage';
import CardSwitch from './CardSwitch';
import CheckoutSwitch from './CheckoutSwitch';
import { EntryPoint } from './EntryPoint/EntryPoint';
import { CARD_ROUTE } from './enum/ECardRoutePaths';
import { CHECKOUT_ROUTE } from './enum/ECheckoutRoutePaths';
import { ENTRYPOINT_ROUTE_PATHS } from './enum/EEntrypointRoutePaths';
import { ELoginRoutePaths } from './enum/ELoginRoutePaths';
import { NOT_ALLOWED_ROUTE_PATHS } from './enum/ENotAllowedRoutePaths';
import { NOT_FOUND_ROUTE_PATHS } from './enum/ENotFoundRoutePaths';
import { EOtpRoutePaths } from './enum/EOtpRoutePaths';
import { PASSWORD_ROUTE } from './enum/EPasswordRoutePaths';
import { USER_ROUTE } from './enum/EUserRoutePaths';
import PasswordSwitch from './PasswordSwitch';
import { PublicRoute } from './PublicRoute/PublicRoute';
import UserSwitch from './UserSwitch';

export const Routes: FC = () => {
    return (
        <BrowserRouter>
            <ProvideBackdrop>
                <ProvideTpa>
                    <ProvideAuth>
                        <ProvideBusiness>
                            <ProvideModalExpired>
                                <HeaderFooterLayout>
                                    <Switch>
                                        <Route exact path={ENTRYPOINT_ROUTE_PATHS}>
                                            <EntryPoint exact path={ENTRYPOINT_ROUTE_PATHS} />
                                        </Route>

                                        <PublicRoute path={ELoginRoutePaths.LOGIN}>
                                            <LoginPage />
                                        </PublicRoute>

                                        <Route path={USER_ROUTE}>
                                            <UserSwitch />
                                        </Route>

                                        <PublicRoute path={EOtpRoutePaths.OTP_VALIDATION}>
                                            <OtpValidationPage />
                                        </PublicRoute>

                                        <Route path={PASSWORD_ROUTE}>
                                            <PasswordSwitch />
                                        </Route>

                                        <Route path={CARD_ROUTE}>
                                            <CardSwitch />
                                        </Route>

                                        <Route path={CHECKOUT_ROUTE}>
                                            <CheckoutSwitch />
                                        </Route>

                                        <Route
                                            path={NOT_ALLOWED_ROUTE_PATHS}
                                            exact
                                            render={() => <NotAllowedPage />}
                                        />
                                        <Route
                                            path={NOT_FOUND_ROUTE_PATHS}
                                            render={() => <NotFoundPage />}
                                        />
                                    </Switch>
                                </HeaderFooterLayout>
                            </ProvideModalExpired>
                        </ProvideBusiness>
                    </ProvideAuth>
                </ProvideTpa>
            </ProvideBackdrop>
        </BrowserRouter>
    );
};
