// @ts-ignore
// @ts-ignore
import jwtVerify from 'jose-browser-runtime/dist/browser/jwt/verify';
// @ts-ignore
import { JWTVerifyGetKey, JWTVerifyOptions } from 'jose-browser-runtime/dist/types/jwt/verify';
import { JWSHeaderParameters, JWTPayload, KeyLike } from 'jose-browser-runtime/types';

/**
 * Verifies a JWT signed with a public key
 * @param {string | Uint8Array} jwt JWT to be verified
 * @param {KeyLike | JWTVerifyGetKey} publicKey public key to verify JWT
 * @param {JWTVerifyOptions} options Options to be used verifying JWT
 * @return {Promise<{ payload: JWTPayload, protectedHeader: JWSHeaderParameters }>} Object with JWT verification data
 */
export const jwtVerification = async (
    jwt: string | Uint8Array,
    publicKey: KeyLike | JWTVerifyGetKey,
    options?: JWTVerifyOptions
): Promise<{ payload: JWTPayload; protectedHeader: JWSHeaderParameters }> => {
    const { payload, protectedHeader } = await jwtVerify(jwt, publicKey, options);
    return { payload, protectedHeader };
};
