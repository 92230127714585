import sabbiHttpInstance from '../../instances/sabbi.instance';
import { ICardDebitSave } from './interfaces/ICardDebitSave';
import { IDeleteCard } from './interfaces/IDeleteCard';
import { ISubscribe } from './interfaces/ISubscribe';
import { ITE } from './interfaces/ITE';
import { IValidateSubscribe } from './interfaces/IValidateSubscribe';

export const binInfoService = async (data: { bin: string }) => {
    const response = await sabbiHttpInstance.post(`/card/bin-info`, data);
    return response.data;
};

export const cardSubscribeDebitService = async (data: ISubscribe) => {
    const response = await sabbiHttpInstance.post(`/card/card-subscribe-debit`, data);
    return response.data;
};

export const validateAndsubscribeService = async (data: IValidateSubscribe) => {
    const response = await sabbiHttpInstance.post(`/card/validate-subscribe`, data);
    return response.data;
};

export const cardDebitSaveService = async (data: ICardDebitSave) => {
    const response = await sabbiHttpInstance.post(`/card/card-debit-save`, data);
    return response.data;
};

export const subscriptionCancelService = async (data: IDeleteCard) => {
    const config = {
        data
    };
    const response = await sabbiHttpInstance.delete(`/card/subscription-cancel`, config);
    return response.data;
};

export const userSubscribedCardsService = async (data: ITE) => {
    const response = await sabbiHttpInstance.post(`/card/user-subscribed-cards`, data);
    return response.data;
};

export const userSubscribedCardsQuantity = async (data: ITE) => {
    const response = await sabbiHttpInstance.post(`/card/user-subscribed-cards-count`, data);
    return response.data;
};
