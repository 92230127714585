import { useState } from 'react';
import { FC, useEffect } from 'react';
import { RouteProps, useHistory, useLocation } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useBusiness } from '../../context/BusinessContext/business';
import { IContractedProducts } from '../../context/BusinessContext/interfaces/IBusinessContext';
import { EModalError } from '../../context/ModalContext/enums/EModalError';
import { useModalExpired } from '../../context/ModalContext/modalExpired';
import { useTpa } from '../../context/TPAContext/tpa';
import { useQuery } from '../../hooks/useQuery';
import { businessInformation } from '../../services/sabbi/businessPartners/business';
import { userSubscribedCardsQuantity } from '../../services/sabbi/card/card';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { IDevice } from '../../utils/device/interfaces/IDevice';
import { EPaymentMethod } from '../../utils/enums/EPaymentMethod';
import {
    getLocalStorageItem,
    getTEInformation,
    getTPAInformation,
    setLocalStorageItem
} from '../../utils/localStorageManipulation';
import { checkBusinessPartnerId } from '../../utils/tokenManipulation';
import { ECardRoutePaths } from '../enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../enum/ELoginRoutePaths';
import { ENotAllowedRoutePaths } from '../enum/ENotAllowedRoutePaths';

export const EntryPoint: FC<RouteProps> = ({ ...rest }) => {
    const [queryParams, setQueryParams] = useState<{
        TPA: string | null;
    }>();
    const [queryParamsExist, setQueryParamsExist] = useState<boolean>();
    const [tpaIsValid, setTpaIsValid] = useState<boolean>();
    const [financialProducts, setFinacialProducts] = useState<IContractedProducts>();
    const [teInfo, setTeInfo] = useState<{ TE: string | null; exist: boolean }>();
    const [userCardsInfo, setUserCardsInfo] = useState<{ hasCards: boolean; quantity: number }>();
    const [redirectToInfo, setRedirectToInfo] = useState<{
        URL: string;
        state: { success: boolean };
    }>();

    const location = useLocation();
    const query = useQuery(location.search);
    const backdrop = useBackdrop();
    const tpaContext = useTpa();
    const teContext = useAuth();
    const fpContext = useBusiness();
    const expiredContext = useModalExpired();
    const history = useHistory();

    useEffect(() => {
        backdrop.openBackdrop();
        getQueryParams();
        return () => {
            backdrop.closeBackdrop();
        };
    }, []);
    useEffect(() => {
        if (queryParams) {
            validateQueryParams();
        }
    }, [queryParams]);
    useEffect(() => {
        if (queryParamsExist) {
            getTpaInfo();
        }
    }, [queryParamsExist]);
    useEffect(() => {
        if (tpaIsValid) {
            getBusinessInfo();
        }
    }, [tpaIsValid]);
    useEffect(() => {
        if (financialProducts) {
            updateBusinessInfo();
            validateUserAuth();
        }
    }, [financialProducts]);
    useEffect(() => {
        if (teInfo) {
            const { exist } = teInfo;
            if (exist) {
                getUserCards();
            } else {
                checkLoggedUserRedirection();
            }
        }
    }, [teInfo]);
    useEffect(() => {
        if (userCardsInfo) {
            updateTeContext();
        }
    }, [userCardsInfo]);
    useEffect(() => {
        if (teContext.user.te && teInfo?.TE && teInfo.exist) {
            checkLoggedUserRedirection();
        }
    }, [teContext]);
    useEffect(() => {
        if (redirectToInfo) {
            finalRedirection();
        }
    }, [redirectToInfo]);

    const getQueryParams = async () => {
        try {
            const TPA = query.get('TPA');
            setQueryParams({ TPA });
        } catch (error) {
            setRedirectionInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
        }
    };

    const validateQueryParams = async () => {
        if (queryParams) {
            const { TPA } = queryParams;
            if (TPA) {
                setQueryParamsExist(true);
            } else {
                setRedirectionInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
            }
        } else {
            setRedirectionInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
        }
    };

    const getTpaInfo = async () => {
        try {
            if (queryParams) {
                const { TPA } = queryParams;
                await getTPAInformation(TPA);
                setTpaIsValid(true);
                updateTpaContext();
            } else {
                setRedirectionInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
            }
        } catch (error) {
            setRedirectionInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
        }
    };

    const getBusinessInfo = async () => {
        try {
            if (queryParams) {
                const { TPA } = queryParams;
                const device: IDevice = getDataDevice();
                const businessResponse = await businessInformation({ TPA: TPA as string, device });
                const financialProducts = businessResponse.payload.contractedProducts;
                const removePsp = !businessResponse.payload.businessPartner.businessCredentials
                    .publicMerchantId
                    ? true
                    : false;
                if (validateFinancialProducts(financialProducts)) {
                    if (removePsp) {
                        financialProducts.financialProducts.psp.financialProducts[0].state = false;
                        financialProducts.financialProducts.psp.financialProducts[1].state = false;
                    } else {
                        setLocalStorageItem(
                            'publicMerchantId',
                            businessResponse.payload.businessPartner.businessCredentials
                                .publicMerchantId
                        );
                    }
                    setFinacialProducts(financialProducts);
                } else {
                    setRedirectionInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
                }
            } else {
                setRedirectionInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
            }
        } catch (error) {
            setRedirectionInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
        }
    };

    const validateFinancialProducts = (contractedProducts: IContractedProducts) => {
        if (contractedProducts && contractedProducts.financialProducts) {
            const { financialProducts } = contractedProducts;
            const {
                bnpl: { paymentMethods: BNPLPaymentMethods },
                psp: { financialProducts: PSPFinancialProducts }
            } = financialProducts;
            const [oneClick] = PSPFinancialProducts.filter(
                (financialProduct) => financialProduct.paymentMethod === EPaymentMethod.ONECLICK
            );
            const [webPayPlus] = PSPFinancialProducts.filter(
                (financialProduct) => financialProduct.paymentMethod === EPaymentMethod.WEBPAYPLUS
            );
            return BNPLPaymentMethods && PSPFinancialProducts && oneClick && webPayPlus;
        }
        return false;
    };

    const validateUserAuth = async () => {
        try {
            const TE = getLocalStorageItem('TE');
            if (TE) {
                const TEInfo = await getTEInformation(teContext.user.te);
                if (TEInfo.isExpired) {
                    expiredContext.setExpiredTE(true);
                    expiredContext.updateModalExpired(EModalError.TEEXPIRED);
                    setTeInfo({ TE: null, exist: false });
                } else {
                    const validBusiness = await checkBusinessPartnerId(
                        tpaContext.tokenInfo.tpa,
                        TE
                    );
                    if (!validBusiness) {
                        expiredContext.setExpiredTE(true);
                        expiredContext.updateModalExpired(EModalError.ERRORCOMERCIO);
                        setTeInfo({ TE: null, exist: false });
                    } else {
                        setTeInfo({ TE, exist: true });
                    }
                }
            } else {
                setTeInfo({ TE: null, exist: false });
            }
        } catch (error) {
            teContext.cleanTE();
            setTeInfo({ TE: null, exist: false });
        }
    };

    const getUserCards = async () => {
        let userCardsLength = 0;
        try {
            const userCards = await userSubscribedCardsQuantity({
                TE: teInfo?.TE as string,
                TPA: queryParams?.TPA as string
            });
            userCardsLength = userCards.payload.cardsQuantity;
        } catch (error) {
            console.log('@@@@@ SOMETHING WRONG GETTING USER CARDS, DEFAULT 0 CARDS');
        }
        setUserCardsInfo({ hasCards: true, quantity: userCardsLength });
    };

    const checkLoggedUserRedirection = () => {
        if (teInfo?.TE && teInfo.exist) {
            if (userCardsInfo && userCardsInfo.quantity === 0) {
                setRedirectionInfo(ECardRoutePaths.CARD_SELECT_TYPE);
            } else {
                setRedirectionInfo(ECardRoutePaths.CARD_SELECT);
            }
        } else {
            setRedirectionInfo(ELoginRoutePaths.LOGIN);
        }
    };

    const setRedirectionInfo = (URL: string, state: { success: boolean } = { success: false }) => {
        setRedirectToInfo({ URL, state });
    };

    const updateTpaContext = () => {
        if (queryParams?.TPA) {
            const tokenInfo = {
                tpa: queryParams?.TPA
            };
            setLocalStorageItem('TPA', queryParams?.TPA);
            tpaContext.updateTokenInfo(tokenInfo);
        } else {
            setRedirectionInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
        }
    };

    const updateBusinessInfo = () => {
        if (financialProducts) {
            setLocalStorageItem('FP', financialProducts);
            fpContext.updateFinancialProducts(financialProducts.financialProducts);
        } else {
            setRedirectionInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
        }
    };

    const updateTeContext = () => {
        if (teInfo?.TE && teInfo.exist) {
            teContext.updateTE(teInfo?.TE);
        } else {
            checkLoggedUserRedirection();
        }
    };

    const finalRedirection = () => {
        if (redirectToInfo) {
            const { URL, state } = redirectToInfo;
            history.replace(URL, state);
        } else {
            history.replace(ENotAllowedRoutePaths.NOT_AUTHORIZED);
        }
    };

    return <></>;
};
