import { FormControl, FormHelperText, RadioGroup } from '@material-ui/core';
import { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import CardRadioButton from '../CardRadioButton/CardRadioButton';
import useStyles from './CardRadioGroupStyles.material';
import ICardRadioGroupProps from './interfaces/ICardRadioGroupProps';

const CardRadioGroup: FC<ICardRadioGroupProps> = ({
    id,
    name,
    classNames = '',
    defaultHelperText,
    rules,
    defaultValue = '',
    radioButtons,
    onChange,
    hasDeleteButton = false,
    deleteOnClick
}) => {
    const classes = useStyles();
    const { formState, control, setValue } = useFormContext();
    const { errors } = formState;
    const handleOnchange = (value: string | undefined) => {
        onChange && onChange(value);
    };
    useEffect(() => {
        if (defaultValue) {
            setValue(name, defaultValue);
        }
    }, [defaultValue]);
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field }) => (
                <FormControl
                    component="fieldset"
                    className={`${classNames} ${classes.CardGroupControl} ${
                        errors?.[name] ? 'error' : 'success'
                    }`}>
                    <RadioGroup
                        aria-label={name}
                        value={defaultValue ? defaultValue : field.value}
                        id={id}
                        name={name}
                        onChange={(value) => {
                            handleOnchange(value.target.value);
                            field.onChange(value);
                        }}>
                        {radioButtons.length > 0 ? (
                            radioButtons.map((radioButton) => (
                                <CardRadioButton
                                    isChecked={
                                        defaultValue
                                            ? defaultValue == radioButton.value
                                            : field.value == radioButton.value
                                    }
                                    disabled={radioButton.disabled}
                                    disabledText={radioButton.disabledText}
                                    classNames={classes.CardRadioButton}
                                    key={`k-${radioButton.key}`}
                                    label={radioButton.label}
                                    value={radioButton.value}
                                    idCard={radioButton.idCard}
                                    hasDeleteButton={hasDeleteButton}
                                    deleteOnClick={deleteOnClick}
                                />
                            ))
                        ) : (
                            <FormHelperText className={classes.CardsHelperText}>
                                No tiene tarjetas agregadas
                            </FormHelperText>
                        )}
                    </RadioGroup>
                    <FormHelperText className={classes.HelperText} id={`helper-text-${id}`}>
                        {errors?.[name] ? errors?.[name].message : defaultHelperText}
                    </FormHelperText>
                </FormControl>
            )}
        />
    );
};
export default CardRadioGroup;
