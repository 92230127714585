import { Grid, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ModalErrorIconSrc from '../../assets/icons/modal-error-icon.svg';
import ModalWarningIconSrc from '../../assets/icons/modal-warning-icon.svg';
import AlertBar from '../../components/AlertBar/AlertBar';
import ContentBox from '../../components/ContentBox/ContentBox';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import HyperLink from '../../components/HyperLink/HyperLink';
import ShowCreditCard from '../../components/ShowCreditCard/ShowCreditCard';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { EModalError } from '../../context/ModalContext/enums/EModalError';
import { useModalExpired } from '../../context/ModalContext/modalExpired';
import { useTpa } from '../../context/TPAContext/tpa';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { cardDebitSaveService } from '../../services/sabbi/card/card';
import { cancelTRXandReturnTrade } from '../../utils/clientHelpers';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { IDevice } from '../../utils/device/interfaces/IDevice';
import { ECardType } from '../../utils/enums/ECardType';
import { handleErrorMessage } from '../../utils/errors';
import { SERVICE_ERRORS } from '../../utils/errors/enums/EServiceErrors';
import { IErrorValues } from '../../utils/errors/interfaces/IErrorValues';
import { getTEInformation } from '../../utils/localStorageManipulation';
import useStyles from './CardSubscribedPageStyles.material';
import { ICardsMetaInfo } from './interface/ICardsMetaInfo';
import { ISubscribedCardLocationState } from './interface/ISubscribedCardLocationState';
import { ISubscribedCardState } from './interface/ISubscribedCardState';

const CardSubscribedPage: FC = () => {
    const [subscriptionSuccess, setSuccess] = useState<boolean>();
    const [isLoading, setIsloading] = useState<boolean>(true);
    const [subscribedCard, setSubscribedCard] = useState<ISubscribedCardState>();
    const [errorCode, setErrorCode] = useState<string>();
    const classes = useStyles();
    const location = useLocation<ISubscribedCardLocationState>();
    const history = useHistory();
    const backdrop = useBackdrop();
    const tpaContext = useTpa();
    const authContext = useAuth();
    const modalContext = useModalExpired();
    const [errorValues, setErrorValues] = useState<IErrorValues>({
        title: '',
        visible: false
    });
    const { handleErrorRedirection, shouldRedirect } = useRedirection();
    const useQuery = () => {
        return new URLSearchParams(location.search);
    };
    const query = useQuery();

    useEffect(() => {
        if (query.get('subscriptionId') || query.get('message')) {
            cardDebitSave();
        } else if (!validateLocationStateData(location.state)) {
            if (authContext.user.te) {
                history.replace(ECardRoutePaths.CARD_SELECT);
            } else {
                history.replace(ELoginRoutePaths.LOGIN);
            }
        } else {
            if (location.state.errorCode) {
                setSuccess(false);
                setErrorCode(location.state.errorCode);
            } else {
                getCardInformation();
            }
        }
    }, []);

    useEffect(() => {
        if (!modalContext.isExpired) {
            setIsloading(false);
        }
    }, [modalContext.isExpired]);

    const validateLocationStateData = (obj: ISubscribedCardLocationState) => {
        if (obj) {
            return 'success' in obj;
        }
        return false;
    };

    const getRedirectUrlTrade = async () => {
        backdrop.openBackdrop();
        try {
            await cancelTRXandReturnTrade(
                tpaContext.tokenInfo.tpa as string,
                authContext.user?.te as string
            );
        } catch (error) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    const getCardInformation = async () => {
        try {
            backdrop.openBackdrop();
            const TE = authContext.user?.te;
            const TEInfo = await getTEInformation(TE);
            const { isExpired } = TEInfo;
            if (isExpired) {
                modalContext.updateModalExpired(EModalError.DEFAULT);
                modalContext.setExpiredTE(true);
                modalContext.openModal();
            }
            const userCards: ICardsMetaInfo[] = TEInfo.bodyTE.metaInfo.cards as ICardsMetaInfo[];
            const firstCard = userCards[0];
            const cardInfo = {
                cardBrand: firstCard.subscriptionData.paymentBrand,
                cardNumber: firstCard.subscriptionData.maskedCardNumber.substr(
                    firstCard.subscriptionData.maskedCardNumber.length - 4
                ),
                cardType: ECardType.CREDIT
            };
            setSubscribedCard(cardInfo);
            setSuccess(location.state.success);
        } catch (error) {
            setSuccess(false);
        } finally {
            backdrop.closeBackdrop();
        }
    };
    const cardDebitSave = async () => {
        try {
            backdrop.openBackdrop();
            const TE = authContext.user?.te;
            const TEInfo = await getTEInformation(TE);
            const { isExpired } = TEInfo;
            if (isExpired) {
                modalContext.updateModalExpired(EModalError.DEFAULT);
                modalContext.setExpiredTE(true);
                modalContext.openModal();
            }
            const subscriptionId = query.get('subscriptionId');
            if (subscriptionId) {
                const device: IDevice = getDataDevice();
                const requestDebitSave = {
                    subscriptionId,
                    TE: authContext.user?.te as string,
                    TPA: tpaContext.tokenInfo.tpa as string,
                    device
                };
                const cardDebitSaveResponse = await cardDebitSaveService(requestDebitSave);
                const updateTE = cardDebitSaveResponse.payload.TE.updatedTE;
                authContext.updateTE(updateTE);
                const TEInfo = await getTEInformation(updateTE);
                const userCards: ICardsMetaInfo[] = TEInfo.bodyTE.metaInfo
                    .cards as ICardsMetaInfo[];
                const firstCard = userCards[0];
                const cardInfo = {
                    cardBrand: firstCard.subscriptionData.paymentBrand,
                    cardNumber: firstCard.subscriptionData.maskedCardNumber.substr(
                        firstCard.subscriptionData.maskedCardNumber.length - 4
                    ),
                    cardType: ECardType.DEBIT
                };
                setSubscribedCard(cardInfo);
                setSuccess(true);
            } else {
                setSuccess(false);
            }
        } catch (error) {
            setSuccess(false);
        } finally {
            backdrop.closeBackdrop();
        }
    };
    const handleContinue = async () => {
        try {
            const TEInfo = await getTEInformation(authContext.user?.te as string);
            if (TEInfo.isExpired) {
                modalContext.updateModalExpired(EModalError.DEFAULT);
                modalContext.setExpiredTE(true);
                modalContext.openModal();
            } else {
                history.push(ECardRoutePaths.CARD_SELECT);
            }
        } catch (error) {
            authContext.cleanTE();
            history.replace(ELoginRoutePaths.LOGIN);
        }
    };
    const handleCreateNewCard = async () => {
        try {
            const TEInfo = await getTEInformation(authContext.user?.te as string);
            if (TEInfo.isExpired) {
                modalContext.updateModalExpired(EModalError.DEFAULT);
                modalContext.setExpiredTE(true);
                modalContext.openModal();
            } else {
                history.push(ECardRoutePaths.CARD_SELECT_TYPE);
            }
        } catch (error) {
            authContext.cleanTE();
            history.replace(ELoginRoutePaths.LOGIN);
        }
    };
    const renderSuccess = () => (
        <div>
            <ShowCreditCard
                classNames={classes.ShowCreditCard}
                cardBrand={subscribedCard?.cardBrand ? subscribedCard?.cardBrand : 'CARD'}
                cardNumber={subscribedCard?.cardNumber ? subscribedCard?.cardNumber : 'XXXX'}
                cardType={subscribedCard?.cardType ? subscribedCard?.cardType : ECardType.CREDIT}
            />
            <Typography className={classes.Title} variant="h2" component="h2">
                ¡Tarjeta agregada con éxito!
            </Typography>
            <Typography className={classes.Message} component="p">
                Ya puedes pagar con Sabbi{' '}
                <span role="img" aria-label="sprinkles">
                    ✨
                </span>
            </Typography>
            <div className={classes.ButtonsDiv}>
                <RoundButton
                    classNames={classes.SubmitButton}
                    color="primary"
                    onClick={handleContinue}>
                    Continuar
                </RoundButton>
                <RoundButton classNames={classes.CancelButton} onClick={handleCreateNewCard}>
                    Agregar otra tarjeta
                </RoundButton>
            </div>
        </div>
    );

    const errorTypeMesssage = () => {
        switch (errorCode) {
            case SERVICE_ERRORS.SABBI_20.code:
                return {
                    icon: ModalWarningIconSrc,
                    title: 'No es posible agregar la tarjeta',
                    subtitle: (
                        <>
                            <b>Ya tienes registrada esta tarjeta en Sabbi,</b> intenta ingresar una
                            nueva.
                        </>
                    ),
                    acceptButtonText: 'Agrega nueva tarjeta',
                    cancelButtonText: 'Volver',
                    acceptButton: () => handleCreateNewCard(),
                    cancelButton: () => handleContinue()
                };
            case SERVICE_ERRORS.SABBI_21.code:
                return {
                    icon: ModalWarningIconSrc,
                    title: 'No es posible agregar la tarjeta',
                    subtitle: (
                        <>
                            <b>Excediste el límite de cuentas permitidas por tarjeta,</b> puedes
                            eliminarla en una cuenta o ingresar una nueva.
                        </>
                    ),
                    acceptButtonText: 'Agrega nueva tarjeta',
                    cancelButtonText: 'Volver',
                    acceptButton: () => handleCreateNewCard(),
                    cancelButton: () => handleContinue()
                };
            case SERVICE_ERRORS.SABBI_43.code:
                return {
                    icon: ModalWarningIconSrc,
                    title: 'No es posible agregar la tarjeta',
                    subtitle: (
                        <>
                            <b>No es posible ingresar esta tarjeta en Sabbi,</b> intenta ingresar
                            una nueva.
                        </>
                    ),
                    acceptButtonText: 'Agrega nueva tarjeta',
                    cancelButtonText: 'Volver',
                    acceptButton: () => handleCreateNewCard(),
                    cancelButton: () => handleContinue()
                };
            case SERVICE_ERRORS.SABBI_59.code:
                return {
                    icon: ModalWarningIconSrc,
                    title: 'No es posible agregar la tarjeta',
                    subtitle: (
                        <>
                            <b>No es posible agregar tu tarjeta, </b> excede el límite máximo
                            permitido.
                        </>
                    ),
                    cancelButtonText: 'Volver',
                    cancelButton: () => handleContinue()
                };
            case SERVICE_ERRORS.SABBI_49.code:
                return {
                    icon: ModalWarningIconSrc,
                    title: 'No es posible agregar la tarjeta',
                    subtitle: (
                        <>
                            <b>No es posible ingresar una tarjeta de débito,</b> por favor ingresa
                            una tarjeta de crédito.
                        </>
                    ),
                    acceptButtonText: 'Agrega nueva tarjeta',
                    cancelButtonText: 'Volver',
                    acceptButton: () => handleCreateNewCard(),
                    cancelButton: () => handleContinue()
                };

            default:
                return {
                    icon: ModalErrorIconSrc,
                    title: 'Validación fallida',
                    subtitle: (
                        <>
                            No pudimos validar tu tarjeta. Si se realizó un cobro,{' '}
                            <b>será devuelto a tu cuenta. </b>
                        </>
                    ),
                    acceptButtonText: 'Intentar de nuevo',
                    cancelButtonText: 'Agregar tarjeta más tarde',
                    acceptButton: () => handleCreateNewCard(),
                    cancelButton: () => handleContinue()
                };
        }
    };

    const renderError = () => {
        const {
            icon,
            title,
            subtitle,
            acceptButtonText,
            cancelButtonText,
            acceptButton,
            cancelButton
        } = errorTypeMesssage();
        return (
            <div>
                <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <img src={icon} alt="modal-icon" />
                </div>
                <Typography className={classes.Title} variant="h2" component="h2">
                    {title}
                </Typography>
                <Typography className={classes.Message} component="p">
                    {subtitle}
                </Typography>
                <div className={classes.ButtonsDiv}>
                    {acceptButton && acceptButtonText && (
                        <RoundButton
                            classNames={classes.SubmitButton}
                            color="primary"
                            onClick={acceptButton}>
                            {acceptButtonText}
                        </RoundButton>
                    )}
                    <RoundButton classNames={classes.CancelButton} onClick={cancelButton}>
                        {cancelButtonText}
                    </RoundButton>
                </div>
            </div>
        );
    };
    const handleAlertTimeout = () => {
        setTimeout(() => {
            clearError();
        }, 6000);
    };
    const showError = (title: string) => {
        setErrorValues({
            title: title,
            visible: true
        });
        handleAlertTimeout();
    };
    const clearError = () => {
        setErrorValues({
            title: '',
            visible: false
        });
    };
    if (isLoading) {
        return <></>;
    } else {
        return (
            <section className={classes.SubscribedCardPage}>
                <Grid container>
                    <Grid item xs={12} lg={12}>
                        <div className={classes.HyperLinkBox}>
                            <HyperLink
                                classNames={classes.HyperLink_GoBack}
                                underline="none"
                                onClick={getRedirectUrlTrade}>
                                <ChevronLeftIcon />
                                volver al comercio
                            </HyperLink>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <AlertBar
                            classNames={classes.AlertBar}
                            variant="filled"
                            severity="error"
                            message={errorValues.title}
                            open={errorValues.visible}
                            closeOnClick={() => clearError()}
                            icon={<WarningRoundedIcon />}
                        />
                        {subscriptionSuccess !== undefined && (
                            <ContentBox
                                classNames={
                                    subscriptionSuccess
                                        ? classes.ContentCard
                                        : classes.ContentCard_Error
                                }>
                                {subscriptionSuccess ? renderSuccess() : renderError()}
                            </ContentBox>
                        )}
                    </Grid>
                </Grid>
            </section>
        );
    }
};

export default CardSubscribedPage;
