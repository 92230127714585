import { makeStyles } from '@material-ui/core/styles';
import COLORS from '../../themes/colors';

const useStyles = makeStyles((theme) => ({
    ProgressCircular: {
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        display: 'inline-flex',
        '& .MuiCircularProgress-colorPrimary': {
            color: theme.palette.secondary.light
        }
    },
    NumberBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        backgroundColor: COLORS.lilacLight,
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        top: 'calc(50% - 13px)',
        left: 'calc(50% - 13px)'
    },
    Number: {
        paddingTop: '1px',
        color: theme.palette.primary.main,
        fontFamily: 'ceraRoundProMedium'
    },
    SendButton: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: '10px',
        textTransform: 'none',
        padding: '10px 1rem',
        fontSize: '.9rem'
    },
    SendIcon: {
        marginRight: '8px'
    }
}));

export default useStyles;
