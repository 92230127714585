import sabbiHttpInstance from '../../instances/sabbi.instance';
import { IParamsPreviewDocument } from './interface/IParamsPreviewDocument';
import { IPreviewDocumentPrivate } from './interface/IPreviewDocumentPrivate';
import { IPreviewDocumentPublic } from './interface/IPreviewDocumentPublic';

export const previewDocumentPrivate = async (
    data: IPreviewDocumentPrivate,
    params: IParamsPreviewDocument
) => {
    const response = await sabbiHttpInstance.post(
        `/document/preview-document/private/${params.paymentMethod}/${params.typeDocument}`,
        data
    );
    return response.data;
};

export const previewDocumentPublic = async (
    data: IPreviewDocumentPublic,
    params: IParamsPreviewDocument
) => {
    const response = await sabbiHttpInstance.post(
        `/document/preview-document/public/${params.paymentMethod}/${params.typeDocument}`,
        data
    );
    return response.data;
};
