import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { EChannel } from '../../utils/enums/EChannel';
import { EChannelType } from '../../utils/enums/EChannelType';
import __RUNTIME_CONFIG__ from '../../utils/envConfig';
import { ApplicationError } from '../../utils/errors/ApplicationError';

const TIMEOUT_FLAG = 'ECONNABORTED';
const TIMEOUT_IN_MILLISECONDS = 90000;

const sabbiInstance = axios.create({
    baseURL: `${__RUNTIME_CONFIG__.CONST_BASE_URL}`,
    headers: {
        app_id: __RUNTIME_CONFIG__.BACKEND.CREDENTIALS.ID,
        app_key: __RUNTIME_CONFIG__.BACKEND.CREDENTIALS.KEY,
        channel: EChannel.SABBI,
        channel_type: EChannelType.WEB
    }
});

sabbiInstance.defaults.timeout = TIMEOUT_IN_MILLISECONDS;

sabbiInstance.interceptors.request.use(
    (request: AxiosRequestConfig) => {
        return request;
    },
    (error: AxiosError) => {
        handleSabbiInstanceAxiosError(error);
    }
);

sabbiInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (error: AxiosError) => {
        handleSabbiInstanceAxiosError(error);
    }
);

const handleSabbiInstanceAxiosError = (error: AxiosError): void => {
    const { request, response, code: axiosCode } = error;
    if (response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const { data } = response;
        throw new ApplicationError(data);
    } else if (request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        const { status } = request;
        throw new ApplicationError({
            code:
                axiosCode === TIMEOUT_FLAG
                    ? 'HTTP_CLIENT_REQUEST_TIMEOUT'
                    : 'HTTP_CLIENT_INTERNAL_REQUEST_ERROR',
            message:
                axiosCode === TIMEOUT_FLAG ? 'REQUEST_TIMEOUT_EXCEEDED' : 'INTERNAL_REQUEST_ERROR',
            statusCode: status
        });
    } else {
        // Something happened in setting up the request that triggered an Error
        throw new ApplicationError({
            code: 'HTTP_CLIENT_INTERNAL_UNKNOWN',
            message: 'INTERNAL_REQUEST_ERROR',
            statusCode: 500
        });
    }
};

export default sabbiInstance;
