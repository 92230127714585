import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FC } from 'react';

import DesktopTransactionDetail from './DesktopTransactionDetail/DesktopTransactionDetail';
import { ITransactionDetail } from './interfaces/ITransactionDetail';
import MobileTransactionDetail from './MobileTransactionDetail/MobileTransactionDetail';

const TransactionDetail: FC<ITransactionDetail> = ({ infoTransactionTpa }) => {
    const isMobile = useMediaQuery('(max-width:768px)');

    return isMobile ? (
        <MobileTransactionDetail infoTransactionTpa={infoTransactionTpa} />
    ) : (
        <DesktopTransactionDetail infoTransactionTpa={infoTransactionTpa} />
    );
};

export default TransactionDetail;
