import { Link } from '@material-ui/core';
import { FC } from 'react';

import useStyles from './HyperLinkStyles.material';
import IHyperLinkProps from './interfaces/IHyperLinkProps';

const HyperLink: FC<IHyperLinkProps> = ({
    id = '',
    children,
    classNames = '',
    href,
    color = 'initial',
    target = '_self',
    onClick,
    underline = 'always'
}) => {
    const classes = useStyles();
    return (
        <span className={`${classNames} ${classes.HyperLink}`}>
            <Link
                id={`${id}`}
                href={href}
                color={color}
                target={target}
                rel={target == '_blank' ? 'noopener' : undefined}
                onClick={onClick}
                underline={underline}>
                {children}
            </Link>
        </span>
    );
};

export default HyperLink;
