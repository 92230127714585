import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { NotFoundPage } from '../pages/NotFoundPage/NotFoundPage';
import PasswordCreatedPage from '../pages/PasswordCreatedPage/PasswordCreatedPage';
import PasswordCreatePage from '../pages/PasswordCreatePage/PasswordCreatePage';
import PasswordRecoveryPage from '../pages/PasswordRecoveryPage/PasswordRecoveryPage';
import { NOT_FOUND_ROUTE_PATHS } from './enum/ENotFoundRoutePaths';
import { EPasswordRoutePaths } from './enum/EPasswordRoutePaths';
import { PublicRoute } from './PublicRoute/PublicRoute';

const PasswordSwitch: FC = () => {
    return (
        <Switch>
            <PublicRoute
                exact
                key={EPasswordRoutePaths.PASSWORD_RECOVER}
                path={EPasswordRoutePaths.PASSWORD_RECOVER}>
                <PasswordRecoveryPage />
            </PublicRoute>
            <PublicRoute
                exact
                key={EPasswordRoutePaths.PASSWORD_CREATE}
                path={EPasswordRoutePaths.PASSWORD_CREATE}>
                <PasswordCreatePage />
            </PublicRoute>
            <PublicRoute
                exact
                key={EPasswordRoutePaths.PASSWORD_CREATED}
                path={EPasswordRoutePaths.PASSWORD_CREATED}>
                <PasswordCreatedPage />
            </PublicRoute>
            <Route path={NOT_FOUND_ROUTE_PATHS} exact render={() => <NotFoundPage />} />
        </Switch>
    );
};

export default PasswordSwitch;
