import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FC } from 'react';

import MessageBox from '../MessageBox/MessageBox';
import { IRejectedBNPLProps } from './interfaces/IRejectedBNPLProps';
import useStyles from './RejectedBNPLStyles.material';

const RejectedBNPL: FC<IRejectedBNPLProps> = ({ rejectionReasons }) => {
    const classes = useStyles();
    return (
        <MessageBox iconColor="error">
            {rejectionReasons && rejectionReasons.reasons?.length > 0 ? (
                <div>
                    <Accordion className={classes.RejectedAccordion} defaultExpanded={false}>
                        <AccordionSummary
                            className={classes.RejectedAccordion_Summary}
                            expandIcon={<ExpandMoreIcon />}>
                            <Typography>
                                <b>¡Lo sentimos!</b> No podemos entregar una oferta para compra en
                                cuotas por la(s) siguiente(s) razón(es):
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.RejectedAccordion_Details}>
                            <ul style={{ paddingLeft: '1rem', margin: 0 }}>
                                {rejectionReasons.reasons.map((reason) => (
                                    <li key={reason.code}>
                                        <Typography>{reason.clientReason}</Typography>
                                    </li>
                                ))}
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                </div>
            ) : (
                <div style={{ marginLeft: '1rem' }}>
                    <Typography>
                        <b>¡Lo sentimos!</b> Por error de sistema no podemos procesar tu
                        transacción. Por favor vuelve a intertarlo.
                    </Typography>
                </div>
            )}
        </MessageBox>
    );
};

export default RejectedBNPL;
