import { useMemo } from 'react';
import { createContext, ReactNode, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ENotAllowedRoutePaths } from '../../routes/enum/ENotAllowedRoutePaths';
import {
    deleteLocalStorageAccessData,
    getLocalStorageItem
} from '../../utils/localStorageManipulation';
import { ITPAContext, TokenInfo } from './interfaces/ITPAContext';

const defaultValues: ITPAContext = {
    tokenInfo: {
        tpa: null
    },
    updateTokenInfo: () => null
};

const TpaContext = createContext(defaultValues);

const useProvideTpa = () => {
    const purchaseId: string | null = null;
    let tpa: string | null = null;
    const history = useHistory();

    try {
        tpa = getLocalStorageItem('TPA');
    } catch (error) {
        deleteLocalStorageAccessData();
        history.replace(ENotAllowedRoutePaths.NOT_AUTHORIZED);
    }

    const [tokenInfo, setTokenInfo] = useState<TokenInfo>({
        tpa
    });

    const updateTokenInfo = (data: TokenInfo) => {
        setTokenInfo({ ...data });
    };

    return {
        tokenInfo,
        updateTokenInfo
    };
};

export const ProvideTpa = ({ children }: { children: ReactNode }) => {
    const tokenInfo: ITPAContext = useProvideTpa();
    const memoizedTpa = useMemo(
        () => ({
            tokenInfo: tokenInfo.tokenInfo,
            updateTokenInfo: tokenInfo.updateTokenInfo
        }),
        [tokenInfo.tokenInfo]
    );
    return <TpaContext.Provider value={memoizedTpa}>{children}</TpaContext.Provider>;
};

export const useTpa = () => {
    return useContext(TpaContext);
};
