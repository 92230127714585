import { parse } from 'bowser';

import { IDevice, TypeDevice } from './interfaces/IDevice';

export const getDataDevice = (): IDevice => {
    const browser = parse(window.navigator.userAgent);
    let mobile = false;
    if (browser.platform.type === 'mobile') {
        mobile = true;
    }
    let typeDevice: TypeDevice = 'DESKTOP';
    if (browser.platform.type === 'tablet') {
        typeDevice = 'MOBILE';
    } else if (browser.platform.type === 'mobile') {
        typeDevice = 'PHONE';
    }
    const deviceObj: IDevice = {
        mobile: mobile,
        typeDevice: typeDevice,
        os: browser.os.name ? browser.os.name.toUpperCase() : 'NOT_FOUND',
        platform: typeDevice,
        userAgent: browser.browser.name ? browser.browser.name.toUpperCase() : 'NOT_FOUND'
    };
    return deviceObj;
};
