import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    SubscribePage: {
        maxWidth: '100%',
        '& h2': {
            textAlign: 'center',
            marginBottom: '3rem'
        }
    },
    HyperLinkBox: {
        maxWidth: '100%',
        marginBottom: '10px',
        ['@media (max-width:959px)']: {
            width: '600px',
            margin: 'auto',
            marginBottom: '10px'
        }
    },
    HyperLink_GoBack: {
        ['@media (max-width:959px)']: {
            marginLeft: '-9px'
        },
        '& a': {
            fontFamily: 'ceraRoundProMedium',
            display: 'flex',
            alignItems: 'center'
        }
    },
    LogoImage: {
        maxWidth: '100%',
        width: '60px',
        margin: '0 auto 24px auto',
        display: 'flex'
    },
    ContentBox: {
        display: 'flex',
        flexDirection: 'column',
        ['@media (max-width:959px)']: {
            margin: 'auto'
        }
    },
    Form: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormControl-root': {
            maxWidth: '100%',
            marginBottom: '30px',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    Tooltip_Text: {
        color: theme.palette.common.white,
        fontSize: '.8rem',
        marginTop: 0,
        lineHeight: '1.5em'
    },
    Tooltip_CardImage: {
        display: 'flex',
        margin: 'auto'
    },
    
    CheckboxInput: {
        alignItems: 'center',
        textAlign: 'center',
        '& a': {
            fontSize: '1rem'
        },
        '& .MuiFormControlLabel-root': {
            marginRight: '5px'
        }
    },
    ModalPhone: {
        '& .MuiDialogContent-root': {
            textAlign: 'center'
        },
        '& .MuiDialogActions-root': {
            paddingTop: 0
        }
    },
    CardImage: {
        maxWidth: '100%',
        width: '300px'
    },
    ButtonsBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      ['@media (max-width:450px)']: {
        flexDirection: 'column-reverse'
      },
    },
    CancelButton: {
        color: theme.palette.grey[800],
        width: '185px',
        maxWidth: '225px',
        minWidth: '185px',
        textTransform: 'none',
        margin: '1rem',
        ['@media (max-width:450px)']: {
          maxWidth: '100%',
          width: '100%',
          margin: '1rem 0',
        }
    },
    SubmitButton: {
        maxWidth: '225px',
        alignSelf: 'center',
        ['@media (max-width:450px)']: {
          width: '100%',
          maxWidth: '100%',
          margin: '1rem 0',
        }
    },
    CancelModal: {
        textAlign: 'center',
        '& p': {
            margin: 0
        }
    },
    AlertBar: {
        '&.opened': {
            marginBottom: '1rem'
        }
    },
    CvvInput: {
      '& input': {
        letterSpacing: '5px',
      }
    }
}));

export default useStyles;
