import { FC } from 'react';

import { IViewFile } from './interfaces/IViewFile';
import useStyles from './ViewFileStyles.material';

const DownloadViewFile: FC<IViewFile> = ({ dataFile }) => {
    const src = `data:text/html;base64,${dataFile}`;
    const classes = useStyles();
    return (
        <div className={classes.EmbedContainer}>
            <embed src={src} type="text/html" />
        </div>
    );
};

export default DownloadViewFile;
