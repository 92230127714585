import { Button, Grid, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import SabbiLogo from '../../assets/logo/sabbi.svg';
import SabbiLogoNoBorderSrc from '../../assets/logo/sabbi-noborder.svg';
import AlertBar from '../../components/AlertBar/AlertBar';
import ContentBox from '../../components/ContentBox/ContentBox';
import { FormContext } from '../../components/FormsContext/FormContext/FormContext';
import OtpInput from '../../components/FormsContext/OtpInput/OtpInput';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import HyperLink from '../../components/HyperLink/HyperLink';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { EModalError } from '../../context/ModalContext/enums/EModalError';
import { useModalExpired } from '../../context/ModalContext/modalExpired';
import { useTpa } from '../../context/TPAContext/tpa';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { validateAndsubscribeService } from '../../services/sabbi/card/card';
import { IValidateSubscribe } from '../../services/sabbi/card/interfaces/IValidateSubscribe';
import { cancelTRXandReturnTrade } from '../../utils/clientHelpers';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { IDevice } from '../../utils/device/interfaces/IDevice';
import { handleErrorMessage } from '../../utils/errors';
import { SERVICE_ERRORS } from '../../utils/errors/enums/EServiceErrors';
import { IErrorValues } from '../../utils/errors/interfaces/IErrorValues';
import { joinOtpValues } from '../../utils/inputHelpers';
import { handleRequestSubscriptionToken } from '../../utils/kushkiHelpers';
import { getTEInformation } from '../../utils/localStorageManipulation';
import useStyles from './CardValidatePageStyles.material';
import { ICardValidationLocationState } from './interfaces/ICardValidationLocationState';
import IModalValuesState from './interfaces/IModalValuesState';

type Inputs = {
    otp?: string[];
};

const CardValidatePage = () => {
    const [modalValues, setModalValues] = useState<IModalValuesState>({
        cancelModal: false
    });
    const [isError, setIsError] = useState<boolean>(false);
    const [maxAttempts, setMaxAttempts] = useState<boolean>(false);
    const [errorValues, setErrorValues] = useState<IErrorValues>({
        title: '',
        visible: false
    });
    const [isLoading, setIsloading] = useState<boolean>(true);
    const classes = useStyles();
    const backdrop = useBackdrop();
    const history = useHistory();
    const authContext = useAuth();
    const tpaContext = useTpa();
    const location = useLocation<ICardValidationLocationState>();
    const modalContext = useModalExpired();
    const defaultValues: Partial<Inputs> = {};
    const methods = useForm<FieldValues>({ mode: 'all', defaultValues });
    const { handleErrorRedirection, shouldRedirect } = useRedirection();

    useEffect(() => {
        if (!validateLocationStateData(location.state)) {
            if (!authContext.user?.te) {
                history.replace(ELoginRoutePaths.LOGIN);
            } else {
                history.replace(ECardRoutePaths.CARD_SELECT);
            }
        } else {
            if (!authContext.user?.te) {
                history.replace(ELoginRoutePaths.LOGIN);
            } else {
                modalContext.checkTeExpiration();
            }
        }
    }, []);
    useEffect(() => {
        if (!modalContext.isExpired) {
            setIsloading(false);
        }
    }, [modalContext.isExpired]);

    const validateLocationStateData = (obj: ICardValidationLocationState) => {
        if (obj) {
            return (
                'cardData' in obj &&
                'card' in obj.cardData &&
                'cvc' in obj.cardData.card &&
                'expiryMonth' in obj.cardData.card &&
                'expiryYear' in obj.cardData.card &&
                'name' in obj.cardData.card &&
                'rut' in obj.cardData.card &&
                'number' in obj.cardData.card &&
                'cardType' in obj &&
                'otpRequestToken' in obj &&
                'secureId' in obj.otpRequestToken &&
                'secureService' in obj.otpRequestToken &&
                'token' in obj.otpRequestToken
            );
        }
        return false;
    };
    const getRedirectUrlTrade = async () => {
        backdrop.openBackdrop();
        try {
            await cancelTRXandReturnTrade(
                tpaContext.tokenInfo.tpa as string,
                authContext.user?.te as string
            );
        } catch (error) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    const onSubmit: SubmitHandler<Inputs> = async (data: any) => {
        try {
            const TEInfo = await getTEInformation(authContext.user?.te as string);
            if (TEInfo.isExpired) {
                modalContext.updateModalExpired(EModalError.DEFAULT);
                modalContext.setExpiredTE(true);
                modalContext.openModal();
            } else {
                backdrop.openBackdrop('Estamos agregando tu tarjeta...');
                const otpValues = joinOtpValues(data.otpFieldArray);
                const device: IDevice = getDataDevice();
                const cardData = location.state.cardData;
                const subscriptionToken = await handleRequestSubscriptionToken(cardData);
                const subscribeRequest: IValidateSubscribe = {
                    cardValidate: {
                        token: location.state.otpRequestToken.token,
                        secureServiceId: location.state.otpRequestToken.secureId,
                        otpValue: otpValues,
                        secureService: location.state.otpRequestToken.secureService
                    },
                    cardSubscription: {
                        token: subscriptionToken.token,
                        cardType: location.state.cardType
                    },
                    TE: authContext.user?.te as string,
                    TPA: tpaContext.tokenInfo.tpa as string,
                    device
                };
                const subscribeResponse = await validateAndsubscribeService(subscribeRequest);
                const updateTE = subscribeResponse.payload.TE.updatedTE;
                authContext.updateTE(updateTE);
                history.replace({
                    pathname: ECardRoutePaths.CARD_CONFIRMATION,
                    state: {
                        success: true
                    }
                });
            }
        } catch (error) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                const cardConfirmationErrorsCodes = [
                    SERVICE_ERRORS.SABBI_20.code,
                    SERVICE_ERRORS.SABBI_21.code,
                    SERVICE_ERRORS.SABBI_43.code,
                    SERVICE_ERRORS.SABBI_49.code,
                    SERVICE_ERRORS.SABBI_58.code,
                    SERVICE_ERRORS.SABBI_59.code
                ];
                const OTP_FAILURE_CODE = 'OTP200';

                if (error.code == OTP_FAILURE_CODE) {
                    setMaxAttempts(true);
                } else {
                    if (cardConfirmationErrorsCodes.some((code) => code === error.code)) {
                        history.replace({
                            pathname: ECardRoutePaths.CARD_CONFIRMATION,
                            state: {
                                success: false,
                                errorCode: error.code
                            }
                        });
                    } else {
                        showError(handleErrorMessage(error));
                    }
                }
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    const handleMaxAttemptsRedirection = () => {
        history.replace({
            pathname: ECardRoutePaths.CARD_SUBSCRIBE,
            state: {
                cardType: 'CREDIT'
            }
        });
    };
    const handleAlertTimeout = () => {
        setTimeout(() => {
            clearError();
        }, 6000);
    };
    const showError = (title: string) => {
        setErrorValues({
            title: title,
            visible: true
        });
        handleAlertTimeout();
    };
    const clearError = () => {
        setErrorValues({
            title: '',
            visible: false
        });
    };
    const handleCancelSubscribe = async () => {
        history.replace(ECardRoutePaths.CARD_SELECT);
    };
    const showModal = (modalName: keyof IModalValuesState) => {
        setModalValues({
            ...modalValues,
            [modalName]: true
        });
    };
    const closeModals = () => {
        setModalValues({
            cancelModal: false
        });
    };
    const returnCollect = () => (
        <p>
            El cobro será devuelto a tu tarjeta, deberás ingresar de nuevo los datos para realizar
            una nueva la validación
        </p>
    );
    const checkMovements = () => (
        <p>
            <b>Cobraremos un monto en tu tarjeta que servirá de código para validarla.</b> No te
            preocupes devolveremos el monto a tu cuenta.
        </p>
    );
    if (isLoading) {
        return <></>;
    } else {
        return (
            <section className={classes.CardValidationPage}>
                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.HyperLinkBox}>
                            <HyperLink
                                classNames={classes.HyperLink_GoBack}
                                underline="none"
                                onClick={getRedirectUrlTrade}>
                                <ChevronLeftIcon />
                                volver al comercio
                            </HyperLink>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <ContentBox classNames={classes.ContentBox}>
                            <img className={classes.SabbiLogo} src={SabbiLogo} alt="SabbiLogo" />
                            <Typography variant="h2" component="h2">
                                {maxAttempts ? (
                                    <div className={classes.MaxAttemptsMessage}>
                                        <ErrorIcon
                                            className={classes.MaxAttemptsMessage_ErrorIcon}
                                        />
                                        ¡Demasiados intentos!
                                    </div>
                                ) : (
                                    <div>
                                        Validaremos tu tarjeta{' '}
                                        <span role="img" aria-label="nerd_face">
                                            🤓
                                        </span>
                                    </div>
                                )}
                            </Typography>
                            <Typography
                                className={classes.CardValidationText}
                                component={'span'}
                                gutterBottom>
                                {maxAttempts ? returnCollect() : checkMovements()}
                            </Typography>
                            <AlertBar
                                classNames={classes.AlertBar}
                                variant="filled"
                                severity="error"
                                message={errorValues.title}
                                open={errorValues.visible}
                                closeOnClick={() => clearError()}
                                icon={<WarningRoundedIcon />}
                            />
                            <FormContext
                                id={'ValidateForm'}
                                onSubmit={maxAttempts ? handleMaxAttemptsRedirection : onSubmit}
                                methods={methods}>
                                <div className={classes.ValueDiv}>
                                    <span
                                        className={`${classes.CurrencySpan} ${
                                            maxAttempts && classes.CurrencySpan_Disabled
                                        }`}>
                                        $
                                    </span>
                                    <OtpInput
                                        classNames={classes.OtpInput}
                                        inputNumber={3}
                                        placeholder="_"
                                        disabled={maxAttempts ? true : false}
                                        isError={isError}
                                        onChange={() => setIsError(false)}
                                    />
                                </div>
                                <Typography variant="caption" className={classes.DisclaimerText}>
                                    <InfoIcon color="secondary" /> Tienes un máximo de 3 intentos
                                    para ingresar el monto.
                                </Typography>
                                <div className={classes.ButtonsBox}>
                                    <Button
                                        className={classes.CancelButton}
                                        onClick={() => showModal('cancelModal')}>
                                        Cancelar
                                    </Button>
                                    <RoundButton
                                        classNames={classes.SubmitButton}
                                        id={'submitbtn'}
                                        color="primary"
                                        name={'submitbtn'}
                                        type="submit">
                                        {maxAttempts ? 'Entendido' : 'Validar tarjeta'}
                                    </RoundButton>
                                </div>
                            </FormContext>
                        </ContentBox>
                    </Grid>
                </Grid>
                <ModalDialog
                    id="ModalCancel"
                    open={modalValues.cancelModal}
                    handleCancel={closeModals}
                    handleAccept={handleCancelSubscribe}
                    title={
                        <>
                            <img src={SabbiLogoNoBorderSrc} alt="Sabbi" />
                            <Typography variant="h2" component="h2">
                                ¿Seguro que deseas cancelar esta operación?
                            </Typography>
                        </>
                    }
                    scroll={'paper'}
                    maxWidth={'sm'}>
                    <div className={classes.CancelModal}>
                        <Typography component="p">
                            Al cancelar la operación, perderás los datos ingresados.
                        </Typography>
                    </div>
                </ModalDialog>
            </section>
        );
    }
};

export default CardValidatePage;
