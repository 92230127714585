import { InputBase } from '@material-ui/core';
import { ChangeEvent, createRef, FC, useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { replaceValue } from '../../../utils/inputHelpers';
import { IOtpProps } from './interfaces/IOtpProps';
import useStyles from './OtpInputStyles.material';

const OtpInput: FC<IOtpProps> = ({
    isError,
    classNames = '',
    inputNumber = 4,
    name = 'otpFieldArray',
    placeholder = '-',
    disabled = false
}) => {
    const [elRefs, setElRefs] = useState<React.MutableRefObject<HTMLInputElement | null>[]>([]);
    const classes = useStyles();
    const { control, watch, setValue } = useFormContext();
    const { fields } = useFieldArray({
        control,
        name: name
    });
    const watchFieldArray = watch?.(name);
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray?.[index]
        };
    });
    useEffect(() => {
        const initialValues = [...Array(inputNumber)].fill('');
        setValue?.(name, initialValues);
        return;
    }, []);
    useEffect(() => {
        setElRefs((elRefs) =>
            Array(inputNumber)
                .fill(null)
                .map((_, i) => elRefs[i] || createRef())
        );
    }, []);
    const handleChange = (value: string, index: number) => {
        if (index === 0) {
            if (value.length === 1) {
                elRefs[index + 1].current?.focus();
            }
        } else if (index === inputNumber - 1) {
            if (value.length === 0) {
                elRefs[index - 1].current?.focus();
            }
        } else {
            if (value.length === 0) {
                elRefs[index - 1].current?.focus();
            } else if (value.length === 1) {
                elRefs[index + 1].current?.focus();
            }
        }
    };
    return (
        <div className={`${classNames} ${classes.OtpInput}`}>
            {controlledFields.map((_field, index) => {
                return (
                    <Controller
                        key={`${name}.${index}.otp`}
                        name={`${name}.${index}.otp`}
                        control={control}
                        defaultValue={''}
                        rules={{
                            required: true,
                            maxLength: 1
                        }}
                        render={({ field }) => (
                            <InputBase
                                autoComplete="off"
                                className={`${classes.OtpInputBase} ${field.value && 'success'} ${
                                    isError && 'invalidOtp'
                                }`}
                                disabled={disabled}
                                id={`${name}.${index}.otp`}
                                name={`${name}.${index}.otp`}
                                type="number"
                                inputProps={{
                                    maxLength: 1
                                }}
                                placeholder={placeholder}
                                onBlur={field.onBlur}
                                onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                                    const limitedOtpValue = await replaceValue(
                                        field.value,
                                        event.target.value
                                    );
                                    handleChange(limitedOtpValue, index);
                                    field.onChange(limitedOtpValue);
                                }}
                                inputRef={(e: HTMLInputElement) => {
                                    elRefs[index].current = e;
                                    field.ref(e);
                                }}
                                value={field.value}
                            />
                        )}
                    />
                );
            })}
        </div>
    );
};

export default OtpInput;
