import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((_theme) => ({
    creditCardImage: {
        alignItems: 'center',
        display: 'flex'
    },
}));

export default useStyles;
