import { FC } from 'react';
import { FormProvider } from 'react-hook-form';

import { IFormProps } from './interfaces/IFormProps';

export const FormContext: FC<IFormProps> = ({
    id,
    children,
    onSubmit,
    autoComplete = 'on',
    className,
    methods
}) => {
    return (
        <FormProvider {...methods}>
            <form
                autoComplete={autoComplete}
                noValidate={true}
                id={id}
                onSubmit={methods.handleSubmit(onSubmit)}
                className={`${className}`}>
                {children}
            </form>
        </FormProvider>
    );
};
