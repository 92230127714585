import { IHandleError } from '../interfaces/IHandleError';

export const SABBI_FRONT_ERRORS: Record<string, IHandleError> = {
    SABBI_F_01: {
        code: 'SABBI_F_01',
        message: 'Invalid TPA',
        messageHandle: 'Transacción inválida.'
    },
    SABBI_F_02: {
        code: 'SABBI_F_02',
        message: 'TPA expired',
        messageHandle: 'Transacción expirada.'
    },
    SABBI_F_03: {
        code: 'SABBI_F_03',
        message: 'Invalid TE',
        messageHandle: 'Sesión inválida.'
    },
    SABBI_F_04: {
        code: 'SABBI_F_04',
        message: 'TE expired',
        messageHandle: 'Sesión expirada.'
    },
    SABBI_F_05: {
        code: 'SABBI_F_05',
        message: 'Invalid JWT',
        messageHandle: 'Error en cifrado.'
    },
    SABBI_F_06: {
        code: 'SABBI_F_05',
        message: 'JWT Expired',
        messageHandle: 'Cifrado expirado.'
    },
    SABBI_F_07: {
        code: 'SABBI_F_05',
        message: 'Unable to retrieve ppKey',
        messageHandle: 'Error en cifrado.'
    },
    SABBI_F_08: {
        code: 'SABBI_F_08',
        message: 'Invalid Rut',
        messageHandle: 'El RUT ingresado es inválido.'
    },
    SABBI_F_09: {
        code: 'SABBI_F_09',
        message: 'Invalid Document',
        messageHandle: 'El numero de documento ingresado es inválido.'
    },
    SABBI_F_10: {
        code: 'SABBI_F_10',
        message: 'Invalid object to parse',
        messageHandle: 'Objeto no válido para analizar.'
    },
    SABBI_F_11: {
        code: 'SABBI_F_11',
        message: 'Invalid object to stringify',
        messageHandle: 'Objeto no válido para encadenar.'
    },
    SABBI_F_12: {
        code: 'SABBI_F_12',
        message: 'Invalid card bin number',
        messageHandle: 'No es posible ingresar esta tarjeta.'
    }
};

export const KUSHKI_ERRORS: Record<string, IHandleError> = {
    KDEFAULT: {
        code: 'KDEFAULT',
        message: 'Ocurrió un error inesperado, intente más tarde.',
        messageHandle: 'Ocurrió un error inesperado, intente más tarde.'
    },
    K006: {
        code: 'K006',
        message: 'DFR029 - Bin de tarjeta inválido',
        messageHandle: 'No es posible registrar esta tarjeta.'
    }
};
