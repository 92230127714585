import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { NotFoundPage } from '../pages/NotFoundPage/NotFoundPage';
import UniqueKeyPage from '../pages/UniqueKeyPage/UniqueKeyPage';
import UniqueKeyValidationPage from '../pages/UniqueKeyValidationPage/UniqueKeyValidationPage';
import UserConfirmationPage from '../pages/UserConfirmationPage/UserConfirmationPage';
import UserRegisterContactPage from '../pages/UserRegisterContactPage/UserRegisterContactPage';
import UserRegisterManualAddressPage from '../pages/UserRegisterManualAddressPage/UserRegisterManualAddressPage';
import UserRegisterPage from '../pages/UserRegisterPage/UserRegisterPage';
import { NOT_FOUND_ROUTE_PATHS } from './enum/ENotFoundRoutePaths';
import { EUserRoutePaths } from './enum/EUserRoutePaths';
import { PublicRoute } from './PublicRoute/PublicRoute';

const UserSwitch: FC = () => {
    return (
        <Switch>
            <PublicRoute
                exact
                key={EUserRoutePaths.USER_REGISTER}
                path={EUserRoutePaths.USER_REGISTER}>
                <UserRegisterPage />
            </PublicRoute>
            <PublicRoute
                exact
                key={EUserRoutePaths.USER_REGISTER_CONTACT}
                path={EUserRoutePaths.USER_REGISTER_CONTACT}>
                <UserRegisterContactPage />
            </PublicRoute>
            <PublicRoute
                exact
                key={EUserRoutePaths.USER_REGISTER_MANUAL_ADDRESS}
                path={EUserRoutePaths.USER_REGISTER_MANUAL_ADDRESS}>
                <UserRegisterManualAddressPage />
            </PublicRoute>
            <PublicRoute
                exact
                key={EUserRoutePaths.USER_CONFIRMATION_ERROR}
                path={EUserRoutePaths.USER_CONFIRMATION_ERROR}>
                <UserConfirmationPage />
            </PublicRoute>
            <PublicRoute exact key={EUserRoutePaths.UNIQUE_KEY} path={EUserRoutePaths.UNIQUE_KEY}>
                <UniqueKeyPage />
            </PublicRoute>
            <PublicRoute
                exact
                key={EUserRoutePaths.UNIQUE_KEY}
                path={EUserRoutePaths.UNIQUE_KEY_VALIDATION}>
                <UniqueKeyValidationPage />
            </PublicRoute>
            <Route path={NOT_FOUND_ROUTE_PATHS} exact render={() => <NotFoundPage />} />
        </Switch>
    );
};

export default UserSwitch;
