import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    OtpValidationPage: {
        maxWidth: '100%',
        '& h2': {
            textAlign: 'center',
            marginBottom: '1rem'
        }
    },
    HyperLinkBox: {
      maxWidth: '100%',
      marginBottom: '10px',
      ['@media (max-width:959px)']: {
          width: '600px',
          margin: 'auto',
          marginBottom: '10px'
      }
    },
    HyperLink_GoBack: {
      ['@media (max-width:959px)']: {
          marginLeft: '-9px'
      },
      '& a': {
          display: 'flex',
          alignItems: 'center'
      }
    },  
    ContentBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    SabbiLogo: {
        width: '60px',
        height: '60px',
        alignSelf: 'center',
        marginBottom: '1.9rem'
    },
    AlertBar: {
        '&.opened': {
            marginBottom: '1rem'
        }
    },
    ValidationTitle:{
      maxWidth: '370px',
      textAlign: 'center',
      margin: '0 auto 1rem auto'
    },
    OtpValidationPageText: {
        textAlign: 'center',
        margin: '0 auto .5rem auto',
        maxWidth: '500px'
    },
    MaskedUserPhone: {
        margin: '.7rem auto 1rem',
        ['@media (max-width:431px)']: {
            margin: '.7rem auto 1rem'
        }
    },
    NotNumber: {
        alignSelf: 'center',
        marginBottom: '.8rem',
        '& a': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    BellIcon: {
        width: '20px',
        height: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.grey[800],
        borderRadius: '50%',
        marginRight: '10px',
        '& svg': {
            fontSize: '1rem',
            color: theme.palette.common.white
        }
    },
    Form: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& .MuiFormControl-root': {
            marginBottom: '30px'
        }
    },
    ProgressCircular: {
        display: 'flex',
        flex: '100%',
        justifyContent: 'center'
    },
    OtpInput: {
        marginBottom: '.8rem',
        flex: '100%'
    },
    OtpModalHyperLink: {
        marginBottom: '25px',
        '& a': {
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '.9rem',
            '& svg': {
                fontSize: '1.2rem',
                marginRight: '6px'
            }
        }
    },
    CaptionText: {
        marginTop: '1.5rem',
        marginBottom: '2rem',
        textAlign: 'center'
    },
    ModalNotNumber: {
        '& p': {
            textAlign: 'center'
        }
    },
    ModalNotNumber_SabbiNoBorder:{
      marginBottom: '2rem'
    },
    ButtonsBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      ['@media (max-width:450px)']: {
        flexDirection: 'column-reverse'
      },
    }, 
    SubmitButton: {
        maxWidth: '225px',
        alignSelf: 'center',
        ['@media (max-width:450px)']: {
          width: '100%',
          maxWidth: '100%',
          margin: '1rem 0',
        }
    },
    CancelButton: {
        color: theme.palette.grey[800],
        width: '185px',
        maxWidth: '225px',
        minWidth: '185px',
        textTransform: 'none',
        margin: '1rem',
        ['@media (max-width:450px)']: {
          maxWidth: '100%',
          width: '100%',
          margin: '1rem 0',
        }
    },
    ModalOtp: {
        '& .MuiDialogContent-root': {
            textAlign: 'center'
        },
        '& .MuiDialogActions-root': {
            paddingTop: 0
        }
    },
    CancelModal: {
        textAlign: 'center',
        '& p': {
            margin: 0
        }
    },
    WarningText: {
        textAlign: 'center',
        maxWidth: '500px',
        margin: 'auto'
    }
}));

export default useStyles;
