import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { FC, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';

import { Routes } from '../../routes/Routes';
import theme from '../../themes/lightTheme';
import __RUNTIME_CONFIG__ from '../../utils/envConfig';

const App: FC = () => {
    useEffect(() => {
        TagManager.initialize({ gtmId: __RUNTIME_CONFIG__.GTM_ID });
        hotjar.initialize(__RUNTIME_CONFIG__.HOTJAR.HJID, __RUNTIME_CONFIG__.HOTJAR.HJSV);
    }, []);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes />
        </ThemeProvider>
    );
};

export default App;
