import { Collapse, Typography } from '@material-ui/core';
import { FC } from 'react';

import { ECardType } from '../../utils/enums/ECardType';
import { EPaymentMethods } from '../../utils/enums/EPaymentMethods';
import CreditCardImage from '../CreditCardIcon/CreditCardImage';
import ISelectedCardProps, { ESelectedCardVariants } from './interfaces/ISelectedCardProps';
import useStyles from './SelectedCardStyles.material';

const SelectedCard: FC<ISelectedCardProps> = ({
    classNames,
    cardBrand,
    cardType,
    cardMaskedNumber = '',
    captionText,
    variant = ESelectedCardVariants.fill
}) => {
    const classes = useStyles();

    const handleCardNumber = (cardNumber: string) => {
        if (cardMaskedNumber) {
            if (cardMaskedNumber.length > 4) {
                return cardMaskedNumber.substr(cardMaskedNumber.length - 4);
            } else {
                return cardNumber;
            }
        }
    };

    const uppercaseCardType = (cardType: string) => {
        const cardTypes = [ECardType.DEBIT, EPaymentMethods.Debito.toUpperCase(), 'DÉBITO'];
        return cardTypes.includes(cardType.toUpperCase());
    };

    return (
        <Collapse
            in={cardMaskedNumber !== ''}
            className={`${classNames} ${classes.CollapseSelectedCard}`}>
            <div className={`${classes.SelectedCard} ${variant}`}>
                <CreditCardImage
                    classNames={classes.CreditCardImage}
                    creditCardBrand={cardBrand?.toLowerCase()}
                    showUnknown={true}
                />
                <span className={classes.CardBrand}>
                    {cardType && uppercaseCardType(cardType) ? 'Débito' : 'Crédito'}
                </span>
                <span className={classes.Dots}>····</span>
                <span className={classes.CardNumber}>{handleCardNumber(cardMaskedNumber)}</span>
            </div>
            {captionText && <Typography variant="caption">{captionText}</Typography>}
        </Collapse>
    );
};

export default SelectedCard;
