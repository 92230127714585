import { KeyLike } from 'jose-browser-runtime/types';

import __RUNTIME_CONFIG__ from '../../envConfig';
import { SABBI_FRONT_ERRORS } from '../../errors/enums/EAppErrors';
import { FrontApplicationError } from '../../errors/FrontApplicationError';
import { jweCompactEncryption } from '../../jose/jwe';
import { jwkToKeyLikeCustomAlg } from '../../jose/parsing';
import { getTPAInformation } from '../../localStorageManipulation';
import { ITPAValidatedData } from '../../tokenManipulation/interfaces/ITPAValidatedData';
import { IGenerateEncryptedLogin } from './interfaces/IGenerateEncryptedLogin';
import { IGenerateEncryptedOtp } from './interfaces/IGenerateEncryptedOtp';
import { IGenerateEncryptedPayloadResponse } from './interfaces/IGenerateEncryptedPayloadResponse';
import { IGenerateEncryptedRecoverPassword } from './interfaces/IGenerateEncryptedRecoverPassword';
import { IGenerateEncryptedRegister } from './interfaces/IGenerateEncryptedRegister';

/**
 * Encrypts a given object in a compact JWE
 * @param {KeyLike} publicKey Public key used to encrypt the compact JWE
 * @param {Record<string, unknown>} objectToEncrypt Object to be encrypted in the compact JWE
 * @param {string} errorMessage Message to be thrown in case of error
 * @return {Promise<string>} Object encrypted as a JWE string
 */
export const encryptObjectWithCompactJweWithKeyLike = async <T>(
    publicKey: KeyLike,
    objectToEncrypt: T | Record<string, unknown>
): Promise<string> => {
    try {
        const jwe = await jweCompactEncryption(
            { publicKey: publicKey },
            JSON.stringify(objectToEncrypt)
        );
        return jwe;
    } catch (error) {
        throw new FrontApplicationError(SABBI_FRONT_ERRORS.SABBI_F_05);
    }
};

/**
 * Encrypts login credentials for payload
 * @param {IGenerateEncryptedLogin} loginCredentials TPA, password and RUT object
 * @return {IGenerateEncryptedPayloadResponse} Encripted login credentials
 */
export const generateEncryptedLogin = async (loginCredentials: IGenerateEncryptedLogin) => {
    try {
        const { TPA, password, rut } = loginCredentials;
        const TPAData: ITPAValidatedData = await getTPAInformation(TPA);
        const TPAPublicKey = TPAData.bodyTPA.publicKey;
        const TPAPublicKeyAsKeyLike = await jwkToKeyLikeCustomAlg(
            TPAPublicKey,
            __RUNTIME_CONFIG__.CRIPTOGRAPHY.JWE.encryptionOptions.alg
        );
        const loginEncrypted = await encryptObjectWithCompactJweWithKeyLike(TPAPublicKeyAsKeyLike, {
            rut,
            password
        });
        const response: IGenerateEncryptedPayloadResponse = {
            payload: loginEncrypted
        };
        return response;
    } catch (error) {
        throw new FrontApplicationError(error);
    }
};

/**
 * Encrypts register credentials for payload
 * @param {IGenerateEncryptedRegister} registerCredentials TPA, password, rut object
 * @return {IGenerateEncryptedPayloadResponse} Encripted register credentials
 */
export const generateEncryptedRegister = async (
    registerCredentials: IGenerateEncryptedRegister
) => {
    try {
        const { TPA, password, rut } = registerCredentials;
        const TPAData: ITPAValidatedData = await getTPAInformation(TPA);
        const TPAPublicKey = TPAData.bodyTPA.publicKey;
        const TPAPublicKeyAsKeyLike = await jwkToKeyLikeCustomAlg(
            TPAPublicKey,
            __RUNTIME_CONFIG__.CRIPTOGRAPHY.JWE.encryptionOptions.alg
        );
        const registerEncrypted = await encryptObjectWithCompactJweWithKeyLike(
            TPAPublicKeyAsKeyLike,
            {
                rut,
                password
            }
        );
        const response: IGenerateEncryptedPayloadResponse = {
            payload: registerEncrypted
        };
        return response;
    } catch (error) {
        throw new FrontApplicationError(error);
    }
};

/**
 * Encrypts OTP credentials for payload
 * @param {IGenerateEncryptedRegister} otpCredentials TPA, OTPCode object
 * @return {IGenerateEncryptedPayloadResponse} Encripted register credentials
 */
export const generateEncryptedOtp = async (otpCredentials: IGenerateEncryptedOtp) => {
    try {
        const { TPA, OTPCode } = otpCredentials;
        const TPAData: ITPAValidatedData = await getTPAInformation(TPA);
        const TPAPublicKey = TPAData.bodyTPA.publicKey;
        const TPAPublicKeyAsKeyLike = await jwkToKeyLikeCustomAlg(
            TPAPublicKey,
            __RUNTIME_CONFIG__.CRIPTOGRAPHY.JWE.encryptionOptions.alg
        );
        const otpEncrypted = await encryptObjectWithCompactJweWithKeyLike(
            TPAPublicKeyAsKeyLike,
            OTPCode
        );
        const response: IGenerateEncryptedPayloadResponse = {
            payload: otpEncrypted
        };
        return response;
    } catch (error) {
        throw new FrontApplicationError(error);
    }
};

/**
 * Encrypts register credentials for payload
 * @param {IGenerateEncryptedRecoverPassword} registerCredentials TPA, newPassword, rut
 * @return {IGenerateEncryptedPayloadResponse} Encripted register credentials
 */
export const generateEncryptedRecoverPassword = async (
    recoverPasswordCredentials: IGenerateEncryptedRecoverPassword
) => {
    try {
        const { TPA, newPassword, rut } = recoverPasswordCredentials;
        const TPAData: ITPAValidatedData = await getTPAInformation(TPA);
        const TPAPublicKey = TPAData.bodyTPA.publicKey;
        const TPAPublicKeyAsKeyLike = await jwkToKeyLikeCustomAlg(
            TPAPublicKey,
            __RUNTIME_CONFIG__.CRIPTOGRAPHY.JWE.encryptionOptions.alg
        );
        const wroteDownAt = new Date().valueOf();
        const registerEncrypted = await encryptObjectWithCompactJweWithKeyLike(
            TPAPublicKeyAsKeyLike,
            {
                rut,
                newPassword,
                wroteDownAt
            }
        );
        const response: IGenerateEncryptedPayloadResponse = {
            payload: registerEncrypted
        };
        return response;
    } catch (error) {
        throw new FrontApplicationError(error);
    }
};
