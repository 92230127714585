import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    CardMessageBNPL: {
        width: '100%',
        minHeight: '5rem',
        borderRadius: '10px',
        border: `0.5px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.warning.light,
        marginTop: '28px'
    },
    CardContentBNPL: {
        padding: '27px'
    },
    CardBNPLTitle: {
        alignItems: 'center',
        display: 'flex',
        color: theme.palette.primary.main,
        marginBottom: '10px'
    },
    CardBNPLTitle_Icon: {
        marginRight: '8px'
    },
    CardBNPLTitle_Text: {
        fontFamily: 'ceraRoundProMedium',
        fontSize: '1rem'
    },
    CardBNPLText: {
        lineHeight: '22px',
        maxWidth: '100%',
        textAlign: 'justify'
    },
    CardBNPLTable: {
        marginTop: '24px'
    },
    CardBNPLTable_Doc_Single: {
        backgroundColor: theme.palette.common.white,
        borderRadius: '20px',
        padding: '12px 24px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    CardBNPLTable_Doc: {
        backgroundColor: theme.palette.common.white,
        padding: '12px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        '&:first-child': {
            borderRadius: '20px 20px 0 0'
        },
        '&:last-child': {
            borderRadius: '0 0 20px 20px',
            borderBottom: 'none'
        }
    },
    CardBNPLTable_Doc_Info_Name: {
        fontFamily: 'ceraRoundProMedium',
        fontSize: '1rem'
    },
    CardBNPLTable_Doc_Info_Read: {
        fontFamily: 'ceraRoundProRegular',
        fontSize: '.8rem',
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    CardBNPLTable_Doc_Icon: {
        display: 'flex',
        alignItems: 'center'
    },
    CardBNPLTable_Doc_Icon_Eye: {
        color: theme.palette.primary.main,
        fontSize: '30px'
    },
    CardBNPLTable_Doc_Check: {
        fontSize: '30px',
        color: theme.palette.secondary.dark
    }
}));

export default useStyles;
