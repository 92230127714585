import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '&.success': {
            transition: 'all .3s ease-in-out',
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.main
                },
                '&.Mui-focused .MuiSvgIcon-root': {
                    color: theme.palette.success.main
                },
                '& .Mui-focused .MuiSvgIcon-colorPrimary': {
                  color: theme.palette.success.main
                },
                '& .MuiSvgIcon-root': {
                    color: theme.palette.success.main
                }
            },
            '& label.Mui-focused': {
                color: theme.palette.primary.main
            },
            '& .MuiFormHelperText-root.Mui-focused': {
                color: theme.palette.primary.main
            },
        },
        '&.error': {
            transition: 'all .3s ease-in-out',
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: theme.palette.error.main
                },
                '&.Mui-focused .MuiSvgIcon-root': {
                    color: theme.palette.error.main
                },
                '& .MuiSvgIcon-colorPrimary': {
                  color: theme.palette.error.main
                },
                '& .MuiSvgIcon-root': {
                    color: theme.palette.error.main
                }
            },
            '& label': {
                color: theme.palette.error.main
            },
            '& .MuiFormHelperText-root': {
                color: theme.palette.error.main
            },
            '& .MuiIconButton-label': {
                color: theme.palette.error.main
            },
        },
        '& .MuiOutlinedInput-root': {
            maxHeight: '40px',
            '&:hover': {
                transition: 'all .2s ease-in-out'
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main
            },
            '&.Mui-focused .MuiSvgIcon-root': {
                color: theme.palette.primary.main
            },
            '&.Mui-focused fieldset': {
                borderWidth: '1px'
            },
            '& fieldset': {
                borderColor: theme.palette.grey[500],
                borderRadius: '20px 20px',
                boxSizing: 'border-box'
            }
        },
        '& .MuiIconButton-label': {
            color: theme.palette.grey[800]
        },
        '& input': {
            letterSpacing: '3px',
            paddingLeft: '20px',
            '&::placeholder': {
                fontSize: '1rem'
            },
            '&[type=password]': {
                fontSize: '1.5rem',
                letterSpacing: '8px'
            },
            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none'
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none'
            },
            '&[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '&:-webkit-autofill': {
                '-webkit-box-shadow': '0 0 0 30px white inset',
                color: theme.palette.grey[800],
                fontFamily: 'ceraRoundProRegular'
            }
        },
        '& .MuiSvgIcon-root': {
            fontSize: '20px'
        },
        '& .MuiInputAdornment-root': {
            marginRight: '5px'
        }
    },
    label: {
        fontSize: '1rem'
    },
    PasswordHyperLink: {
        fontSize: '.8rem',
        marginTop: '1rem',
        textAlign: 'right',
        marginRight: '1.3rem',
        '& a': {
            display: 'flex',
            alignItems: 'center'
        },
        '& .MuiSvgIcon-root': {
            marginRight: '.2rem'
        }
    },
    HelperSection: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

export default useStyles;
