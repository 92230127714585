import { FormControlLabel, Grid, Radio, Typography } from '@material-ui/core';
import { FC } from 'react';

import { thousandSeparator } from '../../../utils/clientHelpers';
import useStyles from './BNPLRadioButtonStyles.material';
import IBNPLRadioButtonProps from './interfaces/IBNPLRadioButtonProps';
import IRadioBoxButtonProps from './interfaces/IRadioBoxButtonProps';

const RadioBoxButton: FC<IRadioBoxButtonProps> = ({ label, amount, caption, priceCaption }) => {
    const classes = useStyles();
    return (
        <div className={classes.RadioBoxButton}>
            <Grid container>
                <Grid item xs={6} lg={6}>
                    <Typography className={classes.Label} variant="subtitle2" component="p">
                        {label}
                    </Typography>
                    <Typography variant="caption" component="p">
                        {caption}
                    </Typography>
                </Grid>
                <Grid item xs={6} lg={6}>
                    <Typography className={classes.Amount} variant="subtitle2" component="p">
                        $ {thousandSeparator(amount, '.')}
                    </Typography>
                    <Typography className={classes.PriceCaption} variant="caption" component="p">
                        {priceCaption}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

const BNPLRadioButton: FC<IBNPLRadioButtonProps> = ({
    label,
    value,
    classNames,
    isChecked,
    amount,
    caption,
    priceCaption
}) => {
    const classes = useStyles();
    return (
        <div className={classes.RadioButtonContainer}>
            <FormControlLabel
                className={`${classNames} ${classes.CardRadioButton} ${
                    isChecked ? 'checked' : null
                }`}
                value={value}
                control={<Radio className={classes.Radio} />}
                labelPlacement="start"
                label={
                    <RadioBoxButton
                        label={label}
                        amount={amount}
                        caption={caption}
                        priceCaption={priceCaption}
                    />
                }
            />
        </div>
    );
};

export default BNPLRadioButton;
