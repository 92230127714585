import { Button, Grid, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { FC, useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import SabbiLogo from '../../assets/logo/sabbi.svg';
import SabbiLogoNoBorderSrc from '../../assets/logo/sabbi-noborder.svg';
import AlertBar from '../../components/AlertBar/AlertBar';
import ContentBox from '../../components/ContentBox/ContentBox';
import { FormContext } from '../../components/FormsContext/FormContext/FormContext';
import PasswordInput from '../../components/FormsContext/PasswordInput/PasswordInput';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import HyperLink from '../../components/HyperLink/HyperLink';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useTpa } from '../../context/TPAContext/tpa';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { EPasswordRoutePaths } from '../../routes/enum/EPasswordRoutePaths';
import { EUserRoutePaths } from '../../routes/enum/EUserRoutePaths';
import { changePasswordService, registerService } from '../../services/sabbi/user/user';
import { IAddress } from '../../utils/address/interfaces/IAddress';
import { cancelTRXandReturnTrade } from '../../utils/clientHelpers';
import {
    generateEncryptedLogin,
    generateEncryptedRecoverPassword,
    generateEncryptedRegister
} from '../../utils/cryptography/cypher';
import { IGenerateEncryptedLogin } from '../../utils/cryptography/cypher/interfaces/IGenerateEncryptedLogin';
import { IGenerateEncryptedRecoverPassword } from '../../utils/cryptography/cypher/interfaces/IGenerateEncryptedRecoverPassword';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { IDevice } from '../../utils/device/interfaces/IDevice';
import { EFlowIndicator } from '../../utils/enums/EFlowIndicator';
import { handleErrorMessage } from '../../utils/errors';
import { IErrorValues } from '../../utils/errors/interfaces/IErrorValues';
import { ICreatePasswordLocationState } from './interfaces/ICreatePasswordLocationState';
import IModalValuesState from './interfaces/IModalValuesState';
import useStyles from './PasswordCreatePageStyles.material';

type Inputs = {
    newPassword?: string;
    newPassword2?: string;
};

const PasswordCreatePage: FC = () => {
    const [modalValues, setModalValues] = useState<IModalValuesState>({
        cancelModal: false
    });
    const [errorValues, setErrorValues] = useState<IErrorValues>({
        title: '',
        visible: false
    });
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation<ICreatePasswordLocationState>();
    const backdrop = useBackdrop();
    const tpaContext = useTpa();
    const authContext = useAuth();
    const { handleErrorRedirection, shouldRedirect } = useRedirection();
    const defaultValues: Partial<Inputs> = {};
    const methods = useForm<FieldValues>({ mode: 'all', defaultValues });

    useEffect(() => {
        if (!validateLocationStateData(location.state)) {
            if (!authContext.user?.te) {
                history.replace(ELoginRoutePaths.LOGIN);
            } else {
                history.replace(ECardRoutePaths.CARD_SELECT);
            }
        }
    }, []);

    const validateLocationStateData = (obj: ICreatePasswordLocationState) => {
        if (obj && obj.flow in EFlowIndicator) {
            if (obj.flow === EFlowIndicator.REGISTER) {
                return 'statusValidation' in obj && 'TOTP' in obj;
            }
            return 'TOTP' in obj && 'flow' in obj;
        }
        return false;
    };

    const getRedirectUrlTrade = async () => {
        backdrop.openBackdrop();
        try {
            await cancelTRXandReturnTrade(tpaContext.tokenInfo.tpa as string);
        } catch (error) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    const onSubmit: SubmitHandler<any> = async (data) => {
        backdrop.openBackdrop();
        const device: IDevice = getDataDevice();
        try {
            if (location.state.flow == EFlowIndicator.REGISTER) {
                const address: IAddress = {
                    country: location.state.userContactData?.address.country as string,
                    region: location.state.userContactData?.address.region as string,
                    comuna: location.state.userContactData?.address.comuna as string,
                    street1: location.state.userContactData?.address.street1 as string,
                    streetNumber: location.state.userContactData?.address.streetNumber as string,
                    longitude: location.state.userContactData?.address.latitude,
                    latitude: location.state.userContactData?.address.longitude
                };

                const unitNumber = location.state.userContactData?.address.unitNumber;
                if (unitNumber && unitNumber != '') address.unitNumber = unitNumber;

                const requestEncrypt = {
                    rut: location.state?.userRegisterData?.statusDoctoIdentidad.numero as string,
                    password: data.newPassword,
                    TPA: tpaContext.tokenInfo.tpa as string
                };
                const encriptedRegister = await generateEncryptedRegister(requestEncrypt);
                const device: IDevice = getDataDevice();
                const registerRequest = {
                    phone: {
                        value: location.state?.userContactData?.cellphone as string,
                        TOTP: location.state.TOTP
                    },
                    email: {
                        value: location.state?.userContactData?.email as string,
                        saveAnyway: true
                    },
                    address: [address],
                    device: device,
                    cipher: encriptedRegister.payload,
                    profession: location.state?.userContactData?.occupation as string,
                    TPA: tpaContext.tokenInfo.tpa as string
                };
                await registerService(registerRequest);
                const cypherLoginData: IGenerateEncryptedLogin = {
                    TPA: tpaContext.tokenInfo.tpa as string,
                    password: data.newPassword,
                    rut: location.state?.userRegisterData?.statusDoctoIdentidad.numero as string
                };
                const encriptedLogin = await generateEncryptedLogin(cypherLoginData);
                const requestLogin = {
                    loginPayload: encriptedLogin.payload,
                    device: device,
                    TPA: tpaContext.tokenInfo.tpa as string
                };
                await authContext.login(requestLogin);
                backdrop.closeBackdrop();
                history.replace({
                    pathname: ECardRoutePaths.CARD_SELECT_TYPE
                });
            }
            if (location.state.flow == EFlowIndicator.RECOVER_PASSWORD) {
                const cypherRecoverPasswordData: IGenerateEncryptedRecoverPassword = {
                    TPA: tpaContext.tokenInfo.tpa as string,
                    newPassword: data.newPassword,
                    rut: location.state?.userRecoverPasswordData?.rut as string
                };
                const encriptedRequest = await generateEncryptedRecoverPassword(
                    cypherRecoverPasswordData
                );
                const requestChangePassword = {
                    TOTP: location.state.TOTP,
                    cipher: encriptedRequest.payload,
                    device: device,
                    TPA: tpaContext.tokenInfo.tpa as string
                };
                await changePasswordService(requestChangePassword);
                backdrop.closeBackdrop();
                history.replace({
                    pathname: EPasswordRoutePaths.PASSWORD_CREATED,
                    state: {
                        success: true,
                        flow: EFlowIndicator.RECOVER_PASSWORD
                    }
                });
            }
        } catch (error) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                let failedUrl: EPasswordRoutePaths | EUserRoutePaths =
                    EUserRoutePaths.USER_CONFIRMATION_ERROR;
                if (location.state.flow == EFlowIndicator.RECOVER_PASSWORD) {
                    failedUrl = EPasswordRoutePaths.PASSWORD_CREATED;
                }
                history.replace({
                    pathname: failedUrl,
                    state: {
                        success: false,
                        flow: location.state.flow
                    }
                });
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    const handleCancel = async () => {
        history.replace(ELoginRoutePaths.LOGIN);
    };
    const showModal = (modalName: keyof IModalValuesState) => {
        setModalValues({
            ...modalValues,
            [modalName]: true
        });
    };
    const closeModals = () => {
        setModalValues({
            cancelModal: false
        });
    };
    const showError = (title: string) => {
        setErrorValues({
            title: title,
            visible: true
        });
        handleAlertTimeout();
    };
    const handleAlertTimeout = () => {
        setTimeout(() => {
            clearError();
        }, 6000);
    };
    const clearError = () => {
        setErrorValues({
            title: '',
            visible: false
        });
    };
    return (
        <section className={classes.CreatePasswordPage}>
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <div className={classes.HyperLinkBox}>
                        <HyperLink
                            classNames={classes.HyperLink_GoBack}
                            underline="none"
                            onClick={getRedirectUrlTrade}>
                            <ChevronLeftIcon />
                            volver al comercio
                        </HyperLink>
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <ContentBox>
                        <img className={classes.SabbiLogo} src={SabbiLogo} alt="LogoSabbi" />
                        <Typography variant="h2" component="h2">
                            Crea tu clave de seguridad
                        </Typography>
                        <Typography className={classes.CreateText} component="p">
                            Esta será tu clave de verificación e ingreso a nuestra plataforma, esta
                            debe contener 6 digitos numéricos.
                        </Typography>
                        <AlertBar
                            classNames={classes.AlertBar}
                            variant="filled"
                            severity="error"
                            message={errorValues.title}
                            open={errorValues.visible}
                            closeOnClick={() => clearError()}
                            icon={<WarningRoundedIcon />}
                        />
                        <FormContext
                            className={classes.Form}
                            id={'CreatePasswordForm'}
                            onSubmit={onSubmit}
                            methods={methods}>
                            <PasswordInput
                                classNames={classes.PasswordInput}
                                id={'newPassword'}
                                name={'newPassword'}
                                label={'Clave de seguridad'}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'La contraseña es requerida'
                                    },
                                    minLength: { value: 6, message: 'Campo Incorrecto' },
                                    maxLength: { value: 6, message: 'Campo Incorrecto' }
                                }}
                                defaultHelperText={'Ingresa una clave numérica de 6 dígitos'}
                            />
                            <PasswordInput
                                classNames={classes.PasswordInput}
                                id={'newPassword2'}
                                name={'newPassword2'}
                                label={'Confirmar clave'}
                                rules={{
                                    validate: (value) => {
                                        const passValue = (
                                            document.getElementById(
                                                'newPassword'
                                            ) as HTMLInputElement
                                        ).value;
                                        return value === passValue;
                                    }
                                }}
                            />
                            <div className={classes.ButtonsBox}>
                                <Button
                                    className={classes.CancelButton}
                                    onClick={() => showModal('cancelModal')}>
                                    Cancelar
                                </Button>
                                <RoundButton
                                    classNames={classes.SubmitButton}
                                    id={'submitbtn'}
                                    color="primary"
                                    name={'submitbtn'}
                                    type="submit">
                                    Continuar
                                </RoundButton>
                            </div>
                        </FormContext>
                    </ContentBox>
                </Grid>
            </Grid>

            <ModalDialog
                id="ModalCancel"
                open={modalValues.cancelModal}
                handleCancel={closeModals}
                handleAccept={handleCancel}
                cancelText="Volver"
                title={
                    <>
                        <img
                            className={classes.ModalNotNumber_SabbiNoBorder}
                            src={SabbiLogoNoBorderSrc}
                            alt="Sabbi"
                        />
                        <Typography variant="h2" component="h2">
                            ¿Deseas cancelar el proceso?{' '}
                            <span role="img" aria-label="confused-face">
                                😕
                            </span>
                        </Typography>
                        <Typography component="p" className={classes.WarningText}>
                            No se ha terminado el proceso de recuperación de contraseña, quedará
                            almacenada tu clave anterior.
                        </Typography>
                    </>
                }
                scroll={'paper'}
                maxWidth={'sm'}>
                <div className={classes.CancelModal}></div>
            </ModalDialog>
        </section>
    );
};

export default PasswordCreatePage;
