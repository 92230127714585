import AmericanExpress from '../assets/creditCardIcons/american-express.svg';
import DinersClub from '../assets/creditCardIcons/diners-club.svg';
import Discover from '../assets/creditCardIcons/discover.svg';
import DiscoverWhite from '../assets/creditCardIcons/discover_white.svg';
import Elo from '../assets/creditCardIcons/elo.svg';
import Hiper from '../assets/creditCardIcons/hiper.svg';
import Jcb from '../assets/creditCardIcons/jcb.svg';
import Maestro from '../assets/creditCardIcons/maestro.svg';
import MasterCard from '../assets/creditCardIcons/mastercard.svg';
import Mir from '../assets/creditCardIcons/mir.svg';
import RedCompra from '../assets/creditCardIcons/redCompra.svg';
import UnionPay from '../assets/creditCardIcons/unionpay.svg';
import Visa from '../assets/creditCardIcons/visa.svg';
import VisaWhite from '../assets/creditCardIcons/visa_white.svg';
import WebPay from '../assets/creditCardIcons/webPay.svg';

export const CreditCardIconsSources: Record<string, string> = {
    'american-express': AmericanExpress,
    'american-express-white': AmericanExpress,
    amex: AmericanExpress,
    'amex-white': AmericanExpress,
    'diners-club': DinersClub,
    'diners-club-white': DinersClub,
    discover: Discover,
    'discover-white': DiscoverWhite,
    elo: Elo,
    'elo-white': Elo,
    hiper: Hiper,
    'hiper-white': Hiper,
    jcb: Jcb,
    'jcb-white': Jcb,
    maestro: Maestro,
    'maestro-white': Maestro,
    mastercard: MasterCard,
    'mastercard-white': MasterCard,
    mir: Mir,
    'mir-white': Mir,
    unionpay: UnionPay,
    'unionpay-white': UnionPay,
    visa: Visa,
    'visa-white': VisaWhite,
    webpayplus: WebPay,
    redcompra: RedCompra,
    'redcompra-white': RedCompra
};

export const validateExpDate = (expDate: string) => {
    const today = new Date();
    const dateToCheck = new Date();
    if (expDate.length == 5) {
        const exMonth = expDate.substring(0, 2);
        const exYear = `20${expDate.substring(3, 5)}`;
        dateToCheck.setFullYear(parseInt(exYear), parseInt(exMonth), 1);
        if (dateToCheck < today) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
};

export const cleanCardNumber = (creditCardNumber: string) => {
    return creditCardNumber.replace(/ /g, '');
};
