import {
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import { FC, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { formatRut, validate } from '../../../utils/rutHelpers';
import IRutInputProps from './interfaces/IRutInputProps';
import useStyles from './RutInputStyles.material';

const RutInput: FC<IRutInputProps> = ({
    autoComplete = 'off',
    classNames = '',
    defaultHelperText,
    defaultValue = '',
    icon,
    id,
    label,
    maxLength = 12,
    name,
    placeholder = '',
    rules,
    watchInput
}) => {
    const [touched, setTouched] = useState(false);
    const classes = useStyles();
    const { formState, control } = useFormContext();
    const documentNumber = useWatch({
        control,
        name
    });
    const errors = formState?.errors;

    useEffect(() => {
        if (watchInput) {
            watchInput(documentNumber);
        }
    }, [documentNumber]);

    const checkError = () => {
        if (errors?.[name]) {
            return 'error';
        } else if (touched) {
            return 'success';
        }
    };
    const handleRenderIcon = () => {
        if (touched) {
            if (errors?.[name]) {
                return <ClearIcon />;
            } else {
                return <CheckCircleIcon />;
            }
        } else {
            if (errors?.[name]) {
                return <ClearIcon />;
            } else {
                return icon;
            }
        }
    };
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={{
                ...rules,
                validate: {
                    isValid: (value) => {
                        return validate(value) ? true : 'Debe tener un formato de RUT válido';
                    }
                }
            }}
            render={({ field }) => (
                <FormControl
                    size="small"
                    classes={{ root: classes.root }}
                    className={`${classNames} ${checkError()}`}
                    variant="outlined">
                    {label && (
                        <InputLabel className={classes.label} htmlFor={`${id}`}>
                            {label}
                        </InputLabel>
                    )}
                    <OutlinedInput
                        autoComplete={autoComplete}
                        id={`${id}`}
                        className={classes.label}
                        type="text"
                        label={label}
                        inputProps={{
                            maxLength: maxLength
                        }}
                        placeholder={`${placeholder}`}
                        onBlur={field.onBlur}
                        onChange={(value) => {
                            const formattedRut = formatRut(value.target.value);
                            setTouched(true);
                            field.onChange(formattedRut);
                        }}
                        value={field.value}
                        endAdornment={
                            <InputAdornment position="end">{handleRenderIcon()}</InputAdornment>
                        }
                    />
                    <FormHelperText id={`helper-text-${id}`}>
                        {errors?.[name] ? errors?.[name].message : defaultHelperText}
                    </FormHelperText>
                </FormControl>
            )}
        />
    );
};

export default RutInput;
