export const USER_ROUTE = '/user';

export enum EUserRoutePaths {
    USER_REGISTER = '/user/register',
    USER_REGISTER_CONTACT = '/user/register-contact',
    USER_REGISTER_MANUAL_ADDRESS = '/user/register-manual-address',
    USER_CONFIRMATION_ERROR = '/user/confirmation-error',
    UNIQUE_KEY = '/user/unique-key',
    UNIQUE_KEY_VALIDATION = '/user/unique-key-validation'
}

export const USER_ROUTE_PATHS: EUserRoutePaths[] = Object.values(EUserRoutePaths);
