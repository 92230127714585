import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    MessageBox: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '10px',
        display: 'flex',
        padding: '10px 15px',
        '&.primary': {
            border: `1px solid ${theme.palette.primary.main}`
        },
        '&.secondary': {
            border: `1px solid ${theme.palette.secondary.main}`
        },
        '&.error': {
            border: `1px solid ${theme.palette.error.main}`
        },
        '&.info': {
            border: `1px solid ${theme.palette.info.main}`
        }
    },
    InfoIcon: {
        alignSelf: 'center',
        '&.primary': {
            color: theme.palette.primary.main
        },
        '&.secondary': {
            color: theme.palette.secondary.main
        },
        '&.error': {
            color: theme.palette.error.main
        },
        '&.info': {
            color: theme.palette.info.main
        }
    }
}));

export default useStyles;
