import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { IProgressCircularProps } from './interfaces/IProgressCircularProps';
import useStyles from './ProgressCircularStyles.material';

const ProgressCircular: FC<IProgressCircularProps> = ({
    isDecrement = true,
    min = 0,
    max = 30,
    intervalTick = 1000,
    classNames = '',
    buttonText,
    linkOnClick
}) => {
    const { reset } = useFormContext();

    const [progress, setProgress] = useState<number>(isDecrement ? max : min);
    const classes = useStyles();

    useEffect(() => {
        setProgress(isDecrement ? max : min);
    }, [max, min]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((previousProgress) => {
                if (isDecrement) {
                    if (previousProgress <= min) {
                        clearInterval(timer);
                    }
                } else {
                    if (previousProgress >= max) {
                        clearInterval(timer);
                    }
                }
                const returnValue = isDecrement ? previousProgress - 1 : previousProgress + 1;
                if ((isDecrement && returnValue < 0) || (!isDecrement && returnValue > max)) {
                    clearInterval(timer);
                    return previousProgress;
                } else {
                    return returnValue;
                }
            });
        }, intervalTick);
        return () => {
            clearInterval(timer);
        };
    }, [progress]);

    const normalise = (value: number) => ((value - min) * 100) / (max - min);

    const handleOnClick = () => {
        setProgress(max);
        if (linkOnClick) {
            reset({
                otpFieldArray: [
                    {
                        otp: ''
                    },
                    {
                        otp: ''
                    },
                    {
                        otp: ''
                    },
                    {
                        otp: ''
                    }
                ]
            });
            linkOnClick();
        }
    };

    return (
        <div className={`${classNames}`}>
            {(isDecrement && progress > min) || (!isDecrement && progress < max) ? (
                <Box className={classes.ProgressCircular}>
                    <CircularProgress variant="determinate" value={normalise(progress)} />
                    <Box className={classes.NumberBox}>
                        <Typography className={classes.Number} variant="caption" component="div">
                            {progress}
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <Button className={classes.SendButton} onClick={handleOnClick}>
                    <SendIcon className={classes.SendIcon} />
                    {buttonText}
                </Button>
            )}
        </div>
    );
};

export default ProgressCircular;
