import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '1rem',
        borderRadius: '100px',
        minWidth: '185px',
        padding: '8px 1rem',
        textTransform: 'none',
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white
        },
        '&.primary': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
        },
        '&.secondary': {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.common.white
        },
        '&.error': {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white
        },
        '&.warning': {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.common.white
        },
        '&.info': {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.common.white
        },
        '&.success': {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.common.white
        },
        '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[300],
        },
    }
}));

export default useStyles;
