import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  UniqueKeyPage: {
      maxWidth: '100%',
      '& h2': {
          textAlign: 'center',
          marginBottom: '2rem'
      }
    },
  HyperLinkBox: {
      maxWidth: '100%',
      marginBottom: '10px',
      ['@media (max-width:959px)']: {
          width: '600px',
          margin: 'auto',
          marginBottom: '10px'
      }
    },
  HyperLink_GoBack: {
      ['@media (max-width:959px)']: {
          marginLeft: '-9px'
      },
      '& a': {
          display: 'flex',
          alignItems: 'center'
      }
    },
  ContentBox: {
      display: 'flex',
      flexDirection: 'column'
    },
  SabbiLogo: {
      width: '60px',
      height: '60px',
      alignSelf: 'center',
      marginBottom: '1.9rem'
    },
  ClaveUnica: {
      width: '190px',
      margin: 'auto'
    },
  AlertBar: {
      '&.opened': {
          marginBottom: '1rem'
      }
    },
  ButtonsBox: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  SubmitButton: {
      margin: 0,
      marginBottom: '1rem',
      maxWidth: '320px',
      padding: '6px 1rem',
      width: '100%',
      ['@media (max-width:450px)']: {
        width: '100%',
        maxWidth: '100%',
      }
    },
  CancelButton: {
      color: theme.palette.grey[800],
      maxWidth: '320px',
      padding: '6px 1rem',
      width: '100%',
      textTransform: 'none',
      ['@media (max-width:450px)']: {
        maxWidth: '100%',
        width: '100%',
      }
    },
  InfoIcon: {
      marginTop: '10px',
      marginBottom: '27px',
      width: '45px',
      height: '45px',
    },
  CancelModalTitle: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      '& h2': {
        marginBottom: '30px'
      }
    },
  ModalText: {
    marginBottom: '30px'
  }
}));
export default useStyles;