import 'simplebar/dist/simplebar.min.css';

import { FC } from 'react';
import SimpleBar from 'simplebar-react';

import useStyles from './CustomScrollbarStyles.material';
import { ICustomScrollbarProps } from './interfaces/CustomScrollbarProps';

const CustomScrollbar: FC<ICustomScrollbarProps> = ({
    children,
    forceVisible = true,
    classNames = '',
    autoHide = false,
    type = 'normal',
    scrollableNodeRef
}) => {
    const classes = useStyles();
    return (
        <SimpleBar
            forceVisible={forceVisible}
            autoHide={autoHide}
            scrollbarMaxSize={type === 'line' ? 50 : 0}
            className={`${classNames} ${classes.CustomScrollbar} ${type}`}
            scrollableNodeProps={{ ref: scrollableNodeRef }}>
            {children}
        </SimpleBar>
    );
};

export default CustomScrollbar;
