import sabbiHttpInstance from '../../instances/sabbi.instance';
import { IGenerateNewOtp } from './interfaces/IGenerateNewOtp';
import { IValidateOtp } from './interfaces/IValidateOtp';

export const validateOtpService = async (data: IValidateOtp) => {
    const response = await sabbiHttpInstance.post(`/user/validate-otp`, data);
    return response.data;
};

export const generateNewOtpService = async (data: IGenerateNewOtp) => {
    const response = await sabbiHttpInstance.post(`/user/generate-new-otp`, data);
    return response.data;
};
