import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    RejectedAccordion: {
        boxShadow: 'none',
        
    },
    RejectedAccordion_Summary: {
        paddingRight: 0,
        '& .MuiAccordionSummary-content': {
            margin: 0,
        },
    },
    RejectedAccordion_Details: {
        flexDirection: 'column',
        paddingBottom: 0
    }
}));

export default useStyles;
