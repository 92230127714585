import { ENameError } from './enums/ENameError';
import { IApplicationError } from './interfaces/IApplicationError';

export class ApplicationError extends Error {
    code: string;
    meta?: { [k: string]: unknown };
    statusCode: number;
    constructor(options: IApplicationError) {
        super();
        this.name = options.name ? options.name : ENameError.SERVICE_ERROR;
        this.code = options.code;
        this.message = options.message;
        this.meta = options.meta;
        this.statusCode = options.statusCode;
        /**
         * setPrototypeOf is needed here because extending from Error, Array and Map causes some issues when using es5 to transpile.
         * For more information:
         * https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
         */
        Object.setPrototypeOf(this, ApplicationError.prototype);
    }
}
