import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    RecoverPasswordPage: {
        maxWidth: '100%',
        '& h2': {
            textAlign: 'center',
            marginBottom: '17px'
        }
    },
    SabbiLogo: {
        width: '60px',
        height: '60px',
        display: 'flex',
        margin: '0 auto 1.9rem auto'
    },
    RecoverText: {
        textAlign: 'center',
        margin: '1rem auto 2rem auto',
        maxWidth: '400px'
    },
    RutInput: {
        marginBottom: '50px',
        maxWidth: '100%',
        margin: '0 auto'
    },
    Form: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& .MuiFormControl-root': {
            marginBottom: '30px'
        }
    },
    ButtonsBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      ['@media (max-width:450px)']: {
        flexDirection: 'column-reverse'
      },
    },
    SubmitButton: {
        maxWidth: '225px',
        alignSelf: 'center',
        ['@media (max-width:450px)']: {
          width: '100%',
          maxWidth: '100%',
          margin: '1rem 0',
        }
    },
    CancelButton: {
        color: theme.palette.grey[800],
        width: '185px',
        maxWidth: '225px',
        minWidth: '185px',
        textTransform: 'none',
        margin: '1rem',
        ['@media (max-width:450px)']: {
          maxWidth: '100%',
          width: '100%',
          margin: '1rem 0',
        }
    },
    AlertBar: {
        '&.opened': {
            marginBottom: '1rem'
        }
    }
}));

export default useStyles;
