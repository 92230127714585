import { FormHelperText, Grid, InputAdornment, TextField } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce } from 'lodash';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import HyperLink from '../../HyperLink/HyperLink';
import useStyles from './GoogleAddressInputStyles.material';
import GoogleAddressInputProps from './interfaces/GoogleAddressInputProps';
import { IGoogleAddress } from './interfaces/IGoogleAddress';

const GoogleAddressInput: FC<GoogleAddressInputProps> = ({
    id = '',
    name,
    label,
    rules,
    defaultValue = '',
    placeholder = '',
    icon,
    classNames = '',
    hasHyperLink = false,
    onClickHyperLink,
    defaultHelperText,
    hyperLinkText,
    closeIcon,
    freeSolo = true,
    autoComplete = false,
    clearOnBlur = true,
    fullWidth = true,
    handleManualAddressButton
}) => {
    const classes = useStyles();
    const startSearchAt = 6;
    const service = new google.maps.places.AutocompleteService();
    const geocoder = new google.maps.Geocoder();
    const autoCompleteRequest: google.maps.places.AutocompletionRequest = {
        input: '',
        types: ['geocode'],
        componentRestrictions: {
            country: 'CL'
        }
    };
    const [touched, setTouched] = useState(false);

    const noAddressOption: IGoogleAddress = {
        country: '',
        region: '',
        comuna: '',
        street1: '',
        streetNumber: '',
        fullAddress: ''
    };
    const [addressSugestions, setAddressSugestions] = useState<IGoogleAddress[]>([]);

    const { formState, control } = useFormContext();
    const errors = formState?.errors;

    const checkError = (value: unknown) => {
        if (errors?.[name]) {
            return 'error';
        } else if (touched || value != '') {
            return 'success';
        }
    };

    const handleRenderIcon = (value: unknown) => {
        if (touched) {
            if (errors?.[name]) {
                return <ClearIcon />;
            } else {
                return <CheckCircleIcon />;
            }
        } else {
            if (errors?.[name]) {
                return <ClearIcon />;
            } else if (value !== '') {
                return <CheckCircleIcon />;
            } else {
                return icon;
            }
        }
    };

    const onChangeTextField = async (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        try {
            if (!event.target.value) {
                setAddressSugestions([]);
            } else {
                if (event.target.value.length > startSearchAt) {
                    const predictions = await getAddressPredictions(event.target.value);
                    if (!predictions) {
                        setAddressSugestions([]);
                    } else {
                        const filteredAddressPredictions = filterAddressPredictions(predictions);
                        const predictionsGeocodeData = await getAllPredictionsGeocodeData(
                            filteredAddressPredictions
                        );
                        const formattedPredictionsAsAddress =
                            formatAllPredictionsGeocodeData(predictionsGeocodeData);
                        setAddressSugestions(formattedPredictionsAsAddress);
                    }
                } else {
                    setAddressSugestions([]);
                }
            }
        } catch (error) {
            setAddressSugestions([]);
        }
    };

    const getAddressPredictions = async (searchString: string) => {
        const { predictions } = await service.getPlacePredictions({
            ...autoCompleteRequest,
            input: searchString
        });
        return predictions;
    };

    const filterAddressPredictions = (
        addressPredictions: google.maps.places.AutocompletePrediction[]
    ) => {
        const filteredPredictions = addressPredictions.filter(
            (prediction: google.maps.places.AutocompletePrediction) =>
                prediction.types.find(
                    (x) => x == 'street_address' || x == 'premise' || x == 'establishment'
                )
        );
        return filteredPredictions;
    };

    const getAddressGeocodeData = async (placeId: string) => {
        const geocodeData = await geocoder.geocode({ placeId });
        return geocodeData;
    };

    const mapAddressResult = (geocodeData: google.maps.GeocoderResult) => {
        const address: IGoogleAddress = {
            fullAddress: geocodeData.formatted_address,
            country:
                geocodeData.address_components.find((component) => component.types[0] === 'country')
                    ?.long_name || '',
            region:
                geocodeData.address_components.find(
                    (component) => component.types[0] === 'administrative_area_level_1'
                )?.long_name || '',
            comuna:
                geocodeData.address_components.find(
                    (component) => component.types[0] === 'administrative_area_level_3'
                )?.long_name || '',
            street1:
                geocodeData.address_components.find((component) => component.types[0] === 'route')
                    ?.long_name || '',
            streetNumber:
                geocodeData.address_components.find(
                    (component) => component.types[0] === 'street_number'
                )?.long_name || '',
            latitude: geocodeData.geometry.location.lat(),
            longitude: geocodeData.geometry.location.lng(),
            street2: '',
            unitNumber: ''
        };
        return address;
    };

    const getAllPredictionsGeocodeData = async (
        addressPredictionsArray: google.maps.places.AutocompletePrediction[]
    ) => {
        let index = 0;
        const predictionsGeocodeDataPromiseArray: Promise<google.maps.GeocoderResponse>[] = [];
        for (index; index < addressPredictionsArray.length; index++) {
            predictionsGeocodeDataPromiseArray.push(
                getAddressGeocodeData(addressPredictionsArray[index].place_id)
            );
        }
        const predictionsGeocodeData = await Promise.all(predictionsGeocodeDataPromiseArray);
        return predictionsGeocodeData;
    };

    const formatAllPredictionsGeocodeData = (
        predictionsGeocodeDataArray: google.maps.GeocoderResponse[]
    ) => {
        let index = 0;
        let result: IGoogleAddress;
        const addressArray: IGoogleAddress[] = [];
        for (index; index < predictionsGeocodeDataArray.length; index++) {
            result = mapAddressResult(predictionsGeocodeDataArray[index].results[0]);
            addressArray.push(result);
        }
        return addressArray;
    };

    const validateAddressProperties = (address: IGoogleAddress) => {
        let isValid = true;
        const { comuna, region, street1, streetNumber, latitude, longitude, country } = address;
        if (
            comuna == '' ||
            region == '' ||
            street1 == '' ||
            streetNumber == '' ||
            latitude == undefined ||
            longitude == undefined ||
            country == ''
        ) {
            isValid = false;
        }
        return isValid;
    };

    const debouncedChangeHandler = useMemo(() => debounce(onChangeTextField, 300), []);

    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, []);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={{
                ...rules,
                validate: {
                    isValid: (value) => {
                        return validateAddressProperties(value)
                            ? true
                            : 'La dirección seleccionada no se puede utilizar';
                    }
                }
            }}
            render={({ field: { onChange, onBlur, value } }) => (
                <Autocomplete
                    className={`${classNames} ${classes.GoogleAddressInput} ${checkError(value)}`}
                    classes={{ root: classes.root }}
                    id={id}
                    getOptionLabel={(option: IGoogleAddress) => option.fullAddress || ''}
                    getOptionSelected={(option: IGoogleAddress, value: IGoogleAddress) =>
                        option.fullAddress === value.fullAddress
                    }
                    options={addressSugestions}
                    autoComplete={autoComplete}
                    value={value}
                    freeSolo={freeSolo}
                    fullWidth={fullWidth}
                    closeIcon={closeIcon}
                    onChange={async (_, data) => {
                        setTouched(true);
                        onChange(data);
                    }}
                    filterOptions={(options) => {
                        const result = [...options];
                        result.push(noAddressOption);
                        return result;
                    }}
                    renderOption={(option) => {
                        if (option.fullAddress === '') {
                            return (
                                <Grid container>
                                    <Grid
                                        item
                                        onClick={handleManualAddressButton}
                                        className={classes.ManualAddressButton}>
                                        Agregar dirección manualmente
                                        <EditIcon />
                                    </Grid>
                                </Grid>
                            );
                        } else {
                            return (
                                <Grid container alignItems="center">
                                    <Grid item>{option.fullAddress}</Grid>
                                </Grid>
                            );
                        }
                    }}
                    renderInput={(params) => (
                        <>
                            <TextField
                                {...params}
                                label={label}
                                className={classes.label}
                                id={id}
                                autoComplete="off"
                                placeholder={placeholder}
                                variant="outlined"
                                onChange={debouncedChangeHandler}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {handleRenderIcon(value)}
                                        </InputAdornment>
                                    ),
                                    className: classes.label
                                }}
                                InputLabelProps={{
                                    className: classes.label
                                }}
                            />
                            <div className={classes.HelperSection}>
                                <FormHelperText id={`helper-text-${id}`}>
                                    {errors?.[name] ? errors?.[name].message : defaultHelperText}
                                </FormHelperText>
                                {hasHyperLink && (
                                    <HyperLink
                                        classNames={classes.HyperLink}
                                        color="initial"
                                        onClick={onClickHyperLink}>
                                        {hyperLinkText}
                                    </HyperLink>
                                )}
                            </div>
                        </>
                    )}
                    clearOnBlur={clearOnBlur}
                    onBlur={onBlur}
                />
            )}
        />
    );
};

export default GoogleAddressInput;
