import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    HyperLinkBox: {
      maxWidth: '100%',
      marginBottom: '10px',
      ['@media (max-width:959px)']: {
          width: '600px',
          margin: 'auto',
          marginBottom: '10px'
      }
    },
    HyperLink_GoBack: {
      ['@media (max-width:959px)']: {
          marginLeft: '-9px'
      },
      '& a': {
          display: 'flex',
          alignItems: 'center'
      }
    },    
    AlertBar: {
      '&.opened': {
          marginBottom: '1rem'
      }
    },
    CreatePasswordPage: {
        maxWidth: '100%',
        '& h2': {
            textAlign: 'center',
            marginBottom: '17px'
        }
    },
    CreateText: {
        margin: '1rem auto 2rem auto',
        textAlign: 'center',
        maxWidth: '470px'
    },
    PasswordInput: {
        marginBottom: '2rem',
        maxWidth: '100%',
        margin: '0 auto',
        '& a': {
            color: theme.palette.grey[800],
            textDecoration: 'none'
        }
    },
    ButtonsBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      ['@media (max-width:450px)']: {
        flexDirection: 'column-reverse'
      },
    }, 
    SubmitButton: {
      maxWidth: '225px',
      alignSelf: 'center',
      ['@media (max-width:450px)']: {
        width: '100%',
        maxWidth: '100%',
        margin: '1rem 0',
      }
    },
    Form: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& .MuiFormControl-root': {
            marginBottom: '30px'
        }
    },
    CancelButton: {
        color: theme.palette.grey[800],
        width: '185px',
        maxWidth: '225px',
        minWidth: '185px',
        textTransform: 'none',
        margin: '1rem',
        ['@media (max-width:450px)']: {
          maxWidth: '100%',
          width: '100%',
          margin: '1rem 0',
        }
    },
    SabbiLogo: {
        width: '60px',
        height: '60px',
        display: 'flex',
        alignSelf: 'center',
        margin: '0 auto 1rem auto'
    },
    ModalNotNumber_SabbiNoBorder:{
        marginBottom: '2rem'
    },
    CancelModal: {
        textAlign: 'center',
        '& p': {
            margin: 0
        }
    },
    WarningText: {
        textAlign: 'center',
        maxWidth: '500px',
        margin: 'auto'
    }
}));

export default useStyles;
