import { FC } from 'react';

import SBLogoSvg from '../../assets/logo/SabbiHeaderLogo.svg';
import ISBLogoProps from './interfaces/ISBLogo';
import useStyles from './SBLogoStyles.material';

const SBLogo: FC<ISBLogoProps> = ({ classNames = '', size = '' }) => {
    const classes = useStyles();
    return (
        <div className={`${classNames}`}>
            <img className={`${classes.SBLogoImg} ${size}`} src={SBLogoSvg} alt="SABBI" />
        </div>
    );
};

export default SBLogo;
