const COLORS = {
    black: '#4D4D4D',
    blackLight: '#1E1E1E',
    //Blue
    blue: '#006DEF',
    blueDark: '#2471CA',
    blueLight: '#33C5FF',
    //Cyan
    cyanMain: '#81F5D6',
    cyanLight: '#CEFFF2',
    //Green
    green: '#90D2B0',
    greenLight: '#C0FFEE',
    //Grey
    grey: '#979797',
    grey50: '#F6F8FC',
    grey100: '#F0F0F0',
    grey200: '#D3D3D3',
    grey300: '#BCBCBC',
    grey400: '#A6A6A6',
    grey500: '#909090',
    grey600: '#7A7A7A',
    grey700: '#646464',
    grey800: '#4D4D4D',
    grey900: '#373737',

    grey1500: '#E7E7E7',
    //Lilac
    lilac: '#C0B3CF',
    lilacLight: '#F1E5FF',
    lilacLighter: '#F3E4FF',
    orange: '#FF966C',
    pink: '#F1BCC4',
    //Red
    red: '#B6343A',
    redLight: '#E26F6F',
    redLighter: '#FFF3F2',
    //SkyBlue
    skyBlue: '#63B0DC',
    skyBlueLight: '#B2D1E2',
    //Violet
    violetAlert: '#F5E7FF',
    violetDark: '#6400A9',
    violetLight: '#F5E7FF',
    violetMain: '#8A00E6',
    white: '#FFFFFF',
    yellow: '#F9DF6E'
};

export default COLORS;
