import { Fade, IconButton } from '@material-ui/core';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import Alert from '@material-ui/lab/Alert';
import { FC } from 'react';

import useStyles from './AlertBarStyles.material';
import { IAlertBarProps } from './interfaces/IAlertBarProps';

const AlertBar: FC<IAlertBarProps> = ({
    variant,
    severity,
    message,
    open,
    closeOnClick,
    icon,
    classNames = ''
}) => {
    const classes = useStyles();
    const handleClose = () => {
        closeOnClick();
    };
    return (
        <div className={`${classes.root} ${classNames} ${open ? 'opened' : 'closed'}`}>
            <Fade in={open}>
                {open ? (
                    <Alert
                        className={classes.Alert}
                        variant={variant}
                        severity={severity}
                        icon={icon}
                        action={
                            <IconButton
                                className={classes.IconButton}
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={handleClose}>
                                <CancelRoundedIcon fontSize="inherit" />
                            </IconButton>
                        }>
                        {message}
                    </Alert>
                ) : (
                    /* Fade component needs an element to be faded in DOM */
                    <div></div>
                )}
            </Fade>
        </div>
    );
};

export default AlertBar;
