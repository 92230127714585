import { IHandleError } from '../interfaces/IHandleError';

export const SERVICE_ERRORS_CUSTOM: Record<string, IHandleError> = {
    'password incorrect': {
        code: 'UNAUTHORIZED',
        message: 'password incorrect',
        messageHandle: 'Contraseña incorrecta.'
    },
    'Missmatching OTP Codes': {
        code: 'BAD_REQUEST',
        message: 'Missmatching OTP Codes',
        messageHandle: 'El código ingresado es incorrecto.'
    },
    'TR006 - Transacción declinada.': {
        code: 'BAD_REQUEST',
        message: 'TR006 - Transacción declinada.',
        messageHandle: 'Transacción declinada.'
    },
    'User not found': {
        code: 'UNAUTHORIZED',
        message: 'User not found',
        messageHandle: 'Usuario no autorizado.'
    }
};
