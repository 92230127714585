import { Grid, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import loader from '../../assets/gifs/loader.gif';
import CheckIconSrc from '../../assets/icons/checkIcon.svg';
import ModalErrorIconSrc from '../../assets/icons/modal-error-icon.svg';
import WebPayIconSrc from '../../assets/icons/webPay.svg';
import SabbiLogoSrc from '../../assets/logo/sabbi.svg';
import AccordionCollapse from '../../components/AccordionCollapse/AccordionCollapse';
import AlertBar from '../../components/AlertBar/AlertBar';
import ContentBox from '../../components/ContentBox/ContentBox';
import BNPLRadioGroup from '../../components/FormsContext/BNPLRadioGroup/BNPLRadioGroup';
import {
    IBNPLRadioButton,
    PAGOPSPVALUE
} from '../../components/FormsContext/BNPLRadioGroup/interfaces/IBNPLRadioGroupProps';
import CardRadioGroup from '../../components/FormsContext/CardRadioGroup/CardRadioGroup';
import { CardRadioButton } from '../../components/FormsContext/CardRadioGroup/interfaces/ICardRadioGroupProps';
import { FormContext } from '../../components/FormsContext/FormContext/FormContext';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import HtmlTooltip from '../../components/HtmlTooltip/HtmlTooltip';
import HyperLink from '../../components/HyperLink/HyperLink';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import { IRejectionTypeBnpl } from '../../components/RejectedBNPL/interfaces/IRejectedBNPLProps';
import RejectedBNPL from '../../components/RejectedBNPL/RejectedBNPL';
import { ESelectedCardVariants } from '../../components/SelectedCard/interfaces/ISelectedCardProps';
import SelectedCard from '../../components/SelectedCard/SelectedCard';
import SquareButton from '../../components/SquareButton/SquareButton';
import { ITransactionDetailInfo } from '../../components/TransactionDetail/interfaces/ITransactionDetailInfo';
import TransactionDetail from '../../components/TransactionDetail/TransactionDetail';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useBusiness } from '../../context/BusinessContext/business';
import { EModalError } from '../../context/ModalContext/enums/EModalError';
import { useModalExpired } from '../../context/ModalContext/modalExpired';
import { useTpa } from '../../context/TPAContext/tpa';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ECheckoutRoutePaths } from '../../routes/enum/ECheckoutRoutePaths';
import { ENotAllowedRoutePaths } from '../../routes/enum/ENotAllowedRoutePaths';
import {
    subscriptionCancelService,
    userSubscribedCardsService
} from '../../services/sabbi/card/card';
import { ICard } from '../../services/sabbi/card/interfaces/ICard';
import { IDeleteCard } from '../../services/sabbi/card/interfaces/IDeleteCard';
import { IIdCard } from '../../services/sabbi/card/interfaces/IIdCard';
import { ISimulationBNPL } from '../../services/sabbi/transaction/interfaces/ISimulationBNPL';
import { simulationBNPLService } from '../../services/sabbi/transaction/transaction';
import { Currencies } from '../../types/currencies';
import { cancelTRXandReturnTrade, thousandSeparator } from '../../utils/clientHelpers';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { IDevice } from '../../utils/device/interfaces/IDevice';
import { EChannel } from '../../utils/enums/EChannel';
import { EChannelType } from '../../utils/enums/EChannelType';
import { ECurrency } from '../../utils/enums/ECurrency';
import { handleErrorMessage, servicesErrorHandler } from '../../utils/errors';
import { IErrorValues } from '../../utils/errors/interfaces/IErrorValues';
import { handleRequestCardAsyncToken } from '../../utils/kushkiHelpers';
import { getTEInformation } from '../../utils/localStorageManipulation';
import { getTPAInformation } from '../../utils/localStorageManipulation';
import { ITPAValidatedData } from '../../utils/tokenManipulation/interfaces/ITPAValidatedData';
import useStyles from './CardSelectPageStyles.material';
import IModalValuesState from './interfaces/IModalValuesState';
import ISelectedCardInfoState from './interfaces/ISelectedCardInfoState';
import { Inputs, Simulation, SimulationState } from './types/CardSelectPageTypes';

const CardSelectPage: FC = () => {
    const [selectedCard, setSelectedCard] = useState<string>();
    const [selectedCardInfo, setSelectedCardInfo] = useState<ISelectedCardInfoState>({
        subscriptionId: '',
        idCard: '',
        cardBrand: '',
        cardMaskedNumber: '',
        cardType: ''
    });
    const [userCards, setUserCards] = useState<Array<CardRadioButton>>();
    const [infoTransactionTpa, setInfoTransactionTpaObject] = useState<ITransactionDetailInfo>();
    const [simulation, setSimulation] = useState<SimulationState>([]);
    const [BNPLInfo, setBNPLInfo] = useState<Array<IBNPLRadioButton>>([]);
    const [selectedPaymentDetail, setSelectedselectedPaymentDetail] = useState<Simulation>();
    const [alertBnpl, setAlertBnpl] = useState<boolean>(false);
    const [bnplRejectionReasons, setBnplRejectionReasons] = useState<{
        reasons: IRejectionTypeBnpl[];
    }>();
    const [isLoading, setIsloading] = useState<boolean>(true);
    const [modalValues, setModalValues] = useState<IModalValuesState>({
        deleteCardModal: false,
        successDeleteModal: false
    });
    const [errorValues, setErrorValues] = useState<IErrorValues>({
        title: '',
        visible: false
    });
    const defaultValues: Partial<Inputs> = {
        cardInput: selectedCard,
        bnplInput: ''
    };
    const methods = useForm<FieldValues>({ mode: 'all', defaultValues });
    const history = useHistory();
    const backdrop = useBackdrop();
    const authContext = useAuth();
    const tpaContext = useTpa();
    const businessContext = useBusiness();
    const modalContext = useModalExpired();
    const classes = useStyles();
    const { handleErrorRedirection, shouldRedirect } = useRedirection();

    const bnplIsAllowed = (businessContext.financialProducts.bnpl as boolean) ? true : false;
    const pspIsAllowed = (businessContext.financialProducts.psp.oneclick as boolean) ? true : false;
    const userCanAddCard: boolean | undefined = userCards && userCards.length < 5;

    useEffect(() => {
        modalContext.checkTeExpiration();
    }, []);

    useEffect(() => {
        if (!modalContext.isExpired) {
            backdrop.openBackdrop();
            obtainTpaInfo();
        }
    }, [modalContext.isExpired]);

    useEffect(() => {
        if (infoTransactionTpa) {
            getUserCards();
        }
    }, [infoTransactionTpa]);

    useEffect(() => {
        if (userCards) {
            if (userCards.length == 0) {
                history.replace({
                    pathname: ECardRoutePaths.CARD_SELECT_TYPE
                });
            } else {
                setInitialCardSelected();
            }
        }
    }, [userCards]);

    useEffect(() => {
        if (selectedCard) {
            const cardData = selectedCard.split(',');
            setSelectedCardInfo({
                subscriptionId: cardData[0],
                idCard: cardData[1],
                cardBrand: cardData[2],
                cardMaskedNumber: cardData[3],
                cardType: cardData[4]
            });
        }
    }, [selectedCard]);

    useEffect(() => {
        if (!isLoading) {
            callBNPLSimulation();
            backdrop.closeBackdrop();
        }
    }, [isLoading]);

    useEffect(() => {
        if (isLoading && selectedCardInfo.idCard !== '') {
            setIsloading(false);
        }
    }, [selectedCardInfo]);

    /**
     * Gets TPA Information
     * @return {Promise<void>}
     */
    const obtainTpaInfo = async (): Promise<void> => {
        try {
            const infoTPA = await getTPAInformation(tpaContext.tokenInfo.tpa);
            const infoTPAFormated: ITransactionDetailInfo = {
                businessPartnerLogo: infoTPA?.bodyTPA.businessPartner?.businessPartnerLogo,
                businessPartnerName: infoTPA?.bodyTPA.businessPartner?.businessPartnerName,
                productList: infoTPA?.bodyTPA.transactionDetails?.orderDetails.productList,
                total: infoTPA?.bodyTPA.transactionDetails?.orderDetails.amount.total,
                transactionId: infoTPA?.bodyTPA.transactionDetails?.orderDetails.transactionId,
                purchaseId: infoTPA?.bodyTPA.metadata?.trxPmi as string
            };
            setInfoTransactionTpaObject(infoTPAFormated);
        } catch (error: any) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        }
    };
    /**
     * Cancel transaction and return to businessPartner URL
     * @return {Promise<void>}
     */
    const getRedirectUrlTrade = async (): Promise<void> => {
        backdrop.openBackdrop();
        try {
            await cancelTRXandReturnTrade(
                tpaContext.tokenInfo.tpa as string,
                authContext.user?.te as string
            );
        } catch (error: any) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    /**
     * Obtain user subscribed cards and set cards state
     * @return {Promise<void>}
     */
    const getUserCards = async (): Promise<void> => {
        try {
            backdrop.openBackdrop();
            const subscribedCardsRequest = {
                TE: authContext.user?.te as string,
                TPA: tpaContext.tokenInfo.tpa as string
            };
            const resCards = await userSubscribedCardsService(subscribedCardsRequest);
            let MAPPED_CARDS = [];
            if (resCards.payload.length > 0) {
                MAPPED_CARDS = resCards.payload.map((card: ICard, index: number) => {
                    const strAuxCard = `${card.subscriptionId},${card.idCard},${card.subscriptionData.paymentBrand},${card.subscriptionData.maskedCardNumber},${card.subscriptionData.cardType}`;
                    const cardValues = {
                        value: strAuxCard,
                        idCard: card.idCard,
                        label: {
                            cardBrand: card.subscriptionData.paymentBrand,
                            cardMaskedNumber: card.subscriptionData.maskedCardNumber,
                            cardType: card.subscriptionData.cardType
                        },
                        key: card.idCard,
                        cardType: card.subscriptionData.cardType
                    };
                    return cardValues;
                });
                setUserCards(MAPPED_CARDS);
            } else {
                setUserCards([]);
            }
        } catch (error: any) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    /**
     * Set initial selected card state
     * @return {Promise<void>}
     */
    const setInitialCardSelected = async (): Promise<void> => {
        const initialCardSelected = (userCards as Array<CardRadioButton>)[0].value as string;
        setSelectedCard(initialCardSelected);
    };
    /**
     * Calls BNPL Simulation
     * @return {Promise<void>}
     */
    const callBNPLSimulation = async (): Promise<void> => {
        try {
            const infoTPA = await getTPAInformation(tpaContext.tokenInfo.tpa);
            await getBNPL(bnplIsAllowed, infoTPA);
        } catch (error: any) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        }
    };
    /**
     * Builds PSP single payment button structure
     * @param {ITPAValidatedData} tpaInfo TPA information
     * @return {IBNPLRadioButton} psp single payment button
     */
    const getPSP = (tpaInfo: ITPAValidatedData): IBNPLRadioButton => {
        const pspUnico: IBNPLRadioButton = {
            label: 'Pago único de ',
            amount: tpaInfo?.bodyTPA.transactionDetails?.orderDetails.amount.total as number,
            caption: 'Costo total ',
            priceCaption: `$ ${thousandSeparator(
                Number(
                    JSON.stringify(
                        tpaInfo?.bodyTPA.transactionDetails?.orderDetails.amount.total as number
                    )
                ),
                '.'
            )}`,
            value: PAGOPSPVALUE,
            key: PAGOPSPVALUE
        };
        return pspUnico;
    };
    /**
     * Gets BNPL Information data and set bnpl buttons
     * @param {boolean} isBnpl should render BNPL buttons
     * @param {ITPAValidatedData} tpaInfo TPA information
     * @return {Promise<void>}
     */
    const getBNPL = async (isBnpl: boolean, tpaInfo: ITPAValidatedData): Promise<void> => {
        let BNPLData;
        try {
            if (isBnpl) {
                let simulationsBNPL = [] as any;
                const simulationBNPLRequest = {
                    TE: authContext.user?.te as string,
                    TPA: tpaContext.tokenInfo.tpa,
                    device: getDataDevice(),
                    metadata: {
                        transactionData: {
                            channel: EChannel.SABBI,
                            channelType: EChannelType.WEB
                        }
                    }
                } as ISimulationBNPL;
                const simulationBNPLResponse = await simulationBNPLService(simulationBNPLRequest);
                simulationsBNPL = simulationBNPLResponse.payload.simulations;
                setSimulation(simulationsBNPL);
                const simulation = simulationsBNPL.map((nSimulation: Simulation) => {
                    const dateFormat = moment(String(nSimulation.fechaVencimiento)).format(
                        'DD/MM/YYYY'
                    );
                    const bnplCuota = {
                        label: `${nSimulation.plazo} cuotas Sabbi`,
                        amount: nSimulation.valorCuota,
                        caption: `Pago primera cuota ${dateFormat}`,
                        priceCaption: `Costo Total $ ${thousandSeparator(
                            Number(nSimulation.CTC),
                            '.'
                        )}`,
                        value: nSimulation.id,
                        key: `BNPL-${nSimulation.plazo}-${nSimulation.id}`
                    };
                    return bnplCuota;
                });
                BNPLData = simulation;
            } else {
                BNPLData = [];
            }
        } catch (error: any) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                BNPLData = [];
                if (error.meta?.decisionParsed) {
                    setBnplRejectionReasons(error.meta.decisionParsed);
                }
            }
            setAlertBnpl(true);
        } finally {
            if (pspIsAllowed) {
                const pagoUnico = getPSP(tpaInfo);
                BNPLData.unshift(pagoUnico);
            }
            setBNPLInfo(BNPLData);
        }
    };
    /**
     * Submit form data. Submits selected payment methods.
     * @param {Inputs} data data received from inputs
     * @return {SubmitHandler<Inputs>}
     */
    const submitPaymentOptions: SubmitHandler<Inputs> = async (data: Inputs): Promise<void> => {
        try {
            const TEInfo = await getTEInformation(authContext.user?.te as string);
            if (TEInfo.isExpired) {
                modalContext.updateModalExpired(EModalError.DEFAULT);
                modalContext.setExpiredTE(true);
                modalContext.openModal();
            } else {
                if (selectedCard) {
                    const cardData = data.cardInput?.split(',');
                    const bnpl = data.bnplInput;
                    if (cardData && cardData.length > 0 && bnpl) {
                        setSelectedCardInfo({
                            subscriptionId: cardData[0],
                            idCard: cardData[1],
                            cardBrand: cardData[2],
                            cardMaskedNumber: cardData[3],
                            cardType: cardData[4]
                        });
                        const paymentMethods = selectedPaymentDetail ? 'BNPL' : 'PSP';
                        const paymentDetailData = {
                            subscriptionId: cardData[0],
                            idCard: cardData[1],
                            cardBrand: cardData[2],
                            cardMaskedNumber: cardData[3],
                            cardType: cardData[4],
                            BNPLInfo: selectedPaymentDetail ? selectedPaymentDetail : null
                        };
                        history.push({
                            pathname: ECardRoutePaths.CARD_PAYMENT_DETAIL,
                            state: {
                                success: true,
                                paymentMethods,
                                meta: paymentDetailData
                            }
                        });
                    }
                }
            }
        } catch (error) {
            history.replace(ENotAllowedRoutePaths.NOT_AUTHORIZED);
        }
    };
    /**
     * Set state on selected BNPL button information
     * @param {number | string | undefined} value value received from BNPL Button
     * @return {void}
     */
    const handleSetSelectedBNPL = (value: number | string | undefined): void => {
        if (value !== PAGOPSPVALUE) {
            const selectedFee = simulation.filter((option) => option.id == value);
            setSelectedselectedPaymentDetail(selectedFee[0]);
        } else {
            setSelectedselectedPaymentDetail(undefined);
        }
    };
    /**
     * Handles WebPayPlus payment
     * @return {Promise<void>}
     */
    const handleWebPayPlusPayment = async (): Promise<void> => {
        try {
            backdrop.openBackdrop();
            const tpaInfo = await getTPAInformation(tpaContext.tokenInfo.tpa);
            const TEInfo = await getTEInformation(authContext.user?.te as string);
            if (TEInfo.isExpired) {
                modalContext.updateModalExpired(EModalError.DEFAULT);
                modalContext.setExpiredTE(true);
                modalContext.openModal();
            } else {
                const currencyType: Currencies = ECurrency.CHILE;
                const obfuscatedEmail = TEInfo.bodyTE.user.email?.value;
                const requestToken = {
                    totalAmount: tpaInfo?.bodyTPA.transactionDetails?.orderDetails.amount
                        .total as number,
                    returnUrl: `${window.location.protocol}//${window.location.hostname}:${window.location.port}${ECheckoutRoutePaths.CHECKOUT_LOGGED_USER_SUCCESS}`,
                    currency: currencyType,
                    email: obfuscatedEmail,
                    description: `Pago en comercio ${tpaInfo.bodyTPA.businessPartner?.businessPartnerName}`
                };
                const responseToken = await handleRequestCardAsyncToken(requestToken);
                history.push({
                    pathname: ECheckoutRoutePaths.CHECKOUT_LOGGED_USER_PLUS_PAYMENT,
                    state: {
                        token: responseToken.token
                    }
                });
            }
        } catch (error: any) {
            const serviceError = servicesErrorHandler(error);
            let TT = serviceError.meta?.TT;
            if (TT === undefined || TT === null) {
                const cancelTrxResponse = await cancelTRXandReturnTrade(
                    tpaContext.tokenInfo.tpa as string,
                    authContext.user?.te as string,
                    true
                );
                TT = cancelTrxResponse;
            }
            history.push({
                pathname: ECheckoutRoutePaths.CHECKOUT_LOGGED_USER_SUCCESS,
                state: {
                    success: false,
                    transaction: {
                        TT
                    }
                }
            });
        } finally {
            backdrop.closeBackdrop();
        }
    };
    /**
     * Handles subscribe card redirection
     * @return {Promise<void>}
     */
    const handleAddCard = async (): Promise<void> => {
        if (userCanAddCard) {
            try {
                const TEInfo = await getTEInformation(authContext.user?.te as string);
                if (TEInfo.isExpired) {
                    modalContext.updateModalExpired(EModalError.DEFAULT);
                    modalContext.setExpiredTE(true);
                    modalContext.openModal();
                } else {
                    history.push({
                        pathname: ECardRoutePaths.CARD_SELECT_TYPE
                    });
                }
            } catch (error) {
                history.replace(ENotAllowedRoutePaths.NOT_AUTHORIZED);
            }
        }
    };
    /**
     * Handles subscribe card redirection
     * @param {IIdCard} idCard card id
     * @return {Promise<void>}
     */
    const handleDeleteCard = async (idCard: IIdCard): Promise<void> => {
        try {
            const TEInfo = await getTEInformation(authContext.user?.te as string);
            if (TEInfo.isExpired) {
                modalContext.updateModalExpired(EModalError.DEFAULT);
                modalContext.setExpiredTE(true);
                modalContext.openModal();
            } else {
                backdrop.openBackdrop();
                const device: IDevice = getDataDevice();
                const deleteCardRequest: IDeleteCard = {
                    idCard: idCard.idCard,
                    TE: authContext.user?.te as string,
                    TPA: tpaContext.tokenInfo.tpa as string,
                    device
                };
                const deleteCardResponse = await subscriptionCancelService(deleteCardRequest);
                closeModals();
                setSelectedCardInfo({
                    subscriptionId: '',
                    idCard: '',
                    cardBrand: '',
                    cardMaskedNumber: '',
                    cardType: ''
                });
                setSelectedCard('');
                await getUserCards();
                const updateTE = deleteCardResponse.payload.TE.updatedTE;
                authContext.updateTE(updateTE);
                setModalValues({
                    deleteCardModal: false,
                    successDeleteModal: true
                });
            }
        } catch (error: any) {
            closeModals();
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    /**
     * Shows specific modal by name
     * @param {keyof IModalValuesState} modalName modal name
     * @return {void}
     */
    const showModal = (modalName: keyof IModalValuesState): void => {
        setModalValues({
            ...modalValues,
            [modalName]: true
        });
    };
    /**
     * Close all modals
     * @return {void}
     */
    const closeModals = (): void => {
        setModalValues({
            deleteCardModal: false,
            successDeleteModal: false
        });
    };
    /**
     * Clear error on timeout
     * @return {void}
     */
    const handleAlertTimeout = (): void => {
        setTimeout(() => {
            clearError();
        }, 6000);
    };
    /**
     * Show Alert error and set error values state
     * @param {string} title error message
     * @return {void}
     */
    const showError = (title: string): void => {
        setErrorValues({
            title: title,
            visible: true
        });
        handleAlertTimeout();
    };
    /**
     * Remove error
     * @return {void}
     */
    const clearError = () => {
        setErrorValues({
            title: '',
            visible: false
        });
    };
    if (isLoading) {
        return <></>;
    } else {
        return (
            <section className={classes.SelectCardPage}>
                <Grid container>
                    <Grid item xs={12} lg={12}>
                        <div className={classes.HyperLinkBox}>
                            <HyperLink
                                classNames={classes.HyperLink_GoBack}
                                underline="none"
                                onClick={getRedirectUrlTrade}>
                                <ChevronLeftIcon />
                                volver al comercio
                            </HyperLink>
                        </div>
                    </Grid>
                    <Grid container className={classes.MainGrid} spacing={2}>
                        <Grid item xs={12} md={7} lg={7}>
                            <ContentBox classNames={classes.ContentBox}>
                                <img
                                    className={classes.SabbiLogo}
                                    src={SabbiLogoSrc}
                                    alt="LogoSabbi"
                                />
                                <Typography
                                    className={classes.SelectCard_Text}
                                    component="p"
                                    gutterBottom>
                                    Puedes pagar directamente con esta tarjeta en una cuota o se
                                    usará para cobrar las cuotas Sabbi automáticamente de forma
                                    mensual.
                                </Typography>
                                <AlertBar
                                    classNames={classes.AlertBar}
                                    variant="filled"
                                    severity="error"
                                    message={errorValues.title}
                                    open={errorValues.visible}
                                    closeOnClick={() => clearError()}
                                    icon={<WarningRoundedIcon />}
                                />
                                <SelectedCard
                                    classNames={classes.SelectedCard}
                                    cardBrand={selectedCardInfo.cardBrand}
                                    cardMaskedNumber={selectedCardInfo.cardMaskedNumber}
                                    cardType={selectedCardInfo.cardType}
                                    captionText=""
                                />
                                <FormContext
                                    className={classes.Form}
                                    id={'SelectCardForm'}
                                    onSubmit={submitPaymentOptions}
                                    methods={methods}>
                                    <AccordionCollapse
                                        classNames={classes.AccordionCollapse}
                                        defaultExpanded={false}
                                        expandIcon={<ExpandMoreIcon />}
                                        accordionSummary={
                                            <>
                                                <div className={classes.HeadingIcon}>
                                                    <CreditCardIcon />
                                                </div>
                                                <Typography className={classes.HeadingText}>
                                                    Mis tarjetas
                                                </Typography>
                                            </>
                                        }>
                                        <CardRadioGroup
                                            classNames={classes.CardRadioGroup}
                                            id="cardInput"
                                            name="cardInput"
                                            defaultValue={selectedCard}
                                            radioButtons={userCards ? userCards : []}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Debe seleccionar una tarjeta'
                                                }
                                            }}
                                            onChange={setSelectedCard}
                                            deleteOnClick={handleDeleteCard}
                                        />
                                        <div className={classes.ButtonsDiv}>
                                            <SquareButton
                                                classNames={classes.DeleteCardButton}
                                                disabled={selectedCard ? false : true}
                                                onClick={() => showModal('deleteCardModal')}>
                                                Eliminar{' '}
                                                <span className={classes.BtnCardText}>
                                                    &nbsp;tarjeta
                                                </span>
                                                <DeleteIcon className={classes.DeleteIcon} />
                                            </SquareButton>
                                            <HtmlTooltip
                                                classNames={classes.ButtonTooltip}
                                                placement={'top'}
                                                size="sm"
                                                arrow={true}
                                                tooltipContent={
                                                    <Typography variant="body1" align="center">
                                                        Tienes el máximo de tarjetas agregadas.
                                                    </Typography>
                                                }
                                                disableTouchListener={userCanAddCard}
                                                disableFocusListener={userCanAddCard}
                                                disableHoverListener={userCanAddCard}>
                                                <div>
                                                    <SquareButton
                                                        disabled={!userCanAddCard}
                                                        classNames={classes.AddCardButton}
                                                        onClick={handleAddCard}>
                                                        Agregar{' '}
                                                        <span className={classes.BtnCardText}>
                                                            &nbsp;tarjeta
                                                        </span>
                                                        <AddCircleIcon
                                                            className={`${classes.AddIcon} ${
                                                                !userCanAddCard && 'disabled'
                                                            }`}
                                                        />
                                                    </SquareButton>
                                                </div>
                                            </HtmlTooltip>
                                        </div>
                                    </AccordionCollapse>
                                    <Typography
                                        className={classes.MaxCards}
                                        variant="caption"
                                        color={!userCanAddCard ? 'primary' : 'inherit'}
                                        gutterBottom>
                                        Máximo de tarjetas cargadas {userCards && userCards.length}
                                        /5
                                    </Typography>
                                    {userCards && userCards.length > 0 ? (
                                        <BNPLRadioGroup
                                            classNames={classes.BNPLRadioGroup}
                                            id="bnplInput"
                                            name="bnplInput"
                                            radioButtons={BNPLInfo}
                                            onChange={handleSetSelectedBNPL}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Debe seleccionar cuotas'
                                                }
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {bnplIsAllowed &&
                                    simulation.length === 0 &&
                                    BNPLInfo.length === 0 &&
                                    !alertBnpl ? (
                                        <div className={classes.BNPLLoader}>
                                            <img
                                                src={loader}
                                                alt="loading"
                                                className={classes.Loader}
                                            />
                                            <Typography component="p" gutterBottom>
                                                En segundos podrás elegir tus cuotas Sabbi.
                                            </Typography>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {alertBnpl ? (
                                        <RejectedBNPL rejectionReasons={bnplRejectionReasons} />
                                    ) : (
                                        <></>
                                    )}
                                    <div className={classes.ButtonsBox}>
                                        <RoundButton
                                            classNames={classes.SubmitButton}
                                            id={'submitbtn'}
                                            color="primary"
                                            name={'submitbtn'}
                                            type="submit">
                                            Continuar con el pago
                                        </RoundButton>
                                    </div>
                                    {businessContext.financialProducts.psp.webpayplus === true ? (
                                        <div
                                            className={`${classes.ButtonsBox} ${classes.WebPayPlus}`}>
                                            <RoundButton
                                                classNames={classes.GuestButton}
                                                id={'submitguest'}
                                                name={'submitguest'}
                                                type="button"
                                                onClick={handleWebPayPlusPayment}>
                                                Pagar con <img src={WebPayIconSrc} alt="WebPay" />
                                            </RoundButton>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </FormContext>
                            </ContentBox>
                        </Grid>

                        <Grid item xs={12} md={5} lg={5}>
                            {infoTransactionTpa && (
                                <TransactionDetail infoTransactionTpa={infoTransactionTpa} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <ModalDialog
                    id="ModalCancel"
                    open={modalValues.deleteCardModal}
                    handleCancel={closeModals}
                    handleAccept={() => handleDeleteCard({ idCard: selectedCardInfo.idCard })}
                    title={
                        <div className={classes.DeleteModalTitle}>
                            <img
                                className={classes.InfoIcon}
                                src={ModalErrorIconSrc}
                                alt="Error Icon"
                            />
                            <Typography component="h2">¿Deseas eliminar esta tarjeta?</Typography>
                            <SelectedCard
                                classNames={classes.ModalSelectedCard}
                                cardBrand={selectedCardInfo.cardBrand}
                                cardMaskedNumber={selectedCardInfo.cardMaskedNumber}
                                cardType={selectedCardInfo.cardType}
                                variant={ESelectedCardVariants.outline}
                            />
                        </div>
                    }
                    scroll={'paper'}
                    acceptText="Eliminar"
                    maxWidth={'sm'}>
                    <Typography component="p" className={classes.ModalDeleteCard_Text}>
                        {userCards?.length == 1
                            ? 'Recuerda que para continuar comprando deberás agregar una nueva tarjeta.'
                            : 'Puedes volver a agregarla cuando quieras.'}
                    </Typography>
                </ModalDialog>
                <ModalDialog
                    id="ModalSuccessDelete"
                    open={modalValues.successDeleteModal}
                    title={
                        <>
                            <img
                                className={classes.CheckIcon}
                                src={CheckIconSrc}
                                alt="Check Icon"
                            />
                            <Typography variant="h2" component="h2">
                                ¡Tarjeta eliminada con éxito!
                            </Typography>
                        </>
                    }
                    customFooter={
                        <RoundButton
                            onClick={closeModals}
                            classNames={classes.ModalDialogSubmitButton}
                            color="primary"
                            type="button">
                            Entendido
                        </RoundButton>
                    }
                    scroll={'paper'}
                    maxWidth={'sm'}>
                    <div>
                        <Typography component="p" className={classes.ModalDeleteCard_Text}>
                            Recuerda que para comprar,{' '}
                            <b>necesitas una tarjeta asociada donde cargar tus próximos pagos.</b>
                        </Typography>
                    </div>
                </ModalDialog>
            </section>
        );
    }
};

export default CardSelectPage;
