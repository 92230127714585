import { Container, Grid } from '@material-ui/core';
import { FC } from 'react';

import BasicFooter from '../../components/BasicFooter/BasicFooter';
import BasicHeader from '../../components/BasicHeader/BasicHeader';
import useStyles from './HeaderFooterStyles.material';
import IBasicLayoutProps from './interfaces/IBasicLayoutProps';

const HeaderFooterLayout: FC<IBasicLayoutProps> = ({ children, classNames = '' }) => {
    const classes = useStyles();
    return (
        <div className={`${classes.HeaderFooterLayout} ${classNames}`}>
            <BasicHeader />
            <Container>
                <Grid container>
                    <Grid className={classes.HeaderFooterLayout_Page} item xs={12} lg={12}>
                        {children}
                    </Grid>
                </Grid>
            </Container>
            <div className={classes.HeaderFooterLayout_Footer}>
                <BasicFooter />
            </div>
        </div>
    );
};

export default HeaderFooterLayout;
