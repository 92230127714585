export const CHECKOUT_ROUTE = '/checkout';

export enum ECheckoutRoutePaths {
    CHECKOUT_SUCCESS = '/checkout/success',
    CHECKOUT_PLUS_PAYMENT = '/checkout/plus-payment',
    CHECKOUT_LOGGED_USER_SUCCESS = '/checkout/checkout-success',
    CHECKOUT_LOGGED_USER_PLUS_PAYMENT = '/checkout/checkout-plus-payment',
    CHECKOUT_PLUS_PAYMENT_APP = '/checkout/app'
}

export const CHECKOUT_ROUTE_PATHS: ECheckoutRoutePaths[] = Object.values(ECheckoutRoutePaths);
