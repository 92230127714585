import Paper from '@material-ui/core/Paper';
import { FC } from 'react';

import useStyles from './ContentBoxStyles.material';
import IContentBoxProps from './interfaces/IContentBox';

const ContentBox: FC<IContentBoxProps> = ({ children, classNames = '', size = 'lg' }) => {
    const classes = useStyles();
    return (
        <Paper classes={classes} className={`${classNames} ${size}`}>
            {children}
        </Paper>
    );
};
export default ContentBox;
