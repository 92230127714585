import { Typography } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ContentBox from '../../components/ContentBox/ContentBox';
import SuccessMessage from '../../components/SuccessMessage/SuccessMessage';
import { useAuth } from '../../context/AuthContext/auth';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { EFlowIndicator } from '../../utils/enums/EFlowIndicator';
import { ICreatedPasswordLocationState } from './interfaces/ICreatedPasswordLocationState';
import useStyles from './PasswordCreatedPageStyles.material';

const PasswordCreatedPage: FC = () => {
    const [creationSuccess, setSuccess] = useState<boolean>(false);
    const location = useLocation<ICreatedPasswordLocationState>();
    const history = useHistory();
    const authContext = useAuth();
    const classes = useStyles();
    useEffect(() => {
        if (!validateLocationStateData(location.state)) {
            if (!authContext.user?.te) {
                history.replace(ELoginRoutePaths.LOGIN);
            } else {
                history.replace(ECardRoutePaths.CARD_SELECT);
            }
        } else {
            setSuccess(location.state.success);
        }
    }, []);
    const validateLocationStateData = (obj: ICreatedPasswordLocationState) => {
        if (obj) {
            return 'success' in obj && 'flow' in obj;
        }
        return false;
    };
    const handleContinue = () => {
        history.replace(ELoginRoutePaths.LOGIN);
    };
    const handleCancel = () => {
        history.replace(ELoginRoutePaths.LOGIN);
    };
    const renderSuccess = () => (
        <Typography className={classes.Message} component="p">
            Ahora podrás acceder con ella a tu cuenta de Sabbi.
        </Typography>
    );
    const renderError = () => (
        <Typography className={classes.Message} component="p">
            La contraseña no pudo ser creada, por favor vuelve <br /> a intentarlo más tarde.
        </Typography>
    );
    const titleMessage =
        location.state.flow == EFlowIndicator.RECOVER_PASSWORD
            ? '¡Se ha cambiado tu contraseña!'
            : '¡Felicidades tu contraseña está creada!';
    return (
        <section className={classes.CreatedPasswordPage}>
            <ContentBox>
                <SuccessMessage
                    success={creationSuccess}
                    title={creationSuccess ? titleMessage : 'Contraseña no creada'}
                    continueOnClick={handleContinue}
                    cancelOnClick={handleCancel}
                    continueText={'Entendido'}>
                    {creationSuccess ? renderSuccess() : renderError()}
                </SuccessMessage>
            </ContentBox>
        </section>
    );
};

export default PasswordCreatedPage;
