import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import useStyles from './CheckboxInputStyles.material';
import ICheckBoxInputProps from './interfaces/ICheckboxInputProps';

const CheckboxInput: FC<ICheckBoxInputProps> = ({
    id,
    name,
    classNames = '',
    label,
    defaultHelperText = '',
    rules,
    defaultValue = false,
    clickableLabel = true,
    onChangeCheck
}) => {
    const classes = useStyles();
    const { formState, control } = useFormContext();
    const errors = formState?.errors;

    const handleOnChange = (value: boolean) => {
        if (onChangeCheck) {
            onChangeCheck(!value);
        }
    };
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field }) => (
                <FormControl
                    component="fieldset"
                    classes={classes}
                    className={`${classNames} ${errors?.[name] && 'error'}`}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id={`${id}`}
                                checked={field.value}
                                onChange={(event) => {
                                    field.onChange(event);
                                    handleOnChange(field.value);
                                }}
                                name={`${name}`}
                                color="secondary"
                            />
                        }
                        label={clickableLabel && label}
                    />
                    {!clickableLabel && label}
                    <FormHelperText id={`helper-text-${id}`}>
                        {errors?.[name] ? errors?.[name].message : defaultHelperText}
                    </FormHelperText>
                </FormControl>
            )}
        />
    );
};

export default CheckboxInput;
