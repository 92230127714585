import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    ModalDialog: {
        '& .MuiPaper-root': {
            ['@media (max-width: 600px)']: {
                padding: '2rem'
            },
            ['@media (max-width: 400px)']: {
                padding: '1rem'
            },
            ['@media (max-width: 320px)']: {
                padding: '.8rem'
            }
        },
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '700px',
            padding: '2.4rem',
            borderRadius: '20px',
        }
    },
    DialogIcon: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      marginBottom: '1.4rem',
    },
    DialogTitle: {
        textAlign: 'center',
        fontFamily: 'ceraRoundProMedium',
        marginBottom: '1rem',
        fontSize: '1.2rem',
        color: theme.palette.grey[800]
    },
    DialogContent: {
        padding: '0 100px',
        color: theme.palette.grey[800],
        fontSize: '1rem',
        '@media (max-width: 768px)': {
            padding: '0 40px',
        },
        '@media (max-width: 500px)': {
            padding: '0 20px',
        },
        '@media (max-width: 320px)': {
            padding: '0 10px',
        }
    },
    Description: {
      fontSize: '.8rem',
      textAlign: 'center',
      marginBottom: '1rem'
    },
    BlockedUserDescription: {
      display: 'block',
      fontSize: '16px',
      marginTop: '30px',
      marginBottom: '60px'
    },
    DialogActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        '@media (max-width: 500px)': {
            flexDirection: 'column',
        },
    },
    UserBtn: {
      color: theme.palette.secondary.dark,
      display: 'flex',
      fontSize: '14px',
      margin: '0px auto 20px auto',
      textDecoration: 'underline',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.common.white
      }
    },
    UserName: {
      textTransform: 'capitalize',
    },
    CancelButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        color: theme.palette.grey[800],
        margin: '5px',
        height: '34px',
        width: '100%',
        '&:hover': {
            color: theme.palette.grey[800],
            backgroundColor: theme.palette.common.white
        }
    },
    RoundButton: {
        margin: '5px',
        height: '34px',
        width: '100%',
    },
    Form: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& .MuiFormControl-root': {
            maxWidth: '100%',
            marginBottom: '50px',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        '& .MuiFormControl-root:last-of-type': {
            marginBottom: '20px'
        }
    },
     AlertBar: {
        '&.opened': {
            marginBottom: '1rem'
        }
    }
}));

export default useStyles;
