import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    ModalTerms: {
        '& .MuiPaper-root': {
            padding: '2rem 3rem',
            borderRadius: '15px',
            maxWidth: '768px',
            ['@media (max-width: 550px)']: {
                padding: '2rem 2rem'
            },
            ['@media (max-width: 380px)']: {
                padding: '1rem'
            }
        }
    },
    DialogTitle: {
        paddingTop: '1rem',
        '& h2': {
            fontFamily: 'ceraRoundProMedium',
            fontSize: '1.3rem',
            color: theme.palette.grey[800]
        }
    },
    DialogContent: {
        padding: '0 50px',
        color: theme.palette.grey[800],
        fontSize: '1rem',
        '@media (max-width:500px)': {
            padding: 0
        }
    },
    DialogActions: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
        paddingRight: 0,
        ['@media (max-width: 550px)']: {
            padding: 0
        }
    },
    SabbiLogo: {
        width: '60px',
        alignSelf: 'flex-start',
        ['@media (max-width: 550px)']: {
            width: '50px',
            margin: 'auto'
        }
    },
    TitleWithLogo: {
        display: 'flex',
        alignItems: 'center',
        '& h3': {
            marginLeft: '1.2rem',
            ['@media (max-width: 550px)']: {
                marginTop: '1rem',
                textAlign: 'center',
                marginLeft: '0'
            }
        },
        ['@media (max-width: 550px)']: {
            flexWrap: 'wrap',
            flexDirection: 'column'
        }
    },
    RoundButton: {
        marginRight: 0,
        ['@media (max-width: 550px)']: {
            marginRight: '1rem'
        }
    },
    HyperLink: {
        alignItems: 'center',
        order: 1,
        '& .MuiTypography-root': {
            color: theme.palette.grey[600],
            display: 'flex',
            alignItems: 'center'
        },
        ['@media (max-width: 550px)']: {
            order: 0,
            justifyContent: 'center'
        }
    },
    HyperLinkIcon: {
        marginLeft: '5px'
    },
}));

export default useStyles;
