import { Grid, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import SecurityIconSvg from '../../assets/icons/security.svg';
import AlertBar from '../../components/AlertBar/AlertBar';
import ContentBox from '../../components/ContentBox/ContentBox';
import HyperLink from '../../components/HyperLink/HyperLink';
import SuccessMessage from '../../components/SuccessMessage/SuccessMessage';
import { ITransactionDetailInfo } from '../../components/TransactionDetail/interfaces/ITransactionDetailInfo';
import TransactionDetail from '../../components/TransactionDetail/TransactionDetail';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { EModalError } from '../../context/ModalContext/enums/EModalError';
import { useModalExpired } from '../../context/ModalContext/modalExpired';
import { useTpa } from '../../context/TPAContext/tpa';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { ENotAllowedRoutePaths } from '../../routes/enum/ENotAllowedRoutePaths';
import { cancelTRXandReturnTrade } from '../../utils/clientHelpers';
import { handleErrorMessage } from '../../utils/errors';
import { IErrorValues } from '../../utils/errors/interfaces/IErrorValues';
import {
    deleteLocalStorageAccessData,
    getTEInformation,
    getTPAInformation
} from '../../utils/localStorageManipulation';
import { ICreatedAccountLocationState } from './interfaces/ICreatedAccountLocationState';
import useStyles from './UserConfirmationPageStyles.material';

const UserConfirmationPage: FC = () => {
    const [creationSuccess, setSuccess] = useState<boolean>(true);
    const [isLoading, setIsloading] = useState<boolean>(true);
    const [errorValues, setErrorValues] = useState<IErrorValues>({
        title: '',
        visible: false
    });
    const [infoTransactionTpa, setInfoTransactionTpaObject] = useState<ITransactionDetailInfo>();
    const location = useLocation<ICreatedAccountLocationState>();
    const history = useHistory();
    const classes = useStyles();
    const tpaContext = useTpa();
    const authContext = useAuth();
    const backdrop = useBackdrop();
    const modalContext = useModalExpired();
    const { handleErrorRedirection, shouldRedirect } = useRedirection();

    useEffect(() => {
        if (!validateLocationStateData(location.state)) {
            if (!authContext.user?.te) {
                history.replace(ELoginRoutePaths.LOGIN);
            } else {
                history.replace(ECardRoutePaths.CARD_SELECT);
            }
        } else {
            if (authContext.user?.te) {
                modalContext.checkTeExpiration();
            } else {
                setSuccess(location.state.success);
                setIsloading(false);
            }
        }
    }, []);

    useEffect(() => {
        if (!modalContext.isExpired) {
            setSuccess(location.state.success);
            if (location.state.success) {
                obtainTpaInfo();
            }
            setIsloading(false);
        }
    }, [modalContext.isExpired]);

    /**
     * Gets TPA Information
     * @return {Promise<void>}
     */
    const obtainTpaInfo = async (): Promise<void> => {
        try {
            const infoTPA = await getTPAInformation(tpaContext.tokenInfo.tpa);
            const infoTPAFormated: ITransactionDetailInfo = {
                businessPartnerLogo: infoTPA?.bodyTPA.businessPartner?.businessPartnerLogo,
                businessPartnerName: infoTPA?.bodyTPA.businessPartner?.businessPartnerName,
                productList: infoTPA?.bodyTPA.transactionDetails?.orderDetails.productList,
                total: infoTPA?.bodyTPA.transactionDetails?.orderDetails.amount.total,
                transactionId: infoTPA?.bodyTPA.transactionDetails?.orderDetails.transactionId,
                purchaseId: infoTPA?.bodyTPA.metadata?.trxPmi as string
            };
            setInfoTransactionTpaObject(infoTPAFormated);
        } catch (error) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        }
    };

    /**
     * Validates location.state data object
     * @param {ICreatedAccountLocationState} obj location.state object
     * @return {boolean} valid/invalid location state object
     */
    const validateLocationStateData = (obj: ICreatedAccountLocationState): boolean => {
        if (obj) {
            return 'success' in obj;
        }
        return false;
    };

    /**
     * Cancel transaction and return to businessPartner URL
     * @return {Promise<void>}
     */
    const getRedirectUrlTrade = async (): Promise<void> => {
        backdrop.openBackdrop();
        try {
            await cancelTRXandReturnTrade(
                tpaContext.tokenInfo.tpa as string,
                authContext.user?.te as string
            );
        } catch (error) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                showError(handleErrorMessage(error));
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };

    /**
     * Handle Continue button redirection
     * @return {Promise<void>}
     */
    const handleContinue = async (): Promise<void> => {
        try {
            const TEInfo = await getTEInformation(authContext.user?.te as string);
            if (TEInfo.isExpired) {
                modalContext.updateModalExpired(EModalError.DEFAULT);
                modalContext.setExpiredTE(true);
                modalContext.openModal();
            } else {
                history.push(ECardRoutePaths.CARD_SELECT_TYPE);
            }
        } catch (error) {
            history.replace(ENotAllowedRoutePaths.NOT_AUTHORIZED);
        }
    };
    /**
     * Handle cancel button redirection
     * @return {Promise<void>}
     */
    const handleCancel = async (): Promise<void> => {
        getRedirectUrlTrade();
        deleteLocalStorageAccessData();
    };
    /**
     * Renders success confirmation page
     * @return {ReactNode}
     */
    const renderSuccess = (): ReactNode => (
        <>
            <Typography className={classes.Message} component="p">
                Para comenzar a disfrutar de los beneficios de pago en cuotas con Sabbi, es
                necesario agregar una tarjeta para activar tu cuenta{' '}
                <span role="img" aria-label="sprinkles">
                    ✨
                </span>
            </Typography>
            <div className={classes.DisclaimerTitleBox}>
                <img className={classes.SecIcon} src={SecurityIconSvg} alt="SecurityIconSvg" />
                <Typography component="p" gutterBottom>
                    Por tu seguridad, realizaremos
                    <b> uno o dos cargos a tu tarjeta, </b> que
                    <b> serán devueltos de forma automática.</b>
                </Typography>
            </div>
        </>
    );
    /**
     * Renders error confirmation page
     * @return {ReactNode}
     */
    const renderError = (): ReactNode => (
        <Typography className={classes.Message} component="p">
            La cuenta no pudo ser creada, por favor vuelve <br /> a intentarlo más tarde.
        </Typography>
    );
    /**
     * Renders success title
     * @return {ReactNode}
     */
    const renderTitleSuccess = (): ReactNode => (
        <div className={classes.Title}>
            ¡Registra tu tarjeta!{' '}
            <span role="img" aria-label="nerd_face">
                🤓
            </span>
        </div>
    );
    /**
     * Renders error title
     * @return {ReactNode}
     */
    const renderTitleError = (): ReactNode => (
        <div className={classes.Title}>
            Error al crear cuenta <CloseIcon className={classes.ErrorIcon} />
        </div>
    );
    const handleAlertTimeout = () => {
        setTimeout(() => {
            clearError();
        }, 6000);
    };
    const showError = (title: string) => {
        setErrorValues({
            title: title,
            visible: true
        });
        handleAlertTimeout();
    };
    const clearError = () => {
        setErrorValues({
            title: '',
            visible: false
        });
    };
    if (isLoading) {
        return <></>;
    } else {
        return (
            <section className={classes.CreatedAccountPage}>
                <Grid container>
                    {creationSuccess && (
                        <Grid item xs={12} lg={12}>
                            <div className={classes.HyperLinkBox}>
                                <HyperLink
                                    classNames={classes.HyperLink_GoBack}
                                    underline="none"
                                    onClick={getRedirectUrlTrade}>
                                    <ChevronLeftIcon />
                                    volver al comercio
                                </HyperLink>
                            </div>
                        </Grid>
                    )}
                    <Grid container className={classes.MainGrid} spacing={2}>
                        <Grid item xs={12} md={7} lg={7}>
                            <ContentBox>
                                <AlertBar
                                    classNames={classes.AlertBar}
                                    variant="filled"
                                    severity="error"
                                    message={errorValues.title}
                                    open={errorValues.visible}
                                    closeOnClick={() => clearError()}
                                    icon={<WarningRoundedIcon />}
                                />
                                <SuccessMessage
                                    success={creationSuccess}
                                    showSuccessIcon={false}
                                    title={
                                        creationSuccess ? renderTitleSuccess() : renderTitleError()
                                    }
                                    continueOnClick={handleContinue}
                                    cancelOnClick={handleCancel}
                                    cancelText={creationSuccess ? 'Cancelar' : 'Volver al comercio'}
                                    continueText={creationSuccess ? 'Agregar tarjeta' : ''}>
                                    {creationSuccess ? renderSuccess() : renderError()}
                                </SuccessMessage>
                            </ContentBox>
                        </Grid>

                        <Grid item xs={12} md={5} lg={5}>
                            {infoTransactionTpa && (
                                <TransactionDetail infoTransactionTpa={infoTransactionTpa} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        );
    }
};

export default UserConfirmationPage;
