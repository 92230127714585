import { CircularProgress, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ContentBox from '../../components/ContentBox/ContentBox';
import { ENotAllowedRoutePaths } from '../../routes/enum/ENotAllowedRoutePaths';
import useStyles from './CheckoutPlusPaymentAppPageStyles.material';

const CheckoutPlusPaymentAppPage = () => {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();

    const useQuery = () => {
        return new URLSearchParams(location.search);
    };
    const query = useQuery();

    useEffect(() => {
        if (!query.get('token') || !query.get('requestID')) {
            history.replace(ENotAllowedRoutePaths.NOT_AUTHORIZED);
        }
    }, []);

    return (
        <section className={classes.CheckoutPlusPaymentAppPage}>
            <ContentBox>
                <Typography variant="h2" component="h2" align="center">
                    Estamos procesando tu pago
                </Typography>
                <CircularProgress className={classes.CircularProgress} color="primary" />
            </ContentBox>
        </section>
    );
};

export default CheckoutPlusPaymentAppPage;
