import { ClickAwayListener } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { FC, useState } from 'react';

import useStyles from './HtmlTooltipStyles.material';
import IHtmlTooltipProps from './interfaces/IHtmlTooltipProps';

const HtmlTooltip: FC<IHtmlTooltipProps> = ({
    tooltipContent,
    children,
    arrow,
    placement,
    disableTouchListener = false,
    disableFocusListener = false,
    disableHoverListener = false,
    size = 'sm',
    enterDelay = 0,
    open,
    classNames = ''
}) => {
    const classes = useStyles();

    return (
        <div className={`${classes.HtmlTooltip} ${size} ${classNames}`}>
            <Tooltip
                arrow={arrow}
                enterDelay={enterDelay}
                PopperProps={{ disablePortal: true }}
                placement={placement}
                classes={{ tooltip: classes.tooltip }}
                title={tooltipContent}
                disableTouchListener={disableTouchListener}
                disableFocusListener={disableFocusListener}
                disableHoverListener={disableHoverListener}
                open={open}>
                {children}
            </Tooltip>
        </div>
    );
};

export const ClickableTooltip: FC<IHtmlTooltipProps> = ({
    tooltipContent,
    children,
    arrow,
    placement,
    enterDelay = 0,
    size = 'sm',
    divTabIndex = 0
}) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <div className={`${classes.HtmlTooltip} ${size}`}>
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    onClose={handleTooltipClose}
                    open={open}
                    enterDelay={enterDelay}
                    arrow={arrow}
                    PopperProps={{ disablePortal: true }}
                    placement={placement}
                    classes={{ tooltip: classes.tooltip }}
                    disableTouchListener
                    disableFocusListener
                    disableHoverListener
                    title={tooltipContent}>
                    <div
                        onClick={handleTooltipOpen}
                        onKeyPress={handleTooltipOpen}
                        role="button"
                        tabIndex={divTabIndex}>
                        {children}
                    </div>
                </Tooltip>
            </ClickAwayListener>
        </div>
    );
};

export default HtmlTooltip;
