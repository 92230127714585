import { makeStyles } from '@material-ui/core/styles';
import COLORS from '../../themes/colors';

const useStyles = makeStyles((theme) => ({
    MaskedUserPhone: {
        display: 'flex',
        height: '40px',
        width: '190px',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.main,
        borderRadius: '100px',
        backgroundColor: COLORS.cyanLight
    },
    Dots: {
        fontSize: '3.5rem',
        letterSpacing: '7px',
        lineHeight: '10px'
    },
    Icon: {
        color: theme.palette.primary.main
    },
    Phone: {
        fontSize: '1rem',
        fontFamily: 'ceraRoundProMedium'
    }
}));

export default useStyles;
