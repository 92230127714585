import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    CheckoutPlusPaymentAppPage: {
        maxWidth: '100%',
        '& h2': {
            textAlign: 'center',
            marginBottom: '30px'
        }
    },
    CircularProgress: {
      margin: '20px auto',
      display: 'flex'
    }
}));

export default useStyles;
