import { SABBI_FRONT_ERRORS } from './errors/enums/EAppErrors';
import { FrontApplicationError } from './errors/FrontApplicationError';

/**
 * Delete special characters from rut
 * @param {string} rut rut number with . and -
 * @returns {string} String with erased characters
 */
export const clean = (rut: string | undefined): string => {
    return typeof rut === 'string' ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase() : '';
};

/**
 * Validate proper RUT structure
 * @param {string} rut rut number with . and -
 * @returns {boolean} returns true if RUT is valid
 */
export const validate = (rut: string): boolean => {
    if (typeof rut !== 'string') {
        return false;
    }

    // if it starts with 0 we return false
    // so a rut like 00000000-0 will not pass
    if (/^0+/.test(rut)) {
        return false;
    }

    if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
        return false;
    }

    rut = clean(rut);

    let t = parseInt(rut.slice(0, -1), 10);
    let m = 0;
    let s = 1;

    while (t > 0) {
        s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
        t = Math.floor(t / 10);
    }

    const v = s > 0 ? '' + (s - 1) : 'K';
    return v === rut.slice(-1);
};

/**
 * Masks RUT number with thousand separator and -
 * @param {string} rut rut number
 * @returns {string} returns Rut number with . and -
 */
export const formatRut = (rut: string | undefined) => {
    rut = clean(rut);

    let result = rut.slice(-4, -1) + '-' + rut.substr(rut.length - 1);
    for (let i = 4; i < rut.length; i += 3) {
        result = rut.slice(-3 - i, -i) + '.' + result;
    }
    if (result == '-') {
        result = '';
    }
    return result;
};

/**
 * Calculates verificator last digit from given RUT
 * @param {string} input rut number
 * @returns {string} returns verificator last digit
 */
export const getCheckDigit = (input: string) => {
    const rut = Array.from(clean(input), Number);

    if (rut.length === 0 || rut.includes(NaN)) {
        throw new FrontApplicationError(SABBI_FRONT_ERRORS.SABBI_F_08);
    }

    const modulus = 11;
    const initialValue = 0;
    const sumResult = rut
        .reverse()
        .reduce(
            (accumulator, currentValue, index) => accumulator + currentValue * ((index % 6) + 2),
            initialValue
        );

    const checkDigit = modulus - (sumResult % modulus);

    if (checkDigit === 10) {
        return 'K';
    } else if (checkDigit === 11) {
        return '0';
    } else {
        return checkDigit.toString();
    }
};
