import { KUSHKI_ERRORS, SABBI_FRONT_ERRORS } from './enums/EAppErrors';
import { EGeneralErrosMessages } from './enums/EGeneralErrosMessages';
import { ENameError } from './enums/ENameError';
import { SERVICE_ERRORS, SOTER_ERRORS } from './enums/EServiceErrors';
import { SERVICE_ERRORS_CUSTOM } from './enums/EServiceErrorsCustom';
import { IApplicationError } from './interfaces/IApplicationError';
import { IHandleError } from './interfaces/IHandleError';
import { IParsedError } from './interfaces/IParsedError';

/**
 * Handle the error to return a message and display it on the screen
 * @param {IHandleError} error
 * @return {string}
 */
export const handleErrorMessage = (error: IHandleError): string => {
    switch (error.name) {
        case ENameError.APP_ERROR:
            return getErrorMessageAPP(error.code);
        case ENameError.SERVICE_ERROR:
            return getErrorMessageService(error.code, error.message);
        default:
            return EGeneralErrosMessages.UNKNOWN_ERROR;
    }
};

/**
 * ErrorHelper return a parsed error Service Checkout
 * @param {IApplicationError} error
 * @return {IParsedError}
 */
export const servicesErrorHandler = (error: IApplicationError): IParsedError => {
    const { code, message, meta } = error;
    const parsedError: IParsedError = {
        name: ENameError.SERVICE_ERROR,
        code: code,
        message: message,
        meta: {
            TT: meta?.TT,
            retryAttempts: meta?.retryAttempts
        }
    };
    return parsedError;
};

const getErrorMessageAPP = (errorCode: string): string => {
    if (SABBI_FRONT_ERRORS[errorCode]) {
        return SABBI_FRONT_ERRORS[errorCode].messageHandle;
    }
    if (KUSHKI_ERRORS[errorCode]) {
        return KUSHKI_ERRORS[errorCode].messageHandle;
    }
    return EGeneralErrosMessages.UNKNOWN_ERROR;
};

const getErrorMessageService = (errorCode: string, errorMessage: string): string => {
    if (SERVICE_ERRORS[errorCode]) {
        return SERVICE_ERRORS[errorCode].messageHandle;
    } else if (SERVICE_ERRORS_CUSTOM[errorMessage]) {
        return SERVICE_ERRORS_CUSTOM[errorMessage].messageHandle;
    } else if (SOTER_ERRORS[errorCode]) {
        return SOTER_ERRORS[errorCode].messageHandle;
    } else {
        return EGeneralErrosMessages.UNKNOWN_ERROR;
    }
};
