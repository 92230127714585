import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    tooltip: {
        '& .MuiTooltip-arrow': {
          borderColor: theme.palette.common.black,
          color: theme.palette.common.black,
        },
        backgroundColor: theme.palette.common.black,
        borderRadius: '20px',
        padding: '.9rem'
    },
    HtmlTooltip: {
        '&.xs .MuiTooltip-tooltip': {
            width: '120px'
        },
        '&.sm .MuiTooltip-tooltip': {
            width: '220px'
        },
        '&.md .MuiTooltip-tooltip': {
            width: '330px'
        },
        '&.lg .MuiTooltip-tooltip': {
            width: '450px'
        },
        '&.xl .MuiTooltip-tooltip': {
            width: '550px'
        }
    }
}));

export default useStyles;
