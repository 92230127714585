import { makeStyles } from '@material-ui/core/styles';
import theme from '../../themes/lightTheme';

const useStyles = makeStyles((_theme) => ({
    MainGrid: {
        ['@media (max-width:959px)']: {
            flexFlow: 'column-reverse'
        }
    },
    Title: {
        fontSize: '1.4rem',
        fontFamily: 'CeraRoundProBold',
        display: 'flex',
        alignItems: 'center'
    },
    CreatedAccountPage: {
        maxWidth: '100%'
    },
    AlertBar: {
        '&.opened': {
            marginBottom: '1rem'
        }
    },
    Message: {
        textAlign: 'center'
    },
    LogoImage: {
        maxWidth: '100%',
        marginBottom: '1,9rem',
        width: '60px',
        margin: '0 auto 30px auto',
        display: 'flex'
    },
    HyperLinkBox: {
        maxWidth: '100%',
        marginBottom: '10px',
        ['@media (max-width:959px)']: {
            width: '650px',
            margin: 'auto',
            marginBottom: '10px'
        }
    },
    HyperLink_GoBack: {
        marginBottom: '.8rem',
        '& a': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    RoundButton: {
        display: 'flex',
        textTransform: 'none'
    },
    CancelButton: {
        color: theme.palette.grey[800],
        display: 'flex',
        width: '185px',
        textTransform: 'none',
        margin: '1rem'
    },
    DisclaimerTitleBox: {
        display: 'flex',
        margin: '2rem auto',
        maxWidth: '100%',
        width: '470px',
        '& p': {
          fontSize: '.9rem'
        }
    },
    SecIcon: {
        marginRight: '.7rem',
        paddingBottom: '.625rem'
    },
    SabbiLogo: {
        width: '60px',
        height: '60px',
        display: 'flex',
        alignSelf: 'center',
        margin: '0 auto 1.9rem auto'
    },
    ErrorIcon: {
      marginLeft: '5px',
      color: theme.palette.error.main
    }
}));

export default useStyles;
