import {
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { limitMaxLength, onlyNumber } from '../../../utils/inputHelpers';
import HyperLink from '../../HyperLink/HyperLink';
import ITextInputProps from './interfaces/ITextInputProps';
import useStyles from './TextInputStyles.material';

const TextInput: FC<ITextInputProps> = ({
    autoComplete = 'off',
    classNames = '',
    defaultHelperText,
    defaultValue = '',
    disabled = false,
    hasHyperLink = false,
    hyperLinkText,
    icon,
    id,
    label,
    maxLength,
    name,
    onClickHyperLink,
    placeholder = '',
    rules,
    type = 'text'
}) => {
    const [touched, setTouched] = useState(false);
    const classes = useStyles();
    const { formState, control } = useFormContext();
    const errors = formState?.errors;
    const checkError = (value: unknown) => {
        if (errors?.[name]) {
            return 'error';
        } else if (touched || value != '') {
            return 'success';
        }
    };
    const handleRenderIcon = (value: unknown) => {
        if (touched) {
            if (errors?.[name]) {
                return <ClearIcon />;
            } else {
                return <CheckCircleIcon />;
            }
        } else {
            if (errors?.[name]) {
                return <ClearIcon />;
            } else if (value !== '') {
                return <CheckCircleIcon />;
            } else {
                return icon;
            }
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field: { onChange, onBlur, value } }) => (
                <FormControl
                    size="small"
                    classes={{ root: classes.root }}
                    className={`${classNames} ${checkError(value)}`}
                    variant="outlined">
                    {label && (
                        <InputLabel className={classes.label} htmlFor={`${id}`}>
                            {label}
                        </InputLabel>
                    )}
                    <OutlinedInput
                        autoComplete={autoComplete}
                        id={`${id}`}
                        className={classes.label}
                        type={type}
                        label={label}
                        disabled={disabled}
                        placeholder={`${placeholder}`}
                        value={value}
                        inputProps={{
                            maxLength: maxLength
                        }}
                        onChange={(value) => {
                            let newValue = '';
                            if (type == 'number') {
                                newValue = onlyNumber(value);
                            }
                            const textValue = limitMaxLength(
                                type == 'number' ? newValue : value.target.value,
                                maxLength
                            );
                            textValue === '' ? setTouched(false) : setTouched(true);
                            onChange(textValue);
                        }}
                        onBlur={onBlur}
                        endAdornment={
                            <InputAdornment position="end">
                                {handleRenderIcon(value)}
                            </InputAdornment>
                        }
                    />
                    <div className={classes.HelperSection}>
                        <FormHelperText id={`helper-text-${id}`}>
                            {errors?.[name] ? errors?.[name].message : defaultHelperText}
                        </FormHelperText>
                        {hasHyperLink && (
                            <HyperLink
                                classNames={classes.HyperLink}
                                color="initial"
                                onClick={onClickHyperLink}>
                                {hyperLinkText}
                            </HyperLink>
                        )}
                    </div>
                </FormControl>
            )}
        />
    );
};

export default TextInput;
